import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';

const path = process.env.REACT_APP_ASSETS_PATH;

export const MultiSplitHead = (props) => {
  const { productName, pageContent } = props;

  return (
    <div className="product-page__head__multi-split__outer-wrap">
      <div className="product-page__head__multi-split__inner-wrap">
        <MediaQuery maxWidth={MOBILE_WIDTH}>
          <div className="product-page__head__bg _bottom" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.webp)`}} />
        </MediaQuery>
        <MediaQuery minWidth={MOBILE_WIDTH_1}>
          <div className="product-page__head__bg _bottom" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.webp)`}} />
        </MediaQuery>
        <div className="product-page__head-wrap _multi-split">
          <h1 className="product-page__head-title _multi-split">Мульти-сплит <span className="m-br"/>системы</h1>
          <MediaQuery maxWidth={MOBILE_WIDTH}>
            <img className="product-page__head__schema" src={`${path}images/products/${productName}/schema-m.webp`} />
          </MediaQuery>
          <MediaQuery minWidth={MOBILE_WIDTH_1}>
            <img className="product-page__head__schema" src={`${path}images/products/${productName}/schema-d.webp`} />
          </MediaQuery>
        </div>
      </div>
    </div>
  )
}

export const MultiSplitContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section inside-id">
        <div className="wrap">
          <SectionHeader
            title={<>Внутренние блоки</>}
            text={<>Изящный дизайн для&nbsp;любого интерьера</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="medium-l"
              wrapClass="bottom-left"
              titleClass="large"
              picName="202"
              title={<>Серия Velato</>}
              text={<>Дыхание природы.</>}
              detailTitle={<>Серия Velato</>}
              detailText={<>Бытовые кондиционеры Casarte серии Velato отличает простой и&nbsp;элегантный дизайн, сочетающий в&nbsp;себе строгость классики и&nbsp;свободу модерна. HEPA-фильтр осуществляет пятиступенчатую очистку приточного воздуха, насыщенного кислородом. Мельчайшие частицы загрязнений нейтрализует электростатический IFD-фильтр. Функция ароматизации помещения позволяет наполнить пространство вашим любимым запахом.</>}
              picFormat="webp"
            />
            <div className="product-page__grid-item medium-s">
              <div className="product-page__grid-extra-item">
                <ExtraItemPic
                  assetsPathName={assetsPathName}
                  picName="202-2"
                  className="_medium"
                />
                <ExtraItemPic
                  assetsPathName={assetsPathName}
                  picName="202-3"
                  className="_small"
                />
              </div>
            </div>
            <MediaQuery minWidth={MOBILE_WIDTH_1}>
              <div className="product-page__grid-item medium-s">
                <div className="product-page__grid-extra-item">
                  <ExtraItemPic
                    assetsPathName={assetsPathName}
                    picName="203-2"
                    className="_large"
                  />
                </div>
              </div>
            </MediaQuery>
            <Plane
              assetsPathName={assetsPathName}
              itemClass="medium-l"
              wrapClass="bottom-left"
              titleClass="large"
              picName="203"
              title={<>Серия Eletto</>}
              text={<>Созданы удивлять.</>}
              detailTitle={<>Серия Eletto</>}
              detailText={<>Кондиционеры серии Eletto - это&nbsp;сдержанные классические формы, четко очерченные грани, простой и&nbsp;элегантный дизайн. Встроенная УФ-лампа уничтожает вирусы и&nbsp;бактерии в&nbsp;воздухе, а&nbsp;встроенный экодатчик позволяет осуществлять интеллектуальное управление воздушным потоком: направить его как&nbsp;на&nbsp;пользователя, так и&nbsp;в&nbsp;сторону от&nbsp;него.</>}
              picFormat="webp"
            />
            <MediaQuery maxWidth={MOBILE_WIDTH}>
              <div className="product-page__grid-item medium-s">
                <div className="product-page__grid-extra-item">
                  <ExtraItemPic
                    assetsPathName={assetsPathName}
                    picName="203-2"
                    className="_large"
                  />
                </div>
              </div>
            </MediaQuery>
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="204"
              title={<>Низконапорный <span className="d-br"/>канальный кондиционер</>}
              text={<>Компактное и&nbsp;технологичное решение.</>}
              detailTitle={<>Низконапорный канальный кондиционер</>}
              detailText={<>Тонкий корпус низконапорных канальных кондиционеров Casarte (высота блока всего 248&nbsp;мм) позволяет значительно расширить возможности монтажа. 3D-жалюзи могут направлять поток теплого воздуха вниз в&nbsp;режиме обогрева для&nbsp;создания дополнительного комфорта.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="205"
              title={<>Средненапорный <span className="d-br"/>канальный кондиционер</>}
              text={<>Комфортный микроклимат, скрытый от&nbsp;глаз.</>}
              detailTitle={<>Средненапорный канальный кондиционер</>}
              detailText={<>Благодаря возможности регулировки статического давления от&nbsp;25&nbsp;до&nbsp;150&nbsp;Па устройство станет незаменимой частью системы кондиционирования дома. Внутренний блок устанавливается в&nbsp;нишу, отверстие закрывается интерьерной вентиляционной решёткой. Встроенная УФ-лампа нейтрализует бактерии и&nbsp;вирусы в&nbsp;воздухе, когда он&nbsp;циркулирует через внутренний блок.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="206"
              title={<>Однопоточный <span className="d-br"/>кассетный кондиционер</>}
              text={<>Элегантное решение для&nbsp;потолочного монтажа.</>}
              detailTitle={<>Однопоточный кассетный кондиционер</>}
              detailText={<>Эффективное решение для монтажа в&nbsp;потолочные конструкции. Увеличенная длина воздушного потока обеспечивает комфортное кондиционирование всего пространства. Декоративная панель идеально впишется в&nbsp;современный интерьер.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <div className="product-page__section outside-id">
        <div className="wrap">
          <SectionHeader
            title={<>Наружные блоки</>}
            text={<>Один наружный блок для&nbsp;всех помещений</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="207"
              title={<>Широкий ассортимент <span className="d-br"/>внешних блоков</>} 
              text={<>Технологичные решения для&nbsp;работы круглый год.</>}
              detailTitle={<>Широкий ассортимент внешних блоков</>}
              detailText={<>Наружные блоки широкого диапазона мощностей оснащены компрессорами с&nbsp;инверторным управлением, работают на&nbsp;озонобезопасном хладагенте R32, как летом при&nbsp;наружной температуре до&nbsp;+43°C, так и&nbsp;зимой при&nbsp;наружной температуре до&nbsp;-25°C. Корпуса блоков покрыты специальной краской оттенка графит, которая защищает их от&nbsp;воздействия коррозии и&nbsp;не&nbsp;выцветает со&nbsp;временем.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <div className="product-page__section control-id">
        <div className="wrap">
          <SectionHeader
            title={<>Удобное управление</>}
            text={<>Интуитивно понятный интерфейс для&nbsp;комфортного использования</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="208"
              title={<>Пульт дистанционного <span className="d-br"/>управления</>}
              text={<>Технологии нового поколения для&nbsp;удобства управления и&nbsp;контроля.</>}
              detailTitle={<>Пульт дистанционного управления</>}
              detailText={<>Эргономичный пульт дистанционного управления в&nbsp;чёрном цвете имеет понятный интерфейс и&nbsp;позволяет управлять всеми функциями и&nbsp;режимами устройства.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <EvoSection
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={true}
        picFormat="webp"
        sectionText={<>Мониторинг и&nbsp;управление с&nbsp;помощью мобильного приложения и&nbsp;голосовых помощников</>}
        planeText={<>Выбор режима работы, настройка скорости вентилятора <span className="d-br"/>и&nbsp;управление температурой на&nbsp;вашем смартфоне.</>}
      />
    </>
  )
}

const ExtraItemPic = (props) => {
  const { assetsPathName, className, picName, picFormat='webp' } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className={`product-page__grid-extra-item__pic ${className}`} style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}-m.${picFormat})`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className={`product-page__grid-extra-item__pic ${className}`} style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}.${picFormat})`}} />
      </MediaQuery>
    </>
  )
}