import React, {useEffect, useState, useRef} from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';
import Swiper from "react-id-swiper";

const path = process.env.REACT_APP_ASSETS_PATH;

export const StylerCasarteHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.webp)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.webp)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Паровой шкаф <br />для&nbsp;ухода <br />за&nbsp;одеждой</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        <p className="product-page__head__center__text">Идеальный уход за любимыми вещами</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const StylerCasarteContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>Удобство</>}
            text={<>Эргономичный дизайн, удобный интерфейс, настройка индивидуальных параметров</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="201"
              title={<>Вместимость до&nbsp;5&nbsp;вещей</>}
              text={<>4&nbsp;вешалки для&nbsp;одежды и&nbsp;1&nbsp;для&nbsp;брюк.</>}
              detailTitle={<>Вместимость до&nbsp;5&nbsp;вещей</>}
              detailText={<>Одновременная обработка 5 предметов одежды на вешалках позволяет эффективно распределять время: за цикл можно подготовить полный образ на одного человека или предметы одежды для нескольких членов семьи.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small _dark-text"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="202"
              title={<>Удобная полка</>}
              text={<>Для небольших предметов одежды, аксессуаров и&nbsp;игрушек.</>}
              detailTitle={<>Удобная полка</>}
              detailText={<>Съемная полка предназначена для деликатного ухода за предметами одежды и вещами, такими как головные уборы, шелковые платки, мягкие игрушки и др.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="203"
              title={<>Съемный резервуар для&nbsp;воды</>}
              text={<>Комфортная эксплуатация и&nbsp;легкость в&nbsp;уходе.</>}
              detailTitle={<>Съемный резервуар для&nbsp;воды</>}
              detailText={<>Для паровой обработки вещей требуется вода. Паровой шкаф оснащен специальным резервуаром, который наполняется чистой дистиллированной водой. Специальные индикаторы оповестят о необходимости добавления воды в резервуар в случае его опустошения.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="204"
              title={<>Настройка <br/>индивидуальных <br/>параметров</>}
              text={<>Отложенный старт, изменение количества вещей <span className="d-br"/>и дополнительные опции ухода.</>}
              detailTitle={<>Настройка индивидуальных параметров</>}
              detailText={<>Персонализация настроек ухода за одеждой и вещами: настройка времени завершения цикла, регулирование количества загруженных вещей для изменения длительности цикла, активация дополнительной защиты от бактерий или режима деликатного ухода для особо требовательных тканей.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <div className="product-page__section technology-id">
        <div className="wrap">
          <SectionHeader
            title={<>Технологии</>}
            text={<>Современные технологии для&nbsp;деликатного ухода</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="205"
              title={<>Тепловой насос</>}
              text={<>Бережная и&nbsp;энергоэффективная сушка.</>}
              detailTitle={<>Тепловой насос</>}
              detailText={<>Экологичный способ сушки с применением теплового насоса не только позволяет ухаживать за вещами с высокой энергоэффективностью, но и делать это на низких температурах.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="206"
              title={<>Аромакапсулы</>}
              text={<>Утонченный аромат на&nbsp;основе эфирных масел пропитает волокна ткани и&nbsp;подарит новые ощущения.</>}
              detailTitle={<>Аромакапсулы</>}
              detailText={<>Усилить эффект обработки одежды можно при помощи аромакапсул, которые помещаются в специальную ячейку в нижней части парового шкафа. Нежный букет из тонких ноток пеларгонии, ванили, лаванды, лимона и лемонграсса мягко проникнет в структуру ткани и придаст одежде деликатный и легкий аромат. Аромакапсула идет в комплекте.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="207"
              title={<>Сенсорная панель управления</>}
              text={<>Цветной отзывчивый к&nbsp;нажатию дисплей.</>}
              detailTitle={<>Сенсорная панель управления</>}
              detailText={<>Полностью сенсорная панель управления с цветными дисплеем, чувствительным к нажатию, с защитой от отпечатков пальцев.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <div className="product-page__section functionality-id">
        <div className="wrap">
          <SectionHeader
            title={<>Функциональность</>}
            text={<>Четыре программы «Освежение», «Гигиена», «Уход» и&nbsp;«Сушка» с&nbsp;различными режимами.</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="208"
              title={<>Освежение</>}
              text={<>Удаление неприятных запахов и&nbsp;быстрое освежение ткани.</>}
              detailTitle={<>Освежение</>}
              detailText={<>
Для быстрого освежения одежды можно выбрать один из четырех доступных режимов:
<br/>Быстрые режимы 5 и 15 минут для удаления легких запахов с помощью плазмы.
<br/>Интенсивный режим длительностью около 1 часа (в зависимости от количества загруженных вещей) с обработкой вещей плазмой, озоном и паром для наиболее эффективного устранения запахов и легкого распрямления складок.
<br/>Уникальный режим для освежения меховых изделий с деликатной обработкой плазмой для устранения неприятных запахов.
</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small _dark-text"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="209"
              title={<>Гигиеническая обработка</>}
              text={<>Антибактериальная обработка и&nbsp;удаление запахов.</>}
              detailTitle={<>Гигиена</>}
              detailText={<>
Четыре режима одновременной обработки озоном и плазмой разных видов тканей:
<br/>Режим «Верхняя одежда» — удаление до 99% вирусов и бактерий с пальто, курток и пуховиков.
<br/>Режим «Домашняя одежда» — стерилизация пижам, халатов, футболок и других домашних вещей.
<br/>Режим «Постельное бельё» — удаление до 99% вирусов и бактерий и до 100% клещей с предметов домашнего текстиля.
<br/>Режим «Детские игрушки» — дезинфекция детских вещей, включая игрушки из плюша и других тканей.
</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="210"
              title={<>Сушка</>}
              text={<>Бережная сушка с&nbsp;сохранением свойств ткани.</>}
              detailTitle={<>Сушка</>}
              detailText={<>
Четыре режима с учётом типа ткани и времени сушки.
<br/>«Сушка по времени» — сушка всех типов одежды в течение фиксированного времени, которое можно настроить на панели управления.
<br/>«Смешанная» — сушка вещей из хлопка и смесовых тканей.
<br/>«Шёлк» — деликатная сушка изделий из шёлка.
<br/>«Кашемир» — сушка шерстяных вещей с максимальной защитой волокна.
</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="211"
              title={<>Уход</>}
              text={<>Уход за одеждой профессионального уровня <span className="d-br"/>и распрямление складок.</>}
              detailTitle={<>Уход</>}
              detailText={<>
Четыре режима ухода в зависимости от типа тканей. Все режимы за исключением режима ухода за изделиями из меха позволяют эффективно расправить складки на одежде благодаря воздействию пара и специальным потряхивающим движениям вешалок.
<br/>Режим «Смешанная» — пар позаботится об одежде из смесовых тканей, хлопка и льна.
<br/>Режим «Шерсть» — паровая обработка улучшит состояние кашемирового свитера, пальто и других вещей из любых видов шерсти.
<br/>Режим «Шёлк» — деликатная паровая обработка натурального шелка или вискозы.
<br/>Режим «Меховые изделия» — улучшение состояния изделий из меха: шуб, дубленок, головных уборов с возможностью активации защиты от выцветания.
</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <EvoSection
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={false}
        picFormat="webp"
        sectionTitle={<>Приложение evo</>}
        sectionText={<>Управление и&nbsp;мониторинг процессов через&nbsp;смартфон</>}
        planeTitle={<>Расширяем границы <span className="d-br"/>возможного в&nbsp;evo</>}
        planeText={<>Дистанционное управление и&nbsp;настройка, контроль состояния, <span className="d-br"/>уведомления и&nbsp;оповещения.</>}
      />
      <SliderSection productName={productName} />
      <TrainerSection />
    </>
  )
}

const SliderSection = (props) => {
  const { productName } = props;

  const data = [
    {
      id: 1, 
      title: <>От&nbsp;запаха <br/>табака</>, 
      text: <>Программа позволит устранить запах табака с&nbsp;одежды при&nbsp;помощи обработки паром и&nbsp;озоном. Дополнительно предусмотрена активация плазмы, а&nbsp;усилить эффект можно при&nbsp;помощи применения аромакапсулы, рекомендованной производителем.</>
    },
    {
      id: 2, 
      title: <>После <br/>барбекю</>, 
      text: <>Программа позволит устранить неприятные запахи с&nbsp;одежды, а&nbsp;также обновит структуру ткани благодаря паровой обработке и&nbsp;специальным движениям вешалки.</>
    },
    {
      id: 3, 
      title: <>Гигиеническая <br/>обработка</>, 
      text: <>В&nbsp;программу продолжительной гигиенической обработки одежды по&nbsp;умолчанию включены обработка паром, озоновая обработка и&nbsp;плазменная стерилизация, которые способны нейтрализовать до&nbsp;99% процентов бактерий и&nbsp;вирусов.</>
    },
    {
      id: 4, 
      title: <>Для деловой <br/>встречи</>, 
      text: <>Продолжительная обработка вещей паром в&nbsp;сочетании со&nbsp;специальными движениями вешалки позволяет выпрямить складки и&nbsp;придать ткани обновленный вид. Для&nbsp;придания дополнительной свежести можно использовать аромакапсулу.</>
    },
    {
      id: 5, 
      title: <>Повседневная</>, 
      text: <>Сбалансированная программа для&nbsp;различных типов ткани на&nbsp;каждый день: пар придаст вещам свежесть, озон и&nbsp;плазма устранят бактерии и&nbsp;легкие запахи.</>
    },
    {
      id: 6, 
      title: <>После сезонного <br/>хранения</>, 
      text: <>Гигиеническая обработка вещей после сезонного хранения. Озон и&nbsp;плазма без&nbsp;обработки паром устраняют бактерии и&nbsp;вирусы, которые могли появиться на&nbsp;одежде.</>
    },
    {
      id: 7, 
      title: <>Для торжественного <br/>мероприятия</>, 
      text: <>Уходовая программа для&nbsp;деликатных вещей, которые уже были в&nbsp;носке или&nbsp;недолго хранились в&nbsp;шкафу. Выпрямление складок и&nbsp;обновление структуры ткани происходит благодаря паровой обработке. Для&nbsp;усиления эффекта можно использовать аромакапсулу, рекомендованную производителем.</>
    }
  ]

  return (
    <div className="product-page__section programs-id">
        <div className="wrap">
          <h3 className="product-page__section-title">Эксклюзивные <br/>программы</h3>
          <MediaQuery maxWidth={MOBILE_WIDTH}>
            <MobileSlides data={data} productName={productName} />
          </MediaQuery>
          <MediaQuery minWidth={MOBILE_WIDTH_1}>
            <DesktopSlides data={data} productName={productName} />
          </MediaQuery>
        </div>
    </div>
  )
}

const MobileSlide = (props) => {
  const { id, title, text, productName } = props;

  const [opened, setOpened] = useState(false);
  const ref = useRef(null);

  const setSlideOpened = (o) => {
    if(!ref.current){
      return;
    }

    setOpened(o);
    ref.current.style.maxHeight = ref.current.style.maxHeight ? null : ref.current.scrollHeight + "px";
  }

  useEffect(() => {
    const onResize = e => {
      if(!ref.current){
        return;
      }

      if(ref.current.style.maxHeight){
        ref.current.style.maxHeight = ref.current.scrollHeight + "px";
      }
    };

    onResize();
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className="product-page__mobile-slider-section__slide">
      <div className="product-page__mobile-slider-section__slide__pic" onClick={() => setSlideOpened(!opened)} style={{backgroundImage: `url(${path}images/products/${productName}/slides/mobile/${id}.webp)`}} />
      <div className="product-page__mobile-slider-section__slide__wrap">
        <div className="product-page__mobile-slider-section__slide__header" onClick={() => setSlideOpened(!opened)}>
          <div className="product-page__mobile-slider-section__slide__icon">
            <img className="_active" src={`${path}images/products/${productName}/icons/active/${id}.svg`} />
          </div>
          <p className="product-page__mobile-slider-section__slide__title">{title}</p>
          <div className={`product-page__mobile-slider-section__slide__arr ${opened?'_opened':''}`} />
        </div>
        <div ref={ref} className={`product-page__mobile-slider-section__slide__text ${opened?'_opened':''}`}>{text}</div>
      </div>
    </div>
  )
}

const MobileSlides = (props) => {
  const { data, productName } = props;

  return (
    <div className="product-page__mobile-slider-section">
      {data.map((item, i) =>
        <MobileSlide key={i} {...item} productName={productName} />
      )}
    </div>
  )
}

const DesktopSlides = (props) => {
  const { data, productName } = props;

  const [currentId, setCurrentId] = useState(data[0].id);

  const setCurrentSlideId = (id) => {
    setCurrentId(id);
    let i = data.findIndex((item) => item.id === id)+1;

    // на всякий
    i = Math.max(1, Math.min(data.length, i));

    swiper.current.swiper.slideTo(i);
  }

  const swiper = useRef(null);
  const swiperParams = {
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    spaceBetween: 0,
    slidesPerView: 1,
    speed: 600,
    loop: true,
    navigation: {
      nextEl: '.product-page__desktop-slider-section__slides__arr._next',
      prevEl: '.product-page__desktop-slider-section__slides__arr._prev',
    },
    uniqueNavElements: false,
    on: {
      snapIndexChange: () => {
        let i = swiper.current.swiper.snapIndex-1;

        // костыль для свайпера
        if(swiper.current.swiper.snapIndex === 8) i=0;
        if(swiper.current.swiper.snapIndex === 0) i=6;

        // на всякий
        i = Math.max(0, Math.min(data.length-1, i));

        setCurrentId(data[i].id);
      }
    }
  };

  return (
    <div className="product-page__desktop-slider-section">
      <div className="product-page__desktop-slider-section__thumbs">
        {data.map((item, i) =>
          <div key={i} className={`product-page__desktop-slider-section__thumb ${item.id===currentId?'_current':''}`} onClick={() => setCurrentSlideId(item.id)}>
            <div className="product-page__desktop-slider-section__thumb__pic">
              <img className="_default" src={`${path}images/products/${productName}/icons/default/${item.id}.svg`} />
              <img className="_active" src={`${path}images/products/${productName}/icons/active/${item.id}.svg`} />
            </div>
            <p className="product-page__desktop-slider-section__thumb__title">{item.title}</p>
          </div>
        )}
      </div>
      <div className="product-page__desktop-slider-section__slides">
        <Swiper {...swiperParams} ref={swiper}>
          {data.map((item, i) =>
            <div key={i} className="product-page__desktop-slider-section__slide" style={{backgroundImage: `url(${path}images/products/${productName}/slides/desktop/${item.id}.webp)`}}>
              <div className="product-page__desktop-slider-section__slide__content">
                <p className="product-page__desktop-slider-section__slide__title">{item.title}</p>
                <div className="product-page__desktop-slider-section__slide__text">{item.text}</div>
              </div>
            </div>
          )}
        </Swiper>
        <div className="product-page__desktop-slider-section__slides__arrs">
          <div className="product-page__desktop-slider-section__slides__arr _prev" />
          <div className="product-page__desktop-slider-section__slides__arr _next" />
        </div>
      </div>
    </div>
  )
}