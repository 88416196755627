import './style.scss';
import React, { useEffect, useState, useMemo } from 'react';
import {inject, observer} from "mobx-react";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Calendar } from 'primereact/calendar';
import { Link } from 'react-router-dom';
import Icon from '../../assets/images/contact-modal/check_icon.svg';
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH } from '../../common/const';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { EMAIL_REGX, ERROR_MESAGES, PHONE_MASK, PHONE_MASK_SLOT } from '../../common/const';
import Recaptcha from '../utils/recaptcha';
import formData from "../../json/serviceForm.json";
import { getParameterByName } from '../../common/utils';
//import { disableBodyScroll, enableBodyScroll } from '../../common/utils';

Yup.addMethod(Yup.string, 'phoneNotFull', function (errorMessage) {
  return this.test('test-phone-length', errorMessage, function (value) {
    return (
      value.replace(/\D/g, '').length === PHONE_MASK.replace(/\D/g, '').length ||
      this.createError({ path: this.path, message: errorMessage })
    );
  });
});

const schema = Yup.object().shape({
  model: Yup.string()
    .required(ERROR_MESAGES.required),
  email: Yup.string()
    .matches(EMAIL_REGX, ERROR_MESAGES.formatEmail)
    .required(ERROR_MESAGES.required),
  phone: Yup.string()
    .phoneNotFull(ERROR_MESAGES.phoneNotFull)
    .required(ERROR_MESAGES.required),
  comment: Yup.string()
    .required(ERROR_MESAGES.required),
  date: Yup.string()
    .required(ERROR_MESAGES.required),
  usageTerms: Yup.boolean()
    .oneOf([true], ERROR_MESAGES.required),
  dataTerms: Yup.boolean()
    .oneOf([true], ERROR_MESAGES.required),
});

const initialLoading = {
  form: false,
};

const initialErrors = {
  form: null,
};

const Modal = ({ store }) => {
  const recaptcha = new Recaptcha();

  const [viewStatus, setViewStatus] = useState(1);
  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);
  const name = 'service';
  const data = store.modal[name];

  const sendFeedback = async (values) => {
    // setViewStatus(2);
    // return;

    if (loading.form) {
      return;
    }
    setErrors({ ...errors, form: null });
    setLoading({ ...loading, form: true });

    const token = await recaptcha.execute();

    store.bridge.feedbackCreateTicket({
      ...values,
      user: store.serviceForm?.user?.id,
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'FEEDBACK_SUCCESS':
          setViewStatus(2);
          break;
        case 'FEEDBACK_FAIL':
          if (data.errorMessage) {
            setErrors({ ...errors, form: data.errorMessage });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, form: false });
    }).catch(() => {
      setLoading({ ...loading, form: false });
    });
  };

  const handleClose = (e) => {
    if(e.target.className.indexOf('service-modal__outer-wrap') >= 0){
      store.setModal(name, null);
    }
  }

  useEffect(() => {
    if(data){
      setViewStatus(1);
    }
  }, [data])

  const models = useMemo(() => {
    return store.serviceForm?.models?.length ? [...store.serviceForm?.models] : formData.models;
  }, [store.serviceForm]);

  const markets = useMemo(() => {
    return store.serviceForm?.markets?.length ? [...store.serviceForm?.markets] : formData.markets;
  }, [store.serviceForm]);

  // --------------------------------------------------------------------------------------------------------- TEMP ---------------------------------------------------------------------------------------------------------
  const isAuth = Boolean(store.serviceForm?.user) || Boolean(getParameterByName('auth'));// авторизованный пользователь (скрываем поле email)

  //data ? disableBodyScroll() : enableBodyScroll();
  useEffect(() => {
    if(data){
      document.body.classList.add('scroll-lock');
    }

    return () => document.body.classList.remove('scroll-lock');
  }, [data]);
  
  return (
    data ?
    <div className={'service-modal _' + data.status + ' '+data?.data?.className} onClick={(e) => handleClose(e) }>
      <div className="service-modal__outer-wrap">
        {viewStatus===1?
        <Formik
          initialValues={{
            model: '',
            market: false,
            email: store.serviceForm?.user?.email || '',
            phone: store.serviceForm?.user?.phone || '',
            comment: '',
            date: '',
            usageTerms: false,
            dataTerms: false,
          }}
          validationSchema={schema}
          // validator={() => {}}
          onSubmit={(values) => sendFeedback(values)}
        >
          {({ errors: formErrors, touched, values, setFieldValue }) => (
          <Form>
            <div className="service-modal__inner-wrap">
              <div className="service-modal__body">
                <div className="service-modal__close" onClick={(e) => store.setModal(name, null) } />
                {
                    data?.data?.id===1 ?
                    <span className="service-modal__title">Заявка на установку</span>
                  : data?.data?.id===2 ?
                    <span className="service-modal__title">Заявка на сервисное обслуживание</span>
                  : null
                }
                <div className="p-fields-grid">
                  <div className={`p-fields-grid__item d-${isAuth?'100':'50'} m-100`}>
                    <span className="p-float-label">
                      <Dropdown
                        className={formErrors["model"] && touched["model"] ? 'p-invalid' : ''}
                        inputId="model"
                        value={values.model}
                        onChange={(e) => {
                          setFieldValue('model', e.value);
                        }}
                        options={models}
                        optionLabel="text"
                        optionValue="id"
                        panelClassName="service-modal__dropdown"
                      />
                      <label htmlFor="model">Модель</label>
                    </span>
                    {formErrors["model"] && touched["model"] ?
                    <span className="p-error">{formErrors["model"]}</span>
                    : null}
                  </div>
                  {// Для авторизованного null, для неавторизованного - календарь
                    isAuth ? null :
                    <div className="p-fields-grid__item d-50 m-100">
                      <span className="p-float-label">
                        <Calendar
                          className={formErrors["date"] && touched["date"] ? 'p-invalid' : ''}
                          inputId="date"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          //dateFormat="dd M"
                          //minDate={new Date()}
                          panelClassName="service-modal__calendar"
                        />
                        <label htmlFor="date">Дата покупки</label>
                      </span>
                      {formErrors["date"] && touched["date"] ?
                      <span className="p-error">{formErrors["date"]}</span>
                      : null}
                    </div>
                  }
                  <div className="p-fields-grid__item d-100 m-100">
                    <div className="flex align-items-center">
                      <Checkbox
                        className={formErrors["market"] && touched["market"] ? 'p-invalid' : ''}
                        inputId="market"
                        onChange={e => setFieldValue('market', e.checked)}
                        checked={values.market}
                      />
                      <label className='service-modal__text' htmlFor="market">Товар приобретен в фирменном онлайн магазине/флагманском бутике Casarte</label>
                    </div>
                    {formErrors["market"] && touched["market"] ?
                    <span className="p-error p-checkbox-error">{formErrors["market"]}</span>
                    : null}
                  </div>
                  {// Для авторизованного показываем календарь, для неавторизованного - email
                    isAuth ?
                    <div className="p-fields-grid__item d-50 m-100">
                      <span className="p-float-label">
                        <Calendar
                          className={formErrors["date"] && touched["date"] ? 'p-invalid' : ''}
                          inputId="date"
                          value={values.date}
                          onChange={(e) => setFieldValue('date', e.target.value)}
                          //dateFormat="dd M"
                          //minDate={new Date()}
                          panelClassName="service-modal__calendar"
                        />
                        <label htmlFor="date">Дата покупки</label>
                      </span>
                      {formErrors["date"] && touched["date"] ?
                      <span className="p-error">{formErrors["date"]}</span>
                      : null}
                    </div>
                    :
                    <div className="p-fields-grid__item d-50 m-100">
                      <span className="p-float-label">
                        <InputText
                          className={formErrors["email"] && touched["email"] ? 'p-invalid' : ''}
                          id="email"
                          value={values.email}
                          onChange={(e) => setFieldValue('email', e.target.value)}
                          onBlur={(e) => { setFieldValue('email', e.target.value); touched["email"] = true;}}
                          onFocus={(e) => { touched["email"] = false;}}
                        />
                        <label htmlFor="email">E-mail</label>
                      </span>
                      {formErrors["email"] && touched["email"] ?
                      <span className="p-error">{formErrors["email"]}</span>
                      : null}
                    </div>
                  }
                  <div className="p-fields-grid__item d-50 m-100">
                    <span className="p-float-label">
                      <InputMask
                        className={formErrors["phone"] && touched["phone"] ? 'p-invalid' : ''}
                        id="phone"
                        value={values.phone}
                        onChange={(e) => setFieldValue('phone', e.target.value)}
                        onBlur={(e) => { setFieldValue('phone', e.target.value); touched["phone"] = true;}}
                        onFocus={(e) => { touched["phone"] = false;}}
                        mask={PHONE_MASK}
                        slotChar={PHONE_MASK_SLOT}
                      />
                      <label htmlFor="phone">Телефон</label>
                    </span>
                    {formErrors["phone"] && touched["phone"] ?
                    <span className="p-error">{formErrors["phone"]}</span>
                    : null}
                  </div>
                  <div className="p-fields-grid__item d-100 m-100">
                    <span className="p-float-label">
                      <InputTextarea
                        className={formErrors["comment"] && touched["comment"] ? 'p-invalid' : ''}
                        id="comment"
                        value={values.comment}
                        onChange={(e) => setFieldValue('comment', e.target.value)}
                        onBlur={(e) => { setFieldValue('comment', e.target.value); touched["comment"] = true;}}
                        onFocus={(e) => { touched["comment"] = false;}}
                      />
                      <label htmlFor="comment">Опишите суть обращения</label>
                    </span>
                    {formErrors["comment"] && touched["comment"] ?
                    <span className="p-error">{formErrors["comment"]}</span>
                    : null}
                  </div>
                  <div className="p-fields-grid__item d-100 m-100">
                    {/* <span className="service-modal__text mt-d-15">Все поля обязательны для заполнения</span> */}
                    <div className="flex align-items-center mt-d-25">
                      <Checkbox
                        className={formErrors["usageTerms"] && touched["usageTerms"] ? 'p-invalid' : ''}
                        inputId="usageTerms"
                        onChange={e => setFieldValue('usageTerms', e.checked)}
                        checked={values.usageTerms}
                      />
                      <label className='service-modal__text' htmlFor="usageTerms">Я согласен <Link to={"/content/usloviya-ispolzovaniya-casarte/"}>с условиями использования</Link> сервиса</label>
                    </div>
                    {formErrors["usageTerms"] && touched["usageTerms"] ?
                    <span className="p-error p-checkbox-error">{formErrors["usageTerms"]}</span>
                    : null}
                    <div className="flex align-items-center mt-d-25">
                      <Checkbox
                        className={formErrors["dataTerms"] && touched["dataTerms"] ? 'p-invalid' : ''}
                        inputId="dataTerms"
                        onChange={e => setFieldValue('dataTerms', e.checked)}
                        checked={values.dataTerms}
                      />
                      <label className='service-modal__text' htmlFor="dataTerms">Я принимаю <Link to={"/content/politika-v-otnoshenii-obrabotki-personalnykh-dannykh-casarte/"}>условия хранения и обработки</Link> данных</label>
                    </div>
                    {formErrors["dataTerms"] && touched["dataTerms"] ?
                    <span className="p-error p-checkbox-error">{formErrors["dataTerms"]}</span>
                    : null}
                    {errors.form && <div className="text-light mt-30">{errors.form}</div>}
                    <Button
                      className={`p-button-secondary p-button-transparent min-w mt-d-35 ${loading.form ? 'p-loader' : ''}`}
                      label="Отправить"
                      type={'submit'}
                    //  disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
          )}
        </Formik>
        :viewStatus===2?
        <div className="service-modal__inner-wrap _min">
          <div className="service-modal__body">
            <div className="service-modal__close" onClick={(e) => store.setModal(name, null) } />
            <img className="service-modal__icon" src={Icon} alt="" />
            <span className="service-modal__title">Заявка отправлена</span>
            <span className="service-modal__text">Специалист Casarte свяжется с&nbsp;вами в&nbsp;ближайшее время.</span>
            <MediaQuery maxWidth={MOBILE_WIDTH}>
              <Link to="/" className="p-button p-component p-button-secondary p-button-transparent flex justify-content-center mt-m-35">Вернуться на сайт</Link>
            </MediaQuery>
          </div>
        </div>
        :null}
      </div>
    </div>
    : null
  )
}

export default inject('store')(observer(Modal));
