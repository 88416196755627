import './style.scss';
import React, {useEffect, useRef} from 'react';

const YandexIdButton = () => {
    const ref = useRef(null);

    useEffect(() => {
        if(!ref.current){
            return
        }
        
        console.log('YandexIdButton added');

        try {
            // либа подключена в /public/index.html
            window.YaAuthSuggest.init(
                // TODO вынести в env настрокйи https://oauth.yandex.ru
                {
                    client_id: "199c6e6a83f24c6d9082faa6b36e3622",
                    response_type: "token",
                    redirect_uri: "http://casarte3.at.dev.bstd.ru/auth"
                },
                "http://casarte3.at.dev.bstd.ru",
                {
                    // конфигурация
                    view: "button",
                    parentId: "buttonContainerId",// ВАЖНО! id для контейнера кнопки
                    buttonSize: 'xxl',
                    buttonView: 'main',
                    buttonTheme: 'dark',
                    buttonBorderRadius: "8",
                    buttonIcon: 'ya',
                }
            )
            .then(({handler}) => handler())
            .then(data => console.log('Сообщение с токеном', data))
            .catch(error => console.log('Обработка ошибки', error))
        } catch (e) {
            console.log(e);
            //console.warn(e);
          }
    }, []);
    
    return (
        <div className="yandex-id-button">
            <div ref={ref} className="yandex-id-button__wrap" id="buttonContainerId" />
            <p className="yandex-id-button__text">Продолжая авторизацию, вы соглашаетесь с&nbsp;<a href="/content/politika-v-otnoshenii-obrabotki-personalnykh-dannykh-casarte/" className="text-blue">политикой о&nbsp;персональных данных</a> и&nbsp;использованием <a href="/content/usloviya-ispolzovaniya-casarte/" className="text-blue">сервиса.</a></p>
        </div>
    );
}

export default YandexIdButton;