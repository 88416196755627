import './style.scss';
import React, {useState, useRef, useMemo, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Swiper from "react-id-swiper";
import { fixDeg } from "../../common/utils"
import Anim from '../Anim';
import {useMediaQuery} from 'react-responsive';
import { MOBILE_WIDTH_1 } from '../../common/const';

const path = process.env.REACT_APP_ASSETS_PATH;

const swiperParams = {
  slidesPerView: 'auto',
  slidesOffsetAfter: 30,
  slidesOffsetBefore: 30,
  freeMode: true,
  spaceBetween: 8,
  freeModeSticky: true,
  threshold: 3,
  init: true,
  breakpoints: {
    1023: {
      slidesPerView: 3,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    }
  },
  rebuildOnUpdate: true,
};

const swiperParams2 = {
  slidesPerView: 'auto',
  slidesOffsetAfter: 30,
  slidesOffsetBefore: 30,
  freeMode: true,
  spaceBetween: 8,
  freeModeSticky: true,
  threshold: 3,
  init: true,
  navigation: {
    nextEl: '.product-page__old-props__swiper__arr._next',
    prevEl: '.product-page__old-props__swiper__arr._prev',
  },
  uniqueNavElements: false,
  breakpoints: {
    1023: {
      slidesPerView: 3,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    },
    1365: {
      slidesPerView: 4,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    }
  },
  // rebuildOnUpdate: true,
};

const getColorClass = (color) => {
  switch (color?.toLowerCase()) {
    case 'матовый черный':
      return 'black';
    case 'шампань':
    case 'глянцевый золотой':
      return 'gold';
    case 'матовый белый':
      return 'white';
    case 'голубой антрацит':
      return 'blue';
    default:
      return '';
  }
};

const getOldPrice = (product) => {
  if (product.discount && product.price) {
    const discount = Number(product.discount.replace(/\D/g, ''));
    const price = Number(product.price.replace(/\D/g, ''));

    if (!isNaN(discount) && !isNaN(price)) {
      return price + discount;
    }
  }
  return null;
};

const OldTechProps = ({ pageContent }) => {
  const isDesktopMediaQuery = useMediaQuery({minWidth: MOBILE_WIDTH_1});

  const [tab, setTab] = useState(0);
  const [tab2, setTab2] = useState(0);
  const propsTitleRef = useRef(null);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedInnerBlock, setSelectedInnerBlock] = useState();
  const [selectedOuterBlock, setSelectedOuterBlock] = useState();
  const plStaticRef = useRef(null);
  const [plStatus, setPlStatus] = useState('_hidden');
  const [plStatus2, setPlStatus2] = useState('');

  const handleTabClick = (id) => {
    setTab(id);
  }

  const handleTab2Click = (id) => {
    setTab2(id);
  }

  useEffect(() => {
    let propsTitleShown = false;
    let timer;

    const onScroll = e => {
      const wh = window.innerHeight;
      const wh05 = wh * 0.5;// при домотке до центра экрана
      const plStatciOB = isDesktopMediaQuery ? 0 : 76;

      if(propsTitleRef.current && plStaticRef.current){
        const otTitle = propsTitleRef.current.getBoundingClientRect().top;
        const otPlStatic = plStaticRef.current.getBoundingClientRect().top;
        const hPlStatic = plStaticRef.current.getBoundingClientRect().height;

        if((otTitle < wh05 && !propsTitleShown)){
          propsTitleShown = true;
          setPlStatus('_show');
          clearTimeout(timer);
          timer = setTimeout(() => {
            setPlStatus('_shown');
          }, 200);
        }
        else if(otTitle > wh05 && propsTitleShown){
          propsTitleShown = false;
          setPlStatus('_hide');
          clearTimeout(timer);
          timer = setTimeout(() => {
            setPlStatus('_hidden');
          }, 200);
        }

        if(otPlStatic < (wh-hPlStatic-plStatciOB)){
          setPlStatus2('_hidden');
        }
        else{
          setPlStatus2('');
        }
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isDesktopMediaQuery]);

  const innerBlockOptions = useMemo(() => {
    return pageContent?.innerBlock?.filter((product) => product.color === selectedColor) || [];
  }, [pageContent, selectedColor]);

  const outerBlockOptions = useMemo(() => {
    return pageContent?.outerBlock || [];
  }, [pageContent]);

  const innerBlockImages = useMemo(() => {
    if (selectedInnerBlock?.images) {
      return selectedInnerBlock?.images;
    }
    return (pageContent?.innerBlock && pageContent?.innerBlock[0]?.images) || [];
  }, [pageContent, selectedInnerBlock]);

  const outerBlockImages = useMemo(() => {
    if (selectedOuterBlock?.images) {
      return selectedOuterBlock?.images;
    }
    return (pageContent?.outerBlock && pageContent?.outerBlock[0]?.images) || [];
  }, [pageContent, selectedOuterBlock]);

  const colorList = useMemo(() => {
    if (pageContent?.innerBlock) {
      const colors = pageContent.innerBlock.reduce((acc, item) => {
        if (!acc[item.color]) {
          acc[item.color] = item.available;
        }
        return acc;
      }, {});

      return Object.entries(colors).map(([label, available]) => ({
        label,
        available,
      }));
    }
    return [];
  }, [pageContent]);

  // Устанавливает выбранный цвет и продукт по-умолчанию (внутренний блок)
  useEffect(() => {
    if (pageContent?.innerBlock?.length && !selectedInnerBlock) {
      const product = pageContent?.innerBlock?.find((item) => item.available && item.color === colorList[0]?.label);

      if (product) {
        setSelectedColor(product.color);
        setSelectedInnerBlock(product);
      }
    }
  }, [pageContent, selectedInnerBlock, colorList]);

  // Устанавливает выбранный продукт по-умолчанию (внешний блок)
  useEffect(() => {
    if (pageContent?.outerBlock?.length && !selectedOuterBlock) {
      const product = pageContent?.outerBlock?.find((item) => item.available);

      if (product) {
        setSelectedOuterBlock(product);
      }
    }
  }, [pageContent, selectedOuterBlock]);

  return (
    <Anim className="props-anim tech-id" id="tech">
      <div className="product-page__old-props">
        <h3 ref={propsTitleRef} className="product-page__old-props-title">Цвета<br/>и характеристики</h3>

        <div className="product-page__old-props-wrap product-page__old-props__tabs__wrap">
          <div className="product-page__old-props__tabs">
            <div className="product-page__old-props__tabs__item _current">Серия Velato</div>
            <div className="product-page__old-props__tabs__item">Серия Eletto</div>
            <div className="product-page__old-props__tabs__item">Низконапорный канальный</div>
            <div className="product-page__old-props__tabs__item">Средненапорный канальный</div>
            <div className="product-page__old-props__tabs__item">Однопоточный кассетный</div>
          </div>
        </div>

        {Boolean(innerBlockImages?.length) && innerBlockImages[0]?.d &&
          <div className="product-page__old-props-pics">
            <img src={innerBlockImages[0]?.d} className={'shown first'} alt="Casarte"/>
          </div>
        }
        <div className="product-page__old-props-colors">
          {colorList?.map((item, index) =>
            <div
              key={index}
              className={`${getColorClass(item.label)}
              ${item.label && item.label?.toLowerCase() === selectedColor?.toLowerCase() ? 'active' : ''}
              ${!item.available ? 'disabled' : ''}`}
              onClick={() => {
                const product = pageContent?.innerBlock?.find((product) => product.available && product.color === item.label);
                setSelectedColor(item.label);
                setSelectedInnerBlock(product);
              }}>
              <span>{item.label}</span>
            </div>
          )}
        </div>

        <div className="product-page__old-props-wrap">
          <div className="product-page__old-props-tabs x2">
            {Boolean(innerBlockOptions?.length) &&
              <Swiper {...swiperParams} activeSlideKey={tab2.toString()}>
                {innerBlockOptions?.map((item, index) =>
                  <div
                    key={index}
                    className={`product-page__old-props-tab
                      ${selectedInnerBlock?.code?.toLowerCase() === item.code.toLowerCase() ? 'active' : ''}
                      ${!item?.available || !selectedColor ? 'disabled' : ''}`}
                    onClick={() => {
                      setSelectedInnerBlock(item);
                      handleTab2Click(index)
                    }}
                  >
                    {item.code}<br/>
                    {item.optionsList && item.optionsList[0]?.value && `До ${item.optionsList[0]?.value} м²`}<br/>
                    от {item.price} Р
                    {Boolean(item.discount) && <span className="product-page__old-price">от {getOldPrice(item)} Р</span>}
                  </div>
                )}
              </Swiper>
            }
          </div>
          <div className="product-page__old-props-table-line" />
          {selectedInnerBlock?.specificationList?.map((item, index) =>
            <Table key={index} data={item} open={true} />
          )}
        </div>


        <div className="product-page__old-props-wrap">
          <p className="product-page__old-props__subtitle">Наружные блоки</p>
        </div>

        {Boolean(outerBlockImages?.length) && outerBlockImages[0]?.d &&
          <div className="product-page__old-props-wrap">
            <img src={outerBlockImages[0]?.d} className={'product-page__old-props-table-pic'} alt=""/>
          </div>
        }

        <div className="product-page__old-props-wrap">
          <div className="product-page__old-props-tabs">
            <div className="product-page__old-props__swiper">
              {Boolean(outerBlockOptions?.length) &&
                <Swiper {...swiperParams2} activeSlideKey={tab.toString()}>
                  {outerBlockOptions?.map((item, index) =>
                    <div
                      key={index}
                      className={`product-page__old-props-tab
                        ${selectedOuterBlock?.code?.toLowerCase() === item.code.toLowerCase() ? 'active' : ''}
                        ${!item?.available || !selectedColor ? 'disabled' : ''}`}
                      onClick={() => {
                        setSelectedOuterBlock(item);
                        handleTabClick(index)
                      }}
                    >
                      {item.code}<br/>
                      от {item.price} Р
                      {Boolean(item.discount) && <span className="product-page__old-price">от {getOldPrice(item)} Р</span>}
                    </div>
                  )}
                </Swiper>
              }
            </div>
            <div className="product-page__old-props__swiper__arr _prev swiper-button-disabled"/>
            <div className="product-page__old-props__swiper__arr _next swiper-button-disabled"/>
          </div>
          <div className="product-page__old-props-table-line" />
          {selectedOuterBlock?.specificationList?.map((item, index) =>
            <Table key={index} data={item} open={true} />
          )}
        </div>

        <div className="product-page__old-props-wrap">
          <p className="product-page__old-props__subtitle">Пульты управления</p>
        </div>

        {Boolean(outerBlockImages?.length) && outerBlockImages[0]?.d &&
          <div className="product-page__old-props-wrap">
            {/* <img src={outerBlockImages[0]?.d} className={'product-page__old-props-table-pic'} alt=""/> */}
            <img src={`${path}images/products/multi-split/2.webp`} className={'product-page__old-props-table-pic'} alt=""/>
          </div>
        }

        <div className="product-page__old-props-wrap">
          <div className="product-page__old-props-tabs">
            <div className="product-page__old-props__swiper">
              {Boolean(outerBlockOptions?.length) &&
                <Swiper {...swiperParams2} activeSlideKey={tab.toString()}>
                  {outerBlockOptions?.map((item, index) =>
                    <div
                      key={index}
                      className={`product-page__old-props-tab
                        ${selectedOuterBlock?.code?.toLowerCase() === item.code.toLowerCase() ? 'active' : ''}
                        ${!item?.available || !selectedColor ? 'disabled' : ''}`}
                      onClick={() => {
                        setSelectedOuterBlock(item);
                        handleTabClick(index)
                      }}
                    >
                      {item.code}<br/>
                      от {item.price} Р
                      {Boolean(item.discount) && <span className="product-page__old-price">от {getOldPrice(item)} Р</span>}
                    </div>
                  )}
                </Swiper>
              }
            </div>
            <div className="product-page__old-props__swiper__arr _prev swiper-button-disabled"/>
            <div className="product-page__old-props__swiper__arr _next swiper-button-disabled"/>
          </div>
          <div className="product-page__old-props-table-line" />
          {selectedOuterBlock?.specificationList?.map((item, index) =>
            <Table key={index} data={item} open={true} />
          )}
        </div>

      </div>
      <div className={`product-page__old-props__pl _fixed ${plStatus} ${plStatus2}`}>
        <PlContent />
      </div>
      <div ref={plStaticRef} className={`product-page__old-props__pl _static`}>
        <PlContent />
      </div>
    </Anim>
  );
}

export default OldTechProps;

const PlContent = ( ) => {
  return (
    <div className="product-page__old-props__pl__wrap">
      <div>
        <p className="product-page__old-props__pl__title">Подбор мульти-сплит системы осуществляется специалистом</p>
        <p className="product-page__old-props__pl__text">Обратитесь к&nbsp;авторизованному партнеру Casarte, чтобы подобрать, приобрести и&nbsp;установить оборудование</p>
      </div>
      <div>
        <Link to="/dealers/" className="product-page__old-props__pl__btn p-button p-component p-button-secondary p-button-white min-w">Подобрать</Link>
      </div>
    </div>
  )
}

const Table = ( {data, open=false} ) => {
  let rows = [];
  const [opened, setOpened] = useState(open);

  if(data){
    for(let i=0; i<data.data.length; i++){
      const group = data.data[i];

      if(group.head.length){
        rows.push(
          <div key={i} className="product-page__old-props-table-tr head">
            <div className="product-page__old-props-table-td first">{fixDeg(group.head[0])}</div>
            <div className="product-page__old-props-table-td last">{fixDeg(group.head[1])}</div>
          </div>
        );
      }

      for(let j=0; j<group.rows.length; j++){
        const row = group.rows[j];

        rows.push(
          <div key={100*(i+1)+j} className="product-page__old-props-table-tr">
            {
              group.links && group.links.length && group.links[j] ?
                <div className="product-page__old-props-table-td first">
                  <a href={group.links[j]} download rel="noreferrer" target="_blank">{fixDeg(row[0])}</a>
                </div>
                :
                <div className="product-page__props-table-td first">{fixDeg(row[0])}</div>
            }
            <div className="product-page__old-props-table-td last">{fixDeg(row[1])}</div>
          </div>
        );
      }
    }
  }

  return (
    <>
    {data ?
    <div className="product-page__old-props-table">
      <div className="product-page__old-props-table-title">{data.title}<div className={`product-page__old-props-table-btn ${opened?'opened':'closed'}`} onClick={() => setOpened(!opened)} /></div>
      <div className={`product-page__old-props-table-wrap ${opened?'opened':'closed'}`}>
        <div className="product-page__old-props-table-body">
          {rows}
        </div>
      </div>
    </div>
    :
    null
    }
    </>
  )
}
