import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import { Button } from 'primereact/button';
import { scrollTo } from "../../../common/utils";
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const DishwasherCasarteHead = (props) => {
    const { isBuyActive, productName } = props;

    return (
        <>
            <MediaQuery maxWidth={MOBILE_WIDTH}>
                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/products/${productName}/head-m.webp)` }} />
            </MediaQuery>
            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/products/${productName}/head-d.webp)` }} />
            </MediaQuery>
            <div className="product-page__head__center__wrap _right">
                <p className="product-page__head__center__label">Встраиваемая<br />посудомоечная</p>
                <h1 className="product-page__head__center__title">машина Casarte</h1>
                {/* <h2 className="product-page__head__center__sub-title">CASDWE14399U</h2> */}
                <p className="product-page__head__center__text">Эргономичный дизайн для максимального удобства.</p>
                <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
                    scrollTo('tech-id')
                }} />
            </div>
        </>
    )
}

export const DishwasherCasarteContent = (props) => {
    const { assetsPathName, productName } = props;

    return (
        <>
            <div className="product-page__section config-id">
                <div className="wrap">
                    <SectionHeader
                        title={<>Конструкция</>}
                        text={<>Полноразмерная встраиваемая посудомоечная машина с&nbsp;тремя корзинами и&nbsp;загрузкой до&nbsp;14&nbsp;комплектов посуды</>}
                    />
                    <div className="product-page__grid">
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="large"
                            wrapClass="bottom-left"
                            titleClass="large"
                            picName="100"
                            title={<>Стандартные<br />размеры<br />для&nbsp;встраивания</>}
                            text={<>Полноразмерная посудомоечная машина <span className="d-br" />с&nbsp;возможностью встраивания в&nbsp; нишу шириной 60&nbsp;см.</>}
                            detailTitle={<>Стандартные размеры для&nbsp;встраивания</>}
                            detailText={<>Посудомоечная машина имеет ширину 60&nbsp;см, что обеспечивает возможность ее встраивания в&nbsp;нишу стандартных размеров. Общая вместимость составляет до&nbsp;14 комплектов посуды.</>}
                            picFormat="webp"
                        />
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="small"
                            wrapClass="bottom-left"
                            titleClass="large-xs"
                            picName="101"
                            title={<>Три<br />эргономичные корзины</>}
                            text={<>Отдельная корзина для&nbsp;столовых приборов.</>}
                            detailTitle={<>Три эргономичные корзины</>}
                            detailText={<>Верхняя корзина используется для&nbsp;размещения столовых приборов, средняя и&nbsp;нижняя - для&nbsp;посуды разного размера, включая сковороды, большие кастрюли и&nbsp;противни.</>}
                            picFormat="webp"
                        />
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="small"
                            wrapClass="bottom-left"
                            titleClass="large-xs"
                            picName="102"
                            title={<>Большая вместимость</>}
                            text={<>До&nbsp;14 комплектов посуды.</>}
                            detailTitle={<>Большая вместимость</>}
                            detailText={<>Три корзины посудомоечной машины способны вместить до&nbsp;14 комплектов посуды одновременно.</>}
                            picFormat="webp"
                        />
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="large"
                            wrapClass="bottom-left"
                            titleClass="large"
                            picName="103"
                            title={<>Тихий<br />инверторный мотор</>}
                            text={<>Надежный инверторный мотор обеспечивает тихую работу.</>}
                            detailTitle={<>Тихий инверторный мотор</>}
                            detailText={<>Надежный, долговечный и&nbsp;эффективный инверторный мотор обеспечивает высокую производительность и&nbsp;низкий уровень шума.</>}
                            picFormat="webp"
                        />
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="large i8"
                            wrapClass="bottom-center"
                            titleClass="large"
                            picName="8"
                            title={<>Деликатный уход</>}
                            text={<>Силиконовые вставки для&nbsp;бережной мойки вашей посуды.</>}
                            detailTitle={<>Деликатный уход за&nbsp;посудой</>}
                            detailText={<>Средняя и&nbsp;нижняя корзины оснащены складными держателями тарелок, полками для&nbsp;чашек, а&nbsp;также силиконовыми держателями бокалов для максимально эффективного и&nbsp;бережного мытья.</>}
                            layer={0}
                            picFormat="webp"
                        />
                    </div>
                </div>
            </div>
            <div className="product-page__section control-id">
                <div className="wrap">
                    <SectionHeader
                        title={<>Программы и&nbsp;управление</>}
                        text={<>Широкий выбор программ мытья, удобное сенсорное управление</>}
                    />
                    <div className="product-page__grid">
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="large"
                            wrapClass="bottom-left"
                            titleClass="large"
                            picName="104"
                            title={<>Девять<br />программ мытья</>}
                            text={<>Широкий выбор программ мытья для&nbsp;разных видов посуды <span className="d-br" />на&nbsp;сенсорной панели управления, включая автопрограмму.</>}
                            detailTitle={<>Девять программ мытья</>}
                            detailText={<>Выбор программ осуществляется с помощью сенсорной панели. При выборе автоматической программы оптимальная температура и время мытья определяются благодаря специальным датчикам, которые определяют объем и степень загрязнения загруженной посуды.</>}
                            picFormat="webp"
                        />
                    </div>
                </div>
            </div>
            <EvoSection
                sectionTitle={<>Приложение evo</>}
                sectionText={<>Управление и мониторинг процессов через смартфон</>}
                planeTitle={<>Расширяем границы<br />возможного в&nbsp;evo</>}
                planeText={<>Дистанционное управление и настройка программ мытья, <span className="d-br" />контроль состояния, уведомления и&nbsp;оповещения.<sup>*</sup></>}
                assetsPathName={assetsPathName}
                productName={productName}
                videoBtn={false}
                desc={true}
                picFormat="webp"
            />
            <div className="product-page__section reliability-id">
                <div className="wrap">
                    <SectionHeader
                        title={<>Функциональность</>}
                        text={<>Вариативные настройки и&nbsp;полезные датчики обеспечат удобство использования</>}
                    />
                    <div className="product-page__grid">
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="large"
                            wrapClass="bottom-left"
                            titleClass="large"
                            picName="105"
                            title={<>Автооткрывание<br />дверцы</>}
                            text={<>Циркуляция воздуха, быстрая сушка посуды.</>}
                            detailTitle={<>Автооткрывание дверцы</>}
                            detailText={<>Дверца машины открывается на&nbsp;несколько сантиметров в&nbsp;конце цикла, что&nbsp;способствует эффективной сушке посуды.</>}
                            picFormat="webp"
                        />
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="small"
                            wrapClass="bottom-left"
                            titleClass="large-xs"
                            picName="106"
                            title={<>Луч на полу:<br />индикация процессов</>}
                            text={<>Мойка, ополаскивание, сушка.</>}
                            detailTitle={<>Индикация процессов</>}
                            detailText={<>Проецируемый на&nbsp;пол световой луч является индикатором стадии цикла мытья: мойка, ополаскивание, сушка, окончание цикла.</>}
                            picFormat="webp"
                        />
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="small"
                            wrapClass="bottom-left"
                            titleClass="large-xs m-small-2"
                            picName="107"
                            title={<>АБТ - антибактериальный материал</>}
                            text={<>Предотвращение размножения бактерий.</>}
                            detailTitle={<>АБТ - антибактериальный материал</>}
                            detailText={<>Фильтр и уплотнитель посудомоечной машины изготовлены из специального антибактериального материала АБТ, который предотвращает размножение бактерий.</>}
                            picFormat="webp"
                        />
                        <Plane
                            assetsPathName={assetsPathName}
                            itemClass="large"
                            wrapClass="bottom-left"
                            titleClass="large"
                            picName="108"
                            title={<>Внутренняя <span className="m-br" />подсветка</>}
                            text={<>Комфортная загрузка и&nbsp;выгрузка посуды.</>}
                            detailTitle={<>Внутренняя подсветка</>}
                            detailText={<>Внутри камеры посудомоечной машины предусмотрена подсветка для&nbsp;более комфортной загрузки и&nbsp;выгрузки посуды.</>}
                            picFormat="webp"
                        />
                    </div>
                </div>
            </div>
            <TrainerSection />
        </>
    )
}