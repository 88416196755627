import './style.scss';
import React from 'react';
import { InputText } from 'primereact/inputtext';
import useOutsideClick from '../../utils/useOutsideClick';

const SearchableInputText = (props) => {
  const {ref, isVisible, setIsVisible} = useOutsideClick(false);

  return (
    <span ref={ref} className="p-float-label">
      <InputText
        className={props.className}
        id={props.id}
        value={props.value}
        autoComplete="off"
        onChange={(e) => {
          props.onInput(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Tab") {
            setIsVisible(false);
          }
        }}
        onFocus={() => {
          setIsVisible(true);
        }}
      />
      <label htmlFor={props.id}>{props.label}</label>
      {props.loading &&
        <div className='searchable-input-text__loader p-loader p-loader-secondary' />
      }
      {isVisible && Boolean(props.suggestions?.length) &&
        <div className='searchable-input-text__items'>
          {props.suggestions.map((suggestion, index) => (
            <div
              className='searchable-input-text__item'
              key={index}
              onClick={() => {
                props.onSelect(suggestion);
                setIsVisible(false);
              }}
            >{suggestion.value}</div>
          ))}
        </div>
      }
    </span>
  );
}

export default SearchableInputText;
