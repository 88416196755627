export default class Recaptcha {
  constructor() {
    this.timer = null;

    this.init();
  }

  init() {
    if (window.grecaptcha) {
      this.siteKey = window.recaptchaSiteKey;
      this.grecaptcha = window.grecaptcha;
    } else {
      window.onloadCallback = () => {
        this.grecaptcha = window.grecaptcha;
      };

      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => {
          this.appendScript();
        });
      } else {
        this.appendScript();
      }
    }
  }

  appendScript() {
    this.siteKey = window.recaptchaSiteKey;

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=${this.siteKey}`;

    document.head.appendChild(script);
  }

  async execute(action = null) {
    if (!this.siteKey) {
      console.error('Recaptcha key is missing');

      return null;
    }
    if (this.grecaptcha) {
      return new Promise((resolve) => {
        this.grecaptcha.ready(() => {
          try {
            resolve(this.grecaptcha.execute(this.siteKey, { action }));
          } catch (e) {
            console.error(e);
            resolve(null);
          }
        });
      });
    }
    return new Promise((resolve) => {
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        resolve(this.execute(action));
      }, 250);
    });
  }
}
