// для IE
// import 'core-js/es/map';
// import 'core-js/es/set';
// import 'raf/polyfill';
// import "core-js";
// import "regenerator-runtime/runtime";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

document.querySelectorAll("body > h1").forEach((h1) => {
  h1.remove();
});


ReactDOM.render(
  <App />,
  document.getElementById('app'),
);
