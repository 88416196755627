import React from "react";
import Table from "../components/Table";
// import ReactDOM from "react-dom";

/**
 * Хук используется для отрисовки элементов с инфоблоков либо сниппетов
 * @returns {{renderComponentsByText: (function(*): React.JSX[])}}
 */
export const useContentRender = () => {
  /**
   * Массив ссылок на уникальные элементы, такие как таблица
   * @type {string[]}
   */
  const componentsClassNames = ['js-table-example']
  /**
   * Парсер строки инфоблока в html разметку
   * @param text - строка
   * @returns {React.JSX[]}
   */
  const renderComponentsByText = (text) => {
    /**
     * Парсим строку
     */
    const parser = new DOMParser().parseFromString(text, 'text/html')
    /**
     * Достаем массив html тэгов
     * @type {ChildNode[]}
     */
    const arrCont = Array.from(parser.childNodes[0].childNodes);
    /**
     * Достаем корневые элементы
     * @type {ChildNode[]}
     */
    const parserArrCont = Array.from(Array.from(Array.from(arrCont)[1].childNodes)[0].childNodes)
    /**
     * Преобразуем в нужный формат, достаем элемент, его контент, и json
     * @type {{json: *, content: string|*, element: ChildNode}[]}
     */
    const mapArr = parserArrCont.map((el, i) => {
      let contents = [<>{el.innerHTML}</>];
      return {
        element: el,
        content: contents[0]?.props?.children?.includes('parsererror') ? 'Ошибка при заполнении данных контента' : contents[0]?.props?.children,
        json: el?.attributes?.['data-json']?.value,
      }
    })
    /**
     * По классу элемента находим нужный реакт компонент и рендерим его
     */
    return mapArr.map((child, index) => {
      const renderedElements = {
        'js-table-example': () => {
          const json = JSON.parse(child?.json)
          return <Table key={`${json}_${index}`} className="table-example" {...json} />
        },
        'default': () => <>{child.content?.includes('Ошибка при заполнении данных контента') ?
          <span key={index} style={{color: 'red'}}>{child.content}</span> :
          <div className={'removedElement'} style={{display: 'contents'}} key={index}
               dangerouslySetInnerHTML={{__html: child.element.outerHTML}} />}

        </>
      }
      return <>
        {componentsClassNames.includes(child.element.className) ? renderedElements[child.element.className]() : renderedElements['default']()}
      </>
    })
  }


  return {
    renderComponentsByText,
  }
};
