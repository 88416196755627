import React from 'react';
import {scrollTo} from "../../common/utils";

const SectionHeader = ({title, text, btn=true}) => {
  return (
    <>
      <h3 className="product-page__section-title">{title}</h3>
      <p className={`product-page__section-text${btn?'':' _no-btn'}`}>{text}</p>
      { btn &&
      <div className="product-page__section-btn" onClick={() => { scrollTo('tech-id')}}>Смотреть характеристики</div>
      }
    </>
  )
}

export default SectionHeader;