import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import EvoVideo from "../EvoVideo";
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const TwinWineZoneHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Винный шкаф</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        <h2 className="product-page__head__center__sub-title">Twin Wine Zone</h2>
        <p className="product-page__head__center__text">Высокий стандарт хранения вин.</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const TwinWineZoneContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section zoning-id">
        <div className="wrap">
          <SectionHeader
            title={<>Эффективное<br/>зонирование</>}
            text={<>Две зоны хранения с&nbsp;возможностью настройки температуры в&nbsp;каждой из&nbsp;них от&nbsp;+5°C до&nbsp;+20°C для&nbsp;всех видов вина</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="70"
              title={<>Большая <span className="d-br"/>вместимость –<span className="d-br"/>170 бутылок</>}
              text={<>Продуманная эргономика для размещения винной <span className="d-br"/>коллекции.</>}
              detailTitle={<>Большая вместимость – 170 бутылок</>}
              detailText={<>Винный шкаф объемом 440 л с дисплейной полкой и держателем для бокалов позволяет разместить до 170 классических бутылок типа «Бордо» и 6 бокалов.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-center-2"
              titleClass="large"
              picName="71"
              title={<>Две температурные зоны</>}
              text={<>Точная настройка температурных режимов в каждой зоне для сохранения свойств вина.</>}
              detailText={<>Две температурные зоны позволяют одновременно хранить вина разных типов. Рекомендуемые температура хранения красных вин от +13°C до +20°C, белых вин от +9°C до +12°C градусов, игристых напитков от +5°C до +8°C.</>}
            />
          </div>
        </div>
      </div>
      <EvoSection
        sectionTitle={<>Приложение evo</>}
        sectionText={<>Управление и мониторинг состояния устройства через смартфон</>}
        planeTitle={<>Расширяем границы <span className="d-br"/>возможного с&nbsp;evo</>}
        planeText={<>Дистанционное управление и настройка, контроль состояния, <span className="d-br"/>уведомления и оповещения, рекомендации и онлайн-поддержка.<sup>*</sup></>}
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={false}
        itemClass={'long no-btn'}
        videoBlocks={
          <div className="product-page__grid-item">
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-1"
              title="Инструкция по&nbsp;подключению"
              text="Подключение винного шкафа Twin Wine Zone с&nbsp;помощью мобильного приложения&nbsp;evo"
            />
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-2"
              direction="right"
              title="Инструкция по&nbsp;управлению"
              text="Управление винным шкафом Twin Wine Zone с&nbsp;помощью мобильного приложения&nbsp;evo"
            />
          </div>
        }
      />
      <div className="product-page__section ergonomics-id">
        <div className="wrap">
          <SectionHeader
            title={<>Эргономичность <span className="d-br"/>и&nbsp;эстетика</>}
            text={<>Внутреннее пространство оснащено полками из&nbsp;массива дуба и&nbsp;удобными держателями для&nbsp;бокалов над&nbsp;верхней полкой</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="72"
              title={<>Держатель для&nbsp;бокалов</>}
              //text={<></>}
              detailText={<>Специальный подвесной держатель для хранения до 6 бокалов под любой тип вина.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-xs"
              picName="73"
              title={<>Дисплейная <span className="m-br"/>полка</>}
              text={<>Для размещения любимых напитков.</>}
              detailText={<>В верхняя зона хранения оснащена специальной подставкой для дисплейного размещения флагманов винной коллекции.</>}
            />
          </div>
        </div>
      </div>
      <TrainerSection />
    </>
  )
}