import './style.scss';
import React, {useEffect, useState} from 'react';
import {withRouter, Link} from 'react-router-dom';
import axios from "axios";
import {getApiPath} from "../utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../common/const';
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import {inject, observer} from "mobx-react";
import PageContainer from "../PageContainer";
import pageData from "../../json/catalog.json";
import Icon1 from '../../assets/images/catalog2/1.svg';
import Icon2 from '../../assets/images/catalog2/2.svg';
import Icon3 from '../../assets/images/catalog2/3.svg';
import MediaQuery from 'react-responsive';

const Page = ({ store, match }) => {
  const [data, setData] = useState(null);
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    document.title = 'Каталог';
  }, [])

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      setData(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(pageData.find((item) => item.url === match.path));
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="catalog-page-2 page">
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="catalog-page__pl">
          <Link to="/" className="catalog-page__pl__back"/>
        </div>
      </MediaQuery>
      <div className="wrap">
        <h1 className="catalog-page-2__title"><Link to="/" className="catalog-page-2__back"/>{data?.title}</h1>
        <div className="catalog-page-2__items">
          {
          data?.items ?
          data?.items.map((item, index) =>
            <div key={index} className="catalog-page-2__item">
              <div className="catalog-page-2__item__pic">
                <img src={item.catalog.imageSet.desktop} alt={item.menu.title} title={item.menu.title} />
              </div>
              <div className="catalog-page-2__item__title">
                {item.catalog.title.prefix && <p className="catalog-page-2__item__title__prefix">{item.catalog.title.prefix}</p>}
                {item.catalog.title.root && <p className="catalog-page-2__item__title__root">{item.catalog.title.root}</p>}
                {item.catalog.title.suffix && <p className="catalog-page-2__item__title__suffix">{item.catalog.title.suffix}</p>}
              </div>
              {item.url && 
                <>
                  <Link to={item.url} className="catalog-page-2__item__extra-btn" />
                  <Link to={item.url} className="catalog-page-2__item__btn" />
                </>
              }
            </div>
          )
          : null
          }
          {
          data?.items.length % 3 ? // если последняя строка пуктов полная, не выводим
            <MediaQuery minWidth={MOBILE_WIDTH_1}>
              <div className="catalog-page-2__item catalog-page-2__item__extra">
                <p className="catalog-page-2__item__extra__title">привилегии клиентов casarte</p>
                <div>
                  <Link to="/delivery" className="catalog-page-2__item__extra__link"><img src={Icon1} alt="Бесплатная доставка" title="Бесплатная доставка" />Бесплатная доставка</Link>
                  <Link to="/service" className="catalog-page-2__item__extra__link"><img src={Icon2} alt="Премиальное обслуживание" title="Премиальное обслуживание" />Премиальное обслуживание</Link>
                  <Link to="/assurance" className="catalog-page-2__item__extra__link"><img src={Icon3} alt="Расширенная гарантия" title="Расширенная гарантия" />Расширенная гарантия</Link>
                </div>
              </div>
            </MediaQuery>
          : null
          }
        </div>
      </div>
    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(Page)));
