import Modal from '../Modal'
import {inject, observer} from "mobx-react";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Formik, Form} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";
import { ERROR_MESAGES } from "../../common/const";

const modalName = 'changePassword';

const formSchema = Yup.object().shape({
  password: Yup.string()
    .required(ERROR_MESAGES.required),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], ERROR_MESAGES.passwordsMismatch)
    .required(ERROR_MESAGES.required),
});

const initialLoading = {
  form: false,
};

const initialErrors = {
  form: null,
};

const ChangePasswordModal = ({ store }) => {
  const { email, userId, token, type } = store.modal[modalName]?.data ?? {};

  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);

  const sendChangePassword = (values) => {
    if (loading.form) {
      return;
    }
    setErrors({ ...errors, form: null });
    setLoading({ ...loading, form: false });

    store.bridge.authChangePassword({
      ...values, email, userId, token, type,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'PASSWORD_CHANGE_SUCCESS':
          // @TODO: сбрасывать поля формы при успешной отправке данных
          store.setModal('auth', { isEmail: type === 'email' });
          break;
        case 'PASSWORD_CHANGE_FAIL':
          if (data.errorMessage) {
            setErrors({ ...errors, changePasswordForm: data.errorMessage });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, form: false });
    }).catch(() => {
      setLoading({ ...loading, form: false });
    });
  };

  return <Modal title={'Смена пароля'} name={modalName}>
    <Formik
      initialValues={{
        password: '',
        passwordConfirm: ''
      }}
      validationSchema={formSchema}
      onSubmit={(values) => sendChangePassword(values)}
    >
      {({ values, setFieldValue, errors: formErrors, touched, setTouched }) => (
        <Form>
          <div className="p-fields-grid">
            <div className="p-fields-grid__item d-100 m-100">
              <span className="p-float-label">
                <Password
                  className={formErrors.password && touched.password ? 'p-invalid' : ''}
                  inputId="changePassword"
                  toggleMask
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  feedback={false}
                />
                <label htmlFor="changePassword">Новый пароль</label>
              </span>
              {formErrors.password && touched.password && <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.password}} />}
            </div>
            <div className="p-fields-grid__item d-100 m-100">
              <span className="p-float-label">
                <Password
                  className={formErrors.passwordConfirm && touched.passwordConfirm ? 'p-invalid' : ''}
                  inputId="changePasswordConfirm"
                  toggleMask
                  value={values.passwordConfirm}
                  onChange={(e) => setFieldValue('passwordConfirm', e.target.value)}
                  feedback={false}
                />
                <label htmlFor="changePasswordConfirm">Повторите пароль</label>
              </span>
              {formErrors.passwordConfirm && touched.passwordConfirm && <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.passwordConfirm}} />}
            </div>
          </div>
          {errors.form && <div className="text-light mt-30" dangerouslySetInnerHTML={{__html: errors.form}} />}
          <Button
            className={`p-button-secondary p-button-white mt-30 min-w-2 ${loading.form ? 'p-loader' : ''}`}
            label="Сохранить пароль"
            type={'submit'}
            onClick={() => {
              setTouched(Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: true }), {}));
            }}
          />
        </Form>
      )}
    </Formik>
  </Modal>
}

export default inject('store')(observer(ChangePasswordModal));
