import { useEffect, useState, useCallback } from 'react';

const useCountdown = (targetDate, callback = () => {}) => {
  const countDownDate = new Date(targetDate).getTime();
  const [counter, setCounter] = useState(countDownDate - new Date().getTime());

  const countDown = useCallback(() => {
    if (counter > 0) {
      setCounter(countDownDate - new Date().getTime());
    }
  }, [counter, countDownDate]);

  useEffect(() => {
    const timer = setInterval(() => {
      countDown();
    }, 1000);

    if (counter < 1000) {
      clearInterval(timer);
      callback();
    }

    return () => {
      clearInterval(timer);
    };
  }, [countDown, counter, callback]);

  return getReturnValues(counter);
};

const getReturnValues = (countDown) => {
  // const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  // const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { minutes, seconds };
};

const CountdownTimer = ({ targetDate, onComplete = () => {} }) => {
  const { minutes, seconds } = useCountdown(targetDate, onComplete);

  if (minutes + seconds <= 0) {
    return '0:00';
  } else {
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }
};

export default CountdownTimer;
