import './style.scss';
import React from 'react';
import Anim from '../Anim';
import Feature1 from '../../assets/images/main/features/1.svg'
import Feature2 from '../../assets/images/main/features/2.svg'
import Feature3 from '../../assets/images/main/features/3.svg'
import Feature4 from '../../assets/images/main/features/4.svg'
import Feature5 from '../../assets/images/main/features/5.svg'
import Feature6 from '../../assets/images/main/features/6.svg'
import Feature7 from '../../assets/images/main/features/7.svg'
import Feature8 from '../../assets/images/main/features/8.svg'

const iconList = {
  1: Feature1,
  2: Feature2,
  3: Feature3,
  4: Feature4,
  5: Feature5,
  6: Feature6,
  7: Feature7,
  8: Feature8,
}

const Features = ({featuresList}) => {

  const features = featuresList?.map((item, index) => {
    return (
      item.url ?
      <a href={item.url} key={item.title + index} className="features__item">
        <div className="features__item-wrap">
          <img src={item.img ?? iconList[item.icon]} className="features__item-pic" alt={item.title}/>
        </div>
        {Boolean(item.title) && <p className="features__item-title" dangerouslySetInnerHTML={{__html: item.title}} />}
        {Boolean(item.text) && <p className="features__item-text" dangerouslySetInnerHTML={{__html: item.text}} />}
      </a>
      :
      <div key={item.title + index} className="features__item">
        <div className="features__item-wrap">
          <img src={item.img ?? iconList[item.icon]} className="features__item-pic" alt={item.title}/>
        </div>
        {Boolean(item.title) && <p className="features__item-title" dangerouslySetInnerHTML={{__html: item.title}} />}
        {Boolean(item.text) && <p className="features__item-text" dangerouslySetInnerHTML={{__html: item.text}} />}
      </div>
    )
  })

  return (
    <Anim className="features-anim" id="safety">
      <div className={`features _x${featuresList.length}`}>
        <div className="wrap">
          <div className="features__items">
            {features}
          </div>
        </div>
      </div>
    </Anim>
  )
}

export default Features;
