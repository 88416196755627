import './style.scss';
import React, { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { numWithSpaces, scrollTo, trackGADataLayer, updateBasketDataLayer } from '../../common/utils';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputSwitch } from 'primereact/inputswitch';
import { Tooltip } from 'primereact/tooltip';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { Button } from 'primereact/button';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { inject, observer } from 'mobx-react';
import ClearCartModal from '../ClearCartModal';
import PartnerCartModal from '../PartnerCartModal';
import {pluralize, debounce, markResults, getApiPath, formatNumber} from '../utils';
import {
  ERROR_MESAGES,
  CONFIRM_CODE_LENGTH,
  CODE_RESEND_COOLDOWN,
  CHECKOUT_CODE_COOKIE_NAME,
  PHONE_MASK, PHONE_MASK_SLOT, MOBILE_WIDTH, MOBILE_WIDTH_1,
} from '../../common/const';
import Recaptcha from '../utils/recaptcha';
import Cookies from 'js-cookie';
import TimeCounter from '../TimeCounter';
import SearchableInputText from '../primereact/SearchableInputText';
import axios from "axios";
import pageData from "../../json/cart.json";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import PageContainer from "../PageContainer";
import MediaQuery from "react-responsive";
import initCloudpaymentsWidget from '../utils/init-cloudpayments-widget'

const cityDropdownOptionTemplate = (option) => {
  return (
      <div className="dropdown__select-city__list__item">
        <span className="dropdown__select-city__list__item__title" dangerouslySetInnerHTML={{__html: option.markedValue || option.value}} />
        <span className="dropdown__select-city__list__item__text">{option.additionalInfo}</span>
      </div>
  );
}

const contactsSchema = Yup.object().shape({
  // @TODO: add notDigits, notEngSymbols
  name: Yup.string()
    .required(ERROR_MESAGES.required),
  // @TODO: make optional, add notDigits, notEngSymbols
  // lastName: Yup.string()
  //   .min(2, "Слишком короткое")
  //   .max(50, "Слишком длинное")
  //   .required("Это обязательное поле"),
  email: Yup.string()
    .email(ERROR_MESAGES.formatEmail)
    .required(ERROR_MESAGES.required),
  // @TODO: add notFull
  phone: Yup.string()
    .required(ERROR_MESAGES.required),
});

const deliverySchema = Yup.object().shape({
  city: Yup.string()
    .required(ERROR_MESAGES.required),
  street: Yup.string()
    .required(ERROR_MESAGES.required),
  // @TODO: add notZero, digits
  home: Yup.string()
    .required(ERROR_MESAGES.requiredShort),
  date: Yup.string()
    .required(ERROR_MESAGES.required),
  time: Yup.string()
    .required(ERROR_MESAGES.required),
});

const path = process.env.REACT_APP_ASSETS_PATH;

const initialLoading = {
  basketReset: false,
  removeNotAvailable: false,
  bonus: false,
  promoCode: false,
  savedAddress: null,
  city: false,
  street: false,
  building: false,
  delivery: false,
  payment: false,
  phoneCode: false,
  confirmPhoneCode: false,
  order: false,
  verificationState: false,
};

const initialErrors = {
  basket: null,
  bonus: null,
  promoCode: null,
  street: null,
  home: null,
  delivery: null,
  date: null,
  time: null,
};

const groupProductsByAvailability = (products = [], isAvailable = true) => {
  return products.filter((product) => {
    if (product.isInstallation || product.isService) {
      return false;
    }
    return isAvailable ? product.maxCount !== 0 : product.maxCount === 0;
  }, []);
};

const Page = ({ store, match }) => {
  const app = document.getElementById('app');
  app.classList.add('dark');
  const recaptcha = new Recaptcha();

  const setCodeResendDateCookie = () => {
    const expires = new Date(
      new Date().getTime() + Number(CODE_RESEND_COOLDOWN) * 1000,
    );
    Cookies.set(CHECKOUT_CODE_COOKIE_NAME, `${expires}`, { expires });
  };

  const getCodeResendDateCookie = () => {
    return Cookies.get(CHECKOUT_CODE_COOKIE_NAME);
  }

  // STATES

  const topRef = useRef(null);
  const [step, setStep] = useState(1);
  const [cartData, setCartData] = useState(null);
  const [basketData, setBasketData] = useState(null);
  const [deliveryData, setDeliveryData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);
  const [summaryStatus, setSummaryStatus] = useState('');
  const [summaryTY, setSummaryTY] = useState(0);
  const [bonusCheckedState, setBonusCheckedState] = useState(false);
  const [isBonusEdit, setIsBonusEdit] = useState(false);
  const [bonusCustomAmount, setBonusCustomAmount] = useState('');
  const [promoCodeCheckedState, setPromoCodeCheckedState] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [promoCodeSuccessMessage, setPromoCodeSuccessMessage] = useState();
  // const [suggestedNames, setSuggestedNames] = useState([]);
  // const [suggestedLastNames, setSuggestedLastNames] = useState([]);
  const [contacts, setContacts] = useState({});
  const [contactsSavedInfo, setContactsSavedInfo] = useState('');
  const [cityId, setCityId] = useState(null);
  const [cityMessage, setCityMessage] = useState(null);
  const [cityErrorMessage, setCityErrorMessage] = useState(null);
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [suggestedStreets, setSuggestedStreets] = useState([]);
  const [suggestedBuildings, setSuggestedBuildings] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryInterval, setDeliveryInterval] = useState('');
  const [deliverySavedInfo, setDeliverySavedInfo] = useState('');
  const [orderVerificationOptions, setOrderVerificationOptions] = useState([]);
  const [isPhoneVerified, setIsPhoneVerified] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneCode, setPhoneCode] = useState('');
  const [isPhoneCodeVerified, setIsPhoneCodeVerified] = useState(false);
  const [codeResendDate, setCodeResendDate] = useState(getCodeResendDateCookie());
  const [courierType, setCourierType] = useState('CASH');
  const [clubDiscount, setClubDiscount] = useState({ isDisabled: true, isChecked: false });
  const [responseError, setResponseError] = useState();

  const [GAViewCart, setGAViewCart] = useState(false);

  // LOADING STATES
  // По какой-то причине loading.delivery не хотел сбрасываться

  const [isDeliveryLoading, setIsDeliveryLoading] = useState(false);

  // COMPUTED

  const productsAvailable = useMemo(() => {
    return groupProductsByAvailability(basketData?.products);
  }, [basketData]);

  const productsNotAvailable = useMemo(() => {
    return groupProductsByAvailability(basketData?.products, false);
  }, [basketData]);

  const promoCodeDiscount = useMemo(() => {
    return basketData?.promoCode?.discount || 0;
  }, [basketData]);

  const creditUnavailableText = useMemo(() => {
    const number = basketData?.credit.unavailableText.replace(/\D/g, '');

    if (number) {
      const index = basketData?.credit.unavailableText.indexOf(`${number} ₽`);

      if (index !== -1) {
        return basketData?.credit.unavailableText.replace(`${number} ₽`, `${numWithSpaces(number)} <span class="rub">P</span>`);
      }
    }
    return basketData?.credit.unavailableText;
  }, [basketData]);

  const isBonusDisabled = useMemo(() => {
    return !basketData?.bonus || basketData?.bonus?.isDisabled || !store.appConst.isAuth;
  }, [basketData, store.appConst.isAuth]);

  const bonusUsageAmount = useMemo(() => {
    return basketData?.bonus?.bonusSubtraction || basketData?.bonus?.bonusAvailable || 0;
  }, [basketData]);

  const isPromoCodeDisabled = useMemo(() => {
    return basketData?.promoCode?.isDisabled;
  }, [basketData]);

  const dateList = useMemo(() => {
    return [...deliveryData?.dateList || []];
  }, [deliveryData]);

  const intervalList = useMemo(() => {
    return [...deliveryData?.intervalList || []];
  }, [deliveryData]);

  const isSMSChecked = useMemo(() => {
    const { isChecked = false } = orderVerificationOptions.find((item) => item.type === 'SMS') || {};

    return isChecked;
  }, [orderVerificationOptions]);

  const productsInstallation = useMemo(() => {
    const defaultValue = { count: 0, totalCost: 0 };

    if (basketData) {
      return basketData?.products.reduce((acc, product) => {
        if (product.isInstallation) {
          const count = typeof product.itemBasketCount !== 'undefined'
            ? product.itemBasketCount // Обычные товары
            : product.count; // Установки

          return {
            ...acc,
            count: count ? acc.count + count : acc.count,
            totalCost: count ? acc.totalCost + product.total : acc.totalCost,
          };
        }
        return acc;
      }, defaultValue);
    }
    return defaultValue;
  }, [basketData]);

  // METHODS

  const setProductState = (product, state = {}) => {
    setBasketData({
      ...basketData,
      products: basketData.products.map((item, index) => {
        if (index === product.index) {
          return {
            ...item,
            ...state,
          };
        }
        return item;
      }),
    });
  };

  const setDelivery = (data, localData = deliveryData) => {
    const keys = ['city', 'cityId', 'street', 'entrance', 'flat', 'floor', 'home', 'intercom', 'serviceLift', 'comment', 'storage', 'dateList', 'intervalList'];

    setDeliveryData({
      ...(localData || {}),
      // Не все эндпоинты возвращают заполненные поля доставки,
      // поэтому в таких случаях используем существующие значения
      ...Object.entries(data).reduce((acc, [key, value]) => {
        if (keys.includes(key)) {
          const { [key]: existing } = localData || {};
          acc[key] = value || existing || '';
        }
        return acc;
      }, {}),
    });
  };

  const toggleBasketError = (data = {}) => {
    const message = !data.available && (data.basket?.errorMessage || data.messages?.warning);

    setErrors({ ...errors, basket: getBasketErrorMessage(message) });

    if (message) {
      scrollTo('scroll-target-top');
    }
  };

  const getBasketErrorMessage = (entry) => {
    if (Array.isArray(entry)) {
      return entry.map((item) => item.message).join('<br>');
    }
    return entry || null;
  };

  const initialSetCartData = (data) => {
    setCartData(data);
    setBonusCheckedState(Boolean(data.basket?.bonus?.bonusSubtraction && !data.basket?.bonus?.isDisabled));

    if (data.basket) {
      setBasketData(data.basket);
    }
    if (data.basket?.clubDiscount) {
      setClubDiscount(data.basket?.clubDiscount);
    }
    if (data.delivery) {
      setDeliveryData(data.delivery);
    }
    if (data.payment) {
      setPaymentData(data.payment);
    }
    if (data.orderVerification) {
      setOrderVerificationOptions(data.orderVerification);
    }
    toggleBasketError(data);
  };

  const updateCheckout = (data = {}, localData = {}) => {
    if (data.reload) {
      window.location.reload();
    } else {
      // @TODO: refactor
      if (data.basket) {
        setBasketData(data.basket);
      }
      if (typeof data.basket?.count !== 'undefined') {
        store.setBasketCount(data.basket.count);
      }
      if (data.basket?.bonus) {
        setIsBonusEdit(false);
      }
      if (typeof data.basket?.clubDiscount?.isChecked !== 'undefined') {
        setClubDiscount({
          ...clubDiscount,
          isChecked: data.basket?.clubDiscount?.isChecked,
        });
      }
      if (data.delivery) {
        setDelivery(data.delivery, localData.delivery);
      }
      if (data.payment) {
        setPaymentData(data.payment);
      }
      if (data.checkout?.contacts?.phone) {
        setPhoneNumber(data.checkout.contacts.phone);
      }
      if (data.orderVerification) {
        setOrderVerificationOptions(data.orderVerification);
      }
      toggleBasketError(data);

      // @TODO: updateOnlineBonus
      // @TODO: showPopupError
      // @TODO: toggleDeliveryAvailability
      // @TODO: togglePaymentBlock
    }
  };

  const sendResetBasket = () => {
    if (loading.basketReset) {
      return;
    }
    setLoading({ ...loading, basketReset: true });

    store.bridge.basketReset().then((response) => {
      const { status } = response?.data || {};

      switch (status) {
        case 'BASKET_RESET_SUCCESS':
          window.location.reload();
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, basketReset: false });
    }).catch(() => {
      setLoading({ ...loading, basketReset: false });
    });
  };

  const getProduct = (id) => {
    const product = basketData.products.find((item) => String(item.id) === String(id));

    if (!product) {
      return null;
    }
    const { index = basketData.products.indexOf(product) } = product;
    const itemBasketCount = typeof product.itemBasketCount !== 'undefined'
      ? product.itemBasketCount // Обычные товары
      : product.count; // Установки

    return {
      ...product,
      itemBasketCount,
      minCount: 0,
      index,
    };
  };

  const setProductAmount = (id, action, value) => {
    let product = getProduct(id);
    let count;
    let itemBasketCount;
    const productBasketCount = Number(product.itemBasketCount);
    const productMinCount = Number(product.minCount);
    const productMaxCount = Number(product.maxCount);

    switch (action) {
      case 'dec':
        count = productBasketCount - 1;
        itemBasketCount = count < productMinCount ? productMinCount : count;
        break;
      case 'inc':
        count = productBasketCount + 1;
        itemBasketCount = count > productMaxCount ? productMaxCount : count;
        break;
      default:
        count = Number(value);
        itemBasketCount = count;
    }

    if (count === 0 || itemBasketCount !== productBasketCount) {
      product = {
        ...product,
        itemBasketCount,
      };

      setProductState(product, product);
      debounceHandleProductAmountChange(product);
    }
  };

  const handleProductAmountChange = (product) => {
    const sendRemove = product.isInstallation ? sendRemoveInstallation : sendRemoveProduct;

    if (Number(product.itemBasketCount) === 0) {
      sendRemove(null, product);
    } else {
      sendSetQuantity(product);
    }
  };

  const sendRemoveProduct = (id, product = getProduct(id)) => {
    if (product.isRemoveLoading) {
      return;
    }
    setProductState(product, { isRemoveLoading: true });

    store.bridge.basketRemoveItem({
      id: String(product.id),
      isCheckout: true,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'BASKET_REMOVE_ITEM_SUCCESS':
          updateBasketDataLayer(product.productId, data);
          updateCheckout(data);
          break;
        case 'BASKET_REMOVE_ITEM_FAIL':
          setProductState(product, { isRemoveLoading: false });
          // this.Error.showMessage(data.errorMessage);
          break;
        default:
          setProductState(product, { isRemoveLoading: false });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setProductState(product, { isRemoveLoading: false });
    });
  };

  const sendSetQuantity = (product) => {
    setProductState(product, { ...product, isAmountLoading: true });

    store.bridge.basketSetItemQuantity({
      id: String(product.id),
      count: String(product.itemBasketCount),
      isCheckout: true,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'BASKET_CHANGE_ITEM_SUCCESS':
          if (!product.isInstallation) {
            updateBasketDataLayer(product.productId, data);
          }
          updateCheckout(data);
          break;
        case 'BASKET_CHANGE_ITEM_FAIL':
          setProductState(product, { isAmountLoading: false });
          // this.Error.showMessage(data.errorMessage);
          break;
        default:
          setProductState(product, { isAmountLoading: false });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setProductState(product, { isAmountLoading: false });
    });
  };

  const sendRemoveNotAvailableProducts = () => {
    if (loading.removeNotAvailable) {
      return;
    }
    setLoading({ ...loading, removeNotAvailable: true });

    store.bridge.basketRemoveItems({
      ids: productsNotAvailable.map((product) => Number(product.id)),
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'BASKET_REMOVE_ITEM_SUCCESS':
          trackGADataLayer(
            "remove_from_cart",
            {
              currency: "RUB",
              value: productsNotAvailable?.reduce((acc, product) => (acc + product.price), 0),
              items: productsNotAvailable?.map((product, index) => ({
                item_id: String(product.productId),
                item_name: product.shortName || product.name,
                index,
                item_brand: product.sectionName, // brand товара
                // item_category: "Кондиционеры", //категория товара
                // item_category2: "eletto",
                // item_variant: "Матовый черный", //вариант товара, если есть
                price: product.price,
                discount: product.priceDiscount,
                quantity: product.itemBasketCount,
              })),
            }
          );
          updateCheckout(data);
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, removeNotAvailable: false });
    }).catch(() => {
      setLoading({ ...loading, removeNotAvailable: false });
    });
  };

  const toggleInstallation = (isChecked, productId, installationId) => {
    if (isChecked) {
      sendAddInstallation(productId);
    } else {
      sendRemoveInstallation(installationId);
    }
  };

  const sendAddInstallation = (id, product = getProduct(id)) => {
    if (product.isInstallationLoading) {
      return;
    }
    setProductState(product, { isInstallationLoading: true, isInstallationChecked: true });

    store.bridge.basketAddMounting({
      id: String(product.id),
      count: String(product.itemBasketCount),
      isCheckout: true,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'BASKET_ADD_SUCCESS':
          updateCheckout(data);
          break;
        case 'BASKET_ADD_FAIL':
          setProductState(product, { isInstallationLoading: false, isInstallationChecked: false });
          // this.Error.showMessage(data.errorMessage);
          break;
        default:
          setProductState(product, { isInstallationLoading: false, isInstallationChecked: false });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setProductState(product, { isInstallationLoading: false, isInstallationChecked: false });
    });
  };

  const sendRemoveInstallation = (id, product = getProduct(id)) => {
    if (product.isAmountLoading) {
      return;
    }
    setProductState(product, {
      isAmountLoading: true,
      count: 0,
      isChecked: false,
    });

    store.bridge.basketRemoveItem({
      id: String(product.id),
      isCheckout: true,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'BASKET_REMOVE_ITEM_SUCCESS':
          updateCheckout(data);
          break;
        case 'BASKET_REMOVE_ITEM_FAIL':
          setProductState(product, {
            isAmountLoading: false,
            count: product.itemBasketCount,
            isChecked: true,
          });
          // this.Error.showMessage(data.errorMessage);
          break;
        default:
          setProductState(product, {
            isAmountLoading: false,
            count: product.itemBasketCount,
            isChecked: true,
          });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setProductState(product, {
        isAmountLoading: false,
        count: product.itemBasketCount,
        isChecked: true,
      });
    });
  };

  const toggleService = (isChecked, productId, serviceId) => {
    if (isChecked) {
      sendAddService(productId);
    } else {
      sendremoveService(serviceId);
    }
  };

  const sendAddService = (id, product = getProduct(id)) => {
    if (product.isServiceLoading) {
      return;
    }
    setProductState(product, { isServiceLoading: true, isServiceChecked: true });

    store.bridge.basketAddServiceToBasket({
      id: String(product.id),
      isCheckout: true,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'BASKET_ADD_SUCCESS':
          updateCheckout(data);
          break;
        case 'BASKET_ADD_FAIL':
          setProductState(product, { isServiceLoading: false, isServiceChecked: false });
          // this.Error.showMessage(data.errorMessage);
          break;
        default:
          setProductState(product, { isServiceLoading: false, isServiceChecked: false });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setProductState(product, { isServiceLoading: false, isServiceChecked: false });
    });
  };

  const sendremoveService = (id, product = getProduct(id)) => {
    if (product.isAmountLoading) {
      return;
    }
    setProductState(product, { isAmountLoading: true, isChecked: false });

    store.bridge.basketRemoveItem({
      id: String(product.id),
      isCheckout: true,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'BASKET_REMOVE_ITEM_SUCCESS':
          updateCheckout(data);
          break;
        case 'BASKET_REMOVE_ITEM_FAIL':
          setProductState(product, { isAmountLoading: false, isChecked: true });
          // this.Error.showMessage(data.errorMessage);
          break;
        default:
          setProductState(product, { isAmountLoading: false, isChecked: true });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setProductState(product, { isAmountLoading: false, isChecked: true });
    });
  };

  const sendUseBonus = (value = bonusUsageAmount) => {
    if (loading.bonus) {
      return;
    }
    setErrors({ ...errors, bonus: null });
    setLoading({ ...loading, bonus: true });

    store.bridge.basketUseBonus({
      value: String(value),
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CHECKOUT_BONUS_SUCCESS':
          updateCheckout(data);
          // @TODO: showPopupError
          break;
        case 'CHECKOUT_BONUS_FAIL':
          if (data.formErrors?.BONUSES || data.popupError?.text) {
            setErrors({ ...errors, bonus: data.formErrors.BONUSES || data.popupError.text });
          }
          // @TODO: showPopupError
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, bonus: false });
    }).catch(() => {
      setLoading({ ...loading, bonus: false });
    });
  };

  const sendCancelBonus = () => {
    if (loading.bonus) {
      return;
    }
    setLoading({ ...loading, bonus: true });

    store.bridge.basketCancelBonus().then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CHECKOUT_BONUS_CANCEL_SUCCESS':
          updateCheckout(data);
          // @TODO: showPopupError
          break;
        case 'CHECKOUT_BONUS_CANCEL_FAIL':
          // @TODO: showPopupError
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, bonus: false });
    }).catch(() => {
      setLoading({ ...loading, bonus: false });
    });
  };

  const toggleBonus = (isChecked) => {
    if (isChecked) {
      sendUseBonus();
    } else {
      sendCancelBonus();
    }
  };

  const sendUsePromoCode = async () => {
    if (loading.promoCode) {
      return;
    }
    setErrors({ ...errors, promoCode: null });
    setLoading({ ...loading, promoCode: true });
    setPromoCodeSuccessMessage(null);

    const token = await recaptcha.execute();

    store.bridge.basketUsePromocode({
      code: promoCodeValue,
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};
      const errorMessage = data.formErrors?.PROMO_CODE || data.popupError?.text || data.errorMessage;

      switch (status) {
        case 'PROMOCODE_ADD_SUCCESS':
          updateCheckout(data);
          setPromoCodeCheckedState(true);
          setErrors({ ...errors, promoCode: null });
          setPromoCodeSuccessMessage(null);

          if (data.successMessage) {
            setPromoCodeSuccessMessage(data.successMessage);
          }
          // @TODO: showPopupError
          break;
        case 'PROMOCODE_ADD_FAIL':
          updateCheckout(data);
          setPromoCodeCheckedState(false);
          setPromoCodeSuccessMessage(null);

          if (errorMessage) {
            setErrors({ ...errors, promoCode: errorMessage });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, promoCode: false });
    }).catch(() => {
      setLoading({ ...loading, promoCode: false });
    });
  };

  const sendCancelPromoCode = async () => {
    if (loading.promoCode) {
      return;
    }
    setErrors({ ...errors, promoCode: null });
    setLoading({ ...loading, promoCode: true });

    const token = await recaptcha.execute();

    store.bridge.basketCancelPromocode({
      value: String(promoCodeValue),
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};
      const errorMessage = data.formErrors?.PROMO_CODE || data.popupError?.text || data.errorMessage;

      switch (status) {
        case 'PROMOCODE_REMOVE_SUCCESS':
          updateCheckout(data);
          setPromoCodeCheckedState(false);
          setPromoCodeSuccessMessage(null);
          setPromoCodeValue('');
          // @TODO: showPopupError
          break;
        case 'PROMOCODE_REMOVE_FAIL':
          updateCheckout(data);
          setPromoCodeSuccessMessage(null);

          if (errorMessage) {
            setErrors({ ...errors, promoCode: errorMessage });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, promoCode: false });
    }).catch(() => {
      setLoading({ ...loading, promoCode: false });
    });
  };

  const togglePromoCode = (isChecked) => {
    if (isChecked) {
      sendUsePromoCode();
    } else {
      sendCancelPromoCode();
    }
  };

  // const handleNameChange = (value) => {
  //   setCartData({
  //     ...cartData,
  //     contacts: {
  //       ...cartData?.contacts,
  //       name: value.value,
  //     },
  //   });
  // };

  // const handleNameInput = (value) => {
  //   handleNameChange(value);
  //   debounceGetName();
  // };

  // const getName = () => {
  //   setLoading({ ...loading, name: true });
  //
  //   store.bridge.checkoutGetName({
  //     term: cartData?.contacts.name,
  //   }).then((response) => {
  //     const { suggestions = [] } = response?.data?.data || {};
  //
  //     if (suggestions.length) {
  //       setSuggestedNames(suggestions);
  //     }
  //     setLoading({ ...loading, name: false });
  //   }).catch(() => {
  //     setLoading({ ...loading, name: false });
  //   });
  // };

  // const handleLastNameChange = (value) => {
  //   setCartData({
  //     ...cartData,
  //     contacts: {
  //       ...cartData?.contacts,
  //       lastName: value.value,
  //     },
  //   });
  // };

  // const handleLastNameInput = (value) => {
  //   handleLastNameChange(value);
  //   debounceGetLastName();
  // };

  // const getLastName = () => {
  //   setLoading({ ...loading, lastName: true });
  //
  //   store.bridge.checkoutGetSecondName({
  //     term: cartData?.contacts.lastName,
  //   }).then((response) => {
  //     const { suggestions = [] } = response?.data?.data || {};
  //
  //     if (suggestions.length) {
  //       setSuggestedLastNames(suggestions);
  //     }
  //     setLoading({ ...loading, lastName: false });
  //   }).catch(() => {
  //     setLoading({ ...loading, lastName: false });
  //   });
  // };

  const sendContacts = (values) => {
    if (errors.basket) {
      scrollTo('scroll-target-top');
      return;
    }
    if (loading.contacts) {
      return;
    }
    setLoading({ ...loading, contacts: true });

    store.bridge.checkoutUpdateContacts({
      ...values,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CHECKOUT_UPDATE_CONTACTS_SUCCESS':
          trackGADataLayer(
            "begin_checkout",
            {
              currency: "RUB",
              value: basketData?.total, //полная стоимость товаров в корзине. С учетом купона, если применен купон
              coupon: basketData?.promoCode?.label, //купон, если есть
              items: productsAvailable?.map((product, index) => ({
                item_id: String(product.productId),
                item_name: product.shortName || product.name,
                index,
                item_brand: product.brand,
                item_category: product.category, //категория товара
                item_category2: product.category2,
                // item_variant: "Матовый черный", //вариант товара, если есть
                price: product.price,
                discount: product.priceDiscount,
                quantity: product.itemBasketCount,
              })),
            }
          );
          updateCheckout(data);

          if (data.checkout?.contacts?.savedInfo) {
            setContactsSavedInfo(data.checkout.contacts.savedInfo);
          }
          setStep(2);
          break;
        case 'CHECKOUT_UPDATE_CONTACTS_FAIL':
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, contacts: false });
    }).catch(() => {
      setLoading({ ...loading, contacts: false });
    });
  };

  const sendSavedAddress = (item) => {
    if (loading.savedAddress) {
      return;
    }
    setLoading({ ...loading, savedAddress: item.id });

    store.bridge.checkoutGetSavedAddress({
      id: String(item.id),
      isCheckout: true,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'SAVED_ADDRESS_GET_SUCCESS':
          if (data.delivery) {
            setDelivery(data.delivery);
          }
          if (data.delivery?.cityId) {
            if (suggestedCities.length) {
              const selected = {
                value: data.delivery?.city,
                id: data.delivery?.cityId,
              };
              const existing = suggestedCities.find((item) => item.id === selected.id);

              if (!existing) {
                setSuggestedCities([
                  ...suggestedCities,
                  selected,
                ]);
              }
            }
            setCityId(data.delivery?.cityId);
            setCityMessage(null);
            setCityErrorMessage(null);
          }
          setErrors({ ...errors, street: null, home: null });
          break;
        case 'SAVED_ADDRESS_GET_FAIL':
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, savedAddress: null });
    }).catch(() => {
      setLoading({ ...loading, savedAddress: null });
    });
  };

  const handleSearchInput = (value) => {
    const inputValue = value.trim();

    if (inputValue.length) {
      debounceGetCities(inputValue);
    } else {
      debounceGetPopularCities();
    }
  };

  const getPopularCities = (isInitial = false) => {
    setLoading({ ...loading, city: true });

    store.bridge.checkoutGetPopularCities().then((response) => {
      const { suggestions = [] } = response?.data?.data || {};

      if (suggestions.length) {
        const selected = {
          value: deliveryData.city,
          id: deliveryData.cityId,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(suggestions);
        } else {
          setSuggestedCities([
            ...suggestions,
            selected,
          ]);
        }
        if (isInitial) {
          handleCityChange((existing || selected).id, true);
        }
      }
      setLoading({ ...loading, city: false });
    }).catch(() => {
      setLoading({ ...loading, city: false });
    });
  };

  const getCities = (term) => {
    setLoading({ ...loading, cities: true });

    store.bridge.checkoutGetCity({
      term,
    }).then((response) => {
      const { suggestions = [] } = response?.data?.data || {};

      if (suggestions.length) {
        const selected = {
          value: deliveryData.city,
          id: deliveryData.cityId,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(markResults(suggestions, term));
        } else {
          setSuggestedCities([
            ...markResults(suggestions, term),
            selected,
          ]);
        }
      }
      setLoading({ ...loading, cities: false });
    }).catch(() => {
      setLoading({ ...loading, cities: false });
    });
  };

  const handleCityChange = (id, isInitial) => {
    const value = suggestedCities.find((item) => item.id === id);
    const data = {
      ...(deliveryData || {}),
      ...(isInitial ? {} : {
        street: '',
        streetId: '',
        home: '',
        dateSelected: '',
        intervalSelected: '',
        dateList: '',
        intervalList: '',
      }),
      city: value.value,
      cityId: value.id,
    };

    setDeliveryData(data);
    setCityId(value.id);
    setDeliveryDate('');
    setDeliveryInterval('');

    sendCityConfirm(data);
  };

  const sendCityConfirm = (delivery) => {
    setCityMessage(null);
    setCityErrorMessage(null);
    setLoading({ ...loading, city: true });

    store.bridge.checkoutConfirmCity({
      isCheckout: true,
      city: delivery.city,
      id: delivery.cityId,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CITY_CONFIRM_SUCCESS':
          updateCheckout(data, { delivery });

          if (data.message) {
            setCityMessage(data.message);
          }
          // @TODO: togglePaymentBlock
          break;
        case 'CITY_CONFIRM_FAIL':
          if (data.errorMessage) {
            setCityErrorMessage(data.errorMessage);
          }
          // @TODO: togglePaymentBlock
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, city: false });
    }).catch(() => {
      setLoading({ ...loading, city: false });
    });
  };

  const handleStreetChange = (value) => {
    setDeliveryData({
      ...(deliveryData || {}),
      street: value.value,
      streetId: value.id,
    });

    setErrors({ ...errors, street: null });
  };

  const handleStreetInput = (term) => {
    handleStreetChange({ value: term, id: '' });
    debounceGetStreets({
      term,
      cityId: deliveryData.cityId,
    });
  };

  const getStreets = ({ term = '', cityId = '' }) => {
    setLoading({ ...loading, street: true });

    store.bridge.checkoutGetStreet({
      term,
      cityId,
    }).then((response) => {
      const { suggestions = [] } = response?.data?.data || {};

      if (suggestions.length) {
        setSuggestedStreets(suggestions);
      }
      setLoading({ ...loading, street: false });
    }).catch(() => {
      setLoading({ ...loading, street: false });
    });
  };

  const handleBuildingChange = (value) => {
    setDeliveryData({
      ...(deliveryData || {}),
      home: value.value,
    });

    setErrors({ ...errors, home: null });
  };

  const handleBuildingInput = (term) => {
    handleBuildingChange({ value: term });
    debounceGetBuildings({
      term,
      streetId: deliveryData.streetId,
    });
  };

  const getBuildings = ({ term = '', streetId = '' }) => {
    setLoading({ ...loading, building: true });

    store.bridge.checkoutGetBuilding({
      term,
      streetId,
    }).then((response) => {
      const { suggestions = [] } = response?.data?.data || {};

      if (suggestions.length) {
        setSuggestedBuildings(suggestions);
      }
      setLoading({ ...loading, building: false });
    }).catch(() => {
      setLoading({ ...loading, building: false });
    });
  };

  const handleDeliveryFieldChange = (payload) => {
    setDeliveryData({
      ...(deliveryData || {}),
      ...payload,
    });
  };

  const handleDeliverySubmit = async () => {
    const data = getSelectedDeliveryData();

    try {
      await deliverySchema.validate(data, { abortEarly: false });
      sendDelivery();
    } catch (err) {
      const formErrors = err.inner.reduce((errors, currentError) => {
        return { ...errors, [currentError.path]: currentError.message };
      }, {});
      setErrors({ ...errors, ...formErrors });
    }
  };

  const getSelectedDeliveryData = (data) => {
    return {
      ...(deliveryData || {}),
      ...(data || {
        date: deliveryDate,
        time: deliveryInterval,
      }),
    };
  };

  const sendDelivery = (data, changeStep = true) => {
    // if (loading.delivery) {
    //   return;
    // }
    if (isDeliveryLoading) {
      return;
    }
    if (productsNotAvailable.length) {
      scrollTo('scroll-target-products-na');
      return;
    }
    // setLoading({ ...loading, delivery: true });
    setIsDeliveryLoading(true);

    store.bridge.checkoutUpdateAddress({
      ...getSelectedDeliveryData(data),
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CHECKOUT_UPDATE_ADDRESS_SUCCESS':
          updateCheckout(data, { delivery: deliveryData });

          if (changeStep) {
            trackGADataLayer(
              "add_shipping_info",
              {
                currency: "RUB",
                value: basketData?.total, //полная стоимость товаров в корзине. С учетом купона, если применен купон
                coupon: basketData?.promoCode?.label, //купон, если есть
                items: productsAvailable?.map((product, index) => ({
                  item_id: String(product.productId),
                  item_name: product.shortName || product.name,
                  index,
                  item_brand: product.brand,
                  item_category: product.category, //категория товара
                  item_category2: product.category2,
                  // item_variant: "Матовый черный", //вариант товара, если есть
                  price: product.price,
                  discount: product.priceDiscount,
                  quantity: product.itemBasketCount,
                })),
              }
            );
            if (data.checkout?.delivery?.savedInfo) {
              setDeliverySavedInfo(data.checkout.delivery.savedInfo);
            }
            setStep(3);
          }
          // @TODO: setCourierPayments
          break;
        case 'CHECKOUT_UPDATE_ADDRESS_FAIL':
          // @TODO: togglePaymentBlock
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      // setLoading({ ...loading, delivery: false });
      setIsDeliveryLoading(false);
    }).catch(() => {
      // setLoading({ ...loading, delivery: false });
      setIsDeliveryLoading(false);
    });
  };

  const handlePaymentChange = (name, isChooser) => {
    if (isChooser && !store.creditPlan) {
      store.setModal('partnerCart', paymentData);
    } else {
      setPayment(name);
    }
  };

  const setPayment = (name, isChooser) => {
    let id;

    setPaymentData(paymentData?.map((item) => {
      const isChecked = isChooser ? item.isChooser : item.name === name;

      if (isChecked) {
        id = item.isChooser ? store.creditPlan.id : item.id;
      }
      return {
        ...item,
        isChecked,
      };
    }));

    sendChangePayment(id);
  };

  const sendChangePayment = (id) => {
    if (loading.payment) {
      return;
    }
    setLoading({ ...loading, payment: true });

    store.bridge.checkoutChangePayment({
      isCheckout: true,
      id: String(id),
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CHECKOUT_CHANGE_PAYMENT_SUCCESS':
          updateCheckout(data);
          break;
        case 'CHECKOUT_CHANGE_PAYMENT_FAIL':
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, payment: false });
    }).catch(() => {
      setLoading({ ...loading, payment: false });
    });
  };

  const handleVerificationChange = (type) => {
    const options = orderVerificationOptions.map((item) => ({
      ...item,
      isChecked: item.type === type,
    }));

    setOrderVerificationOptions(options);
  };

  const getPhoneVerificationState = () => {
    // setIsPhoneVerified(false);
    // sendPhoneCode();
    // return;
    if (loading.verificationState) {
      return;
    }
    setLoading({ ...loading, verificationState: true });

    store.bridge.checkoutGetPhoneVerifiedState().then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'IS_PHONE_VERIFIED_SUCCESS':
          setIsPhoneVerified(true);

          if (data.message) {
            setPhoneNumber(data.message);
          }
          break;
        case 'IS_PHONE_VERIFIED_FAIL':
          setIsPhoneVerified(false);

          if (getCodeResendDateCookie()) {
            setCodeResendDate(getCodeResendDateCookie());
          } else {
            sendPhoneCode();
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, verificationState: false });
    }).catch(() => {
      setLoading({ ...loading, verificationState: false });
    });
  };

  const sendPhoneCode = () => {
    // setCodeResendDateCookie();
    // setCodeResendDate(getCodeResendDateCookie());
    // return;

    if (loading.phoneCode) {
      return;
    }
    setErrors({ ...errors, phoneCode: null });
    setLoading({ ...loading, phoneCode: true });

    store.bridge.checkoutSendCode().then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'SEND_CODE_SUCCESS':
          setCodeResendDateCookie();
          setCodeResendDate(getCodeResendDateCookie());

          if (data.message) {
            setPhoneNumber(data.message);
          }
          break;
        case 'SEND_CODE_FAIL':
          setErrors({ ...errors, phoneCode: 'Код подтверждения выслать не&nbsp;удалось.' });

          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, phoneCode: false });
    }).catch(() => {
      setLoading({ ...loading, phoneCode: false });
    });
  };

  const handlePhoneCodeInput = (code) => {
    if (code.length === CONFIRM_CODE_LENGTH) {
      sendConfirmPhoneCode(code);
    }
  };

  const sendConfirmPhoneCode = (code) => {
    if (loading.confirmPhoneCode) {
      return;
    }
    setErrors({ ...errors, phoneCode: null });
    setLoading({ ...loading, confirmPhoneCode: true });

    store.bridge.checkoutVerifyCode({
      code,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'VERIFY_CODE_SUCCESS':
          setIsPhoneCodeVerified(true);

          if (data.message) {
            setPhoneNumber(data.message);
          }
          break;
        case 'VERIFY_CODE_FAIL':
          setIsPhoneCodeVerified(false);
          setErrors({ ...errors, phoneCode: 'Неверный код, попробуйте ввести повторно' });

          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, confirmPhoneCode: false });
    }).catch(() => {
      setLoading({ ...loading, confirmPhoneCode: false });
    });
  };

  const getSelectedPaymentData = () => {
    const payment = paymentData?.find((item) => item.isChecked);

    if (payment.isChooser) {
      return {
        paymentType: store.creditPlan.id,
        creditDuration: store.creditPlan.period,
        creditPayment: store.creditPlan.payment,
        creditProvider: store.creditPlan.provider,
      };
    }
    return {
      ...(payment.name === 'PAYMENT_CASH' ? { courierType } : {}),
      paymentType: payment.id,
    };
  };

  const sendOrder = () => {
    if (loading.order) {
      return;
    }
    if (productsNotAvailable.length) {
      scrollTo('scroll-target-products-na');
      return;
    }
    setErrors({ ...errors, basket: null });
    setLoading({ ...loading, order: true });

    const data = {
      ...contacts,
      ...getSelectedDeliveryData(),
      ...getSelectedPaymentData(),
      confirmationType: orderVerificationOptions.find((item) => item.isChecked).type,
    };

    store.bridge.checkoutPlaceOrder({
      ...data,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CHECKOUT_SUBMIT_SUCCESS':
          trackGADataLayer(
            "add_payment_info",
            {
              currency: "RUB",
              value: basketData?.total, //полная стоимость товаров в корзине. С учетом купона, если применен купон
              coupon: basketData?.promoCode?.label, //купон, если есть
              items: productsAvailable?.map((product, index) => ({
                item_id: String(product.productId),
                item_name: product.shortName || product.name,
                index,
                item_brand: product.brand,
                item_category: product.category, //категория товара
                item_category2: product.category2,
                // item_variant: "Матовый черный", //вариант товара, если есть
                price: product.price,
                discount: product.priceDiscount,
                quantity: product.itemBasketCount,
              })),
            }
          );

          if (data.checkout?.payment?.widget?.show) {
            initCloudpaymentsWidget(data.dataLayer.transaction_id, () => {
              // не зависимо от успешности оплаты, при закрытии виджета отрабатывает редирект
              // оставляем прелоадер на кнопке во избежание повторных запросов
              // setLoading({ ...loading, order: false });
            });
          } else if (data.checkout?.payment?.link) {
            window.location.href = data.checkout.payment.link;
          } else {
            setLoading({ ...loading, order: false });
          }
          break;
        case 'CHECKOUT_SUBMIT_FAIL':
          if (data.products) {
            setBasketData({
              ...basketData,
              products: data.products,
            });
          }
          if (data.errorMessage) {
            setErrors({ ...errors, basket: getBasketErrorMessage(data.errorMessage) });
            scrollTo('scroll-target-top');
          }
          setLoading({ ...loading, order: false });
          break;
        default:
          setLoading({ ...loading, order: false });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setLoading({ ...loading, order: false });
    });
  };

  const toggleClubDiscount = (isChecked) => {
    if (clubDiscount.isLoading) {
      return;
    }
    setClubDiscount({
      ...clubDiscount,
      errorMessage: null,
      successMessage: null,
      isLoading: false,
    });

    store.bridge.orderUseClubDiscount({
      isChecked,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'CLUB_DISCOUNT_TOGGLE_SUCCESS':
          updateCheckout(data);

          setClubDiscount({
            ...clubDiscount,
            isChecked,
            isLoading: false,
            errorMessage: null,
            successMessage: data.successMessage || null,
          });
          if (!data.basket?.promoCode) {
            setPromoCodeCheckedState(false);
            setPromoCodeSuccessMessage(null);
            setPromoCodeValue('');
          }
          break;
        case 'CLUB_DISCOUNT_TOGGLE_FAIL':
          setClubDiscount({
            ...clubDiscount,
            isChecked: !isChecked,
            isLoading: false,
            errorMessage: data.errorMessage || null,
            successMessage: null,
          });
          break;
        default:
          setClubDiscount({
            ...clubDiscount,
            isChecked: !isChecked,
            isLoading: false,
            errorMessage: null,
            successMessage: null,
          });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setClubDiscount({
        ...clubDiscount,
        isChecked: !isChecked,
        isLoading: false,
        errorMessage: null,
        successMessage: null,
      });
    });
  };

  // @TODO: fix debouncing
  // const debounceGetName = debounce(getName);
  // const debounceGetLastName = debounce(getLastName);
  // const debounceSetProductAmount = debounce(setProductAmount);
  const debounceHandleProductAmountChange = debounce(handleProductAmountChange);
  const debounceToggleBonus = debounce(toggleBonus);
  const debounceTogglePromoCode = debounce(togglePromoCode);
  const debounceToggleClubDiscount = debounce(toggleClubDiscount);
  const debounceGetPopularCities = debounce(getPopularCities);
  const debounceGetCities = debounce(getCities);
  const debounceGetStreets = debounce(getStreets);
  const debounceGetBuildings = debounce(getBuildings);
  const debounceHandlePhoneCodeInput = debounce(handlePhoneCodeInput);
  const debounceToggleInstallation = debounce(toggleInstallation);
  const debounceToggleService = debounce(toggleService);

  // EFFECTS

  // Устанавливает заголовок страницы
  useEffect(() => {
    document.title = 'Корзина';
  }, [])

  // Отправляет GA событие при получении данных корзины
  useEffect(() => {
    if (!GAViewCart && basketData) {
      setGAViewCart(true);
      trackGADataLayer(
        "view_cart",
        {
          currency: "RUB",
          value: basketData?.total, // полная стоимость товаров в корзине
          items: productsAvailable?.map((product, index) => ({
            item_id: String(product.productId),
            item_name: product.shortName || product.name,
            index,
            item_brand: product.brand,
            item_category: product.category, //категория товара
            item_category2: product.category2,
            // item_variant: "Матовый черный", //вариант товара, если есть
            price: product.price,
            discount: product.priceDiscount,
            quantity: product.itemBasketCount,
          })),
        }
      );
    }
  }, [GAViewCart, basketData, productsAvailable])

  // Фиксирует сайдбар при скролле страницы
  useEffect(() => {
    let fixed = false;

    const onScroll = e => {
      if(!topRef.current){
        return;
      }

      const ot = topRef.current.getBoundingClientRect().top;
      const fixedOB = document.querySelector(`.cart-page__summary`).getBoundingClientRect().bottom + 40;
      const footerOT = document.querySelector(`.page-footer`).getBoundingClientRect().top;

      if(ot <= 128){
        if(!fixed){
          fixed = true;
          setSummaryStatus('_fixed');
        }

        if(fixedOB >= footerOT){
          setSummaryTY(footerOT-fixedOB);
        }
        else{
          setSummaryTY(0);
        }
      }
      else{
        if(fixed){
          fixed = false;
          setSummaryStatus('');
          setSummaryTY(0);
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  // Отправляет запрос на смену способа оплаты при выборе кредитного плана
  useEffect(() => {
    if (store.creditPlan) {
      setPayment(null, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.creditPlan]);

  // Обновляет стейты при изменении данных
  useEffect(() => {
    // Город доставки
    if (!cityId && deliveryData?.cityId) {
        setCityId(deliveryData.cityId);
        getPopularCities(true);
        sendCityConfirm(deliveryData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId, deliveryData]);

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      initialSetCartData(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      initialSetCartData(pageData);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    return () => {
      setCartData(null);
      setResponseError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  // Меняет цвет заднего фона
  useEffect(() => {
    app.classList.add('dark');

    return(() => {
      app.classList.remove('dark');
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  // Отправляет запрос на проверку подтвержденного номера телефона
  useEffect(() => {
    if (step === 3 && isSMSChecked && !isPhoneVerified) {
      getPhoneVerificationState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, isSMSChecked, isPhoneVerified]);

  // Устанавливает промокод
  useEffect(() => {
    if (!promoCodeValue && !promoCodeCheckedState && basketData?.promoCode?.value) {
      setPromoCodeValue(basketData?.promoCode?.value);
      setPromoCodeCheckedState(true);
    }
  }, [promoCodeValue, promoCodeCheckedState, basketData]);

  return (
    <PageContainer responseError={responseError} data={cartData}>
    <div className="cart-page page scroll-target-top">
      <div className="page-wrap">
        {!cartData?.isEmptyCart ? // Корзина с товарами
          <>
            <div className="cart-page__left">
              <Link to="/" className="cart-page__back"><span className="cart-page__arr _left" />Вернуться к покупкам</Link>
              <h1 className="cart-page__h1">Корзина</h1>
              <MediaQuery maxWidth={MOBILE_WIDTH}>
                <span className="cart-page__clear" onClick={() => {store.setModal('clearCart')}}>
                  Очистить
                </span>
              </MediaQuery>
              <MediaQuery minWidth={MOBILE_WIDTH_1}>
                <span className="cart-page__clear" onClick={() => {store.setModal('clearCart')}}>
                  Очистить корзину
                </span>
              </MediaQuery>
            </div>
            <div className="cart-page__top" ref={topRef}>
              <div className="cart-page__left">
                {errors.basket &&
                  <span className="cart-page__min-sum"><span className="cart-page__icon _error _left" />
                    {/*<span>Минимальная сумма заказа — <nobr>2 500 <span className="rub">P</span></nobr></span>*/}
                    <span>{errors.basket}</span>
                  </span>
                }
                {deliveryData?.storage && <span className="cart-page__delivery-date" dangerouslySetInnerHTML={{__html: deliveryData.storage}} />}
                <div className="cart-page__products">
                  {productsAvailable.map((item) => {
                    const installation = item.installationId ? getProduct(item.installationId) : null;
                    const service = item.serviceId ? getProduct(item.serviceId) : null;

                    return <Product
                      key={item.id}
                      store={store}
                      loading={loading}
                      errors={errors}
                      item={item}
                      notAvailable={false}
                      sendRemoveProduct={sendRemoveProduct}
                      setProductAmount={setProductAmount}
                      installation={installation}
                      service={service}
                      debounceToggleInstallation={debounceToggleInstallation}
                      debounceToggleService={debounceToggleService}
                      debounceHandleProductAmountChange={debounceHandleProductAmountChange}
                    />
                  })}
                  {Boolean(productsNotAvailable.length) &&
                    <div className="cart-page__products__item__out-of-stock scroll-target-products-na">
                      <span>Нет в наличии: {productsNotAvailable.length} {pluralize(productsNotAvailable.length, ['товар', 'товара', 'товаров'])}</span>
                      <span className="cart-page__products__item__trash" onClick={() => {
                        sendRemoveNotAvailableProducts();
                      }}>Удалить</span>
                    </div>
                  }
                  {productsNotAvailable.map((item) =>
                    <Product
                      key={item.id}
                      store={store}
                      loading={loading}
                      errors={errors}
                      item={item}
                      notAvailable={true}
                      sendRemoveProduct={sendRemoveProduct}
                      setProductAmount={setProductAmount}
                    />
                  )}
                </div>
                <Benefit
                  basketData={basketData}
                  loading={loading}
                  errors={errors}
                  isBonusDisabled={isBonusDisabled}
                  isBonusEdit={isBonusEdit}
                  setIsBonusEdit={setIsBonusEdit}
                  bonusCheckedState={bonusCheckedState}
                  setBonusCheckedState={setBonusCheckedState}
                  bonusUsageAmount={bonusUsageAmount}
                  bonusCustomAmount={bonusCustomAmount}
                  setBonusCustomAmount={setBonusCustomAmount}
                  promoCodeCheckedState={promoCodeCheckedState}
                  setPromoCodeCheckedState={setPromoCodeCheckedState}
                  promoCodeSuccessMessage={promoCodeSuccessMessage}
                  isPromoCodeDisabled={isPromoCodeDisabled}
                  promoCodeValue={promoCodeValue}
                  setPromoCodeValue={setPromoCodeValue}
                  debounceToggleBonus={debounceToggleBonus}
                  sendUseBonus={sendUseBonus}
                  sendUsePromoCode={sendUsePromoCode}
                  sendCancelPromoCode={sendCancelPromoCode}
                  debounceTogglePromoCode={debounceTogglePromoCode}
                  clubDiscount={clubDiscount}
                  debounceToggleClubDiscount={debounceToggleClubDiscount}
                  store={store}
                />
              </div>
              <div className="cart-page__right">
                <div className={`cart-page__summary ${summaryStatus}`}>
                  <div className="cart-page__summary__outer-wrap" style={{transform: `translateY(${summaryTY}px)`}}>
                    <div className="cart-page__summary__inner-wrap">
                      <div className="cart-page__summary__row">
                        <div>{basketData?.count} {pluralize(basketData?.count, ['товар', 'товара', 'товаров'])} на сумму</div>
                        <div>{numWithSpaces(basketData?.totalWithoutDiscount)} <span className="rub">P</span></div>
                      </div>
                      {Boolean(basketData?.discount) &&
                        <div className="cart-page__summary__row">
                          <div>Скидка</div>
                          <div>{basketData?.discountLeadingMinus ? '- ' : ''}{numWithSpaces(basketData?.discount)} <span className="rub">P</span></div>
                        </div>
                      }
                      {Boolean(basketData?.bonus?.bonusSubtraction) &&
                        <div className="cart-page__summary__row">
                          <div>Оплата Haier-баллами</div>
                          <div>{numWithSpaces(basketData?.bonus?.bonusSubtraction)} <span className="rub">P</span></div>
                        </div>
                      }
                      {Boolean(promoCodeDiscount) &&
                        <div className="cart-page__summary__row">
                          <div>Промокод</div>
                          <div>{numWithSpaces(promoCodeDiscount)} <span className="rub">P</span></div>
                        </div>
                      }
                      <div className="cart-page__summary__row">
                        <div>Доставка</div>
                        {basketData?.delivery ?
                          <div>{numWithSpaces(basketData?.delivery)} <span className="rub">P</span></div>
                        :
                          <div>Бесплатно</div>
                        }
                      </div>
                      {Boolean(productsInstallation?.count) && <div className="cart-page__summary__row">
                        <div>{productsInstallation?.count} {pluralize(productsInstallation?.count, ['установка', 'установки', 'установок'])}</div>
                        <div>{productsInstallation?.totalCost
                          ? <><span dangerouslySetInnerHTML={{__html: formatNumber(productsInstallation?.totalCost)}} />&nbsp;<span className="rub">P</span></>
                          : 'Бесплатно'}
                        </div>
                      </div>}
                      <div className="cart-page__summary__row _summary">
                        <div>Итого</div>
                        <div>{numWithSpaces(basketData?.total)} <span className="rub">P</span></div>
                      </div>
                      {Boolean(basketData?.bonus?.countTotal) &&
                        <div className="cart-page__summary__row _bonus">
                          <div>Баллы за заказ<span className="cart-page__question" /></div>
                          <div>+ {numWithSpaces(basketData?.bonus?.countTotal)} {pluralize(basketData?.bonus?.countTotal, ['Балл', 'Балла', 'Баллов'])}</div>
                        </div>
                      }
                      <Button
                        className="p-button-primary p-button-white"
                        label="Продолжить оформление"
                        onClick={() => {
                          if (!store.appConst.isAuth) {
                            scrollTo('scroll-target-signup');
                          } else if (errors.basket) {
                            scrollTo('scroll-target-top');
                          } else {
                            scrollTo('scroll-target-steps');
                          }
                        }}
                      />
                      <div className="cart-page__line" />
                      {basketData?.credit?.isAvailable &&
                        <>
                          <Button
                            className="p-button-primary p-button-transparent _installment"
                            data-pr-position="bottom"
                            label={<>Рассрочка от {numWithSpaces(basketData?.credit?.monthlyPayment)} <span className="rub">P</span>/мес</>}
                          />
                          <Tooltip className="cart-page__summary__tooltip" target="._installment" autoHide={false}>
                            <span className="cart-page__summary__tooltip__title">Вам доступна рассрочка на&nbsp;выбранные товары</span>
                            <div className="cart-page__summary__tooltip__row">
                              <span className="cart-page__summary__tooltip__label">Срок рассрочки</span>
                              <span className="cart-page__summary__tooltip__value">{basketData?.credit?.duration}</span>
                            </div>
                            <div className="cart-page__summary__tooltip__row">
                              <span className="cart-page__summary__tooltip__label">Платеж в месяц</span>
                              <span className="cart-page__summary__tooltip__value">от {numWithSpaces(basketData?.credit?.monthlyPayment)} <span className="rub">P</span></span>
                            </div>
                            <div className="cart-page__summary__tooltip__row">
                              <span className="cart-page__summary__tooltip__label">Первоначальный взнос</span>
                              <span className="cart-page__summary__tooltip__value">{basketData?.credit?.initialPayment} <span className="rub">P</span></span>
                            </div>
                            <div className="cart-page__summary__tooltip__row">
                              <span className="cart-page__summary__tooltip__label">Переплата</span>
                              <span className="cart-page__summary__tooltip__value">{basketData?.credit?.overPayment} <span className="rub">P</span></span>
                            </div>
                            {basketData?.credit?.showBuyInCreditBtn &&
                              <Button className="p-button-secondary p-button-white" label="Оформить рассрочку" onClick={() => scrollTo('scroll-target-steps')} />
                            }
                            {basketData?.credit?.termsLink &&
                              <a className="cart-page__summary__more" href={"/content/aktsiya-rassrochka-casarte"}>Условия рассрочки<span className="cart-page__arr _right" /></a>
                            }
                          </Tooltip>
                        </>
                      }
                      {basketData?.credit?.unavailableText &&
                        <span className="cart-page__summary__no-avaible" dangerouslySetInnerHTML={{__html: creditUnavailableText}} />
                      }
                      {basketData?.credit?.termsLink &&
                        <a className="cart-page__summary__more" href={"/content/aktsiya-rassrochka-casarte"}>Подробнее о рассрочке<span className="cart-page__arr _right" /></a>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!store.appConst.isAuth ? // Шаги без авторизации
              <div className="cart-page__left scroll-target-signup">
                <p className="cart-page__h2 mt-d-60 mt-t-40 mt-m-30">Оформление заказа</p>
                <p className="cart-page__login">Для продолжения войдите или зарегистрируйтесь.</p>
                <div className="p-fields-grid">
                  <div className="p-fields-grid__item m-100">
                    <Button
                      className="p-button-secondary p-button-white min-w"
                      label="войти"
                      onClick={() => store.setModal('auth')}
                    />
                  </div>
                  <div className="p-fields-grid__item m-100">
                    <Button
                      className="p-button-secondary p-button-transparent min-w"
                      label="Зарегистрироваться"
                      onClick={() => store.setModal('register')}
                    />
                  </div>
                </div>
              </div>
            : // Шаги с авторизацией
              <div className="cart-page__left scroll-target-steps">
                {step !== 1 ?
                  <Step num="1" title="Контактные данные получателя" className="mt-d-60 mt-t-40 mt-m-30">
                    <div className="p-fields-grid _m-t">
                      <div className="p-fields-grid__item d-70-4 m-100 _p-d-l _p-m-l">
                        {contactsSavedInfo &&
                          <span className="_opacity" dangerouslySetInnerHTML={{__html: contactsSavedInfo}} />
                        }
                      </div>
                      <div className="p-fields-grid__item d-29-6 m-100 _m-t">
                        <Button className="p-button-secondary p-button-transparent" label="Изменить данные" onClick={() => setStep(1)} />
                      </div>
                    </div>
                  </Step>
                :
                  <Step num="1" title="Контактные данные получателя" className="mt-d-60 mt-t-40 mt-m-30">
                    <Formik
                      initialValues={{
                        name: cartData?.contacts?.name || '',
                        lastName: cartData?.contacts?.lastName || '',
                        email: cartData?.contacts?.email || '',
                        phone: cartData?.contacts?.phone || '',
                      }}
                      validationSchema={contactsSchema}
                      onSubmit={(values) => {
                        setContacts(values);
                        sendContacts(values);
                      }}
                    >
                      {({errors, values, setFieldValue}) => (
                        <Form>
                          <div className="p-fields-grid">
                            <div className="p-fields-grid__item d-50 m-100">
                              <span className="p-float-label">
                                <InputText
                                  className={errors.name ? 'p-invalid' : ''}
                                  id="name"
                                  value={values.name}
                                  onChange={(e) => setFieldValue('name', e.target.value)}
                                />
                                <label htmlFor="name">Имя</label>
                              </span>
                              {errors.name && <span className="p-error">{errors.name}</span>}
                            </div>
                            <div className="p-fields-grid__item d-50 m-100">
                              <span className="p-float-label">
                                <InputText
                                  className={errors.lastName ? 'p-invalid' : ''}
                                  id="lastName"
                                  value={values.lastName}
                                  onChange={(e) => setFieldValue('lastName', e.target.value)}
                                />
                                <label htmlFor="lastName">Фамилия</label>
                              </span>
                              {errors.lastName && <span className="p-error">{errors.lastName}</span>}
                            </div>
                            <div className="p-fields-grid__item d-50 m-100">
                              <span className="p-float-label">
                                <InputText
                                  className={errors.email ? 'p-invalid' : ''}
                                  id="email"
                                  value={values.email}
                                  onChange={(e) => setFieldValue('email', e.target.value)}
                                />
                                <label htmlFor="email">E-mail</label>
                              </span>
                              {errors.email && <span className="p-error">{errors.email}</span>}
                            </div>
                            <div className="p-fields-grid__item d-50 m-100">
                              <span className="p-float-label">
                                <InputMask
                                  className={errors.phone ? 'p-invalid' : ''}
                                  id="phone"
                                  value={values.phone}
                                  onChange={(e) => setFieldValue('phone', e.target.value)}
                                  mask={PHONE_MASK}
                                  slotChar={PHONE_MASK_SLOT}
                                />
                                <label htmlFor="phone">Номер телефона</label>
                              </span>
                              {errors.phone && <span className="p-error">{errors.phone}</span>}
                            </div>
                            <div className="p-fields-grid__btn">
                              <div className="p-fields-grid__item d-29-6 m-100">
                                <Button
                                  className={`p-button-secondary p-button-transparent ${loading.contacts ? 'p-loader' : ''}`}
                                  type="submit"
                                  label="Продолжить"
                                />
                              </div>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </Step>
                }
                {step !== 2 ?
                  !deliverySavedInfo ?
                    <Step num="2" title="Адрес доставки" className="_disabled"/>
                  : <Step num="2" title="Адрес доставки">
                      <div className="p-fields-grid _m-t">
                        <div className="p-fields-grid__item d-70-4 m-100 _p-d-l _p-m-l">
                          {Boolean(deliverySavedInfo) &&
                            <span className="_opacity" dangerouslySetInnerHTML={{__html: deliverySavedInfo}} />
                          }
                        </div>
                        <div className="p-fields-grid__item d-29-6 m-100 _m-t">
                          <Button className="p-button-secondary p-button-transparent" label="Изменить данные" onClick={() => setStep(2)} />
                        </div>
                        <div className="p-fields-grid__item d-100 m-100">
                          <DeliveryDate
                            id="1"
                            deliveryData={deliveryData}
                            productsAvailable={productsAvailable}
                            dateList={dateList}
                            intervalList={intervalList}
                            deliveryDate={deliveryDate}
                            deliveryInterval={deliveryInterval}
                            handleDateChange={(value) => {
                              setDeliveryDate(value);
                              setErrors({ ...errors, date: null });
                              sendDelivery();
                            }}
                            handleIntervalChange={(value) => {
                              setDeliveryInterval(value);
                              setErrors({ ...errors, time: null });
                              sendDelivery();
                            }}
                            errors={errors}
                            isDeliveryLoading={isDeliveryLoading}
                          />
                        </div>
                      </div>
                    </Step>
                  : <Step num="2" title="Адрес доставки">
                    <p className="cart-page__step__title">История адресов</p>
                    {cartData?.savedAddresses.map((address) =>
                      <p key={address.id} className="cart-page__step__text" onClick={() => sendSavedAddress(address)}>
                        <span dangerouslySetInnerHTML={{__html: address.text}} />
                        {loading.savedAddress === address.id
                          ? <span style={{position: 'relative', display: 'inline-block', marginLeft: '15px', width: '20px', height: '16px', verticalAlign: 'middle'}}>
                            <div className={'p-loader p-loader-primary'} />
                          </span>
                          : <span className="cart-page__step-2__select">Выбрать<span className="cart-page__arr _right"/></span>}
                      </p>
                    )}
                    <div className="cart-page__line"/>
                    <p className="cart-page__step__title">Новый адрес</p>
                    <p className="cart-page__step__text _secondary">Убедитесь, что правильно указали населённый пункт — от этого зависят доступные виды доставки.</p>
                    <div className="p-fields-grid mt-d-3">
                      <div className="p-fields-grid__item d-50 m-100">
                        <span className="p-float-label">
                          <Dropdown
                            className=""
                            panelClassName={`dropdown__select-city__panel ${loading.city ? '_loader' : ''}`}
                            itemTemplate={cityDropdownOptionTemplate}
                            inputId="deliveryCity"
                            value={cityId}
                            options={suggestedCities}
                            onChange={(e) => handleCityChange(e.value)}
                            onFilter={(e) => handleSearchInput(e.filter)}
                            filter
                            optionLabel="value"
                            optionValue="id"
                          />
                          <label htmlFor="deliveryCity">Город</label>
                        </span>
                      </div>
                      <div className="p-fields-grid__item d-50 m-100">
                        <SearchableInputText
                          className={errors.street ? 'p-invalid' : ''}
                          id="deliveryStreet"
                          value={deliveryData?.street}
                          label="Улица"
                          loading={loading.street}
                          suggestions={suggestedStreets}
                          onInput={(value) => handleStreetInput(value)}
                          onSelect={(value) => handleStreetChange(value)}
                        />
                        {errors.street &&
                          <span className="p-error" dangerouslySetInnerHTML={{__html: errors.street}} />
                        }
                      </div>
                      {cityMessage &&
                        <div className="p-fields-grid__item d-100 m-100">
                          <span className="p-fields-grid__message" dangerouslySetInnerHTML={{__html: cityMessage}} />
                        </div>
                      }
                      {cityErrorMessage &&
                        <div className="p-fields-grid__item d-100 m-100">
                          <span className="p-fields-grid__error" dangerouslySetInnerHTML={{__html: cityErrorMessage}} />
                        </div>
                      }
                      <div className="p-fields-grid__item d-20 m-50">
                        <SearchableInputText
                          className={errors.home ? 'p-invalid' : ''}
                          id="deliveryHome"
                          value={deliveryData?.home}
                          label="Дом"
                          loading={loading.building}
                          suggestions={suggestedBuildings}
                          onInput={(value) => handleBuildingInput(value)}
                          onSelect={(value) => handleBuildingChange(value)}
                        />
                        {errors.home &&
                          <span className="p-error" dangerouslySetInnerHTML={{__html: errors.home}} />
                        }
                      </div>
                      <div className="p-fields-grid__item d-20 m-50">
                        <span className="p-float-label">
                          <InputNumber
                            className={errors.flat ? 'p-invalid' : ''}
                            inputId="deliveryFlat"
                            value={deliveryData?.flat || null}
                            onValueChange={(e) => handleDeliveryFieldChange({ flat: e.value })}
                          />
                          <label htmlFor="deliveryFlat">Кв/офис</label>
                        </span>
                        {errors.flat &&
                          <span className="p-error" dangerouslySetInnerHTML={{__html: errors.flat}} />
                        }
                      </div>
                      <div className="p-fields-grid__item d-20 m-50">
                        <span className="p-float-label">
                          <InputNumber
                            className={errors.entrance ? 'p-invalid' : ''}
                            inputId="deliveryEntrance"
                            value={deliveryData?.entrance || null}
                            onValueChange={(e) => handleDeliveryFieldChange({ entrance: e.value })}
                          />
                          <label htmlFor="deliveryEntrance">Подъезд</label>
                        </span>
                        {errors.entrance &&
                          <span className="p-error" dangerouslySetInnerHTML={{__html: errors.entrance}} />
                        }
                      </div>
                      <div className="p-fields-grid__item d-20 m-50">
                        <span className="p-float-label">
                          <InputNumber
                            className={errors.floor ? 'p-invalid' : ''}
                            inputId="deliveryFloor"
                            value={deliveryData?.floor || null}
                            onValueChange={(e) => handleDeliveryFieldChange({ floor: e.value })}
                          />
                          <label htmlFor="deliveryFloor">Этаж</label>
                        </span>
                        {errors.floor &&
                          <span className="p-error" dangerouslySetInnerHTML={{__html: errors.floor}} />
                        }
                      </div>
                      <div className="p-fields-grid__item d-20 m-100">
                        <span className="p-float-label">
                          <InputNumber
                            className={errors.intercom ? 'p-invalid' : ''}
                            inputId="deliveryIntercom"
                            value={deliveryData?.intercom || null}
                            onValueChange={(e) => handleDeliveryFieldChange({ intercom: e.value })}
                          />
                          <label htmlFor="deliveryIntercom">Домофон</label>
                        </span>
                        {errors.intercom &&
                          <span className="p-error" dangerouslySetInnerHTML={{__html: errors.intercom}} />
                        }
                      </div>
                      <div className="p-fields-grid__item d-100 m-100">
                        <span className="p-float-label">
                          <InputTextarea
                            className=""
                            id="deliveryComment"
                            value={deliveryData?.comment}
                            onChange={(e) => handleDeliveryFieldChange({ comment: e.target.value })}
                          />
                          <label htmlFor="deliveryComment">Комментарий к заказу</label>
                        </span>
                      </div>
                      <div className="p-fields-grid__item d-100 m-100">
                        <div className="flex align-items-center">
                          <Checkbox
                            className=""
                            inputId="deliveryServiceLift"
                            checked={deliveryData?.serviceLift}
                            onChange={(e) => handleDeliveryFieldChange({ serviceLift: e.checked })}
                          />
                          <label htmlFor="deliveryServiceLift">Есть грузовой лифт</label>
                        </div>
                      </div>
                      <div className="p-fields-grid__item d-100 m-100">
                        <DeliveryDate
                          id="2"
                          deliveryData={deliveryData}
                          productsAvailable={productsAvailable}
                          dateList={dateList}
                          intervalList={intervalList}
                          deliveryDate={deliveryDate}
                          deliveryInterval={deliveryInterval}
                          handleDateChange={(value) => {
                            setDeliveryDate(value);
                            setErrors({ ...errors, date: null });
                            sendDelivery({ date: value, time: '' }, false);
                          }}
                          handleIntervalChange={(value) => {
                            setDeliveryInterval(value);
                            setErrors({ ...errors, time: null });
                          }}
                          errors={errors}
                          isDeliveryLoading={isDeliveryLoading}
                        />
                      </div>
                      <div className="p-fields-grid__btn">
                        <div className="p-fields-grid__item d-29-6 m-100">
                          <Button
                            className={`p-button-secondary p-button-transparent ${isDeliveryLoading ? 'p-loader' : ''}`}
                            type="button"
                            disabled={Boolean(cityErrorMessage)}
                            label="Продолжить"
                            onClick={() => {
                              handleDeliverySubmit();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Step>
                }
                {step !== 3 ?
                  <Step num="3" title="Оплата" className="_disabled"/>
                :
                  <Step num="3" title="Оплата" price={basketData?.total}>
                    {paymentData?.map((item, index) =>
                      <div key={item.name} className={`cart-page__step-3__wrap ${index === 0 ? 'mt-d-4' : ''}`} style={item.isHidden ? { display: 'none' } : {}}>
                        <div className="flex align-items-center">
                          <InputSwitch
                            className=""
                            inputId={item.name}
                            checked={item.isChecked}
                            disabled={item.isDisabled}
                            onChange={() => handlePaymentChange(item.name, item.isChooser)}
                          />
                          {item.parts ?
                            <label htmlFor="bonusCheckedState"><img src={`${path}images/cart/chastyami.svg`} alt={item.title} /></label>
                          :
                            <label htmlFor={item.name} dangerouslySetInnerHTML={{__html: item.title}} />
                          }
                        </div>
                        <div className="p-fields-grid">
                          <div className="p-fields-grid__item d-100 m-100 _p-d-l">
                            <div className="_secondary" dangerouslySetInnerHTML={{__html: item.message}} />
                            {item.name === 'PAYMENT_ONLINE' &&
                              /*<div className="cart-page__step-3__cards">
                                <img src={item.img} alt=""/>
                              </div>*/
                              <div className="cart-page__step-3__cards">
                                <img src={`${path}images/cart/visa.svg`} alt="Visa"/>
                                <img src={`${path}images/cart/mastercard.svg`} alt="Master Card"/>
                                <img src={`${path}images/cart/mir.svg`} alt="Мир"/>
                                <img src={`${path}images/cart/xalva.svg`} alt="Халва"/>
                                <img src={`${path}images/cart/union.svg`} alt="Union Pay"/>
                              </div>
                            }
                            {item.name === 'PAYMENT_CASH' && !item.isDisabled && item.isChecked &&
                              <div className="cart-page__step-3__payment">
                                <Button
                                  className={`p-button-secondary p-button-transparent ${courierType === 'CASH' ? '' : '_v2'}`}
                                  label="Наличными"
                                  onClick={() => setCourierType('CASH')}
                                />
                                <Button
                                  className={`p-button-secondary p-button-transparent ${courierType === 'CARD' ? '' : '_v2'}`}
                                  label="По карте"
                                  onClick={() => setCourierType('CARD')}
                                />
                              </div>
                            }
                            {item.isChooser && store.creditPlan &&
                              <div className="p-fields-grid cart-page__step-3__grid">
                                <div className="p-fields-grid__item d-25 m-100">
                                  <div className="cart-page__step-3__group">
                                    <span className="cart-page__step-3__label">Банк</span>
                                    <span className="cart-page__step-3__value"><img src={store.creditPlan.logo} alt={store.creditPlan.title} /></span>
                                  </div>
                                </div>
                                <div className="p-fields-grid__item d-25 m-100">
                                  <div className="cart-page__step-3__group">
                                    <span className="cart-page__step-3__label">Срок рассрочки</span>
                                    <span className="cart-page__step-3__value" dangerouslySetInnerHTML={{__html: store.creditPlan.period}} />
                                  </div>
                                </div>
                                <div className="p-fields-grid__item d-25 m-100">
                                  <div className="cart-page__step-3__group">
                                    <span className="cart-page__step-3__label">Платёж в месяц</span>
                                    <span className="cart-page__step-3__value"><nobr>{numWithSpaces(store.creditPlan.payment)} <span className="rub">P</span></nobr></span>
                                  </div>
                                </div>
                                <div className="p-fields-grid__item d-25 m-100">
                                  <Button className="p-button-secondary p-button-white" label="Изменить" type="button" onClick={() => {
                                    store.setModal('partnerCart', paymentData);
                                  }} />
                                </div>
                              </div>
                            }
                            {item.isChooser && !store.creditPlan && item.isChecked &&
                              <div className="p-fields-grid cart-page__step-3__grid">
                                <div className="p-fields-grid__item d-25 m-100">
                                  <Button className="p-button-secondary p-button-white" label="Выбрать" type="button" onClick={() => store.setModal('partnerCart', paymentData)} />
                                </div>
                              </div>
                            }
                            {item.parts && !item.isDisabled &&
                              <div className="p-fields-grid cart-page__step-3__grid cart-page__step-3__rents">
                                {item.parts.map((part) =>
                                  <div key={part.date} className="p-fields-grid__item d-25 m-100">
                                    <div className="cart-page__step-3__group">
                                      <span className="cart-page__step-3__line"/>
                                      <span className="cart-page__step-3__label" dangerouslySetInnerHTML={{__html: part.date}} />
                                      <span className="cart-page__step-3__value"><nobr>{numWithSpaces(part.sum.replace(/\D/g, ''))} <span className="rub">P</span></nobr></span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            }
                          </div>
                        </div>
                      </div>
                    )}

                    <p className="cart-page__step__sub-title mt-d-40 mt-t-30 mt-m-35">Подтверждение заказа</p>

                    {orderVerificationOptions.map((item) =>
                      <div key={item.type}>
                        <div className="flex align-items-center mt-d-30 mt-t-25 mt-m-35">
                          <RadioButton inputId={item.type} name="orderConfirmation" value={item.type} onChange={() => handleVerificationChange(item.type)}
                                       checked={item.isChecked} />
                          <label htmlFor={item.type} dangerouslySetInnerHTML={{__html: item.title}} />
                        </div>
                        {item.text ? <p className="_secondary ml-d-35" dangerouslySetInnerHTML={{__html: item.text}} /> : null}
                      </div>
                    )}

                    {isSMSChecked && isPhoneVerified ? <div className="cart-page__step-3__wrap _sms">
                      <p>Подтверждение по SMS</p>
                      <p className="_secondary">После оформления заказа вам придет SMS на номер {phoneNumber}</p>
                    </div> : null}

                    {isSMSChecked && !isPhoneVerified ? <div className="cart-page__step-3__wrap _sms">
                      <p>Подтверждение по SMS</p>
                      <p className="_secondary">Помогите нам удостовериться, что заказ сделали именно вы. Для этого введите
                        код подтверждения из SMS, которое мы отправили на ваш номер {phoneNumber}</p>
                      <div className="p-fields-grid">
                        <div className="p-fields-grid__item d-50 m-100">
                          <span className="p-float-label">
                            <InputText
                              className={errors.phoneCode ? 'p-invalid' : ''}
                              id="phoneCode"
                              value={phoneCode}
                              maxLength={CONFIRM_CODE_LENGTH}
                              readOnly={loading.phoneCode}
                              onInput={(e) => {
                                setPhoneCode(e.target.value);
                                debounceHandlePhoneCodeInput(e.target.value); // debounce not working
                              }}
                              onFocus={() => setErrors({ ...errors, phoneCode: null })}
                            />
                            <label htmlFor="inputText">Введите код</label>
                          </span>
                          {errors.phoneCode ? <span className="p-error" dangerouslySetInnerHTML={{__html: errors.phoneCode}} /> : null}
                        </div>
                      </div>
                      {codeResendDate ?
                        <p className="_secondary mt-d-15">Отправить код повторно можно будет через <TimeCounter
                          targetDate={codeResendDate}
                          onComplete={() => setCodeResendDate(null)}
                        /></p>
                        :
                        <p className="mt-d-15"><span className="a" onClick={sendPhoneCode}>Отправить код повторно</span></p>
                      }
                      {isPhoneCodeVerified ? <p className="_secondary mt-d-15">Для завершения нажмите кнопку «оформить заказ»</p>: null}
                    </div> : null}

                    <p className="_secondary mt-d-50 mt-t-40 mt-m-35">
                      При приеме заказа просьба произвести осмотр упаковки и внешнего вида товаров, проверить их количество,
                      ассортимент и комплектность
                    </p>
                    <div className="cart-page__step-3__wrap-2">
                      Сертификат на расширенную гарантию будет доступен в личном кабинете после покупки.
                    </div>
                    <div className="p-fields-grid">
                      <div className="p-fields-grid__btn">
                        <div className="p-fields-grid__item d-29-6 m-100">
                          <Button
                            className={`p-button-secondary p-button-transparent ${loading.order ? 'p-loader' : ''}`}
                            label="Оплатить заказ"
                            disabled={errors.basket}
                            onClick={() => {
                              sendOrder();
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Step>
                }
                <p className="cart-page__legal">
                  Нажимая на кнопку «Оформить заказ» вы соглашаетесь на обработку и хранение персональных данных
                  в&nbsp;соответствии <a href={"/content/politika-v-otnoshenii-obrabotki-personalnykh-dannykh-casarte/"}>с&nbsp;политикой конфиденциальности</a><br/>
                  Нажимая на кнопку «Оформить заказ» вы соглашаетесь <a href={"/content/usloviya-ispolzovaniya-casarte/"}>с условиями сервисного обслуживания</a>
                </p>
              </div>
            }
          </>
        : // Пустая корзина
          <>
            <Link to="/" className="cart-page__back"><span className="cart-page__arr _left" />Вернуться к покупкам</Link>
            <h1 className="cart-page__h1">Корзина</h1>
            <div className="cart-page__no-items">
              <span className="cart-page__no-items__title">В корзине нет товаров</span>
              <span className="cart-page__no-items__text">Вы можете выбрать подходящую технику в нашем каталоге</span>
              <Link to="/" className="p-button p-component p-button-secondary p-button-transparent min-w">Смотреть каталог</Link>
            </div>
            {/*<div className="cart-page__warning"><span className="cart-page__icon _warning _left" />Ошибка: ошибка сети</div>
            <div className="cart-page__alert">
              <span className="cart-page__alert__close" />
              <span className="cart-page__alert__title">Дополнительная выгода</span>
              <span className="cart-page__alert__text">Lorem ipsum dolor sit amet, consectetur</span>
            </div>*/}
          </>
        }
      </div>
      <ClearCartModal loading={loading} sendResetBasket={sendResetBasket} />
      <PartnerCartModal />
    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(Page)));

const Step = (props) => {
  return (
    <div className={`cart-page__step cart-page__step-${props.num} ${props.className?props.className:''}`}>
      <div className="cart-page__step__head">
        <span className="cart-page__step__num">{props.num}</span>
        <span className="cart-page__step__title">{props.title}</span>
        {
          !props.price ? null :
          <span className="cart-page__step__price">{numWithSpaces(props.price)} <span className="rub">P</span></span>
        }
      </div>
      {props.children}
    </div>
  );
}

const Product = ({
  item,
  notAvailable,
  sendRemoveProduct,
  setProductAmount,
  installation,
  service,
  debounceToggleInstallation = () => {},
  debounceToggleService = () => {},
  debounceHandleProductAmountChange = () => {},
}) => {
  const isInstallationLoading = item.isInstallationLoading || installation?.isAmountLoading;
  const isServiceLoading = item.isServiceLoading || service?.isAmountLoading;

  const getInstallationCheckedState = useCallback(() => {
    if (typeof item.isInstallationChecked !== 'undefined') {
      return item.isInstallationChecked;
    }
    if (typeof installation?.isChecked !== 'undefined') {
      return installation?.isChecked;
    }
    return Boolean(installation?.itemBasketCount);
  }, [item, installation]);

  const getServiceCheckedState = useCallback(() => {
    if (typeof item.isServiceChecked !== 'undefined') {
      return item.isServiceChecked;
    }
    if (typeof service?.isChecked !== 'undefined') {
      return service?.isChecked;
    }
    return false;
  }, [item, service]);

  const [isInstallationChecked, setIsInstallationChecked] = useState(getInstallationCheckedState());
  const [installationCount, setInstallationCount] = useState(installation?.itemBasketCount || 0);
  const [isServiceChecked, setIsServiceChecked] = useState(getServiceCheckedState());

  // Меняет состояние чекбокса установок
  useEffect(() => {
    if (installation) {
      setIsInstallationChecked(getInstallationCheckedState());
      setInstallationCount(installation?.itemBasketCount);
    }
  }, [installation, getInstallationCheckedState]);

  // Меняет состояние чекбокса доп. услуги
  useEffect(() => {
    if (service) {
      setIsServiceChecked(getServiceCheckedState());
    }
  }, [service, getServiceCheckedState]);

  return (
    <>
      <div className={`cart-page__products__item ${installation ? '' : '_no-installation'} ${notAvailable ? '_out-of-stock' : ''}`}>
        <div className="cart-page__products__item__wrap">
          <Link className="cart-page__products__item__pic" to={item.link}>
            <img src={item.image} alt={item.name} />
          </Link>
          <Link className="cart-page__products__item__title" to={item.link}><span dangerouslySetInnerHTML={{__html:item.name || ''}} /></Link>
          <span className={`cart-page__products__item__price ${Number(item.discountPercent) ? '_old-price' : ''}`}>
            {numWithSpaces(item.total)} <span className="rub">P</span>
          </span>
          {Boolean(Number(item.discountPercent)) && <div className="cart-page__products__item__discount">
            <span className="cart-page__products__item__discount__price">
              {numWithSpaces(item.totalWithoutDiscount)} <span className="rub">P</span>
            </span>
            <span className="cart-page__products__item__discount__rates">
              <span className="_value">- {item.discountPercent}%</span>
              <span className={`cart-page__question _discount-question-${item.id}`} data-pr-position="top" />
              {Boolean(item.discountList) && <Tooltip
                className="cart-page__products__item__discount__tooltip"
                target={`._discount-question-${item.id}`}
                autoHide={false}
              >
                <span className="cart-page__products__item__discount__tooltip__title">Детализация скидок</span>
                {item.discountList.map((item, i) =>
                  <div key={i} className="cart-page__products__item__discount__tooltip__row">
                    <span className="cart-page__products__item__discount__tooltip__label">{item.label}</span>
                    <span className="cart-page__products__item__discount__tooltip__value">
                      {item.leadingMinus ? '- ' : ''}{numWithSpaces(item.value)} <span className="rub">P</span>
                    </span>
                  </div>
                )}
              </Tooltip>}
            </span>
          </div>}
          {Boolean(Number(item.bonus)) && <span className="cart-page__products__item__bonus">
            + {numWithSpaces(item.bonus)} {pluralize(item.bonus, ['Балл', 'Балла', 'Баллов'])}
          </span>}
          <span className="cart-page__products__item__price-per-one">
            {numWithSpaces(item.price)} <span className="rub">P</span> / шт
          </span>
          <div className="cart-page__products__item__group">
            <span
              className={`cart-page__products__item__trash ${item.isRemoveLoading ? 'p-loader' : ''}`}
              onClick={() => {
                sendRemoveProduct(item.id);
              }}
            >Удалить</span>
            {/*@TODO: Тултип максимального количества*/}
            {/*<div className="cart-page__products__item__counter _product-counter"
              data-pr-tooltip="Это максимальное количество товаров, доступное к заказу"
              data-pr-position="top"
            >*/}
            <div
              className="cart-page__products__item__counter _product-counter"
              style={item.isAmountLoading ? {opacity: 0.5, pointerEvents: 'none'} : null}
            >
              <span
                className={`cart-page__products__item__counter__minus ${notAvailable?'_disabled':''}`}
                onClick={() => {
                  if (!notAvailable) {
                    setProductAmount(item.id, 'dec');
                  }
                }}
              />
              <span className="cart-page__products__item__counter__num">{item.itemBasketCount}</span>
              <span
                className={`cart-page__products__item__counter__plus ${notAvailable || item.itemBasketCount === item.maxCount ? '_disabled':''}`} // - класс _disabled скрывает элемент
                onClick={() => {
                  if (!notAvailable) {
                    setProductAmount(item.id, 'inc');
                  }
                }}
              />
            </div>
            <Tooltip className="cart-page__products__item__counter__tooltip" target="._product-counter" autoHide={false} />
          </div>
        </div>
        {Boolean(installation) && <div className="cart-page__products__item__installation">
          <div
            className="cart-page__products__item__installation__header"
            style={isInstallationLoading ? {opacity: 0.5, pointerEvents: 'none'} : null}
          >
            <div className="flex align-items-center">
              <InputSwitch
                className=""
                inputId={`installationStatus${item.id}`}
                checked={isInstallationChecked}
                onChange={(e) => {
                  setIsInstallationChecked(e.value);
                  debounceToggleInstallation(e.value, item.id, item.installationId);
                }}
              />
              <label htmlFor={`installationStatus${item.id}`}>
                Стандартная установка
              </label>
              <span className="cart-page__question _inline _installation-standart" data-pr-position="top" />
              <Tooltip className="cart-page__installation-standart__tooltip" target="._installation-standart" autoHide={false}>
                Бесплатная установка в соответствии с&nbsp;<Link to="/installation" className="cart-page__products__item__installation__agreement">условиями</Link> компании Haier.
              </Tooltip>
            </div>
            <div>
              {installation?.total
                ? <span className="cart-page__products__item__installation__label">
                  {numWithSpaces(installation?.total)} <span className="rub">P</span>
                </span>
                : <span className="cart-page__products__item__installation__label">Бесплатно</span>}
            </div>
            {Boolean(installation?.itemBasketCount) && <div className="cart-page__products__item__counter _product-counter">
              <span
                className={"cart-page__products__item__counter__minus"}
                onClick={() => {
                  const itemBasketCount = installationCount - 1;

                  setInstallationCount(itemBasketCount);
                  // setProductAmount(installation?.id, 'dec');
                  debounceHandleProductAmountChange({
                    ...installation,
                    itemBasketCount,
                  });
                }}
              />
              <span className="cart-page__products__item__counter__num">{installationCount}</span>
              <span
                className={`cart-page__products__item__counter__plus ${installationCount === installation?.maxCount ? '_disabled':''}`} // - класс _disabled скрывает элемент
                onClick={() => {
                  const itemBasketCount = installationCount + 1;

                  setInstallationCount(itemBasketCount);
                  // setProductAmount(installation?.id, 'inc');
                  debounceHandleProductAmountChange({
                    ...installation,
                    itemBasketCount,
                  });
                }}
              />
            </div>}
          </div>
          {Boolean(installation?.itemBasketCount) && <div className="cart-page__products__item__installation__extra">
            {Boolean(service) && <div
              className="cart-page__products__item__installation__extra__row"
              style={isServiceLoading ? {opacity: 0.5, pointerEvents: 'none'} : null}
            >
              <div className="cart-page__products__item__installation__extra__dop">
                <Checkbox
                  className="cart-page__products__item__installation__extra__dop__checkbox"
                  inputId={`serviceStatus${item.id}`}
                  checked={isServiceChecked}
                  onChange={(e) => {
                    setIsServiceChecked(e.checked);
                    debounceToggleService(e.checked, item.id, item.serviceId);
                  }}
                />
                <div>
                  <label
                    className="cart-page__products__item__installation__extra__dop__label"
                    htmlFor={`serviceStatus${item.id}`}
                  >
                    Добавить <nobr>дополнительные услуги<span className="cart-page__question _inline _installation-dop" data-pr-position="top" /></nobr>
                  </label>
                  <Tooltip className="cart-page__installation-dop__tooltip" target="._installation-dop" autoHide={false}>
                    Оператор свяжется с вами, чтобы уточнить детали и рассчитать стоимость. Дополнительными услугами являются демонтаж старой техники, удлинение проводов и шлангов; а также все, что не входит в стандартную установку. <Link to="/installation" className="cart-page__products__item__installation__agreement _arrow">Подробнее</Link>
                  </Tooltip>
                </div>
              </div>
              <p className="cart-page__products__item__installation__extra__label">Индивидуальный расчет</p>
            </div>}
            <div className="cart-page__products__item__installation__info">
              После покупки вам будет доступен сертификат на установку.<br/>
              При добавлении услуги вы соглашаетесь c <Link to="/content/usloviya-ispolzovaniya-casarte/" className="cart-page__products__item__installation__agreement">условиями сервисного обслуживания</Link>.
            </div>
          </div>}
        </div>}
      </div>
    </>
  );
}

const Benefit = ({
  basketData,
  isBonusDisabled,
  bonusCheckedState,
  loading,
  setBonusCheckedState,
  debounceToggleBonus,
  bonusUsageAmount,
  isBonusEdit,
  setIsBonusEdit,
  errors,
  bonusCustomAmount,
  setBonusCustomAmount,
  sendUseBonus,
  promoCodeCheckedState,
  setPromoCodeCheckedState,
  promoCodeSuccessMessage,
  isPromoCodeDisabled,
  debounceTogglePromoCode,
  promoCodeValue,
  setPromoCodeValue,
  sendUsePromoCode,
  sendCancelPromoCode,
  clubDiscount,
  debounceToggleClubDiscount,
  store,
}) => {
  const isAuth = store.appConst.isAuth;
  const isBonusHidden = basketData?.bonus?.isHidden || !basketData?.bonus;
  const isHidden = isBonusHidden && basketData?.promoCode?.isHidden && clubDiscount.isHidden;

  const handleClubDiscountClick = (e) => {
    if (!isAuth) {
      e.preventDefault();
      store.setModal('auth');
    }
  };

  return (isHidden ? null :
    <div className="cart-page__benefit">
      <p className="cart-page__benefit__title">Дополнительная выгода</p>
      {!isBonusHidden ? <div className="cart-page__benefit__wrap">
        <div className="flex align-items-center">
          <InputSwitch
            className=""
            inputId="bonusPointsSwitch"
            checked={bonusCheckedState}
            disabled={isBonusDisabled}
            readOnly={loading.bonus}
            onChange={(e) => {
              setBonusCheckedState(e.value);
              debounceToggleBonus(e.value);
            }}
          />
          {
            isBonusDisabled
              ? <label htmlFor="bonusPointsSwitch">Оплата Баллами недоступна</label>
              : <label className="cursor-pointer" htmlFor="bonusPointsSwitch">Оплатить {numWithSpaces(bonusUsageAmount)} {pluralize(bonusUsageAmount, ['Баллом', 'Баллами', 'Баллами'])}</label>
          }
        </div>
        {isBonusDisabled && basketData?.bonus?.disabledText ? <div className="p-fields-grid">
          <div className="p-fields-grid__item d-100 m-100 _p-d-l _p-m-l _m-t-b">
            <div className="flex align-items-center" dangerouslySetInnerHTML={{__html: basketData?.bonus?.disabledText}} />
          </div>
        </div> :
        <div className="p-fields-grid">
          <div className="p-fields-grid__item d-66 m-100 _p-d-l _p-m-l _m-t-b">
            Всего у вас {numWithSpaces(basketData?.bonus?.bonusAvailableCard)} <nobr>{pluralize(basketData?.bonus?.bonusAvailableCard, ['Балл', 'Балла', 'Баллов'])}</nobr>
          </div>
          {!isBonusEdit && !isBonusDisabled ? <div className="p-fields-grid__item d-34 m-100 _m-t">
            <Button className="p-button-secondary p-button-transparent" label="Изменить сумму" onClick={() => setIsBonusEdit(true)} />
          </div> : null}
          {isBonusEdit && !isBonusDisabled ? <div className="p-fields-grid__item d-66 m-100 _p-d-l">
            <span className="p-float-label">
              <InputText
                className={errors.bonus ? 'p-invalid' : ''}
                id="bonusPointsInput"
                value={bonusCustomAmount}
                readOnly={loading.bonus}
                onInput={(e) => setBonusCustomAmount(e.target.value)}
              />
              <label htmlFor="inputNumber">Введите количество баллов</label>
            </span>
            {errors.bonus ? <span className="p-error" dangerouslySetInnerHTML={{__html: errors.bonus}} /> : null}
          </div> : null}
          {isBonusEdit && !isBonusDisabled ? <div className="p-fields-grid__item d-34 m-100">
            <Button
              className={`p-button-secondary p-button-white ${loading.bonus ? 'p-loader' : ''}`}
              label="применить"
              onClick={() => {
                sendUseBonus(bonusCustomAmount);
              }}
            />
          </div> : null}
        </div>}
      </div> : null}
      {!basketData?.promoCode?.isHidden ? <div className="cart-page__benefit__wrap">
        <div className="flex align-items-center">
          <InputSwitch
            className={!basketData?.promoCode?.value ? 'pointer-events-none' : ''}
            inputId="promoCodeSwitch"
            checked={promoCodeCheckedState}
            disabled={isPromoCodeDisabled}
            readOnly={!basketData?.promoCode?.value || loading.promoCode}
            onChange={(e) => {
              setPromoCodeCheckedState(e.value);
              debounceTogglePromoCode(e.value);
            }}
          />
          {
            isPromoCodeDisabled
              ? <label className="pointer-events-none" htmlFor="promoCodeSwitch">Ввод промокода недоступен</label>
              : <label className={!basketData?.promoCode?.value ? 'pointer-events-none' : 'cursor-pointer'} htmlFor="promoCodeSwitch">Промокод</label>
          }
        </div>
        {!isPromoCodeDisabled ? <div className="p-fields-grid">
          <div className="p-fields-grid__item d-66 m-100 _p-d-l">
            <span className="p-float-label">
              <InputText
                className={errors.promoCode ? 'p-invalid' : ''}
                id="promoCodeInput"
                value={promoCodeValue}
                readOnly={loading.promoCode}
                onInput={(e) => {
                  setPromoCodeValue(e.target.value);
                }}
              />
              <label htmlFor="promoCodeInput">Введите промокод</label>
            </span>
            {errors.promoCode ? <span className="p-error" dangerouslySetInnerHTML={{__html: errors.promoCode}} /> : null}
            {promoCodeSuccessMessage ? <span dangerouslySetInnerHTML={{__html: promoCodeSuccessMessage}} /> : null}
          </div>
          <div className="p-fields-grid__item d-34 m-100">
            <Button
              className={`p-button-secondary p-button-transparent ${loading.promoCode ? 'p-loader' : ''} ${!promoCodeValue ? 'p-disabled' : ''}`}
              label={promoCodeCheckedState ? 'Удалить' : 'Применить'}
              onClick={() => {
                promoCodeCheckedState ? sendCancelPromoCode() : sendUsePromoCode(promoCodeValue);
              }}
            />
          </div>
        </div> : null}
      </div> : null}
      {!clubDiscount.isHidden ? <div className="cart-page__benefit__wrap">
        <div className="flex align-items-center" onClick={handleClubDiscountClick}>
          <InputSwitch
            className={!isAuth ? 'pointer-events-none' : ''}
            inputId="clubDiscountSwitch"
            checked={clubDiscount.isChecked}
            disabled={clubDiscount.isDisabled}
            readOnly={loading.clubDiscount}
            onChange={(e) => {
              // setClubDiscount({ ...clubDiscount, isChecked: e.value });
              debounceToggleClubDiscount(e.value);
            }}
          />
          {clubDiscount.title
            ? <label className={!isAuth ? 'pointer-events-none' : 'cursor-pointer'} htmlFor="clubDiscountSwitch" dangerouslySetInnerHTML={{__html: clubDiscount.title}} />
            : null}
        </div>
        <div className="p-fields-grid">
          {clubDiscount.text ?
            <div className="p-fields-grid__item m-100 _p-d-l _p-m-l _m-t-b">
              <span dangerouslySetInnerHTML={{__html: clubDiscount.text}} />
              {clubDiscount.tooltip ?
                <>
                  <span className="cart-page__question _inline _question-bonus" data-pr-position="top"/>
                  <Tooltip className="cart-page__bonus__tooltip" target="._question-bonus" autoHide={false}>
                    {clubDiscount.tooltip?.title ? <b><span dangerouslySetInnerHTML={{__html: clubDiscount.tooltip?.title}} /><br /></b> : null}
                    {clubDiscount.tooltip?.text ? <><span dangerouslySetInnerHTML={{__html: clubDiscount.tooltip?.text}} /><br /></> : null}
                    {clubDiscount.tooltip?.url ? <a
                      href={clubDiscount.tooltip?.url}
                      style={{color: 'white'}}
                      dangerouslySetInnerHTML={{__html: clubDiscount.tooltip?.urlText || ''}}
                    /> : null}
                  </Tooltip>
                </>
                : null}
            </div>
            : null}
          {clubDiscount.successMessage
            ? <div dangerouslySetInnerHTML={{__html: clubDiscount.successMessage}} />
            : null}
          {clubDiscount.errorMessage
            ? <div className="p-error _p-l-error" dangerouslySetInnerHTML={{__html: clubDiscount.errorMessage}} />
            : null}
        </div>
      </div> : null}
      {/* <div className="cart-page__benefit__wrap">
        <div className="p-fields-grid">
          <div className="p-fields-grid__item d-66 m-100">
            <p className="f-b">За этот заказ вам будет начислено 23 699 Баллов</p>
            <p className="mt-d-3">Оформляли заказ ранее? Авторизуйтесь, чтобы увидеть свои Баллы</p>
          </div>
          <div className="p-fields-grid__item d-34 m-100">
            <Button className="p-button-secondary p-button-white" label="Авторизоваться" />
          </div>
        </div>
      </div> */}
    </div>
  );
}

const DeliveryDate = ({ deliveryData, id, productsAvailable, deliveryDate, handleDateChange, dateList, isDeliveryLoading, errors, deliveryInterval, handleIntervalChange, intervalList }) => {
  return (
    <div className="cart-page__step__wrap">
      <p className="cart-page__step__sub-title">Дата и время доставки</p>
      {deliveryData?.storage && <p className="cart-page__step__text _secondary" dangerouslySetInnerHTML={{__html: deliveryData.storage}} />}
      <div className="cart-page__step-2__products">
        {productsAvailable.map((product) => (
          <Link key={product.id} className="cart-page__step-2__product" to={product.link}>
            <img src={product.image} alt={product.name} />
          </Link>
        ))}
      </div>
      <div className="p-fields-grid">
        <div className="p-fields-grid__item d-50 m-100">
          <span className="p-float-label">
            <Dropdown
              className=""
              inputId={`deliveryDate${id}`}
              value={deliveryDate}
              onChange={(e) => handleDateChange(e.value)}
              options={dateList}
              optionLabel="text"
              optionValue="value"
              disabled={isDeliveryLoading}
            />
            <label htmlFor={`deliveryDate${id}`}>Желаемая дата доставки</label>
          </span>
          {errors.date &&
            <span className="p-error" dangerouslySetInnerHTML={{__html: errors.date}} />
          }
        </div>
        <div className="p-fields-grid__item d-50 m-100">
          <span className="p-float-label">
            <Dropdown
              className=""
              inputId={`deliveryInterval${id}`}
              value={deliveryInterval}
              onChange={(e) => handleIntervalChange(e.value)}
              options={intervalList}
              optionLabel="text"
              optionValue="value"
              disabled={isDeliveryLoading}
            />
            <label htmlFor={`deliveryInterval${id}`}>Время доставки</label>
          </span>
          {errors.time &&
            <span className="p-error" dangerouslySetInnerHTML={{__html: errors.time}} />
          }
        </div>
      </div>
    </div>
  );
}
