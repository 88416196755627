import './style.scss';
import React, { useEffect, useState, useMemo } from 'react';
import {inject, observer} from "mobx-react";
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputMask } from 'primereact/inputmask';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { Link } from 'react-router-dom';
import Icon from '../../assets/images/contact-modal/check_icon.svg';
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH } from '../../common/const';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { EMAIL_REGX, ERROR_MESAGES, PHONE_MASK, PHONE_MASK_SLOT } from '../../common/const';
import Recaptcha from '../utils/recaptcha';
import qs from 'qs';
//import { disableBodyScroll, enableBodyScroll } from '../../common/utils';
import formData from "../../json/feedbackForm.json";

Yup.addMethod(Yup.string, 'phoneNotFull', function (errorMessage) {
  return this.test('test-phone-length', errorMessage, function (value) {
    return (
      value.replace(/\D/g, '').length === PHONE_MASK.replace(/\D/g, '').length ||
      this.createError({ path: this.path, message: errorMessage })
    );
  });
});

const isOrdersRequired = (value) => ['order_ask', 'order_blame', 'order_blame_2'].includes(value);

const schema = Yup.object().shape({
  topic: Yup.string()
    .required(ERROR_MESAGES.required),
  name: Yup.string()
    // @TODO: add notDigits, notEngSymbols
    .required(ERROR_MESAGES.required),
  email: Yup.string()
    .matches(EMAIL_REGX, ERROR_MESAGES.formatEmail)
    .required(ERROR_MESAGES.required),
  phone: Yup.string()
    .phoneNotFull(ERROR_MESAGES.phoneNotFull)
    .required(ERROR_MESAGES.required),
  order: Yup.string()
    .when('topic', {
      is: (value) => isOrdersRequired(value),
      then: (schema) => schema.required(ERROR_MESAGES.required),
    }),
  comment: Yup.string()
    .required(ERROR_MESAGES.required),
  usageTerms: Yup.boolean()
    .oneOf([true], ERROR_MESAGES.required),
  dataTerms: Yup.boolean()
    .oneOf([true], ERROR_MESAGES.required),
});

const initialLoading = {
  form: false,
};

const initialErrors = {
  form: null,
};

const Modal = ({ store }) => {
  const recaptcha = new Recaptcha();

  const [viewStatus, setViewStatus] = useState(1);
  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);
  const name = 'contact';
  const data = store.modal[name];

  const sendFeedback = async (values) => {
    // setViewStatus(2);
    // return;

    if (loading.form) {
      return;
    }
    setErrors({ ...errors, form: null });
    setLoading({ ...loading, form: true });

    const token = await recaptcha.execute();

    store.bridge.feedbackCreateTicket({
      ...values,
      user: store.feedbackForm?.user?.id,
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'FEEDBACK_SUCCESS':
          setViewStatus(2);
          break;
        case 'FEEDBACK_FAIL':
          if (data.errorMessage) {
            setErrors({ ...errors, form: data.errorMessage });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, form: false });
    }).catch(() => {
      setLoading({ ...loading, form: false });
    });
  };

  const handleTopicChange = (value) => {
    if (isOrdersRequired(value) && !store.appConst.isAuth) {
      const getParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      const query = qs.stringify({ ...getParams, from: 'feedback' }, { encode: false, addQueryPrefix: true });

      store.setModal('auth', {
        backUrl: `${window.location.origin}${window.location.pathname}${query}`,
      });
    }
  };

  const handleClose = (e) => {
    if(e.target.className.indexOf('contact-modal__outer-wrap') >= 0){
      store.setModal(name, null);
    }
  }

  useEffect(() => {
    if(data){
      setViewStatus(1);
    }
  }, [data])

  const topics = useMemo(() => {
    return store.feedbackForm?.topics?.length ? [...store.feedbackForm?.topics] : formData.topics;
  }, [store.feedbackForm]);

  const orders = useMemo(() => {
    return store.feedbackForm?.orders?.length
      ? store.feedbackForm?.orders?.map((order) => ({
        id: order.id,
        text: order.id,
      }))
      : [];
  }, [store.feedbackForm]);

  //data ? disableBodyScroll() : enableBodyScroll();
  useEffect(() => {
    if(data){
      document.body.classList.add('scroll-lock');
    }

    return () => document.body.classList.remove('scroll-lock');
  }, []);

  return (
    data ?
    <div className={'contact-modal _' + data.status + ' '+data?.data?.className} onClick={(e) => handleClose(e) }>
      <div className="contact-modal__outer-wrap">
        {viewStatus===1?
        <Formik
          initialValues={{
            topic: '',
            name: store.feedbackForm?.user?.name || '',
            email: store.feedbackForm?.user?.email || '',
            phone: store.feedbackForm?.user?.phone || '',
            order: '',
            comment: '',
            usageTerms: false,
            dataTerms: false,
          }}
          validationSchema={schema}
          // validator={() => {}}
          onSubmit={(values) => sendFeedback(values)}
        >
          {({ errors: formErrors, touched, values, setFieldValue }) => (
          <Form>
            <div className="contact-modal__inner-wrap">
              <div className="contact-modal__body">
                <div className="contact-modal__close" onClick={(e) => store.setModal(name, null) } />
                <span className="contact-modal__title">Задать вопрос</span>
                <div className="p-fields-grid">
                  <div className="p-fields-grid__item d-100 m-100">
                    <span className="p-float-label">
                      <Dropdown
                        className={formErrors["topic"] && touched["topic"] ? 'p-invalid' : ''}
                        inputId="topic"
                        value={values.topic}
                        onChange={(e) => {
                          setFieldValue('topic', e.value);
                          handleTopicChange(e.value);
                        }}
                        options={topics}
                        optionLabel="text"
                        optionValue="id"
                        panelClassName="contact-modal__dropdown"
                      />
                      <label htmlFor="topic">К какой теме относится вопрос?</label>
                    </span>
                    {formErrors["topic"] && touched["topic"] ?
                    <span className="p-error">{formErrors["topic"]}</span>
                    : null}
                  </div>
                  <div className="p-fields-grid__item d-100 m-100" style={{ display: store.feedbackForm?.user?.name ? 'none' : '' }}>
                    <span className="p-float-label">
                      <InputText
                        className={formErrors["name"] && touched["name"] ? 'p-invalid' : ''}
                        id="name"
                        value={values.name}
                        onChange={(e) => setFieldValue('name', e.target.value)}
                        onBlur={(e) => { setFieldValue('name', e.target.value); touched["name"] = true;}}
                        onFocus={(e) => { touched["name"] = false;}}
                      />
                      <label htmlFor="name">ФИО</label>
                    </span>
                    {formErrors["name"] && touched["name"] ?
                    <span className="p-error">{formErrors["name"]}</span>
                    : null}
                  </div>
                  <div className="p-fields-grid__item d-100 m-100" style={{ display: store.feedbackForm?.user?.email ? 'none' : '' }}>
                    <span className="p-float-label">
                      <InputText
                        className={formErrors["email"] && touched["email"] ? 'p-invalid' : ''}
                        id="email"
                        value={values.email}
                        onChange={(e) => setFieldValue('email', e.target.value)}
                        onBlur={(e) => { setFieldValue('email', e.target.value); touched["email"] = true;}}
                        onFocus={(e) => { touched["email"] = false;}}
                      />
                      <label htmlFor="email">E-mail</label>
                    </span>
                    {formErrors["email"] && touched["email"] ?
                    <span className="p-error">{formErrors["email"]}</span>
                    : null}
                  </div>
                  <div className="p-fields-grid__item d-100 m-100">
                    <span className="p-float-label">
                      <InputMask
                        className={formErrors["phone"] && touched["phone"] ? 'p-invalid' : ''}
                        id="phone"
                        value={values.phone}
                        onChange={(e) => setFieldValue('phone', e.target.value)}
                        onBlur={(e) => { setFieldValue('phone', e.target.value); touched["phone"] = true;}}
                        onFocus={(e) => { touched["phone"] = false;}}
                        mask={PHONE_MASK}
                        slotChar={PHONE_MASK_SLOT}
                      />
                      <label htmlFor="phone">Телефон</label>
                    </span>
                    {formErrors["phone"] && touched["phone"] ?
                    <span className="p-error">{formErrors["phone"]}</span>
                    : null}
                  </div>
                  {isOrdersRequired(values.topic) &&
                  <div className="p-fields-grid__item d-100 m-100">
                    <span className="p-float-label">
                      <Dropdown
                        className={formErrors["order"] && touched["order"] ? 'p-invalid' : ''}
                        inputId="order"
                        value={values.order}
                        onChange={(e) => setFieldValue('order', e.value)}
                        options={orders}
                        optionLabel="text"
                        optionValue="id"
                      />
                      <label htmlFor="order">Номер заказа</label>
                    </span>
                    {formErrors["order"] && touched["order"] ?
                    <span className="p-error">{formErrors["order"]}</span>
                    : null}
                  </div>}
                  <div className="p-fields-grid__item d-100 m-100">
                    <span className="p-float-label">
                      <InputTextarea
                        className={formErrors["comment"] && touched["comment"] ? 'p-invalid' : ''}
                        id="comment"
                        value={values.comment}
                        onChange={(e) => setFieldValue('comment', e.target.value)}
                        onBlur={(e) => { setFieldValue('comment', e.target.value); touched["comment"] = true;}}
                        onFocus={(e) => { touched["comment"] = false;}}
                      />
                      <label htmlFor="comment">Опишите суть обращения</label>
                    </span>
                    {formErrors["comment"] && touched["comment"] ?
                    <span className="p-error">{formErrors["comment"]}</span>
                    : null}
                  </div>
                  <div className="p-fields-grid__item d-100 m-100">
                    {/* <span className="contact-modal__text mt-d-15">Все поля обязательны для заполнения</span> */}
                    <div className="flex align-items-center mt-d-25">
                      <Checkbox
                        className={formErrors["usageTerms"] && touched["usageTerms"] ? 'p-invalid' : ''}
                        inputId="usageTerms"
                        onChange={e => setFieldValue('usageTerms', e.checked)}
                        checked={values.usageTerms}
                      />
                      <label className='contact-modal__text' htmlFor="usageTerms">Я согласен <Link to={"/content/usloviya-ispolzovaniya-casarte/"}>с условиями использования</Link> сервиса</label>
                    </div>
                    {formErrors["usageTerms"] && touched["usageTerms"] ?
                    <span className="p-error p-checkbox-error">{formErrors["usageTerms"]}</span>
                    : null}
                    <div className="flex align-items-center mt-d-25">
                      <Checkbox
                        className={formErrors["dataTerms"] && touched["dataTerms"] ? 'p-invalid' : ''}
                        inputId="dataTerms"
                        onChange={e => setFieldValue('dataTerms', e.checked)}
                        checked={values.dataTerms}
                      />
                      <label className='contact-modal__text' htmlFor="dataTerms">Я принимаю <Link to={"/content/politika-v-otnoshenii-obrabotki-personalnykh-dannykh-casarte/"}>условия хранения и обработки</Link> данных</label>
                    </div>
                    {formErrors["dataTerms"] && touched["dataTerms"] ?
                    <span className="p-error p-checkbox-error">{formErrors["dataTerms"]}</span>
                    : null}
                    {errors.form && <div className="text-light mt-30">{errors.form}</div>}
                    <Button
                      className={`p-button-secondary p-button-transparent min-w mt-d-35 ${loading.form ? 'p-loader' : ''}`}
                      label="Отправить"
                      type={'submit'}
                    //  disabled
                    />
                  </div>
                </div>
              </div>
            </div>
          </Form>
          )}
        </Formik>
        :viewStatus===2?
        <div className="contact-modal__inner-wrap _min">
          <div className="contact-modal__body">
            <div className="contact-modal__close" onClick={(e) => store.setModal(name, null) } />
            <img className="contact-modal__icon" src={Icon} alt="" />
            <span className="contact-modal__title">Обращение отправлено</span>
            <span className="contact-modal__text">Специалист Casarte свяжется с&nbsp;вами <br/>в&nbsp;ближайшее время.</span>
            <MediaQuery maxWidth={MOBILE_WIDTH}>
              <Link to="/" className="p-button p-component p-button-secondary p-button-transparent flex justify-content-center mt-m-35">Вернуться на сайт</Link>
            </MediaQuery>
          </div>
        </div>
        :null}
      </div>
    </div>
    : null
  )
}

export default inject('store')(observer(Modal));
