import './style.scss';
import React, {useEffect, useState, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import PersonalPageSummary from '../PersonalPage';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {InputMask} from 'primereact/inputmask';
import {InputText} from 'primereact/inputtext';
import {Calendar} from 'primereact/calendar';
import {Password} from 'primereact/password';
import {Checkbox} from "primereact/checkbox";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import * as Yup from 'yup';
import {ERROR_MESAGES, PHONE_MASK, PHONE_MASK_SLOT} from '../../common/const';
import SearchableInputText from '../primereact/SearchableInputText';
import {inject, observer} from 'mobx-react';
import axios from "axios";
import {debounce, getApiPath, markResults} from "../utils";
import pageData from "../../json/personalData.json";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import {InputNumber} from "primereact/inputnumber";
import qs from 'qs';
import {scrollTo} from "../../common/utils";
import PageContainer from "../PageContainer";

dayjs.extend(customParseFormat);

const schema = Yup.object().shape({
  // @TODO: add notDigits, notEngSymbols
  name: Yup.string()
    .required(ERROR_MESAGES.required),
  // @TODO: make optional, add notDigits, notEngSymbols
  // lastName: Yup.string()
  //   .min(2, "Слишком короткое")
  //   .max(50, "Слишком длинное")
  //   .required("Это обязательное поле"),
  email: Yup.string()
    .email(ERROR_MESAGES.formatEmail)
    .required(ERROR_MESAGES.required),
  // @TODO: add notFull
  phone: Yup.string()
    .required(ERROR_MESAGES.required),
  // city: Yup.string()
  //   .required(ERROR_MESAGES.required),
  street: Yup.string()
    .required(ERROR_MESAGES.required),
  // @TODO: add notZero, digits
  building: Yup.string()
    .required(ERROR_MESAGES.requiredShort),
  // flat: Yup.string(),
  //  .required("Это обязательное поле"),
  // floor: Yup.string(),
  //  .required("Это обязательное поле"),
  passOld: Yup.string(),
  // passOld: Yup.string()
  //   .when('passNew', {
  //     is: (value) => value.length,
  //     then: (schema) => schema.required(ERROR_MESAGES.required),
  //   }),
  passNew: Yup.string()
    .when('passOld', {
      is: (value) => value.length,
      then: (schema) => schema.required(ERROR_MESAGES.required),
    }),
  passConfirm: Yup.string()
    .when('passNew', {
      is: (value) => value.length,
      then: (schema) => schema
        .oneOf([Yup.ref('passNew'), null], ERROR_MESAGES.passwordsMismatch)
        .required(ERROR_MESAGES.required),
    }),
});

const genderOptions = [
  {label: "Мужской", value: "М"},
  {label: "Женский", value: "Ж"},
]

const cityDropdownOptionTemplate = (option) => {
  return (
    <div className="dropdown__select-city__list__item">
      <span className="dropdown__select-city__list__item__title"
            dangerouslySetInnerHTML={{__html: option.markedValue || option.value}}/>
      <span className="dropdown__select-city__list__item__text">{option.additionalInfo}</span>
    </div>
  );
}

const initialLoading = {
  city: false,
  street: false,
  building: false,
  form: false,
};

const initialErrors = {
  name: null,
  email: null,
  phone: null,
  street: null,
  building: null,
  passOld: null,
  passNew: null,
  passConfirm: null,
};

const Page = ({store, match}) => {
  const [data, setData] = useState();
  const [birthdate, setBirthdate] = useState();
  const [emailOld, setEmailOld] = useState();
  const [isPasswordEdit, setIsPasswordEdit] = useState(false);
  const [isBirthdayReadonly, setIsBirthdayReadonly] = useState(false);
  const [popularCities, setPopularCities] = useState([]);
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [suggestedStreets, setSuggestedStreets] = useState([]);
  const [suggestedBuildings, setSuggestedBuildings] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);
  const [responseError, setResponseError] = useState();

  // Устанавливает заголовок страницы
  useEffect(() => {
    document.title = 'Личные данные';
  }, [])

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      setData({
        ...response.data.pageContent.contacts,
        ...response.data.pageContent.address,
        passOld: '',
        passNew: '',
        passConfirm: '',
        promoSub: '',
      });
      store.setSidebar(response.data.pageContent.sidebar);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData({
        ...pageData.contacts,
        ...pageData.address,
        passOld: '',
        passNew: '',
        passConfirm: '',
        promoSub: '',
      });
      store.setSidebar(pageData.sidebar);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  // Устанавливает дату рождения
  useEffect(() => {
    if (data?.birthday && !birthdate) {
      const date = dayjs(data?.birthday, 'DD.MM.YYYY').toDate();
      setBirthdate(date);
      // setBirthdate(data?.birthday);
    }
  }, [data, birthdate]);

  const birth = useMemo(() => {
    if (data?.birthday) {
      const [birthDay, birthMonth, birthYear] = data?.birthday?.split('.');
      return {birthDay, birthMonth, birthYear};
    }
  }, [data]);

  // Сохраняет email для дальнейшей передачи в случае его смены
  useEffect(() => {
    if (data?.email && !emailOld) {
      setEmailOld(data?.email);
    }
  }, [data, emailOld]);

  // Устанавливает выбранный город
  useEffect(() => {
    if (store.header?.geolocation && !selectedCity) {
      setSelectedCity({
        value: store.header.geolocation.value,
        id: store.header.geolocation.id,
      });
    }
  }, [store.header, selectedCity]);

  // Устанавливает саджесты
  useEffect(() => {
    if (store.header?.geolocation?.items && !popularCities.length) {
      const suggestions = store.header.geolocation.items;

      if (suggestions.length) {
        const selected = {
          value: store.header.geolocation.value,
          id: store.header.geolocation.id,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(suggestions);
        } else {
          setSuggestedCities([
            ...suggestions,
            selected,
          ]);
        }
      }
      setPopularCities(suggestions);
      setSuggestedCities(suggestions);
    }
  }, [store.header, popularCities]);

  const handleSearchInput = (value) => {
    const inputValue = value.trim();

    if (inputValue.length) {
      debounceGetCities(inputValue);
    } else {
      debounceGetPopularCities();
    }
  };

  const getPopularCities = () => {
    // if (popularCities.length) {
    //   const selected = {
    //     value: selectedCity?.value,
    //     id: selectedCity?.id,
    //   };
    //   const existing = popularCities.find((item) => item.id === selected.id);
    //
    //   if (existing) {
    //     setSuggestedCities(popularCities);
    //   } else {
    //     setSuggestedCities([
    //       ...popularCities,
    //       selected,
    //     ]);
    //   }
    //   return;
    // }
    setLoading({...loading, city: true});

    store.bridge.checkoutGetPopularCities().then((response) => {
      const {suggestions = []} = response?.data?.data || {};

      if (suggestions.length) {
        const selected = {
          value: selectedCity?.value,
          id: selectedCity?.id,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(suggestions);
        } else {
          setSuggestedCities([
            ...suggestions,
            selected,
          ]);
        }
      }
      setLoading({...loading, city: false});
    }).catch(() => {
      setLoading({...loading, city: false});
    });
  };

  const getCities = (term) => {
    setLoading({...loading, cities: true});

    store.bridge.checkoutGetCity({
      term,
    }).then((response) => {
      const {suggestions = []} = response?.data?.data || {};

      if (suggestions.length) {
        const selected = {
          value: selectedCity?.value,
          id: selectedCity?.id,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(markResults(suggestions, term));
        } else {
          setSuggestedCities([
            ...markResults(suggestions, term),
            selected,
          ]);
        }
      }
      setLoading({...loading, cities: false});
    }).catch(() => {
      setLoading({...loading, cities: false});
    });
  };

  const handleCityChange = (id, isInitial) => {
    const value = suggestedCities.find((item) => item.id === id);

    setData({
      ...(data || {}),
      ...(isInitial ? {} : {
        street: '',
        streetId: '',
        building: '',
      }),
      city: value.value,
      cityId: value.id,
    });
    setSelectedCity({
      value: value.value,
      id: value.id,
    });
  };

  const handleFieldChange = (payload) => {
    setData({
      ...(data || {}),
      ...payload,
    });
  };

  const handleStreetChange = (value) => {
    setData({
      ...(data || {}),
      street: value.value,
      streetId: value.id,
    });

    setErrors({...errors, street: null});
  };

  const handleStreetInput = (term) => {
    handleStreetChange({value: term, id: ''});
    debounceGetStreets({
      term,
      cityId: data?.cityId,
    });
  };

  const getStreets = ({term = '', cityId = ''}) => {
    setLoading({...loading, street: true});

    store.bridge.checkoutGetStreet({
      term,
      cityId,
    }).then((response) => {
      const {suggestions = []} = response?.data?.data || {};

      if (suggestions.length) {
        setSuggestedStreets(suggestions);
      }
      setLoading({...loading, street: false});
    }).catch(() => {
      setLoading({...loading, street: false});
    });
  };

  const handleBuildingChange = (value) => {
    setData({
      ...(data || {}),
      building: value.value,
    });

    setErrors({...errors, building: null});
  };

  const handleBuildingInput = (term) => {
    handleBuildingChange({value: term});
    debounceGetBuildings({
      term,
      streetId: data?.streetId,
    });
  };

  const getBuildings = ({term = '', streetId = ''}) => {
    setLoading({...loading, building: true});

    store.bridge.checkoutGetBuilding({
      term,
      streetId,
    }).then((response) => {
      const {suggestions = []} = response?.data?.data || {};

      if (suggestions.length) {
        setSuggestedBuildings(suggestions);
      }
      setLoading({...loading, building: false});
    }).catch(() => {
      setLoading({...loading, building: false});
    });
  };

  const handleSubmit = async () => {
    try {
      await schema.validate(data, {abortEarly: false});
      sendForm();
    } catch (err) {
      const formErrors = err.inner.reduce((errors, currentError) => {
        return {...errors, [currentError.path]: currentError.message};
      }, {});
      setErrors({...errors, ...formErrors});
    }
  };

  const sendForm = () => {
    if (loading.form) {
      return;
    }
    setLoading({...loading, form: true});

    store.bridge.profileUpdate({
      ...data,
      ...birth,
      emailOld,
    }).then((response) => {
      const {status, data = {}} = response?.data || {};

      switch (status) {
        case 'PERSONAL_SAVE_SUCCESS':
          setIsPasswordEdit(false);

          if (typeof data.inputBirthDayReadonly !== 'undefined') {
            setIsBirthdayReadonly(data.inputBirthDayReadonly);
          }
          break;
        case 'PERSONAL_SAVE_FAIL':
          if (data.errorMessage) {
            setErrors({...errors, form: data.errorMessage});
          }
          if (data.formErrors) {
            setErrors({...errors, ...data.formErrors});
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({...loading, form: false});
    }).catch(() => {
      setLoading({...loading, form: false});
    });
  };

  const debounceGetPopularCities = debounce(getPopularCities);
  const debounceGetCities = debounce(getCities);
  const debounceGetStreets = debounce(getStreets);
  const debounceGetBuildings = debounce(getBuildings);

  // Разворачивает поля смены пароля и скроллит до них
  useEffect(() => {
    if (data) {
      const getParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });

      if (getParams.changePassword === 'Y') {
        setIsPasswordEdit(true);
        scrollTo('scroll-target-address');
      }
    }
  }, [data]);

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="personal-details-page page">
      <div className="page-wrap">
        <div className="personal-page__left">
          <h1 className="personal-page__h1">Личные данные</h1>
        </div>
        {!store.appConst.isAuth ?
          <div className="bg-gray rounded-15 p-50 tablet:p-40 mobile:py-35 mobile:px-20">
            Чтобы попасть в личный кабинет, пожалуйста, <a href="#" className="text-blue" onClick={(e) => {
            e.preventDefault();
            store.setModal('auth');
          }}>авторизуйтесь.</a>
          </div>
          :
          <div className="personal-page__top">
            <div className="personal-page__left">
              <div className="personal-details-page__wrap">
                <span className="personal-details-page__h2">Контактные данные</span>
                <div className="p-fields-grid">
                  <div className="p-fields-grid__item d-33 t-50 m-100">
                    <span className="p-float-label">
                      <InputText
                        className={errors.name ? 'p-invalid' : ''}
                        id="name"
                        value={data?.name}
                        onChange={(e) => handleFieldChange({name: e.target.value})}
                        onFocus={() => setErrors({...errors, name: null})}
                      />
                      <label htmlFor="name">Имя *</label>
                    </span>
                    {errors.name && <span className="p-error">{errors.name}</span>}
                  </div>
                  <div className="p-fields-grid__item d-33 t-50 m-100">
                    <span className="p-float-label">
                      <InputText
                        className={errors.lastName ? 'p-invalid' : ''}
                        id="lastName"
                        value={data?.lastName}
                        onChange={(e) => handleFieldChange({lastName: e.target.value})}
                        onFocus={() => setErrors({...errors, lastName: null})}
                      />
                      <label htmlFor="lastName">Фамилия</label>
                    </span>
                    {errors.lastName && <span className="p-error">{errors["lastName"]}</span>}
                  </div>
                  <div className="p-fields-grid__item d-33 t-50 m-100">
                    <span className="p-float-label">
                      <Dropdown
                        //className={errors["gender"] && touched["gender"] ? 'p-invalid' : ''}
                        inputId="gender"
                        value={data?.genderCode}
                        onChange={(e) => handleFieldChange({genderCode: e.value})}
                        options={genderOptions}
                        optionLabel="label"
                      />
                      <label htmlFor="gender">Пол</label>
                    </span>
                    {/* {errors["gender"] && touched["gender"] ?
                      <span className="p-error">{errors["gender"]}</span>
                      : null}  */}
                  </div>
                  <div className="p-fields-grid__item d-33 t-50 m-100">
                    <span className="p-float-label">
                      <InputText
                        className={errors.email ? 'p-invalid' : ''}
                        id="email"
                        value={data?.email}
                        onChange={(e) => handleFieldChange({email: e.target.value})}
                        onFocus={() => setErrors({...errors, email: null})}
                      />
                      <label htmlFor="email">E-mail *</label>
                    </span>
                    {errors.email && <span className="p-error">{errors["email"]}</span>}
                  </div>
                  <div className="p-fields-grid__item d-33 t-50 m-100">
                    <span className="p-float-label">
                      <InputMask
                        disabled
                        className={errors.phone ? 'p-invalid' : ''}
                        id="phone"
                        value={data?.phone}
                        onChange={(e) => handleFieldChange({phone: e.target.value})}
                        onFocus={() => setErrors({...errors, phone: null})}
                        mask={PHONE_MASK}
                        slotChar={PHONE_MASK_SLOT}
                      />
                      <label htmlFor="phone">Телефон</label>
                    </span>
                    {errors.phone && <span className="p-error">{errors["phone"]}</span>}
                  </div>
                  <div className="p-fields-grid__item d-33 t-50 m-100">
                    <span className="p-float-label">
                      <Calendar
                        className={errors.birthday ? 'p-invalid' : ''}
                        inputId="birthday"
                        value={birthdate}
                        onChange={(e) => {
                          const birthday = dayjs(e.target.value).format('DD.MM.YYYY');
                          handleFieldChange({birthday});
                          setBirthdate(e.target.value);
                        }}
                        onFocus={() => setErrors({...errors, birthday: null})}
                        dateFormat="dd.mm.yy"
                        readOnlyInput
                        disabled={isBirthdayReadonly}
                      />
                      <label htmlFor="birthday">Дата рождения</label>
                    </span>
                    {errors.birthday && <span className="p-error">{errors["errors"]}</span>}
                  </div>
                </div>
                <div className="personal-details-page__label">
                    Для изменения email и номера телефона обратитесь на&nbsp;<a href="mailto:support@casarte.ru">support@casarte.ru</a>
                </div>
                <div className="personal-details-page__line"/>
                <span className="personal-details-page__h2 scroll-target-address">Адрес</span>
                <div className="p-fields-grid">
                  <div className="p-fields-grid__item d-50 m-100">
                    <span className="p-float-label">
                      <Dropdown
                        //className={errors["city"] && touched["city"] ? 'p-invalid' : ''}
                        panelClassName={`dropdown__select-city__panel ${loading.city ? '_loader' : ''}`}
                        itemTemplate={cityDropdownOptionTemplate}
                        inputId="city"
                        value={selectedCity?.id}
                        onChange={(e) => handleCityChange(e.value)}
                        onFilter={(e) => handleSearchInput(e.filter)}
                        options={suggestedCities}
                        optionLabel="value"
                        optionValue="id"
                        filter
                      />
                      <label htmlFor="city">Город</label>
                    </span>
                    {/* {errors["city"] && touched["city"] ?
                      <span className="p-error">{errors["city"]}</span>
                      : null}  */}
                  </div>
                  <div className="p-fields-grid__item d-50 m-100">
                    <SearchableInputText
                      className={errors.street ? 'p-invalid' : ''}
                      id="steet"
                      value={data?.street}
                      label="Улица"
                      loading={loading.street}
                      suggestions={suggestedStreets}
                      onInput={(value) => handleStreetInput(value)}
                      onSelect={(value) => handleStreetChange(value)}
                    />
                    {errors.street && <span className="p-error">{errors.street}</span>}
                  </div>
                  <div className="p-fields-grid__item d-33 m-100">
                    <SearchableInputText
                      className={errors.building ? 'p-invalid' : ''}
                      id="building"
                      value={data?.building}
                      label="Дом"
                      loading={loading.building}
                      suggestions={suggestedBuildings}
                      onInput={(value) => handleBuildingInput(value)}
                      onSelect={(value) => handleBuildingChange(value)}
                    />
                    {errors.building && <span className="p-error">{errors.building}</span>}
                  </div>
                  <div className="p-fields-grid__item d-33 m-100">
                    <span className="p-float-label">
                      <InputNumber
                        className={errors.flat ? 'p-invalid' : ''}
                        inputId="flat"
                        value={data?.flat || null}
                        onValueChange={(e) => handleFieldChange({flat: e.value})}
                      />
                      <label htmlFor="flat">Квартира/офис</label>
                    </span>
                    {errors.flat && <span className="p-error">{errors.flat}</span>}
                  </div>
                  <div className="p-fields-grid__item d-33 m-100">
                    <span className="p-float-label">
                      <InputNumber
                        className={errors.floor ? 'p-invalid' : ''}
                        inputId="floor"
                        value={data?.floor || null}
                        onValueChange={(e) => handleFieldChange({floor: e.value})}
                      />
                      <label htmlFor="floor">Этаж</label>
                    </span>
                    {errors.floor && <span className="p-error">{errors.floor}</span>}
                  </div>
                  {isPasswordEdit &&
                  <>
                    <div className="p-fields-grid__item d-33 m-100">
                      <span className="p-float-label">
                        <Password
                          className={errors.passOld ? 'p-invalid' : ''}
                          inputId="passOld"
                          value={data?.passOld}
                          onChange={(e) => handleFieldChange({passOld: e.target.value})}
                          onFocus={() => setErrors({...errors, passOld: null})}
                          toggleMask
                          feedback={false}
                        />
                        <label htmlFor="passOld">Старый пароль</label>
                      </span>
                      {errors.passOld && <span className="p-error">{errors.passOld}</span>}
                    </div>
                    <div className="p-fields-grid__item d-33 m-100">
                      <span className="p-float-label">
                        <Password
                          className={errors.passNew ? 'p-invalid' : ''}
                          inputId="passNew"
                          value={data?.passNew}
                          onChange={(e) => handleFieldChange({passNew: e.target.value})}
                          onFocus={() => setErrors({...errors, passNew: null})}
                          toggleMask
                          feedback
                        />
                        <label htmlFor="passNew">Новый пароль</label>
                      </span>
                      {errors.passNew && <span className="p-error">{errors.passNew}</span>}
                    </div>
                    <div className="p-fields-grid__item d-33 m-100">
                      <span className="p-float-label">
                        <Password
                          className={errors.passConfirm ? 'p-invalid' : ''}
                          inputId="passConfirm"
                          value={data?.passConfirm}
                          onChange={(e) => handleFieldChange({passConfirm: e.target.value})}
                          onFocus={() => setErrors({...errors, passConfirm: null})}
                          toggleMask
                          feedback
                        />
                        <label htmlFor="passConfirm">Повторите пароль</label>
                      </span>
                      {errors.passConfirm && <span className="p-error">{errors.passConfirm}</span>}
                    </div>
                  </>
                  }
                  <div className="p-fields-grid__item d-100 m-100">
                    <div className="mt-d-15">* Поля обязательные для заполнения</div>
                    {!isPasswordEdit &&
                      <span
                        className="personal-details-page__link"
                        onClick={() => setIsPasswordEdit(true)}
                      >Поменять пароль</span>
                    }
                    {errors.form && <span className="p-fields-grid__error">{errors.form}</span>}
                    <div className="personal-details-page__line"/>
                    <div className="p-checkbox-field">
                        <Checkbox
                            inputId="regPromoSub"
                            name="regPromoSub"
                            className=""
                            onChange={e => handleFieldChange({regPromoSub: e.checked})}
                            checked={data?.promoSub}
                        />
                        <label htmlFor="regPromoSub">Я согласен с&nbsp;получением информации о&nbsp;персональных скидках и&nbsp;акциях</label>
                    </div>
                    <Button disabled={false} className="p-button-secondary p-button-white min-w mt-d-35"
                            label="Сохранить изменения" onClick={() => handleSubmit()}/>
                  </div>
                </div>
              </div>
            </div>
            <PersonalPageSummary/>
          </div>
        }
      </div>
    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(Page)));
