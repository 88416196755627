import './style.scss';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH_1 } from '../../common/const';
import QR_IMG from '../../assets/images/complete/qr.svg';
import IOS_IMG from '../../assets/images/complete/app-store.svg';
import ANDROID_IMG from '../../assets/images/complete/google-play.svg';
import CART_IMG from '../../assets/images/complete/cart.svg';
import FREE_IMG from '../../assets/images/complete/free.svg';
import axios from 'axios';
import { formatNumber, getApiPath, pluralize } from '../utils';
import pageData from '../../json/orderComplete.json';
import menuData from '../../json/catalogItems.json';
import headerData from '../../json/header.json';
import footerData from '../../json/footer.json';
import feedbackFormData from '../../json/feedbackForm.json';
import PageContainer from '../PageContainer';
import initCloudpaymentsWidget from '../utils/init-cloudpayments-widget'
import { trackGADataLayer } from "../../common/utils";

const path = process.env.REACT_APP_ASSETS_PATH;

const Page = ({ store, match }) => {
  useEffect(() => {
    document.title = 'Заказ оформлен';
  }, [])

  const [data, setData] = useState(null);
  const [responseError, setResponseError] = useState();
  const [productsStatus, setProductsStatus] = useState(false);
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const [isCloudpaymentsInited, setIsCloudpaymentsInited] = useState(false);

  const payment = useMemo(() => {
    if (data?.order?.paymentText && data?.order?.total) {
      return `${data?.order?.paymentText}&nbsp;<span class="complete-page__b">${formatNumber(Number(data?.order?.total))} <span class="rub">P</span>`
    }
    return null
  }, [data])

  const contacts = useMemo(() => {
    if (data?.order?.contacts) {
      const [name, phone, email] = data?.order?.contacts.split(', ')

      return `Получатель: ${name}, <span class="br-m" />${phone}, <span class="br-m" />${email}`
    }
    return null
  }, [data])

  const initWidget = (button, e) => {
    if (button.id === 'payButton' && !isCloudpaymentsInited) {
      e.preventDefault();
      setIsWidgetLoading(true);

      initCloudpaymentsWidget(data.order.id, () => {
        setIsCloudpaymentsInited(true);
        setIsWidgetLoading(false);
      })
    }
  }

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path).replace(':ORDER_ID', match.params.ORDER_ID) + window.location.search,
      method: 'get',
    }).then((response) => {
      setData(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(response.data.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(pageData);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  const sendOrderData = useCallback(async () => {
    axios({
      method: 'get',
      url: `/ajax/?controller=order&action=sendOrderDataInGtm&orderId=${data.order.id}`,
    })
      .then((response) => {
        const { data, status } = response?.data || {};

        switch (status) {
          case 'CHECKOUT_FINAL_SUCCESS':
            if (data?.dataLayerSubmit?.event && data?.dataLayerSubmit?.ecommerce) {
              trackGADataLayer(
                data.dataLayerSubmit.event,
                data.dataLayerSubmit.ecommerce,
              )
            }
            break;
          case 'CHECKOUT_FINAL_FAIL':
            if (data.errorMessage) {
              // eslint-disable-next-line no-console
              console.error(data.errorMessage);
            }
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(`Error ${status}: Request failed`);
            break;
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  }, [data])

  // Отправляет аналитику
  useEffect(() => {
    if (data?.order?.id) {
      sendOrderData();
    }
  }, [data, sendOrderData])

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="complete-page page">
      <div className="page-wrap">
        {Boolean(data?.backButton?.link && data?.backButton?.text) && <Link to={data.backButton.link} className="cart-page__back">
          <span className="cart-page__arr _left" />
          <span dangerouslySetInnerHTML={{__html: data.backButton.text}} />
        </Link>}
        {Boolean(data?.title) && <h1 className="cart-page__h1" dangerouslySetInnerHTML={{__html: data.title}} />}
        <div className="complete-page__wrap">
          <div className="complete-page__left">
            <div className="complete-page__pl complete-page__summary">
              {Boolean(data?.order?.link && data?.order?.id) && <p className="complete-page__summary__item _i1"><span>Заказ&nbsp;<span className="complete-page__b _golden">№{data?.order?.id}</span></span></p>}
              {Boolean(payment) && <p className="complete-page__summary__item _i2"><span dangerouslySetInnerHTML={{__html: payment}} /></p>}
              {Boolean(contacts) && <p className="complete-page__summary__item _i3"><span dangerouslySetInnerHTML={{__html: contacts}} /></p>}
              {Boolean(data?.certificate?.link && data?.certificate?.text) && <p className="complete-page__summary__item _i4"><span><a href={data.certificate.link} className="_golden" dangerouslySetInnerHTML={{__html: data.certificate.text}} /></span></p>}
              {Boolean(data?.buttons?.length) && <div className="complete-page__summary__buttons">
                {data.buttons.map((button, index) =>
                  button.link && button.text
                    ? <Link key={index} to={button.link} className="p-button p-component p-button-secondary p-button-white min-w">
                        <span dangerouslySetInnerHTML={{__html: button.text}} />
                      </Link>
                    : Boolean(button.text) && <button
                      key={index}
                      id={button.id}
                      type="button"
                      className={`p-button p-component p-button-secondary p-button-white min-w ${isWidgetLoading ? 'p-loader' : ''}`}
                      dangerouslySetInnerHTML={{__html: button.text}}
                      onClick={(e) => initWidget(button, e)}
                    />
                )}
              </div>}
            </div>
            <div className="complete-page__pl complete-page__details">
              <p className="complete-page__b">Детали заказа</p>
              {Boolean(data?.delivery?.details?.length) && <div className="complete-page__details__pl">
                <ul>
                  {data.delivery.details.map((item, index) =>
                    <li key={index} dangerouslySetInnerHTML={{__html: item}} />
                  )}
                </ul>
              </div>}
              {Boolean(data?.delivery?.date && data?.delivery?.address) && <p className="complete-page__address">
                Желаемая дата доставки: {data.delivery.date}<br/>
                Адрес доставки: {data.delivery.address}
              </p>}
              {Boolean(data?.products?.length) && <div>
                <p className="complete-page__b">{data.products.length}&nbsp;{pluralize(data.products.length, ['товар', 'товара', 'товаров'])}&nbsp;&nbsp;<span
                  onClick={() => setProductsStatus(!productsStatus)}
                  className={`complete-page__a _${productsStatus?'up':'down'}`}>{productsStatus?'Свернуть':'Развернуть'}</span></p>
                  {
                    productsStatus &&
                    <div className="complete-page__products">
                      {data.products.map((product, i) =>
                        <div key={i} className="complete-page__product">
                          <div className="complete-page__product__wrap">
                            {Boolean(product.link && product.image)
                              ? <Link to={product.link} className="complete-page__product__pic">
                                  <img src={product.image} alt={product.name}/>
                                </Link>
                              : Boolean(product.image) && <span className="complete-page__product__pic">
                                  <img src={product.image} alt={product.name}/>
                                </span>
                            }
                            {Boolean(product.isInstallation) && <span className="complete-page__product__pic">
                              <img src={FREE_IMG} alt="Бесплатная установка"/>
                            </span>}
                            <p className="complete-page__product__info">
                              <div className="complete-page__product__title">
                                {Boolean(product.link && product.name)
                                  ? <>
                                    <Link to={product.link} className="_golden">
                                      <span dangerouslySetInnerHTML={{ __html: product.name }}/>
                                    </Link>, {product.itemBasketCount}&nbsp;шт.
                                  </>
                                  : Boolean(product.name) && <span dangerouslySetInnerHTML={{ __html: product.name }}/>
                                }
                              </div>
                              {typeof product.total !== 'undefined' && <div
                                className="complete-page__b"
                                dangerouslySetInnerHTML={{__html: `${formatNumber(product.total)} <span class="rub">P</span>`}}
                              />}
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  }
              </div>}
            </div>
            {Boolean(data?.discount) && <div className="complete-page__pl complete-page__sale">
              <MediaQuery minWidth={MOBILE_WIDTH_1}>
                <div className="complete-page__pl__left">
                  <img className="complete-page__cart" src={CART_IMG} alt="Casarte"/>
                </div>
              </MediaQuery>
              <div className="complete-page__pl__right">
                {Boolean(data?.discount?.text) && <p className="complete-page__h2" dangerouslySetInnerHTML={{__html: data.discount.text}} />}
                <p className="complete-page__b">
                  {Boolean(data?.discount?.description) && <>
                    <span dangerouslySetInnerHTML={{__html: data.discount.description}} />&nbsp;&nbsp;<span className="br-m" />
                  </>}
                  {Boolean(data?.discount?.link && data?.discount?.linkText) && <Link to={data.discount.link} className="complete-page__a">
                    <span dangerouslySetInnerHTML={{__html: data.discount.linkText}} />
                  </Link>}
                </p>
              </div>
            </div>}
            {Boolean(data?.showApp) && <div className="complete-page__pl complete-page__apps">
              <div className="complete-page__pl__left">
                <p className="complete-page__b">Статусы по заказу удобно отслеживать в приложении evo</p>
                <div className="complete-page__apps-wrap">
                  <a className="complete-page__app"  href="https://apps.apple.com/ru/app/evo-клуб-привилегий-от-haier/id1542043377" target='_blank'>
                    <img src={IOS_IMG} alt="App Store" />
                  </a>
                  <a className="complete-page__app" href="https://play.google.com/store/apps/details?id=ru.haier.app" target='_blank'>
                    <img src={ANDROID_IMG} alt="Google Play" />
                  </a>
                </div>
              </div>
              <MediaQuery minWidth={MOBILE_WIDTH_1}>
                <div className="complete-page__pl__right">
                  <img className="complete-page__qr" src={QR_IMG} alt="Статусы по заказу удобно отслеживать в приложении evo" />
                </div>
              </MediaQuery>
            </div>}
          </div>
          <MediaQuery minWidth={MOBILE_WIDTH_1}>
            <div className="complete-page__right">
              <Link to="/">
                <img className="complete-page__banner" src={`${path}images/complete/banner.jpg`} alt="Casarte"/>
              </Link>
            </div>
          </MediaQuery>
        </div>
      </div>
    </div>
    </PageContainer>
  );
}

export default inject('store')(withRouter(Page));
