import './style.scss';
import React from 'react';
import {inject, observer} from "mobx-react";
import { disableBodyScroll, enableBodyScroll } from '../../common/utils';
import { Button } from 'primereact/button';

const Modal = (props) => {
  const name = 'clearCart';
  const data = props.store.modal[name];

  const handleClose = (e) => {
    if(e.target.className.indexOf('cart-page__clear-modal__outer-wrap') >= 0){
      props.store.setModal(name, null);
    }
  }

  data?.data ? disableBodyScroll() : enableBodyScroll();

  return (
    data ?
    <div className={'cart-page__clear-modal _' + data.status + ' '+data?.data?.className} onClick={(e) => handleClose(e) }>
      <div className="cart-page__clear-modal__outer-wrap">
        <div className="cart-page__clear-modal__inner-wrap">
          <div className="cart-page__clear-modal__body">
            <div className="cart-page__clear-modal__close" onClick={() => props.store.setModal(name, null) } />
            <span className="cart-page__clear-modal__title">Очистить корзину</span>
            <span className="cart-page__clear-modal__text">Вы точно хотите удалить все товары в корзине?</span>
            <div className="p-fields-grid">
              <div className="p-fields-grid__item d-50 m-50">
                <Button className="p-button-secondary p-button-transparent" label="Отменить" onClick={() => props.store.setModal(name, null)} />
              </div>
              <div className="p-fields-grid__item d-50 m-50">
                <Button className={`p-button-secondary p-button-white ${props.loading.basketReset ? 'p-loader' : ''}`} label="Да, удалить" onClick={() => props.sendResetBasket()}  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    : null
  )
}

export default inject('store')(observer(Modal));
