import { scroller } from 'react-scroll';
import React from 'react';
import {
  useLocation
} from "react-router-dom";
import ReactDOM from "react-dom";
import Table from "../components/Table";

const getScrollbarWidth = () => {
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll';
  outer.style.msOverflowStyle = 'scrollbar';
  document.body.appendChild(outer);

  const inner = document.createElement('div');
  outer.appendChild(inner);

  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export const disableBodyScroll = () => {
  const scrollbarWidth = getScrollbarWidth();
  const scroll = window.pageYOffset;

  document.body.style.paddingRight = `${ scrollbarWidth }px`;

  if(!document.body.dataset.lock) {
    document.body.style.top = `${ -scroll }px`;
  }

  document.body.dataset.lock = true;

  const header = document.querySelector('.page-header');
  const headerFixed = document.querySelector('.page-header__fixed');

  if(header)
    header.style.right = `${ scrollbarWidth }px`;

  if(headerFixed)
    headerFixed.style.right = `${ scrollbarWidth }px`;

  document.body.classList.add('scroll-lock');
}

export const enableBodyScroll = () => {
  if(document.body.dataset.lock) {
    document.body.style.paddingRight = 0;

    const header = document.querySelector('.page-header');
    const headerFixed = document.querySelector('.page-header__fixed');

    if(header)
      header.style.right = 0;

    if(headerFixed)
      headerFixed.style.right = 0;

    const scroll = Math.abs(parseInt(document.body.style.top));
    document.body.dataset.lock = '';

    document.body.style.top = 0;
    document.body.classList.remove('scroll-lock');
    window.scrollTo(0, scroll);
  }
}

export const scrollTo = (className, duration=1000) => {
  const wh = window.document.body.offsetWidth;
  const offset =
    wh > 1366 ? 88 :
    wh > 1200 ? 72 :
    wh > 1023 ? 66 : 60;

  scroller.scrollTo(className, {
    duration: duration,
    smooth: 'easeInOutCubic',
    offset: -offset
  })
}

export const numWithSpaces = x => {
  if(!x)
    return 0;

  x = Math.floor(x);

  let parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

export const viewport = () => {
  return {
    innerWidth: Math.min(1920, window.document.body.offsetWidth),
    innerHeight: window.innerHeight
  }
}

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const capitalizeFirstLetter = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const fixDeg = str => {
  if(!str || typeof str !== 'string')
    return '';

  str = str.split('[c]').join('\u00b0C');

  return str;
}

export const formatTel = str => {
  return str;

  //let cleaned = ('' + str.split('+7').join('8')).replace(/\D/g, '');

  //return cleaned;
}

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function trackGADataLayer(event, obj) {
  try {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: event,
      ecommerce: obj
    })
  } catch (e) {
    // console.warn(e);
  }
}

export function updateBasketDataLayer(id, data) {
  const { ecommerce } = (data?.yandexMetrica || data?.dataLayer) || {};
  const currency = ecommerce?.currencyCode || '';
  const product = (ecommerce?.add || ecommerce?.remove)?.products?.find((item) => String(item.id) === String(id)) || {};

  if (ecommerce) {
    trackGADataLayer(
      ecommerce?.add ? 'add_to_cart' : 'remove_from_cart',
      {
        currency,
        value: product.price * product.quantity,
        items: [
          {
            item_id: String(product.id),
            item_name: product.shortName || product.name,
            index: 0,
            item_brand: product.brand,
            item_category: product.category,
            item_variant: product.variant,
            price: product.price,
            quantity: product.quantity,
          },
        ],
      },
    );
  }
}

/**
 * Метод замены таблицы с контента на компонент реакта
 */
export const renderTables = () => {
  document.querySelectorAll('.js-table-example').forEach((item) => {
    try {
      const json = JSON.parse(item.dataset.json);
      ReactDOM.render(<Table className="table-example" {...json} />, item);
    } catch (e) {
      console.error(e);
    }
  });
};

export function isTouchDevice() {
  return (
    ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0)
  );
}
