import {useEffect, useState} from "react";
import classNames from 'classnames';

const Table = ({className = '', columns, columnListLabel, items, thClassName = '', tdClassName = ''}) => {
  const [isMobile, setIsMobile] = useState(false);
  const [activeColumn, setActiveColumn] = useState(0);

  const currentColumns = isMobile ? [columns[activeColumn]] : columns;

  useEffect(() => {
    function resizeListener() {
      setIsMobile(window.matchMedia('(max-width: 1023px)').matches);
    }

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, []);

  const rows = items.map((item) => ({
    label: item.label,
    values: currentColumns.map((col) => item.data[col.name]),
  }));

  return <div className={'table table--border-bottom -current-container ' + className}>
    {
      isMobile ? <div className="table__cols current-container">
        {
          columns.map((col, colIndex) => {
            return (
              <div
                key={col.name}
                className={classNames({
                  'table__col-selector': true,
                  'table__col-selector_mod_active': activeColumn === colIndex
                })}
                onClick={() => {
                  setActiveColumn(colIndex)
                }}
              >
                {col.label}
              </div>
            )
          })
        }
      </div> : null
    }
    <div className="current-container">
      <table>
        <tbody>
        <tr className="header">
          <th>{columnListLabel}</th>
          {
            currentColumns.map(col => <th key={col.name}>{col.label}</th>)
          }
        </tr>
        {
          rows.map(row => <tr key={row.label}>
            <th className={thClassName || ''}>{row.label}</th>
            {
              row.values.map(val => <td className={tdClassName} key={val}>{val}</td>)
            }
          </tr>)
        }
        </tbody>
      </table>
    </div>
  </div>
}

export default Table;
