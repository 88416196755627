import './style.scss';
import React, { useEffect, useState, useRef } from 'react';

const kShowHeight = 0.0;

const Anim = (props) => {
  const [animationStatus, setAnimationStatus] = useState('hidden');
  const ref = useRef(null);

  useEffect(() => {
    const onScroll = e => {
      if(!ref.current){
        return
      }

      const wh = window.innerHeight;
      const ot = ref.current.getBoundingClientRect().top;
      const y = ot - wh;

      if(y < -wh*kShowHeight && animationStatus === 'hidden'){
        window.removeEventListener("scroll", onScroll);
        setAnimationStatus('show');

        setTimeout(() => {
          setAnimationStatus('shown');
        }, 3000);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} className={`anim ${props.className} ${animationStatus}`} id={`${props.id ? props.id : ''}`}>
      {props.children}
    </div>
  )
}

export default Anim;
