import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';

const path = process.env.REACT_APP_ASSETS_PATH;

export const VelatoHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.webp)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.webp)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Кондиционер</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        <h2 className="product-page__head__center__sub-title">VELATO</h2>
        <p className="product-page__head__center__text">Дыхание природы</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const VelatoContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section comfort-id">
        <div className="wrap">
          <SectionHeader
            title={<>Максимальный <br/>комфорт</>}
            text={<>Гибкое управление воздушными потоками, сверхтихая работа, ароматизация помещения</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="201"
              title={<>Двухзонный <br/>климат-контроль</>}
              text={<>Гибкая настройка направления воздушных потоков.</>}
              detailTitle={<>Двухзонный климат-контроль</>}
              detailText={<>Для обеспечения максимального комфорта кондиционер Velato позволяет регулировать два сегмента воздушных жалюзи как вместе, так и раздельно.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="202"
              title={<>Ароматизация воздуха</>}
              text={<>Наполнение комнаты любимым ароматом.</>}
              detailText={<>Velato позаботится не&nbsp;только о&nbsp;комфортной температуре и&nbsp;свежести воздуха, но&nbsp;и&nbsp;поможет создать особую атмосферу, наполнив комнату любимым ароматом. Для этого в&nbsp;кондиционер встроена специальная емкость для&nbsp;ароматической жидкости.</>}
              picFormat="webp"
              //videoFormat="webm"
              video={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="203"
              title={<>Сверхтихая работа</>}
              text={<>От 17 дБ(А)</>}
              detailText={<>Благодаря специальной конструкции вентилятора внутреннего блока кондиционера, его корпуса, большой площади испарителя, а&nbsp;также использованию высококлассных приводов обеспечивается минимально возможный уровень шума.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="204"
              title={<>Трехпоточная <br/>подача воздуха</>}
              text={<>Оптимизация распределения воздушного потока.</>}
              detailTitle={<>Трехпоточная подача воздуха</>}
              detailText={<>Сдвоенный воздушный дефлектор и&nbsp;дополнительный канал подачи воздуха в&nbsp;нижней части внутреннего блока для&nbsp;равномерного распределения воздушного потока.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>С&nbsp;заботой <br/>о&nbsp;здоровье</>}
            text={<>Чистый воздух, насыщенный кислородом</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large m-small-2"
              picName="205"
              title={<>Высокоэффективный <br/>IFD-фильтр</>}
              text={<>Очистка воздуха за счет электростатического эффекта.</>}
              detailTitle={<>Высокоэффективный IFD-фильтр</>}
              detailText={<>В&nbsp;конструкции фильтра IFD (англ. Intense Field Dielectric) используется принцип электростатического поля, благодаря чему фильтр улавливает мельчайшие твердые и&nbsp;каплеобразные частицы, находящиеся в&nbsp;воздухе. Фильтр не&nbsp;требует замены и&nbsp;рассчитан на&nbsp;весь срок службы кондиционера. Потребуется лишь периодическая его очистка под&nbsp;струей воды.</>}
              picFormat="webp"
              //videoFormat="webm"
              video={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="206"
              title={<>Приток свежего воздуха, насыщенного кислородом</>}
              text={<>Встроенный вентилятор обеспечивает подачу до&nbsp;40м3/ч свежего воздуха с&nbsp;улицы с&nbsp;предварительной очисткой HEPA-фильтром.</>}
              detailTitle={<>Приток свежего воздуха, насыщенного кислородом</>}
              detailText={<>Когда в&nbsp;комнате находятся люди, содержание кислорода в&nbsp;воздухе постепенно снижается. Стандартные кондиционеры рассчитаны только на&nbsp;изменение температуры воздуха внутри помещения. Кондиционеры серии Velato способны сделать его по&nbsp;настоящему свежим, благодаря возможности подачи до&nbsp;40м3/ч свежего уличного воздуха с&nbsp;предварительной 5-ступенчатой очисткой HEPA-фильтром.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="207"
              title={<>Устранение <br/>вирусов <br/>и&nbsp;бактерий</>}
              text={<>Стерилизация теплообменника при&nbsp;56°С в&nbsp;течение 30&nbsp;минут.</>}
              detailTitle={<>Устранение вирусов и&nbsp;бактерий</>}
              detailText={<>После завершения цикла самоочистки автоматически запускается процесс стерилизации внутреннего блока сплит-системы. С&nbsp;помощью интеллектуального контроля температуры и&nbsp;регулирования частоты вращения вентилятора температура на&nbsp;поверхности теплообменника поддерживается на&nbsp;уровне 56°С в&nbsp;течение 30&nbsp;минут.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large i8"
              wrapClass="bottom-center"
              titleClass="large"
              picName="8"
              title={<>Эффективная <br/>самоочистка</>}
              text={<>Очистка теплообменника на основе принципа термического расширения.</>}
              detailTitle={<>Эффективная самоочистка за&nbsp;21&nbsp;минуту</>}
              detailText={<>Инновационная технология очистки удаляет микробы, бактерии, пыль и&nbsp;загрязнения, которые скапливаются на&nbsp;поверхности теплообменника в&nbsp;процессе использования прибора. После активации функции очистки кондиционер намораживает небольшой слой инея на&nbsp;теплообменнике, а&nbsp;затем нагревает его. В&nbsp;процессе оттаивания жидкость удаляется вместе с&nbsp;грязью, очищая теплообменник.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <div className="product-page__section performance-id">
        <div className="wrap">
          <SectionHeader
            title={<>Комфорт круглый год</>}
            text={<>Стабильная работа в сложных условиях эксплуатации</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="208"
              title={<>Идеальный <br/>микроклимат</>}
              text={<>Расширенный диапазон рабочих температур.</>}
              detailTitle={<>Идеальный микроклимат</>}
              detailText={<>Наружный блок с&nbsp;технологией теплового насоса воздух - воздух позволяет использовать кондиционер как&nbsp;летом при&nbsp;наружной температуре до&nbsp;+43°С, так и&nbsp;в&nbsp;зимний период при&nbsp;температуре до&nbsp;-30°С.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="209"
              title={<>Компрессор <br/>Mitsubishi <br/>Electric </>}
              detailTitle={<>Компрессор <br/>Mitsubishi Electric </>}
              detailText={<>В&nbsp;конструкции наружного блока используются надежные и&nbsp;высококлассные компрессоры Mitsubishi Electric - мирового лидера в&nbsp;области технологий производства компрессоров для&nbsp;кондиционеров. Гарантия на&nbsp;компрессор составляет 10&nbsp;лет.</>}
              picFormat="webp"
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="top-left m-top-left"
              titleClass="l-small"
              picName="210"
              title={<>Максимальный класс энергоэффективности в&nbsp;режимах охлаждения и&nbsp;обогрева</>}
              detailTitle={<>Максимальный класс энергоэффективности в&nbsp;режимах охлаждения и&nbsp;обогрева</>}
              detailText={<>Передовые инверторные технологии обеспечивают наивысший класс сезонной энергоэффективности А+++ при&nbsp;работе как на&nbsp;охлаждение, так и&nbsp;на&nbsp;обогрев. Кондиционер обеспечивает максимальный комфорт пользователя даже при&nbsp;сверхнизких внешних температурах, затрачивая при&nbsp;этом минимум электроэнергии.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <div className="product-page__section control-id">
        <div className="wrap">
          <SectionHeader
            title={<>Возможности управления</>}
            text={<>Управление кондиционером удобным способом</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="211"
              title={<>Управление <span className="d-br"/>с&nbsp;помощью пульта ДУ</>}
              text={<>Элегантный пульт управления черного цвета, выполненный из&nbsp;премиальных материалов.</>}
              detailTitle={<>Управление с&nbsp;помощью пульта ДУ</>}
              detailText={<>Пульт дистанционного управления работает на&nbsp;расстоянии до&nbsp;10 метров, выполнен в&nbsp;черном цвете из&nbsp;премиальных материалов, имеет большой информативный дисплей с&nbsp;подсветкой и&nbsp;полностью русифицирован. Пульт обладает возможностью настройки таймера на&nbsp;включение/выключение и&nbsp;оснащен датчиком I-Feel.</>}
              picFormat="webp"
            />
          </div>
        </div>
      </div>
      <EvoSection
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={true}
        picFormat="webp"
        sectionText={<>Мониторинг и&nbsp;управление с&nbsp;помощью мобильного приложения и&nbsp;голосовых помощников</>}
        planeText={<>Выбор режима работы, настройка скорости <span className="d-br"/>и&nbsp;управление температурой.</>}
      />
    </>
  )
}