import React, { useState } from 'react';
import Modal from '../Modal'
import {inject, observer} from "mobx-react";
import {InputMask} from "primereact/inputmask";
import {Button} from "primereact/button";
import classNames from "classnames";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Form, Formik} from "formik";
import './style.scss';
import {
  CODE_RESEND_COOLDOWN,
  CONFIRM_CODE_LENGTH,
  ERROR_MESAGES, AUTH_CODE_COOKIE_NAME,
  PHONE_MASK,
  PHONE_VALUE_COOKIE_NAME, PHONE_MASK_SLOT
} from "../../common/const";
import * as Yup from "yup";
import Cookies from 'js-cookie';
import Recaptcha from "../utils/recaptcha";
import TimeCounter from "../TimeCounter";
import { debounce } from '../utils';
import YandexIdButton from '../YandexIdButton';

Yup.addMethod(Yup.string, 'phoneNotFull', function (errorMessage) {
  return this.test('test-phone-length', errorMessage, function (value) {
    return (
      value.replace(/\D/g, '').length === PHONE_MASK.replace(/\D/g, '').length ||
      this.createError({ path: this.path, message: errorMessage })
    );
  });
});

const authPhoneFormSchema = Yup.object().shape({
  phone: Yup.string()
    .phoneNotFull(ERROR_MESAGES.phoneNotFull)
    .required(ERROR_MESAGES.required),
});

const authEmailFormSchema = Yup.object().shape({
  email: Yup.string()
    .email(ERROR_MESAGES.formatEmail)
    .required(ERROR_MESAGES.required),
  password: Yup.string()
    .required(ERROR_MESAGES.required),
});

const phoneCodeFormSchema = Yup.object().shape({
  code: Yup.string()
    .length(CONFIRM_CODE_LENGTH, ERROR_MESAGES.codeNotFull)
    .required(ERROR_MESAGES.required),
});

const initialLoading = {
  phoneCode: false,
  confirmPhoneCode: false,
  authEmail: false,
};

const initialErrors = {
  authPhoneForm: null,
  phoneCodeForm: null,
  authEmailForm: null,
};

const modalName = 'auth';
const path = process.env.REACT_APP_ASSETS_PATH;

const AuthModal = ({ store }) => {
  const { isConfirm, isEmail, backUrl, isCodeResend, isError } = store.modal[modalName]?.data ?? {};
  const recaptcha = new Recaptcha();

  const setCodeResendDateCookie = () => {
    const expires = new Date(
      new Date().getTime() + Number(CODE_RESEND_COOLDOWN) * 1000,
    );
    Cookies.set(AUTH_CODE_COOKIE_NAME, `${expires}`, { expires });
  };

  const getCodeResendDateCookie = () => {
    return Cookies.get(AUTH_CODE_COOKIE_NAME);
  }

  const setPhoneValueCookie = (phoneNumber) => {
    const expires = new Date(
      new Date().getTime() + Number(CODE_RESEND_COOLDOWN) * 1000,
    );
    Cookies.set(PHONE_VALUE_COOKIE_NAME, `${phoneNumber}`, { expires });
  };

  const getPhoneValueCookie = () => {
    return Cookies.get(PHONE_VALUE_COOKIE_NAME);
  };

  // STATES

  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);
  const [phoneValue, setPhoneValue] = useState(getPhoneValueCookie());
  const [codeResendDate, setCodeResendDate] = useState(getCodeResendDateCookie());

  // METHODS

  const sendPhoneCode = async (phone = phoneValue) => {
    // setCodeResendDateCookie();
    // setCodeResendDate(getCodeResendDateCookie());
    // store.setModal('auth', { isConfirm: true });
    // return;

    if (getCodeResendDateCookie()) {
      setCodeResendDate(getCodeResendDateCookie());
      store.setModal('auth', { isConfirm: true })
      return;
    }
    if (loading.phoneCode) {
      return;
    }
    setErrors({
      ...errors,
      authPhoneForm: null,
      phoneCodeForm: null,
    });
    setLoading({ ...loading, phoneCode: true });

    const token = await recaptcha.execute();

    store.bridge.authPhone({
      phone,
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'AUTH_PHONE_SUCCESS':
          setPhoneValueCookie(phone);
          setCodeResendDateCookie();
          setCodeResendDate(getCodeResendDateCookie());
          store.setModal('auth', { isConfirm: true })

          // if (data.phone) {
          //   // Возвращаемый эндпоинтом номер телефона идет без маски
          //   // Пострадает маска на первом шаге
          //   // @TODO: записывать это значение отдельно, либо использовать только введенный пользователем номер
          //   authPhone = {
          //     phoneValue: data.phone,
          //   };
          // }
          break;
        case 'AUTH_PHONE_FAIL':
          if (data.errorMessage) {
            setErrors({
              ...errors,
              authPhoneForm: data.errorMessage,
              phoneCodeForm: data.errorMessage,
            });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, phoneCode: false });
    }).catch(() => {
      setLoading({ ...loading, phoneCode: false });
    });
  };

  const handlePhoneCodeInput = (code) => {
    if (code.length === CONFIRM_CODE_LENGTH) {
      sendConfirmPhoneCode(code);
    }
  };

  const sendConfirmPhoneCode = async (code) => {
    if (loading.confirmPhoneCode) {
      return;
    }
    setErrors({ ...errors, phoneCodeForm: null });
    setLoading({ ...loading, confirmPhoneCode: true });

    const token = await recaptcha.execute();

    store.bridge.authVerifyPhone({
      code,
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'AUTH_CONFIRM_CODE_SUCCESS':
          if (backUrl) {
            window.location.href = backUrl;
          } else {
            window.location.reload();
          }
          break;
        case 'AUTH_CONFIRM_CODE_FAIL':
          if (data.errorMessage) {
            setErrors({ ...errors, phoneCodeForm: data.errorMessage });
          }
          setLoading({ ...loading, confirmPhoneCode: false });
          break;
        default:
          setLoading({ ...loading, confirmPhoneCode: false });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setLoading({ ...loading, confirmPhoneCode: false });
    });
  };

  const sendAuthEmail = (values) => {
    if (loading.authEmail) {
      return;
    }
    setErrors({ ...errors, authEmailForm: null });
    setLoading({ ...loading, authEmail: true });

    store.bridge.authEmail({
      email: values.email,
      password: values.password,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'AUTH_EMAIL_SUCCESS':
          if (backUrl) {
            window.location.href = backUrl;
          } else {
            window.location.reload();
          }
          break;
        case 'AUTH_EMAIL_FAIL':
          if (data.errorMessage) {
            setErrors({ ...errors, authEmailForm: data.errorMessage });
          }
          setLoading({ ...loading, authEmail: false });
          break;
        default:
          setLoading({ ...loading, authEmail: false });
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
    }).catch(() => {
      setLoading({ ...loading, authEmail: false });
    });
  };

  const debounceHandlePhoneCodeInput = debounce(handlePhoneCodeInput);

  if (isConfirm) {
    return (
      <Modal title={'Введите код'} name={modalName}>
        <div className="auth-form__text-1">
          На номер телефона <strong className='text-blue'>{phoneValue || '+7 (999) 899-65-65'}</strong> выслан код подтверждения. Введите его в поле ниже для входа
        </div>

        <Formik
          initialValues={{
            code: '',
          }}
          validationSchema={phoneCodeFormSchema}
          onSubmit={(values) => sendConfirmPhoneCode(values.code)}
        >
          {({ errors: formErrors, values, setFieldValue, touched, setTouched }) => (
            <Form>
              <span className="p-float-label mt-20">
                <InputText
                  className={formErrors.code && touched.code ? 'p-invalid' : ''}
                  id="authPhoneCode"
                  name="authPhoneCode"
                  value={values.code}
                  readOnly={loading.confirmPhoneCode}
                  maxLength={CONFIRM_CODE_LENGTH}
                  onChange={(e) => {
                    setFieldValue('code', e.target.value);
                    debounceHandlePhoneCodeInput(e.target.value);
                  }}
                />
                <label htmlFor="authPhoneCode">Введите код</label>
              </span>
              {formErrors.code && touched.code && <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.code}} />}
              {errors.phoneCodeForm && <div className="text-light mt-30" dangerouslySetInnerHTML={{__html: errors.phoneCodeForm}} />}
              <div className="mt-30">
                {codeResendDate || isCodeResend ?
                  <strong>Отправить код повторно можно будет через {isCodeResend ? '00:59' : <TimeCounter
                    targetDate={codeResendDate}
                    onComplete={() => setCodeResendDate(null)}
                  />}</strong>
                  :
                  <div className={`retry ${loading.phoneCode ? '_loading' : ''}`} onClick={() => sendPhoneCode()}>Отправить код повторно</div>
                }
              </div>
              <div className="flex justify-beetween gap-15 mt-40 mobile:flex-item-fullwidth">
                <Button
                  className={`p-button-secondary p-button-white ${loading.confirmPhoneCode ? 'p-loader' : ''}`}
                  type="submit"
                  label="Подтвердить"
                  onClick={() => {
                    setTouched(Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: true }), {}));
                  }}
                />
                <Button
                  className="p-button-secondary p-button-transparent"
                  type="button"
                  label="Войти по почте"
                  onClick={() => {
                    store.setModal('auth', { isEmail: true });
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>

        <div className="mt-40">
          <a href="#" className="link-back" onClick={(e) => {
            e.preventDefault();
            store.setModal('auth');
          }}>Назад</a>
        </div>
      </Modal>
    )
  }

  const PhoneForm = () => {
    return (
      <Formik
        initialValues={{
          phone: phoneValue,
        }}
        validationSchema={authPhoneFormSchema}
        onSubmit={(values) => {
          setPhoneValue(values.phone);
          sendPhoneCode(values.phone);
        }}
      >
        {({ errors: formErrors, values, setFieldValue, touched, setTouched }) => (
          <Form>
            <span className="p-float-label">
              <InputMask
                type="tel"
                className={(errors.phone && touched.phone || isError) ? 'p-invalid' : ''}
                id="authPhoneValue"
                name="authPhoneValue"
                mask={PHONE_MASK}
                slotChar={PHONE_MASK_SLOT}
                value={values.phone}
                readOnly={codeResendDate}
                onChange={(e) => setFieldValue('phone', e.target.value)}
              />
              <label htmlFor="authPhoneValue">Номер телефона</label>
            </span>
            {isError && <span className="p-error">Пользователь не найден</span>}
            {formErrors.phone && touched.phone ? <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.phone}} /> : null}
            {errors.authPhoneForm ? <div className="text-light mt-30" dangerouslySetInnerHTML={{__html: errors.authPhoneForm}} /> : null}

            <div className={classNames('flex justify-beetween gap-15 mobile:flex-item-fullwidth mt-30')}>
              <Button
                className={`p-button-secondary p-button-white ${loading.phoneCode ? 'p-loader' : ''}`}
                type="submit"
                label={codeResendDate ? 'Ввести код' : 'Получить код'}
                onClick={() => {
                  setTouched(Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: true }), {}));
                }}
              />
              <Button
                className="p-button-secondary p-button-transparent"
                type="button"
                label="Войти по почте"
                onClick={() => store.setModal('auth', { isEmail: true })}
              />
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  const EmailForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={authEmailFormSchema}
        onSubmit={(values) => sendAuthEmail(values)}
      >
        {({ errors: formErrors, values, setFieldValue, touched, setTouched }) => (
          <Form>
            <div className="p-fields-grid">
              <div className="p-fields-grid__item d-100 m-100">
                <span className="p-float-label">
                  <InputText
                    className={(formErrors.email && touched.email) || isError ? 'p-invalid' : ''}
                    id="authEmailValue"
                    name="authEmailValue"
                    value={values.email}
                    onChange={e => setFieldValue('email', e.target.value)}
                  />
                  <label htmlFor="authEmailValue">E-mail</label>
                </span>
                {isError && <span className="p-error">{ERROR_MESAGES.required}</span>}
                {formErrors.email && touched.email ? <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.email}} /> : null}
              </div>
              <div className="p-fields-grid__item d-100 m-100">
                <span className="p-float-label">
                  <Password
                    className={formErrors.password && touched.password ? 'p-invalid' : ''}
                    inputId="authEmailPassword"
                    name="authEmailPassword"
                    feedback={false}
                    toggleMask
                    value={values.password}
                    onChange={e => setFieldValue('password', e.target.value)}
                  />
                  <label htmlFor="authEmailPassword">Пароль</label>
                </span>
                {formErrors.password && touched.password ? <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.password}} /> : null}
              </div>
            </div>

            <div className="mt-30">
              <a href="#" className={"text-blue"} onClick={(e) => {
                e.preventDefault();
                store.setModal('restorePassword');
              }}><strong>Забыли пароль?</strong></a>
            </div>

            {errors.authEmailForm ? <div className="text-light mt-30" dangerouslySetInnerHTML={{__html: errors.authEmailForm}} /> : null}

            <div className={classNames('flex justify-beetween gap-15 mobile:flex-item-fullwidth mt-30')}>
              <Button
                className={`p-button-secondary p-button-white ${loading.authEmail ? 'p-loader' : ''}`}
                type="submit"
                label="Войти"
                onClick={() => {
                  setTouched(Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: true }), {}));
                }}
              />
              <Button
                className="p-button-secondary p-button-transparent"
                type="button"
                label="Войти по телефону"
                onClick={() => store.setModal('auth')}
              />
            </div>
          </Form>
        )}
      </Formik>
    )
  }

  return (
    <Modal
      title={(
        <>Вход<a href="#" onClick={(e) => {
          e.preventDefault();
          store.setModal('register');
        }}>Регистрация</a></>
      )}
      name={modalName}
    >
      <div className="auth-form">
        {isEmail ?
          <EmailForm />
          :
          <PhoneForm />
        }
        <YandexIdButton />

        {/*<div className="mt-40">*/}
        {/*  Авторизуйтесь с помощью вашей учетной записи, если вы зарегистрированы на этих сайтах:&nbsp;&nbsp;&nbsp;*/}
        {/*  <span className="logo-line">*/}
        {/*    <a href="https://haieronline.ru/" target="_blank"><img src={`${path}images/haier.svg`} alt="Частями" /></a>*/}
        {/*    <a href="https://haieronline.ru/thunderobot/" target="_blank"><img src={`${path}images/thunderobot.svg`} alt="Частями" /></a>*/}
        {/*  </span>*/}
        {/*</div>*/}
      </div>
    </Modal>
  )
}

export default inject('store')(observer(AuthModal));
