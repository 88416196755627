import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const CoveCasarteHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Индукционная<br/>варочная<br/>поверхность</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        {/* <h2 className="product-page__head__center__sub-title">CASHY85RWJVB</h2> */}
        <p className="product-page__head__center__text">Пять зон нагрева, мультислайдерное управление.</p>
        <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />
      </div>
    </>
  )
}

export const CoveCasarteContent = (props) => {
  const { assetsPathName } = props;

  return (
    <>
      <div className="product-page__section design-id">
        <div className="wrap">
          <SectionHeader
            title={<>Дизайн</>}
            text={<>Casarte помогает поддерживать особую атмосферу в доме, когда создание любимого блюда – искусство</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large m-small"
              picName="80"
              title={<>Стеклокерамика <span className="m-br"/>премиум-класса</>}
              text={<>Износостойкая стеклокерамическая поверхность.</>}
              detailText={<>Для создания варочных поверхностей Casarte мы используем стеклокерамику самого высокого качества, однородной структуры, стойкую к&nbsp;износу и&nbsp;невероятно легкую в&nbsp;уходе.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="81"
              title={<>Все грани<br/>вкуса</>}
              detailTitle={<>Все грани вкуса</>}
              text={<>5 зон нагрева, увеличенная ширина <span className="d-br"/>поверхности до 77 см.</>}
              detailText={<>Casarte помогает поддерживать особую атмосферу в&nbsp;доме, когда создание любимого блюда – искусство. Пять зон нагрева с&nbsp;возможностью расширения боковых зон, позволяют одновременно приготовить и&nbsp;основу блюда, и&nbsp;соус, и&nbsp;гарнир к&nbsp;нему, раскрыв все грани вкуса. Время – великая ценность, поэтому мы сделали все возможное, чтобы ускорить те этапы, где важен результат, а не процесс. Каждая зона приготовления имеет 9 уровней мощности и функцию ускоренного нагрева для максимально быстрого закипания воды или разогрева сковороды до нужной температуры. </>}
              video={1}
              videoInteractive={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="82"
              title={<>Эстетика<br/>во всем</>}
              detailTitle={<>Эстетика во всем</>}
              text={<>Лаконичный дизайн панели управления, <span className="d-br"/>белые индикаторы.</>}
              detailText={<>Гармония сдержанности, чистоты линий и&nbsp;удобства управления легли в&nbsp;основу концепции дизайна техники Casarte. Идеально ровную стеклокерамическую поверхность дополняют сенсорные элементы управления, имеющие мягкую подсветку белого цвета, появляющуюся при активном режиме работы. </>}
              video={1}
              videoInteractive={1}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section control-id">
        <div className="wrap">
          <SectionHeader
            title={<>Программы и&nbsp;управление</>}
            text={<>Интуитивно понятная панель управления совмещает в&nbsp;себе чувствительные к&nbsp;нажатию сенсорные кнопки выбора функций и&nbsp;круговые слайдеры регулирования мощности нагрева</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large m-small"
              picName="83"
              title={<>Исключительный <br/>минимализм</>}
              text={<>Индикаторы управления не&nbsp;видны, когда варочная поверхность выключена.</>}
              detailText={<>Придерживаясь принципов минимализма в дизайне нашей варочной поверхности, мы сделали невидимыми все индикаторы управления, если варочная поверхность не используется. </>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="84"
              title={<>Круговые <br/>слайдеры</>}
              detailTitle={<>Круговые слайдеры</>}
              text={<>Удобное управление зонами нагрева.</>}
              detailText={<>Круговые слайдеры обеспечивают удобное и быстрое управление каждой зоной в отдельности. Уровень мощности зоны нагрева можно задавать скольжением по слайдеру подушечкой пальца или нажатием на необходимый индикатор уровня нагрева.</>}
              video={1}
              videoInteractive={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="85"
              title={<>Управление <br/>таймером</>}
              detailTitle={<>Управление таймером</>}
              text={<>Звуковое оповещение или&nbsp;отключение зон нагрева.</>}
              detailText={<>Мультитаймер – удобный помощник в процессе приготовления, а главное, очень пунктуальный. Он вовремя активирует оповещение, например, если вам необходимо добавить ингредиент в блюдо или завершит процесс приготовления в заданное вами время, отключив нагрев зоны.
              <br/>Функцию таймера можно использовать двумя способами:
              <br/>- для обратного отчета времени без отключения зон приготовления. В&nbsp;этом случае по истечении времени варочная поверхность будет издавать звуковой сигнал в&nbsp;течение 30&nbsp;секунд без&nbsp;отключения зон нагрева.
              <br/>- для настройки автоматического отключения одной или нескольких зон приготовления после истечения заданного времени.</>}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section reliability-id">
        <div className="wrap">
          <SectionHeader
            title={<>Функциональность</>}
            text={<>Широкие функциональные возможности с&nbsp;особым отношением к&nbsp;комфорту и&nbsp;безопасности</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="86"
              title={<>Больше возможностей</>}
              text={<>Две зоны расширения для самых смелых кулинарных идей.</>}
              detailText={<>Готовить в удовольствие для себя или большой семьи с Casarte – это удобно. Наличие двух зон расширения позволяет расположить посуду увеличенного объема, такую как гусятницы, жаровни или сковороды-гриль, подходящие, например, для приготовления тушеных блюд, овощей-гриль и стейков.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="87"
              title={<>BOOST –<br/>ускоренный<br/>нагрев</>}
              detailTitle={<>BOOST – ускоренный нагрев</>}
              text={<>Мгновенное закипание и разогрев.</>}
              detailText={<>Мы в Casarte знаем, как вы цените ваше время, поэтому наша варочная поверхность оснащена функцией BOOST, позволяющей максимально быстро довести до кипения воду или растопить масло на сковороде. Через пять минут после включения функции зона нагрева переключится на установленную ранее мощность. Также функцию можно отключить вручную в любое время.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="top-left"
              titleClass="large"
              picName="88"
              title={<>Взять паузу</>}
              text={<>С сохранением текущих настроек зон.</>}
              detailText={<>Вы можете воспользоваться функцией «Пауза» при возникновении срочной необходимости временного прерывания процесса приготовления, сохранив при этом заданные настройки активных зон нагрева.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-center-2"
              titleClass="large"
              picName="89"
              title={<>Всегда готово к&nbsp;подаче на&nbsp;стол</>}
              text={<>Поддержание блюда в&nbsp;теплом состоянии.</>}
              detailText={<>Бывают моменты, когда собрать всю семью за стол – задача не из легких. Casarte cохранит блюдо теплым до его подачи. При активации функции сохранения тепла зона индукционной варочной поверхности будет работать на минимальной мощности, не давая блюду остыть до его подачи на стол.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="90"
              title={<>Функция <span className="m-br"/>«Шеф-повар»</>}
              text={<>Сохранение любимого режима приготовления.</>}
              detailText={<>Функция «Шеф-повар» (Chef) позволяет сохранить данные об уровне мощности и времени приготовления для повседневного использования. Сохранение настроек избранного режима может облегчить процесс и сократить время на приготовление любимого блюда. Buon appetito!</>}
            />
          </div>
        </div>
      </div>
      <TrainerSection />
    </>
  )
}