import './style.scss';
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {NavLink, useHistory, useLocation, useParams, withRouter} from 'react-router-dom';
import {inject} from "mobx-react";
import pageData from "../../json/textPage.json";
import menuData from "../../json/catalogItems.json";
import axios from "axios";
import {getApiPath} from "../utils";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import PageContainer from "../PageContainer";
import {renderTables} from "../../common/utils";

const Page = ({match, store}) => {
  const history = useHistory()
  const location = useLocation()
  const [data, setData] = useState(null);
  const [links, setLinks] = useState(null)
  const [responseError, setResponseError] = useState();
  /**
   * Временное решение для скрытия касарте премиум
   */
  useLayoutEffect(() => {
    if (location.pathname === '/premium') {
      history.push('/content/programma-loyalnosti-casarte-premium')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: `/api/casarte${location.pathname}`,
      method: 'get',
    }).then((response) => {
      if (Array.isArray(response.data.pageContent) && response.data.pageContent?.length === 0) {
        history.push('/404')
      }
      if (response.data.seoData?.pageTitle) {
        document.title = response.data.seoData.pageTitle
      }
      setData(response.data.pageContent);
      setLinks(response.data.otherLinks);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
      renderTables();
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(pageData);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
      renderTables();
    });
    return () => {
      setData(null);
      setResponseError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const {__html} = data || {};

  return (
    <PageContainer responseError={responseError} data={data}>
      <div className="page">
        <div className="page-wrap page-wrap--article">
          <NavLink to="/" className="wysiwyg__link-back">Вернуться к покупкам</NavLink>
          <div className="wysiwyg">
            {__html ? <div dangerouslySetInnerHTML={{__html}}/> : ''}
            <div className={'otherLinks'}>
              {links && links.length > 0 && links.map(link => <a href={link.description}>{link.name}</a>)}
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default inject('store')(withRouter(Page));
