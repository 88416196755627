import './style.scss';
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from '../../common/const';
import DeliveryModal from '../DeliveryModal';
import {Dropdown} from 'primereact/dropdown';
import {Button} from 'primereact/button';
import {Accordion, AccordionTab} from 'primereact/accordion';
import axios from "axios";
import {debounce, getApiPath, markResults} from "../utils";
import pageData from "../../json/deliveryPage.json";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import {inject, observer} from "mobx-react";
import Recaptcha from "../utils/recaptcha";
import PageContainer from "../PageContainer";
import {useContentRender} from "../../hooks/useContentRender";

const path = process.env.REACT_APP_ASSETS_PATH;

const cityDropdownOptionTemplate = (option) => {
  return (
    <div className="dropdown__select-city__list__item">
      <span className="dropdown__select-city__list__item__title" dangerouslySetInnerHTML={{__html: option.markedValue || option.value}} />
      <span className="dropdown__select-city__list__item__text">{option.additionalInfo}</span>
    </div>
  );
}

const Page = ({ store, match }) => {
  const recaptcha = new Recaptcha();
  const {renderComponentsByText} = useContentRender();
  const [data, setData] = useState(null);
  const [popularCities, setPopularCities] = useState([]);
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [deliveryData, setDeliveryData] = useState(null);
  const [isCityLoading, setIsCityLoading] = useState(false);
  const [isCheckCityLoading, setIsCheckCityLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [checkResults, setCheckResults] = useState(null);
  const [responseError, setResponseError] = useState();

  const handleSearchInput = (value) => {
    const inputValue = value.trim();

    if (inputValue.length) {
      debounceGetCities(inputValue);
    } else {
      debounceGetPopularCities();
    }
  };

  const getPopularCities = () => {
    const suggestions = popularCities;

    if (suggestions.length) {
      const selected = {
        value: deliveryData.city,
        id: deliveryData.cityId,
      };
      const existing = suggestions.find((item) => item.id === selected.id);

      if (existing) {
        setSuggestedCities(suggestions);
      } else {
        setSuggestedCities([
          ...suggestions,
          selected,
        ]);
      }
    }
  };

  const getCities = (term) => {
    setIsCityLoading(true);

    store.bridge.termsdeliveryCities({
      term,
    }).then((response) => {
      const { suggestions = [], errorMessage } = response?.data?.data || {};

      if (suggestions.length) {
        const selected = {
          value: deliveryData?.city,
          id: deliveryData?.cityId,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(markResults(suggestions, term));
        } else {
          setSuggestedCities([
            ...markResults(suggestions, term),
            selected,
          ]);
        }
      }
      if (errorMessage) {
        setErrorMessage(errorMessage);
      }
      setIsCityLoading(false);
    }).catch(() => {
      setIsCityLoading(false);
    });
  };

  const sendSearchDelivery = async (data) => {
    if (isCheckCityLoading) {
      return;
    }
    setCheckResults(null);
    setErrorMessage(null);
    setIsCheckCityLoading(true);

    const token = await recaptcha.execute();

    store.bridge.termsdeliverySearchDelivery({
      ...(data || {}),
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'DELIVERY_SEARCH_SUCCESS':
          if (data.result) {
            setCheckResults(data.result);
          }
          break;
        case 'DELIVERY_SEARCH_FAIL':
          if (data.errorMessage) {
            setErrorMessage(data.errorMessage);
          }
          break;
        default:
          setErrorMessage(`Error ${status}: Unknown status`);
          break;
      }
      setIsCheckCityLoading(false);
    }).catch(() => {
      setIsCheckCityLoading(false);
    });
  };

  const handleCityChange = (id) => {
    const value = suggestedCities.find((item) => item.id === id);
    const data = {
      city: value.value,
      cityId: value.id,
    };

    setDeliveryData(data);
    sendSearchDelivery(data);
  };

  useEffect(() => {
    document.title = 'Оплата и доставка';
  }, [])

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      setData(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(pageData);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);
  // Устанавливает саджесты
  useEffect(() => {
    if (data?.popularCities?.length && !popularCities.length) {
      const suggestions = data?.popularCities;

      setPopularCities(suggestions);
      setSuggestedCities(suggestions);
    }
  }, [data, popularCities]);

  const debounceGetPopularCities = debounce(getPopularCities);
  const debounceGetCities = debounce(getCities);
  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="delivery-page page">
      <div className="delivery-page__bg-top">
        <div className="delivery-page__bg-bottom">
          <div className="wrap">
            {data?.pageTitle ? <h3 className="delivery-page__title" dangerouslySetInnerHTML={{__html: data?.pageTitle}} /> : null}
            {data?.pageText ? <p className="delivery-page__text" dangerouslySetInnerHTML={{__html: data?.pageText}} /> : null}
            {/* <div className="delivery-page__city">
              <div className="delivery-page__city__select-wrap">
                <span className="p-float-label">
                  <Dropdown
                    className=""
                    panelClassName={`dropdown__select-city__panel ${isCityLoading ? '_loader' : ''}`}
                    itemTemplate={cityDropdownOptionTemplate}
                    inputId="cityDropdown"
                    value={deliveryData?.cityId}
                    options={suggestedCities}
                    onChange={(e) => handleCityChange(e.value)}
                    onFilter={(e) => handleSearchInput(e.filter)}
                    filter
                    optionLabel="value"
                    optionValue="id"
                    appendTo="self"
                  />
                  <label htmlFor="cityDropdown">Город</label>
                </span>
              </div>
              <div className="delivery-page__city__btn-wrap">
                <Button
                  className={`p-button-primary p-button-white ${isCheckCityLoading ? 'p-loader' : ''}`}
                  label="проверить"
                  onClick={() => sendSearchDelivery(deliveryData)}
                />
              </div>
              {checkResults ? <div className={`delivery-page__city__alert ${checkResults?.success ? '_message' : '_error'}`}>
                {checkResults?.title ? <p className="delivery-page__city__alert__title" dangerouslySetInnerHTML={{__html: checkResults?.title}} /> : null}
                {checkResults?.text ? <p className="delivery-page__city__alert__text" dangerouslySetInnerHTML={{__html: checkResults?.text}} /> : null}
              </div> : null}
              {errorMessage ? <div className="delivery-page__city__alert _error">
                <p className="delivery-page__city__alert__title" dangerouslySetInnerHTML={{__html: errorMessage}} />
              </div> : null}
            </div> */}
            <div className="delivery-page__planes">
              <p className="delivery-page__sub-title">Способы оплаты</p>
              <div className="delivery-page__planes__wrap">
                <div className="delivery-page__planes__cols">
                  <div className="delivery-page__planes__col">
                    <MediaQuery maxWidth={MOBILE_WIDTH}>
                      <div className="delivery-page__planes__pic" style={{backgroundImage: `url(${path}images/delivery/1-m.jpg)`}} />
                    </MediaQuery>
                    <MediaQuery minWidth={MOBILE_WIDTH_1}>
                      <div className="delivery-page__planes__pic" style={{backgroundImage: `url(${path}images/delivery/1-d.jpg)`}} />
                    </MediaQuery>
                  </div>
                  <div className="delivery-page__planes__col">
                    <Plane
                      id={1}
                      title={<>Оплата онлайн</>}
                      text={<>Оплачивайте через систему быстрых платежей в своем мобильном банке или картой онлайн.</>}
                    />
                    <Plane
                      id={2}
                      title={<>Рассрочка без переплат</>}
                      text={<>Пользуйтесь сейчас, платите потом без комиссий и&nbsp;первоначального взноса.</>}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="delivery-page__planes">
              <p className="delivery-page__sub-title">Доставка</p>
              <div className="delivery-page__planes__wrap">
                {/* <div className="delivery-page__planes__row">
                  <Plane
                    id={5}
                    title={<>Доставка по Москве<br/>и Московской области</>}
                    text={<>Бережная доставка от нашего склада до ваших дверей.</>}
                  />
                  <Plane
                    id={6}
                    title={<>Желаемые дата и время доставки</>}
                    text={<>Согласуйте удобное для вас время получения товара и занимайтесь любимыми делами.</>}
                  />
                </div> */}
                <div className="delivery-page__planes__row">
                  <Plane
                    id={5}
                    title={<>Доставка по Москве<br/>и Московской области</>}
                    text={<>Бережная доставка от нашего склада до ваших дверей.</>}
                    bg={true}
                  />
                </div>
                <div className="delivery-page__planes__row">
                  <MediaQuery maxWidth={MOBILE_WIDTH}>
                    <div className="delivery-page__planes__bg-pic" style={{backgroundImage: `url(${path}images/delivery/pl-10-d.jpg)`}}/>
                  </MediaQuery>
                  <MediaQuery minWidth={MOBILE_WIDTH_1}>
                    <div className="delivery-page__planes__bg-pic" style={{backgroundImage: `url(${path}images/delivery/pl-10-d.jpg)`}}/>
                  </MediaQuery>
                </div>
                <div className="delivery-page__planes__row">
                  <Plane
                    id={6}
                    title={<>Желаемые дата и время доставки</>}
                    text={<>Согласуйте удобное для вас время получения товара и занимайтесь любимыми делами.</>}
                    bgAlignLeft={true}
                    bg={true}
                  />
                </div>
              </div>
            </div>
            <p className="delivery-page__sub-title">Частые вопросы</p>
            {data?.faq?.length ? data?.faq?.map((section) => <div key={section.title}>
              {section.items?.length ? <div className="delivery-page__return">
                {section.title ? <p className="delivery-page__sub-line" dangerouslySetInnerHTML={{__html: section.title}} /> : null}
                <Accordion className="delivery-modal__accordion">
                  {section.items?.map((item) => {
                      const parsingElements = renderComponentsByText(item.text)
                      return (
                        <AccordionTab key={item.title} header={item.title}>
                          <>{parsingElements.map(el => <>{el}</>)}
                          </>
                        </AccordionTab>
                      )
                    }
                  )}
                </Accordion>
              </div> : null}
            </div>) : null}

            <div className="delivery-page__contact">
              {/* <p className="delivery-page__sub-title _center">Задать вопрос</p>
              <p className="delivery-page__contact__text">
                Не нашли что искали? Напишите нам, и наш менеджер свяжется с вами удобным для вас способом.
              </p> */}
              <div className="delivery-page__contact__btn-wrap">
                <Button className="p-button-primary p-button-white" label="Задать вопрос" onClick={() => {window.store.setModal('contact', true)}} />
              </div>
            </div>

            <div className="delivery-page__desclaimer">
              * При наличии технической возможности и&nbsp;/&nbsp;или транспортной доступности населенного пункта. Услуга бесплатной доставки предоставляется в&nbsp;случае приобретения техники Casarte в&nbsp;<Link to="/">онлайн-магазине</Link> или&nbsp;в&nbsp;фирменном магазине Casarte.
            </div>
          </div>
          <DeliveryModal />
        </div>
      </div>
    </div>
    </PageContainer>
  );
}

const Plane = ( props ) => {

  return (
    <div className={`delivery-page__plane ${props.bgAlignLeft?'_align-left':''} ${props.bg?'_wide':''} ${props.id === 5 ? '_no-hover' : ''}`} onClick={() => {
      if (props.id !== 5) {
        window.store.setModal('delivery', {id: props.id})}
      }
    }>
      {props.bg && <img className="delivery-page__plane__bg" src={`${path}images/delivery/pl-${props.id}.jpg`} alt="" />}
      <div className="delivery-page__plane__wrap">
        <img className="delivery-page__plane__pic" src={`${path}images/delivery/pl-${props.id}.svg`} alt="" />
        <p className="delivery-page__plane__title">{props.title}</p>
        <p className="delivery-page__plane__text">{props.text}</p>
        {props.id !== 5 && <span className="delivery-page__plane__link">Подробнее</span>}
      </div>
    </div>
  );
}

export default withRouter(inject('store')(observer(Page)));
