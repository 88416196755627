import './style.scss';
import React from 'react';
import {inject, observer} from "mobx-react";
import { disableBodyScroll, enableBodyScroll } from '../../common/utils';
import { Button } from 'primereact/button';
import {Form, Formik} from "formik";
import {Checkbox} from "primereact/checkbox";

const Modal = (props) => {
    const name = 'confirm';
    const data = props.store.modal[name];

    const handleClose = (e) => {
        if (e.target.className.indexOf('confirm-modal__outer-wrap') >= 0) {
            props.store.setModal(name, null);
        }
    }

    data?.data ? disableBodyScroll() : enableBodyScroll();

    return (
        data ?
            <div className={'confirm-modal _' + data.status + ' ' + data?.data?.className} onClick={(e) => handleClose(e)}>
                <div className="confirm-modal__outer-wrap">
                    <div className="confirm-modal__inner-wrap">
                        <div className="confirm-modal__body">
                            <div className="confirm-modal__close" onClick={() => props.store.setModal(name, null) } />
                            <span className="confirm-modal__title">Получайте эксклюзивные <br/>предложения и скидки</span>
                            <div className="confirm-modal__pic" />
                            <Formik
                                initialValues={{
                                email: '',
                                password: '',
                                }}
                                onSubmit={(values) => console.log(values)}
                            >
                            {({ errors: formErrors, values, setFieldValue, touched, setTouched }) => (
                                <Form>
                                    <div className="p-fields-grid">
                                        <div className="p-fields-grid__item d-100 m-100">
                                            <div className="p-checkbox-field">
                                                <Checkbox
                                                inputId="regPromoSub"
                                                name="regPromoSub"
                                                className=""
                                                onChange={e => setFieldValue('promoSub', e.checked)}
                                                checked={values.promoSub}
                                                />
                                                <label htmlFor="regPromoSub">Я согласен с&nbsp;получением информации о&nbsp;персональных скидках и&nbsp;акциях</label>
                                            </div>
                                        </div>
                                        <div className="p-fields-grid__item d-50 m-50">
                                            <Button className="p-button-secondary p-button-transparent" label="Нет, спасибо" onClick={() => props.store.setModal(name, null)} />
                                        </div>
                                        <div className="p-fields-grid__item d-50 m-50">
                                            <Button className={`p-button-secondary p-button-white ${0 ? 'p-loader' : ''}`} label="Согласен" onClick={() => {
                                                //записать куку
                                                props.store.setModal(name, null);
                                            }} />
                                        </div>
                                    </div>
                                </Form>
                            )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
            : null
    )
}

export default inject('store')(observer(Modal));
