import React, { useEffect, useState, useRef } from 'react';
import {inject, observer} from "mobx-react";
import { isDesktop } from 'react-device-detect';
import { Button } from 'primereact/button';
import MediaQuery from 'react-responsive';
import {
  MOBILE_WIDTH,
  MOBILE_WIDTH_1
} from '../../common/const';

const path = process.env.REACT_APP_ASSETS_PATH;
const kShowHeight = 0.0;

const Plane = ( {store, assetsPathName, itemClass, wrapClass, titleClass, picName, title, text, detailText, detailTitle, layer=1, video=0, videoInteractive=false, videoBtn=false, picFormat="jpg", videoFormat="mp4", btn=true } ) => {
  const isOpened = store.plane === picName;
  const [animationStatus, setAnimationStatus] = useState('hidden');
  //const [translateY, setTranslateY] = useState(20);
  //const translateY = 20;
  const [lineX, setLineX] = useState(50);
  const ref = useRef(null);
  const videoRef = useRef(null);

  useEffect(() => {
    return () => store.setPlane(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const onScroll = e => {
      const wh = window.innerHeight;
      const ot = ref.current.getBoundingClientRect().top;
      const y = ot - wh;

      if(y < -wh*kShowHeight && animationStatus === 'hidden'){
        window.removeEventListener("scroll", onScroll);
        setAnimationStatus('show');

        setTimeout(() => {
          setAnimationStatus('shown');
        }, 3000);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  /*useEffect(() => {
    const onScroll = e => {
      const wh = window.innerHeight;
      const ot = ref.current.getBoundingClientRect().top;
      const ob = ref.current.getBoundingClientRect().bottom;
      const y = ot - wh;
      const h = ob - ot;
      const k = y / (-wh-h);
      const dy = 180;
      const ty = dy * 0.5 - dy * k;

      //if(picName==="1"){
      if(ot < wh && ob > 0){
        setTranslateY(ty);
      }
      //}
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);*/

  useEffect(() => {
    if(! videoInteractive){
      return;
    }

    if(!ref.current || !videoRef.current){
      return;
    }

    let isPlay = false;

    const onScroll = e => {
      const wh = window.innerHeight;
      const wh05 = wh * 0.5;
      const ot = ref.current.getBoundingClientRect().top;
      const ob = ref.current.getBoundingClientRect().bottom;
      const isOnScreen = (ot < wh && ob > 0);
      const isOnCenterScreen = (ot < wh05 && ob > wh05);

      if(isOnCenterScreen){
        if(!isPlay){
          isPlay = true;
          videoRef.current.currentTime = 0;
          videoRef.current.play();
        }
      }
      else if(!isOnScreen){
        if(isPlay){
          isPlay = false;
          videoRef.current.currentTime = 0;
          videoRef.current.pause();
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(picName === "8" && isDesktop){
      let w=50, _w = 50, ease = 14;

      const onMouseMove = e => {
        if(!ref || !ref.current) return;

        const ot = ref.current.getBoundingClientRect().top;
        const ob = ref.current.getBoundingClientRect().bottom;
        const ol = ref.current.getBoundingClientRect().left;
        const or = ref.current.getBoundingClientRect().right;
        const mx = e.clientX;
        const my = e.clientY;

        if(mx > ol && mx < or && my > ot && my < ob ){
          const _mx = Math.min(or, Math.max(ol, mx));
          const k = (_mx - ol) / (or - ol);

          w = Math.min(77, Math.max(23, 100 * k));
          ease = 7;
        }
        else{
          w = 50;
          ease = 14;
        }
      };
      window.addEventListener("mousemove", onMouseMove);

      const timer = setInterval(() => {
        _w += (w - _w) / ease;
        setLineX(_w);
      }, 1000/60);


      return () => {
        window.removeEventListener("mousemove", onMouseMove);
        clearInterval(timer);
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={ref} className={`product-page__grid-item ${itemClass} ${isOpened?'opened':'closed'} ${animationStatus}`}>
      <div className="product-page__grid-item-table">
        <div className="product-page__grid-item-cell preview">
          <div className="product-page__grid-item-bg-wrap">
            {picName === "8" ?
            <>
              <div className="product-page__grid-item-bg d-view bg-left" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}-2.${picFormat})`}} />
              <div className="product-page__grid-item-bg d-view bg-left" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}-1.${picFormat})`, width: `${lineX}%`}} />
              <div className="product-page__grid-item-bg-wrap" style={{transform: `translateX(${lineX-50}%)`}}>
                <div className="product-page__grid-item-bg d-view bg-left" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}-3.png)`}} />
              </div>
              <div className="product-page__grid-item-bg m-view" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}-m.${picFormat})`}} />
            </>
            : video ?
            <>
              <MediaQuery maxWidth={MOBILE_WIDTH}>
                {
                  videoFormat==='webm'?
                  <video ref={videoRef} className="product-page__grid-item-video" poster={`${path}images/products/${assetsPathName}/features/${picName}-m.${picFormat}`} loop={!videoInteractive} autoPlay={!videoInteractive} muted playsInline>
                    <source src={`${path}images/products/${assetsPathName}/features/${picName}-m.webm`} type="video/webm" />
                    <source src={`${path}images/products/${assetsPathName}/features/${picName}-m.mp4`} type="video/mp4" />
                  </video>
                  :
                  <video ref={videoRef} className="product-page__grid-item-video" src={`${path}images/products/${assetsPathName}/features/${picName}-m.${videoFormat}`} poster={`${path}images/products/${assetsPathName}/features/${picName}-m.${picFormat}`} loop={!videoInteractive} autoPlay={!videoInteractive} muted playsInline />
                }
              </MediaQuery>
              <MediaQuery minWidth={MOBILE_WIDTH_1}>
                {
                  videoFormat==='webm'?
                  <video ref={videoRef} className="product-page__grid-item-video" poster={`${path}images/products/${assetsPathName}/features/${picName}.${picFormat}`} loop={!videoInteractive} autoPlay={!videoInteractive} muted playsInline>
                    <source src={`${path}images/products/${assetsPathName}/features/${picName}.webm`} type="video/webm" />
                    <source src={`${path}images/products/${assetsPathName}/features/${picName}.mp4`} type="video/mp4" />
                  </video>
                  :
                  <video ref={videoRef} className="product-page__grid-item-video" src={`${path}images/products/${assetsPathName}/features/${picName}.${videoFormat}`} poster={`${path}images/products/${assetsPathName}/features/${picName}.${picFormat}`} loop={!videoInteractive} autoPlay={!videoInteractive} muted playsInline />
                }
              </MediaQuery>
            </>
            :
            <>
              <div className="product-page__grid-item-bg d-view" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}.${picFormat})`}} />
              <div className="product-page__grid-item-bg m-view" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}-m.${picFormat})`}} />
              { layer ?
              <>
                <div className="product-page__grid-item-bg product-page__grid-item-bg2 d-view" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/_${picName}.${picFormat})`}} />
                <div className="product-page__grid-item-bg product-page__grid-item-bg2 m-view" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/_${picName}-m.${picFormat})`}} />
              </>
              : null}
              {picName === "4" ?
              <>
                <div className="product-page__grid-item-bg product-page__grid-item-bg3 d-view" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/__${picName}.jpg)`}} />
                <div className="product-page__grid-item-bg product-page__grid-item-bg3 m-view" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/__${picName}-m.jpg)`}} />
              </>
              : null}
            </>
            }
          </div>
          <div className={`product-page__grid-item-wrap ${wrapClass}`}>
            <div className='product-page__grid-item-content'>
              <p className={`product-page__grid-item-title ${titleClass}`}>{title}</p>
              {text ?
              <p className="product-page__grid-item-text">{text}</p>
              : null}
              {picName === "10" && videoBtn ?
              <Button className="p-button-primary p-button-white min-w product-page__grid-item-simple-btn" label="как подключить" onClick={() => window.store.setModal('video', {url: `${path}images/products/eletto/features/10.mp4`})}/>
              : null}
            </div>
          </div>
        </div>
        <div className="product-page__grid-item-cell detail">
          <MediaQuery minWidth={MOBILE_WIDTH_1}>
            {itemClass.indexOf('large') >= 0 ?
            <>
              {video && !videoInteractive ?
                <video className="product-page__grid-item-video _detail" src={`${path}images/products/${assetsPathName}/features/${picName}.${videoFormat}`} poster={`${path}images/products/${assetsPathName}/features/${picName}.${picFormat}`} muted loop autoPlay playsInline />
              :
                <div className="product-page__grid-item-detail-pic" style={{backgroundImage: `url(${path}images/products/${assetsPathName}/features/${picName}-d.${picFormat})`}} />
              }
            </>
            : null}
          </MediaQuery>
          <div className="product-page__grid-item-detail-wrap">
            <p className="product-page__grid-item-detail-ttile">{detailTitle || title}</p>
            <div className="product-page__grid-item-detail-text">{detailText}</div>
          </div>
        </div>
      </div>
      {picName === "10" || !btn ? null :
      <div className="product-page__grid-item-btn" onClick={() => store.setPlane(isOpened ? null : picName)} />
      }
    </div>
  )
}

export default inject('store')(observer(Plane));
