import './style.scss';
import React, { useEffect } from 'react';
import {Link, withRouter} from 'react-router-dom';

import {inject} from "mobx-react";


const Page = ( { store } ) => {
  useEffect(() => {
    document.title = 'Авторизация';
    document
      .querySelector('.page')
      .querySelectorAll('a')
      .forEach((a) => {
        a.addEventListener('click', (e) => e.preventDefault());
      });
  }, [])

  return (
    <div className="page">
      <div className="page-wrap">
        <h1 className="demo-header">Регистрация</h1>
        <div className="demo-links">
          <span onClick={() => store.setModal('register', {})}>Начальное состояние</span>
          <span onClick={() => store.setModal('register', {
            isError: true
          })}>Ошибка</span>
          <span onClick={() => store.setModal('register', {
            isConfirm: true,
            isCodeResend: true
          })}>Выслан код</span>
          <span onClick={() => store.setModal('register', {
            isConfirm: true,
          })}>Отправить код повторно</span>
          <span onClick={() => store.setModal('register', {
            isConfirm: true,
            isConfirmError: true,
            isCodeResend: true,
            isError: true
          })}>Код введен неверно</span>
          <span onClick={() => store.setModal('register', {
            isSuccess: true
          })}>E-mail успешно подтвержден</span>
          {/*<span onClick={() => store.setModal('register', {*/}
          {/*  isConfirm: true*/}
          {/*})}>На e-mail выслан код</span>*/}
        </div>

        <h1 className="demo-header">Авторизация - телефон</h1>
        <div className="demo-links">
          <span onClick={() => store.setModal('auth', {
            type: 'phone'
          })}>Начальное состояние</span>
          <span onClick={() => store.setModal('auth', {
            type: 'phone',
            isError: true
          })}>Пользователь не найден</span>
          <span onClick={() => store.setModal('auth', {
            type: 'phone',
            isConfirm: true,
            isCodeResend: true,
          })}>Выслан код</span>
          <span onClick={() => store.setModal('auth', {
            type: 'phone',
            isConfirm: true
          })}>Отправить код повторно</span>
        </div>

        <h1 className="demo-header">Авторизация - почта</h1>
        <div className="demo-links">
          <span onClick={() => store.setModal('auth', {
            type: 'mail',
            isEmail: true
          })}>Начальное состояние</span>
          <span onClick={() => store.setModal('auth', {
            type: 'mail',
            isEmail: true,
            isError: true,
          })}>Ошибка</span>
        </div>

        <h1 className="demo-header">Восстановление пароля</h1>
        <div className="demo-links">
          <span onClick={() => store.setModal('restorePassword', {
            type: 'mail'
          })}>Начальное состояние</span>
          <span onClick={() => store.setModal('restorePassword', {
            type: 'mail',
            isError: true
          })}>Ошибка</span>
          <span onClick={() => store.setModal('restorePassword', {
            type: 'phone',
            isConfirm: true,
            isCodeResend: true,
          })}>Выслан код</span>
          <span onClick={() => store.setModal('restorePassword', {
            type: 'phone',
            isConfirm: true
          })}>Отправить код повторно</span>
        </div>

        <h1 className="demo-header">Смена пароля</h1>
        <div className="demo-links">
          <span onClick={() => store.setModal('changePassword', {})}>Начальное состояние</span>
          <span onClick={() => store.setModal('changePassword', {
            isError: true
          })}>Пароли не совпадают</span>
        </div>

        <h1 className="demo-header">Пользователь не авторизован</h1>
        <div className="demo-links">
          <Link to="/personal/orders?auth=false">Мои заказы</Link>
        </div>

        <h1 className="demo-header">Всплывающие модалки</h1>
        <div className="demo-links">
          <span onClick={() => store.setModal('confirm', true)}>Поп-ап согласия на рассылки</span>
        </div>

      </div>
    </div>
  );
}

export default inject('store')(withRouter(Page));
