import cn from 'classnames';
import React, { useRef } from 'react';

const path = process.env.REACT_APP_ASSETS_PATH;

const EvoVideo = ({ picName, videoFormat="mp4", assetsPathName, videoInteractive = false, direction="left", title, text }) => {
  const videoRef = useRef();

  return (
    <div className={cn("product-page-video", direction == "right" && "product-page-video--right")}>
      {
        videoFormat==='webm'?
        <video ref={videoRef} className="product-page-video__container" loop={true} autoPlay={!videoInteractive} muted playsInline>
          <source src={`${path}images/products/${assetsPathName}/features/${picName}.webm`} type="video/webm" />
          <source src={`${path}images/products/${assetsPathName}/features/${picName}.mp4`} type="video/mp4" />
        </video>
        :
        <video ref={videoRef} className="product-page-video__container" src={`${path}images/products/${assetsPathName}/features/${picName}.${videoFormat}`} loop={true} autoPlay={!videoInteractive} muted playsInline />
      }
      <div className="product-page-video__content">
        <h2 className="product-page-video__title">{title}</h2>
        <p className="product-page-video__text">{text}</p>
        <div className="product-page-video__btn" onClick={() => window.store.setModal('video', {url: `${path}images/products/${assetsPathName}/features/${picName + "_full"}.${videoFormat}`})}>
          смотреть видео
        </div>
      </div>
    </div>
  )
}

export default EvoVideo;
