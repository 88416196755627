import axios from 'axios';

/**
 * отправляет запрос на получение виджета
 * добавляет на страницу скрипт виджета и методы для работы с ним
 * @TODO не добавлять скрипты при их наличии
 * @param { Number | String } orderId
 * @param { Function } callback
 */
export default function initCloudpaymentsWidget(orderId, callback = () => {}) {
  const data = new FormData();

  data.append('orderId', orderId);

  // @TODO: вынести метод в мост
  axios({
    method: 'post',
    url: '/ajax/?controller=order&action=getCloudpaymentsWidget',
    data,
  })
    .then((response) => {
      const { src, js } = response.data.data.widget || {};
      const script = document.createElement('script');
      const script2 = document.createElement('script');

      // script.id = 'cloudpayments-widget';
      script.type = 'text/javascript';
      script.src = src;

      // script.id = 'cloudpayments-call';
      script2.type = 'text/javascript';
      script2.innerHTML = js;

      script.addEventListener('load', () => {
        document.head.appendChild(script2);
      });

      document.head.appendChild(script);

      callback();
    });
}
