export default class OverlayQueue {
  constructor() {
    window.overlayQueue = this;

    this.queue = [];
    this.timer = null;
  }

  init() {
    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.sort();
      this.show();
    }, 500);
  }

  add(item) {
    const order = item.order || 1000;

    this.queue.push({
      ...item,
      order,
    });
  }

  sort() {
    if (!this.queue.length) {
      return;
    }
    this.queue = this.queue.sort((a, b) => a.order - b.order);
  }

  show(id) {
    if (!this.queue.length || this.isDisaled) {
      return;
    }
    const queueItem = this.queue.find((item) => item.id === id);
    const queueIndex = this.queue.findIndex((item) => item.id === id);
    const queueNext = this.queue[queueIndex + 1];

    if (queueItem && !queueItem.isCalled) {
      queueItem.callback();
      queueItem.isCalled = true;
    } else if (queueNext) {
      queueNext.callback();
      queueNext.isCalled = true;
    }
  }

  reset() {
    this.queue = [];
  }
}
