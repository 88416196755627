import './style.scss';
import {inject, observer} from "mobx-react";

const PageLoader = () => {
  return (
    <div className={'page-loader'}>
      <div className={'p-loader p-loader-primary'} />
    </div>
  );
}

export default inject('store')(observer(PageLoader));
