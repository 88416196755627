import Modal from '../Modal'
import {inject, observer} from "mobx-react";
import React, { useState } from "react";
import {InputText} from "primereact/inputtext";
import {InputMask} from "primereact/inputmask";
import {Password} from "primereact/password";
import {Checkbox} from "primereact/checkbox";
import { Button } from 'primereact/button';
// import { ReactComponent as MailIcon } from '../../assets/images/main.svg';
import './style.scss';
import classNames from 'classnames';
import {Formik, Form} from "formik";
import {
  CODE_RESEND_COOLDOWN,
  CONFIRM_CODE_LENGTH,
  ERROR_MESAGES, REG_CODE_COOKIE_NAME,
  PHONE_MASK, PHONE_MASK_SLOT,
} from "../../common/const";
import * as Yup from "yup";
import Cookies from 'js-cookie';
import {debounce} from "../utils";
import Recaptcha from "../utils/recaptcha";
import TimeCounter from "../TimeCounter";
import {Link} from "react-router-dom";
import YandexIdButton from '../YandexIdButton';

const path = process.env.REACT_APP_ASSETS_PATH;

const modalName = 'register';

Yup.addMethod(Yup.string, 'phoneNotFull', function (errorMessage) {
  return this.test('test-phone-length', errorMessage, function (value) {
    return (
      value.replace(/\D/g, '').length === PHONE_MASK.replace(/\D/g, '').length ||
      this.createError({ path: this.path, message: errorMessage })
    );
  });
});

const regFormSchema = Yup.object().shape({
  name: Yup.string()
    .required(ERROR_MESAGES.required),
  email: Yup.string()
    .email(ERROR_MESAGES.formatEmail)
    .required(ERROR_MESAGES.required),
  phone: Yup.string()
    .phoneNotFull(ERROR_MESAGES.phoneNotFull)
    .required(ERROR_MESAGES.required),
  password: Yup.string()
    .required(ERROR_MESAGES.required),
  serviceTerms: Yup.boolean()
    .oneOf([true], ERROR_MESAGES.required),
  dataTerms: Yup.boolean()
    .oneOf([true], ERROR_MESAGES.required),
});

const codeFormSchema = Yup.object().shape({
  code: Yup.string()
    .length(CONFIRM_CODE_LENGTH, ERROR_MESAGES.codeNotFull)
    .required(ERROR_MESAGES.required),
});

const initialLoading = {
  regForm: false,
};

const initialErrors = {
  regForm: null,
  codeForm: null,
};

const RegisterModal = ({ store }) => {
  const { isConfirm, isSuccess, isCodeResend, isError } = store.modal[modalName]?.data ?? {};
  const recaptcha = new Recaptcha();

  const setCodeResendDateCookie = () => {
    const expires = new Date(
      new Date().getTime() + Number(CODE_RESEND_COOLDOWN) * 1000,
    );
    Cookies.set(REG_CODE_COOKIE_NAME, `${expires}`, { expires });
  };

  const getCodeResendDateCookie = () => {
    return Cookies.get(REG_CODE_COOKIE_NAME);
  }

  const [formValues, setFormValues] = useState(null);
  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);
  const [code, setCode] = useState('');
  const [codeResendDate, setCodeResendDate] = useState(getCodeResendDateCookie());

  const sendRegForm = (values) => {
    // setCodeResendDateCookie();
    // setCodeResendDate(getCodeResendDateCookie());
    // store.setModal('register', { isConfirm: true });
    // return;

    if (values.needCode && getCodeResendDateCookie()) {
      setCodeResendDate(getCodeResendDateCookie());
      store.setModal('register', { isConfirm: true });
      return;
    }
    if (loading.regForm) {
      return;
    }
    setErrors({
      ...errors,
      regForm: null,
      codeForm: null,
    });
    setLoading({ ...loading, regForm: true });

    store.bridge.profileRegisterWithCode({
      ...values,
      passwordConfirm: values.password,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'SEND_CODE_SUCCESS':
          setCodeResendDateCookie();
          setCodeResendDate(getCodeResendDateCookie());
          store.setModal('register', { isConfirm: true });
          break;
        case 'REGISTER_SUCCESS':
          store.setModal('register', { isSuccess: true });
          break;
        case 'SEND_CODE_FAIL':
        case 'REGISTER_FAIL':
          if (data.errorMessage) {
            setErrors({
              ...errors,
              regForm: data.errorMessage,
              codeForm: data.errorMessage,
            });
          }
          // @TODO: data.formErrors
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, regForm: false });
    }).catch(() => {
      setLoading({ ...loading, regForm: false });
    });
  };

  const handleCodeInput = (code) => {
    if (code.length === CONFIRM_CODE_LENGTH) {
      submitCode(code);
    }
  };

  const submitCode = async (code) => {
    try {
      await codeFormSchema.validate({ code }, { abortEarly: false });
      const token = await recaptcha.execute();

      sendRegForm({
        ...formValues,
        needCode: false,
        code,
        token,
      });
    } catch (err) {
      const formErrors = err.inner.reduce((errors, currentError) => {
        return { ...errors, [currentError.path]: currentError.message };
      }, {});

      setErrors({ ...errors, ...formErrors });
    }
  };

  const debounceHandleCodeInput = debounce(handleCodeInput);

  const renderCol2 = ({ values, setFieldValue, formErrors, touched, setTouched }) => {
    if (isSuccess) {
      // @TODO
      return (
        <div>
          <div className="register-form__text-1">Адрес электронной почты подтвержден, теперь вам доступен личный кабинет.</div>
          <div className="fieldset mt-20">
              <div className="fieldset__item">
                  <button aria-label="закрыть" className="p-button p-component p-button-secondary p-button-white" type="button" onClick={() => store.setModal('register', null)}><span
                      className="p-button-label p-c">закрыть</span></button>
              </div>
              <div className="fieldset__item">
                  <Link to={"/personal/profile"} aria-label="личный кабинет" className="p-button p-component p-button-secondary p-button-transparent">
                      <span className="p-button-label p-c">личный кабинет</span></Link>
              </div>
          </div>
        </div>
      )
    }
    if (isConfirm) {
      return (
        <div>
          <div className="-mt-5 mobile:mb-35">
            <a href="#" className="link-back" onClick={(e) => {
              e.preventDefault();
              store.setModal('register');
            }}>Изменить данные</a>
          </div>
          <div className="mt-17 tablet:mt-12 register-form__text-1">Код придет на <strong className='text-blue'>konstantin@mail.ru</strong></div>
          <div className="enter-code mt-20 tablet:mt-15 mobile:mt-20">
            <div>
              <span className="p-float-label">
                <InputText
                  id="regCode"
                  className={formErrors.code || isError ? 'p-invalid' : ''}
                  value={code}
                  readOnly={loading.codeForm}
                  maxLength={CONFIRM_CODE_LENGTH}
                  onInput={(e) => {
                    setCode(e.target.value);
                    debounceHandleCodeInput(e.target.value); // debounce not working
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      submitCode(e.target.value);
                    }
                  }}
                />
                <label htmlFor="regCode">Введите код</label>
              </span>
              {isError && <span className="p-error">Код введен неверно</span>}
              {formErrors.code && <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.code}} />}
              {errors.regForm && <span className="p-error" dangerouslySetInnerHTML={{__html: errors.regForm}} />}
            </div>
            <div className="mobile:hidden">
              <Button
                className={`p-button-secondary p-button-white ${loading.regForm ? 'p-loader' : ''}`}
                label="подтвердить"
                type="button"
                onClick={() => submitCode(code)}
              />
            </div>
          </div>
          <div className="mt-20 tablet:mt-15 mobile:mt-20">
            {codeResendDate || isCodeResend ?
              <div className="font-500">Отправить код повторно можно будет через {isCodeResend ? '00:59' : <TimeCounter
                targetDate={codeResendDate}
                onComplete={() => setCodeResendDate(null)}
              />}</div>
            :
              <div className={`retry ${loading.regForm ? '_loading' : ''}`} onClick={() => sendRegForm({
                ...values,
                needCode: true,
              })}>Отправить код повторно</div>
            }
          </div>
          <div className="from-tablet:hidden mt-35">
            <Button
              className={`p-button-secondary p-button-white ${loading.regForm ? 'p-loader' : ''}`}
              label="подтвердить"
              type="button"
              onClick={() => submitCode(code)}
            />
          </div>
        </div>
      )
    }

    return (
      <>
        <div>
          <div className="p-checkbox-field">
            <Checkbox
              inputId="regUsageTermsLink"
              name="regUsageTermsLink"
              className={formErrors.serviceTerms && touched.serviceTerms ? 'p-invalid' : ''}
              checked={values.serviceTerms}
              onChange={(e) => {
                setFieldValue('serviceTerms', e.checked);
                // setTouched({ ...touched, serviceTerms: false });
              }}
            />
            <label htmlFor="regUsageTermsLink">Я согласен <a href={"/content/usloviya-ispolzovaniya-casarte/"} className={"text-blue"}>с условиями использования</a> сервиса</label>
          </div>
          {formErrors.serviceTerms && touched.serviceTerms && <span className="p-error">{formErrors.serviceTerms}</span>}
          <div className="p-checkbox-field mt-12">
            <Checkbox
              inputId="regDataTerms"
              name="regDataTerms"
              className={formErrors.dataTerms && touched.dataTerms ? 'p-invalid' : ''}
              checked={values.dataTerms}
              onChange={(e) => {
                setFieldValue('dataTerms', e.checked);
                // setTouched({ ...touched, dataTerms: false });
              }}
            />
            <label htmlFor="regDataTerms">Я принимаю <a href={"/content/politika-v-otnoshenii-obrabotki-personalnykh-dannykh-casarte/"} className={"text-blue"}>условия хранения и обработки</a> данных</label>
          </div>
          {formErrors.dataTerms && touched.dataTerms && <span className="p-error">{formErrors.dataTerms}</span>}
          <div className="p-checkbox-field mt-12">
            <Checkbox
              inputId="regPromoSub"
              name="regPromoSub"
              className=""
              onChange={e => setFieldValue('promoSub', e.checked)}
              checked={values.promoSub}
            />
            <label htmlFor="regPromoSub">Я согласен с&nbsp;получением информации о&nbsp;персональных скидках и&nbsp;акциях</label>
          </div>
        </div>
        <div className="mt-30 mobile:mt-35">
          {/*@TODO: ошибки формы в одном месте*/}
          {errors.regForm &&
            <div className="mb-20">
              <span className="p-error mb-10">{errors.regForm}</span>
            </div>
          }
          <div className={'p-fields-grid'}>
            <div className="p-fields-grid__item d-100 m-100">
              <Button
                className={`p-button-secondary p-button-white ${loading.regForm ? 'p-loader' : ''}`}
                label="Зарегистрироваться"
                type="submit"
                onClick={() => {
                  setTouched(Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: true }), {}));
                }}
              />
              <YandexIdButton />
            </div>
          </div>
        </div>
      </>
    )
  }

  return <Modal title={(<>Регистрация<a href="#" onClick={(e) => {
    e.preventDefault();
    store.setModal('auth');
  }}>Вход</a></>)} name={'register'} size={'xm'}>
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        password: '',
        serviceTerms: false,
        dataTerms: false,
        promoSub: false,
      }}
      validationSchema={regFormSchema}
      // validator={() => {}}
      onSubmit={(values) => {
        setFormValues(values);
        sendRegForm({
          ...values,
          needCode: true,
        });
      }}
    >
      {({ values, setFieldValue, errors: formErrors, touched, setTouched }) => (
        <Form className={classNames('register-form', { 'register-form--locked': isConfirm })}>
          {
          !isConfirm && !isSuccess && 
          <div className="register-form__section-1 fieldset">
            <div className="fieldset__item">
              <span className="p-float-label">
                <InputText
                  id="regName"
                  name="regName"
                  className={(formErrors.name && touched.name) || isError ? 'p-invalid' : ''}
                  value={values.name}
                  onChange={(e) => setFieldValue('name', e.target.value)}
                  // onFocus={() => setTouched({ ...touched, name: false })}
                />
                <label htmlFor="regName">Имя</label>
              </span>
              {isError && <span className="p-error">{ERROR_MESAGES.required}</span>}
              {formErrors.name && touched.name && <span className="p-error">{formErrors.name}</span>}
            </div>
            <div className="fieldset__item">
              <span className="p-float-label">
                <InputText
                  id="regEmail"
                  name="regEmail"
                  className={formErrors.email && touched.email ? 'p-invalid' : ''}
                  value={values.email}
                  onChange={(e) => setFieldValue('email', e.target.value)}
                  // onFocus={() => setTouched({ ...touched, email: false })}
                />
                <label htmlFor="regEmail">E-mail</label>
              </span>
              {formErrors.email && touched.email && <span className="p-error">{formErrors.email}</span>}
            </div>
            <div className="fieldset__item">
              <span className="p-float-label">
                <InputMask
                  id="regPhone"
                  name="regPhone"
                  className={formErrors.phone && touched.phone ? 'p-invalid' : ''}
                  type="tel"
                  value={values.phone}
                  onChange={(e) => setFieldValue('phone', e.target.value)}
                  // onFocus={() => setTouched({ ...touched, phone: false })}
                  mask={PHONE_MASK}
                  slotChar={PHONE_MASK_SLOT}
                />
                <label htmlFor="regPhone">Номер телефона</label>
              </span>
              {formErrors.phone && touched.phone && <span className="p-error">{formErrors.phone}</span>}
            </div>
            <div className="fieldset__item">
              <span className="p-float-label">
                <Password
                  inputId="regPassword"
                  name="regPassword"
                  className={formErrors.password && touched.password ? 'p-invalid' : ''}
                  toggleMask
                  value={values.password}
                  onChange={(e) => setFieldValue('password', e.target.value)}
                  // onFocus={() => setTouched({ ...touched, password: false })}
                  feedback={false}
                />
                <label htmlFor="regPassword">Пароль</label>
              </span>
              {formErrors.password && touched.password && <span className="p-error">{formErrors.password}</span>}
            </div>
          </div>
          }
          <div className={`${!isConfirm && !isSuccess ?'register-form__section-2':'register-form__section-3'}`}>
            {renderCol2({ values, setFieldValue, formErrors, touched, setTouched })}
          </div>
          {/*<div className="register-form__footer">*/}
          {/*  <span>Есть аккаунт на этих сайтах? </span>*/}
          {/*  <span className={'logo-line mobile:hidden'}>*/}
          {/*    <a href="https://haieronline.ru/" target="_blank"><img src={`${path}images/haier.svg`} alt="Частями" /></a>*/}
          {/*    <a href="https://haieronline.ru/thunderobot/" target="_blank"><img src={`${path}images/thunderobot.svg`} className="logo-thunderobot" alt="Частями" /></a>*/}
          {/*  </span>*/}
          {/*  <a href="#" className="link-forward mobile:ml-15" onClick={(e) => {*/}
          {/*    e.preventDefault();*/}
          {/*    store.setModal('auth');*/}
          {/*  }}>Войти</a>*/}
          {/*  <div className={'logo-line from-tablet:hidden fullwidth'}>*/}
          {/*    <a href="https://haieronline.ru/" target="_blank"><img src={`${path}images/haier.svg`} alt="Частями" /></a>*/}
          {/*    <a href="https://haieronline.ru/thunderobot/" target="_blank"><img src={`${path}images/thunderobot.svg`} className="logo-thunderobot" alt="Частями" /></a>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Form>
      )}
    </Formik>
  </Modal>
}

export default inject('store')(observer(RegisterModal));
