import React, {useEffect, useRef} from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import EvoVideo from "../EvoVideo";
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const DoubleDrumHead = (props) => {
  const { isBuyActive, assetsPathName } = props;
  const refVideo = useRef();

  useEffect(() => {
    if(refVideo.current) {
      refVideo.current.defaultMuted = true;
      refVideo.current.muted = true;
    }
  }, [refVideo]);

  return (
    <>
      <video ref={refVideo} className="product-page__head-video" src={`${path}images/products/${assetsPathName}/head.mp4`} poster={`${path}images/products/${assetsPathName}/head.jpg`} loop autoPlay muted={true} playsInline />
      <div className="product-page__head__center__wrap">
        <p className="product-page__head__center__label">Стирально-сушильная</p>
        <h1 className="product-page__head__center__title">колонна Casarte</h1>
        <h2 className="product-page__head__center__sub-title">DOUBLE DRUM</h2>
        <p className="product-page__head__center__text">Идеальное сочетание функциональности и элегантности: премиум уход за&nbsp;вашим гардеробом и неповторимый дизайн, подчеркивающий вашу индивидуальность.</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const DoubleDrumContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section config-id">
        <div className="wrap">
          <SectionHeader
            title={<>Уникальная конструкция</>}
            text={<>Casarte Double Drum объединяет в&nbsp;себе все доступные технологии и&nbsp;возможности для&nbsp;эффективной стирки, деликатной сушки и&nbsp;бережного ухода за&nbsp;вашими вещами</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large long no-btn"
              wrapClass="bottom-left"
              titleClass="large"
              picName="122"
              title={<>Единый корпус</>}
              text={<>Нижний барабан – стиральная машина с&nbsp;инверторным мотором <span className="d-br"/>и&nbsp;загрузкой до&nbsp;12&nbsp;кг. Верхний барабан – сушильная машина с&nbsp;тепловым <span className="d-br"/>насосом, инверторными мотором и&nbsp;вентилятором, загрузкой до&nbsp;9&nbsp;кг.</>}
              detailText={<></>}
              layer={0}
              btn={false}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section carefully-id">
        <div className="wrap">
          <SectionHeader
            title={<>Бережный уход</>}
            text={<>Особый уход за деликатными тканями и материалами</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="top-center"
              titleClass="large"
              picName="22"
              title={<>IoT-технологии<br/>для умной стирки</>}
              text={<>Достижение идеального результата.</>}
              detailTitle={<>IoT-технологии <br/>для&nbsp;умной стирки</>}
              detailText={<>Возможности искусственного интеллекта позволили изменить подход к процессу стирки. Машина с помощью мультисенсоров сама определит тип ткани, подберет температурный режим, настроит число оборотов, время стирки, а также другие параметры для эффективного и бережного удаления загрязнений.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="23"
              title={<><img src={`${path}images/products/double-drum/features/1.svg`} alt=""
              className="duo-feature-pic"/>Функция<br/>освежения<br/>i-Refresh</>}
              text={<>Уникальная технология бережного ухода за&nbsp;самыми деликатными тканями и&nbsp;материалами.</>}
              detailTitle={<>Функция освежения <nobr>i-Refresh</nobr></>}
              detailText={<>Облако из молекул воды размером с микрон глубоко проникает в структуру ткани, очищает и смягчает ее, удаляя запахи и уменьшая количество складок. Машина сама определит интенсивность обработки в зависимости от выбранного типа ткани в режиме «Уход» без добавления моющих средств.</>}
              layer={0}
              video={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-xs"
              picName="24"
              title={<>Аромакапсулы</>}
              text={<>Утонченный аромат на&nbsp;основе эфирных масел пропитает волокна ткани и&nbsp;подарит новые ощущения.</>}
              detailTitle={<>Аромакапсулы</>}
              detailText={<>Для придания одежде тонкого аромата необходимо установить капсулу в специальный отсек в верхнем барабане и&nbsp;запустить цикл сушки. Нежный букет из тонких ноток пеларгонии, ванили, лаванды, лимона и лемонграсса мягко проникнет в структуру ткани и придаст одежде деликатный и легкий аромат. Аромакапсула идет в комплекте.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="21"
              title={<>Специальный <span className="m-br"/>уход <span className="d-br"/>с заботой <span className="m-br"/>о ткани</>}
              text={<>Вернет шелку, кашемиру и меху мягкость, упругость и блеск.</>}
              detailText={<>
                Режим «Уход» позаботится о шелке, кашемире, натуральном и&nbsp;искусственном мехе, пуховых изделиях. В случае, когда стирка в воде невозможна или не рекомендована производителем, технология особо бережной обработки позволит очистить вещи от пыли и ворса, расправит и освежит волокна, вернет изделиям прежнюю форму и внешний вид.
                </>}
              layer={0}
            />
          </div>
        </div>
      </div>
      <EvoSection
        sectionTitle={<>Приложение evo</>}
        sectionText={<>Управление и мониторинг процессов через смартфон</>}
        planeTitle={<>Расширяем границы <span className="d-br"/>возможного в&nbsp;evo</>}
        planeText={<>Дистанционное управление и настройка, контроль состояния, <span className="d-br"/>уведомления и&nbsp;оповещения, рекомендации и&nbsp;онлайн-поддержка.<sup>*</sup></>}
        desc={true}
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={false}
        videoBlocks={
          <div className="product-page__grid-item">
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-1"
              title="Инструкция по&nbsp;подключению"
              text="Подключение стирально-сушильной колонны Double&nbsp;Drum с&nbsp;помощью мобильного приложения&nbsp;evo"
            />
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-2"
              direction="right"
              title="Инструкция по&nbsp;управлению"
              text="Управление стирально-сушильной колонной Double&nbsp;Drum с&nbsp;помощью мобильного приложения&nbsp;evo"
            />
          </div>
        }
      />
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>С&nbsp;заботой о&nbsp;здоровье</>}
            text={<>Глубокая обработка тканей на микроуровне</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="29"
              title={<>Гигиеническая обработка <br/>ультрафиолетом и&nbsp;плазмой</>}
              text={<>Устранение до&nbsp;99% вирусов и&nbsp;бактерий.</>}
              detailText={<>Для эффективной гигиенической обработки вещей Casarte Double Drum оснащен технологиями стерилизации ультрафиолетом и плазмой. Ультрафиолетовая обработка вещей производится в процессе стирки в нижнем барабане. Стерилизация вещей плазмой осуществляется в верхнем барабане в режиме «Уход».</>}
              layer={0}
              video={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="middle-left"
              titleClass="large"
              picName="28"
              title={<>Обработка<br/>паром</>}
              text={<>Мягкая сила пара повысит <span className="m-br"/>эффективность <span className="d-br"/>стирки, <span className="m-br"/>защитит от клещей <span className="m-br"/>и микробов, <span className="d-br"/>сократит время <span className="m-br"/>на глажку.</>}
              detailText={<>Обработка паром на начальном этапе цикла стирки применяется для глубокой очистки сильно загрязненных вещей, в конце цикла – для минимизации появления большого количества заломов. Дополнительная обработка вещей паром – эффективный способ борьбы с пылевыми клещами, микробами и аллергенами.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section reliability-id">
        <div className="wrap">
          <SectionHeader
            title={<>Надежность <br/>и функциональность</>}
            text={<>Никаких компромиссов при&nbsp;создании техники — высочайшие стандарты производства и&nbsp;стремление к&nbsp;революционным решениям</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="25"
              title={<>Режим «Стирка + Сушка»</>}
              text={<>Минимум действий, максимум комфорта.</>}
              detailText={<>Достаточно выбрать программу стирки, а программа сушки определится автоматически. В режиме «Стирка + Сушка» верхний барабан начнет прогреваться за 15 минут до окончания стирки. Когда цикл стирки будет закончен, достаточно просто переложить вещи в сушильный барабан и закрыть дверцу.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="top-left"
              titleClass="large-xs"
              picName="26"
              title={<>Сушка без <span className="d-br"/>термических <span className="d-br"/>заломов</>}
              text={<>Экономичная и бережная сушка с тепловым насосом.</>}
              detailTitle={<>Сушка без термических заломов</>}
              detailText={<>Экологичный способ сушки с применением теплового насоса не только позволяет высушивать вещи с существенной экономией электроэнергии, но и делать это на более низких температурах по сравнению со стандартными сушильными конденсационного типа.</>}
              layer={0}
              video={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-xs"
              picName="27"
              title={<>Автодозировка<br/>моющих средств</>}
              text={<>Умные алгоритмы определят, какое количество моющего средства использовать во время стирки для получения идеального результата.</>}
              detailTitle={<>Автодозировка моющих средств</>}
              detailText={<>Система автоматического дозирования рассчитывает оптимальный объем моющего средства и кондиционера для получения превосходного результата. Оптимизированное использование моющих средств является основой бережного отношения к природным ресурсам и состоянию окружающей среды. </>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="121"
              title={<>Равномерное высушивание <span className="d-br"/>и бережный уход</>}
              text={<>Инновационная технология Casarte Twin Turbo.</>}
              detailTitle={<>Равномерное высушивание и бережный уход</>}
              detailText={<>Использование независимых инверторных мотора и&nbsp;вентилятора позволяет обеспечить бережный уход за тканями и равномерный обдув в процессе сушки. Мотор отвечает за реверсивное вращение барабана, вентилятор – за&nbsp;непрерывный обдув для тщательного высушивания одежды, сохраняя объем, структуру тканей и материалов.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
      <TrainerSection />
    </>
  )
}