import './style.scss';
import React, {useEffect, useState, useMemo} from 'react';
import {withRouter, Link} from 'react-router-dom';
import MediaQuery from 'react-responsive';
import {
  CATALOG_NAME_INDEX,
  MOBILE_WIDTH,
  MOBILE_WIDTH_1
} from '../../common/const';
import {inject, observer} from 'mobx-react';
import axios from "axios";
import menuData from "../../json/catalogItems.json";
import {getApiPath} from "../utils";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import PageContainer from "../PageContainer";

// const path = process.env.REACT_APP_ASSETS_PATH;

const Page = ({ store, match }) => {
  const catalogName = match.path.split('/')[2];
  const catalogIndex = CATALOG_NAME_INDEX[catalogName];

  const [data, setData] = useState([]);
  const [responseError, setResponseError] = useState();

  const currentCatalog = useMemo(() => {
    return data[catalogIndex];
  }, [data, catalogIndex]);

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      if(response.data.seoData?.pageTitle){
        document.title = response.data.seoData.pageTitle
      }
      setData(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(menuData);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    return () => {
      setData([]);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  const catalogItems = currentCatalog && currentCatalog.items.map((item, index) => {
    return (
      <Link to={item.url} className="catalog-page__item" key={index}>
        <MediaQuery maxWidth={MOBILE_WIDTH}>
          <img src={item.imageMobile} className="catalog-page__item__pic"
               alt={item.name}/>
        </MediaQuery>
        <MediaQuery minWidth={MOBILE_WIDTH_1}>
          <img src={item.imageDesktop} className="catalog-page__item__pic"
               alt={item.name}/>
        </MediaQuery>
        <div className="catalog-page__item__wrap">
          <p className="catalog-page__item__text">{item.type}</p>
          <p className="catalog-page__item__title">{item.name}</p>
        </div>
      </Link>
    )
  });

  return (
    <PageContainer responseError={responseError} data={data?.length}>
    <div className="catalog-page page">
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="catalog-page__pl">
          <Link to="/" className="catalog-page__pl__back"/>
        </div>
      </MediaQuery>
      <h1 className="catalog-page__title"><Link to="/" className="catalog-page__back"/>{currentCatalog?.title}</h1>
      <div className="catalog-page__items">
        {catalogItems}
      </div>
    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(Page)));
