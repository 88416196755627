import './style.scss';
import React, { useEffect, useState, useMemo } from 'react';
import { withRouter, Link, useLocation } from 'react-router-dom';
import Swiper from "react-id-swiper";
import Features from '../Features';
import LogoMain from '../../assets/images/logo_main.svg'
import LogoMainM from '../../assets/images/logo_main-m.svg'
import { scrollTo } from "../../common/utils"
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from '../../common/const';
import pageData from "../../json/mainPage.json";
import axios from "axios";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import featuresList from "../../json/featuresList.json";
import { inject, observer } from "mobx-react";
import { getApiPath } from "../utils";
import PageContainer from "../PageContainer";
import catalogData from "../../json/catalog.json";

const path = process.env.REACT_APP_ASSETS_PATH;
const swiperParams = {
    autoplay: {
        delay: 3000,
    },
    slidesPerView: 1,
    spaceBetween: 0,
    initialSlide: 0,
    threshold: 3,
    speed: 700,
    loop: true,
    navigation: {
        nextEl: '.main-page__slider__arr._next',
        prevEl: '.main-page__slider__arr._prev',
    },
    pagination: {
        el: '.main-page__slider__pagination',
        clickable: true,
    },
    uniqueNavElements: false,
};

const Page = ({ store, match }) => {
    const [data, setData] = useState(null);
    const [responseError, setResponseError] = useState();

    const catalogList = useMemo(() => {
        if (!data) {
            return [];
        }
        let i = 0;

        return data.catalogList.reduce((acc, item, index) => {
            if (acc[i] && acc[i].length === 2) {
                i = i + 1;
            }
            if (!acc[i]) {
                acc[i] = [];
            }
            acc[i].push(item);

            return acc;
        }, []);
    }, [data]);

    const catalogModClass = useMemo(() => {
        return `_x${catalogList.flat().length}`
    }, [catalogList])

    // Устанавливает данные страницы
    useEffect(() => {
        axios({
            url: getApiPath(match.path),
            method: 'get',
        }).then((response) => {
            if (response.data.seoData?.pageTitle) {
                document.title = response.data.seoData.pageTitle
            }
            setData(response.data.pageContent);
            store.setCatalogItems(response.data.menu);
            store.setAppConst(response.data.appConst);
            store.setHeader(response.data.header);
            store.setFooter(response.data.footer);
            store.setFeedbackForm(window.feedbackForm);
        }).catch((error) => {
            if (!store.isDev && [404, 500].includes(error?.response?.status)) {
                setResponseError(error.response);
                return;
            }
            setData(pageData);
            store.setCatalogItems(menuData);
            store.setHeader(headerData);
            store.setFooter(footerData);
            store.setFeedbackForm(feedbackFormData);
        });
        return () => {
            setData(null);
            setResponseError(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match.path]);

    const location = useLocation();

    // Скролл до элемента по хэшу из адресной строки
    useEffect(() => {
        if (location.hash && data) {
            const target = `${location.hash.replace('#', '')}-id`;
            document.querySelector(`.${target}`) && scrollTo(target)
        }
    }, [location, data]);

    return (
        <PageContainer responseError={responseError} data={data}>
            <div className="page main-page">
                <div className="main-page__slider">
                    <Swiper {...swiperParams}>
                        <div className="main-page__slide">
                            <MediaQuery maxWidth={MOBILE_WIDTH}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/0-m.jpg)` }} />
                                <img src={LogoMainM} className="main-page__slide__logo" alt="Casarte - гармония технологий и стиля" />
                            </MediaQuery>
                            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/0-d.jpg)` }} />
                                <img src={LogoMain} className="main-page__slide__logo" alt="Casarte - гармония технологий и стиля" />
                            </MediaQuery>
                        </div>
                        <div className="main-page__slide">
                            <MediaQuery maxWidth={MOBILE_WIDTH}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/13-m.webp)` }} />
                            </MediaQuery>
                            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/13-d.webp)` }} />
                            </MediaQuery>
                            <div className="main-page__slide__wrap _right">
                                <div className="main-page__slide__content">
                                    <p className="main-page__slide__label">Паровой шкаф Casarte</p>
                                    <p className="main-page__slide__title">Искусство <br />бережного ухода</p>
                                    <p className="main-page__slide__text">для&nbsp;вашей одежды и&nbsp;предметов гардероба</p>
                                    <div className="main-page__slide__btns">
                                        <Link to="/catalog/stylers/styler-casarte" className="p-button p-component p-button-primary p-button-white min-w">Подробнее</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-page__slide">
                            <MediaQuery maxWidth={MOBILE_WIDTH}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/11-m.jpg)` }} />
                            </MediaQuery>
                            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/11-d.jpg)` }} />
                            </MediaQuery>
                            <div className="main-page__slide__wrap _right">
                                <div className="main-page__slide__content">
                                    <p className="main-page__slide__label">Casarte double drum</p>
                                    <p className="main-page__slide__title">Исключительные<br />технологии</p>
                                    <p className="main-page__slide__text">Уникальная система с&nbsp;неповторимым дизайном <span className='br-d' />для&nbsp;самого бережного ухода за&nbsp;тканями и&nbsp;материалами</p>
                                    <div className="main-page__slide__btns">
                                        <Link to="/catalog/washers/double-drum/double-drum" className="p-button p-component p-button-primary p-button-white min-w">Подробнее</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-page__slide">
                            <MediaQuery maxWidth={MOBILE_WIDTH}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/12-m.jpg)` }} />
                            </MediaQuery>
                            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                <div className="main-page__slide__bg" style={{ backgroundImage: `url(${path}images/main/banners/12-d.jpg)` }} />
                            </MediaQuery>
                            <div className="main-page__slide__wrap _right">
                                <div className="main-page__slide__content">
                                    <p className="main-page__slide__label">casarte french Door</p>
                                    <p className="main-page__slide__title">Изысканный<br />стиль</p>
                                    <p className="main-page__slide__text">Больше, чем&nbsp;технологии. Больше, чем&nbsp;просто дизайн</p>
                                    <div className="main-page__slide__btns">
                                        <Link to="/catalog/fridges/french-door/french-door" className="p-button p-component p-button-primary p-button-white min-w">Подробнее</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Swiper>
                    <div className='main-page__slider__pagination' />
                </div>

                <div className={`main-page__catalog _x7 first catalog-id ${catalogModClass}`}>
                    <div className="main-page__catalog__h1">Откройте для&nbsp;себя <span className='br-m' />технику&nbsp;Casarte</div>
                    <div className="wrap">
                        <div className="main-page__catalog__items">
                            {
                                catalogData.map((item, index) =>
                                    <Link key={index} to={item.url} className="main-page__catalog__item">
                                        <div className="main-page__catalog__item__pic">
                                            <MediaQuery maxWidth={MOBILE_WIDTH}>
                                                <img src={item.imageSet.mobile} alt={item.title} title={item.title} />
                                            </MediaQuery>
                                            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                                <img src={item.imageSet.desktop} alt={item.title} title={item.title} />
                                            </MediaQuery>
                                        </div>
                                        <p className="main-page__catalog__item__title">{item.title}</p>
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                </div>

                <div className="main-page__section brand-id">
                    <h3 className="main-page__section-title">О&nbsp;бренде <span className='br-m' />Casarte</h3>
                    <p className="main-page__section-text large">Международный бренд бытовой техники премиум класса, в&nbsp;основе которого лежит гармония технологии и&nbsp;стиля.</p>
                    <div className="wrap">
                        <InfoBlock
                            picName="1"
                            title={<>Искусство дома</>}
                            text={<>
                                <p>Название бренда Casarte, впервые представленного в 2007 году, происходит от итальянских слов La casa и arte, что означает «искусство дома». Вдохновленные эстетикой мирового художественного наследия и желанием производить совершенную технику, создатели Casarte разрабатывают не просто эксклюзивные решения с премиальным дизайном. В основе каждого продукта лежат технологии, опережающие время, удивляющие возможностями и удобством.</p>
                            </>}
                            className="_reverse"
                        />

                        <InfoBlock
                            picName="2"
                            title={<>Дизайн</>}
                            text={<>
                                <p>Техника Casarte оригинальна во всем: в дизайне, в функциональных возможностях и даже в цвете.</p>
                                <p>За основу цветовой палитры всех продуктов бренда были выбраны оттенки лунного света, глубокого и многогранного.</p>
                                <p>Лунный свет, отражающийся на безмятежной глади воды, – всегда уникальный и завораживающий, дарящий ощущение умиротворения и благополучия.</p>
                            </>}
                            className=""
                        />

                        <InfoBlock
                            picName="3"
                            title={<>Технологии</>}
                            text={<>
                                <p>Уверенность в технологиях, их пользе и востребованности, ответственность за каждый этап производства лежат в основе создания продуктов Casarte.</p>
                                <p>За визуальную и техническую части отвечают инженеры собственных центров дизайна, исследований и разработок.</p>
                            </>}
                            className="_reverse"
                        />

                        <InfoBlock
                            picName="4"
                            title={<>Исключительный контроль качества</>}
                            text={<>
                                <p>Процесс производства сопровождается многоступенчатым контролем качества, сочетающим в себе тщательную проверку каждого изделия автоматизированным способом и вручную.</p>
                            </>}
                            className=""
                        />

                        <InfoBlock
                            picName="5"
                            title={<>Премиальное обслуживание</>}
                            text={<>
                                <p>Приобретая технику Casarte, владелец получает не только технически и стилистически совершенный продукт, но и обслуживание премиального уровня: с момента консультации, покупки, доставки до установки и сервисного обслуживания на протяжении всего срока эксплуатации.</p>
                            </>}
                            className="_reverse"
                        />
                    </div>
                </div>
                {/* <div className="main-page__product-block__section">
                    <div className="wrap">
                        <div className="main-page__product-single-block__group">
                            <ProductBlock
                                picName={5}
                                label={<>Стирально-сушильная<br />колонна Casarte</>}
                                title={<>Double Drum</>}
                                text={<>Дань классике, идеальная интеграция линий, позволяющая перенести традиционный дизайн в&nbsp;эстетику дома будущего.</>}
                                link={"/catalog/washers/double-drum/double-drum"}
                                video={true}
                                single={true}
                            />
                        </div>
                    </div>
                </div> */}
                <div className="main-page__product-block__section">
                    <div className="wrap">
                        <div className="main-page__product-single-block__group">
                            <ProductBlock
                                picName={10}
                                label={<>Паровой шкаф <span className='br-m' />для&nbsp;ухода <br />за&nbsp;одеждой <span className='br-m' />Casarte</>}
                                text={<>Идеальный уход за любимыми вещами.</>}
                                link={"/catalog/stylers/styler-casarte"}
                                single={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="main-page__product-block__section _black">
                    <div className="wrap">
                        <div className="main-page__product-block__group">
                            <ProductBlock
                                picName={6}
                                //label={<>CASHY85RWJVB</>}
                                title={<>Индукционная <br />варочная поверхность <br />Casarte</>}
                                text={<>Пять зон нагрева и&nbsp;сенсорное мультислайдерное управление</>}
                                link={"/catalog/glass-ceramic-coves/cove-casarte/cove-casarte"}
                            />
                            <ProductBlock
                                picName={9}
                                // label={<></>}
                                title={<>Встраиваемая <br />посудомоечная <br />машина Casarte</>}
                                text={<>Эргономичный дизайн для&nbsp;максимального удобства</>}
                                link={"/catalog/built-in-dishwashers/dishwasher-casarte"}
                            />
                        </div>
                        <div className="main-page__product-block__group">
                            <ProductBlock
                                picName={4}
                                //label={<>CASOQC5QAP4GBU/<span className='br-m' />IGU</>}
                                title={<>Духовой <span className='br-m' />шкаф <span className='br-d' />Casarte</>}
                                text={<>Классические формы, элегантный дизайн, современные технологии</>}
                                link={"/catalog/ovens/oven-casarte"}
                            />
                            <ProductBlock
                                picName={2}
                                //label={<>CASVHP9DT1NBGH</>}
                                // label={<>
                                //   <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                //     &nbsp;
                                //   </MediaQuery>
                                // </>}
                                title={<>Вытяжка <br />Casarte</>}
                                text={<>Лаконичный дизайн, изящные формы, продвинутые технологии</>}
                                link={"/catalog/hoods/hood-casarte/hood-casarte"}
                            />
                        </div>
                    </div>
                </div>
                <div className="main-page__product-block__section">
                    <div className="wrap">
                        <div className="main-page__product-single-block__group">
                            <ProductBlock
                                picName={7}
                                label={<>Кондиционеры <span className='br-m' />Casarte</>}
                                //title={<></>}
                                text={<>Технологии будущего. Безупречный стиль.</>}
                                link={"/catalog/air-conditioners"}
                                mosaic={true}
                                single={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="main-page__product-block__section _black">
                    <div className="wrap">
                        <div className="main-page__product-block__group">
                            <ProductBlock
                                picName={3}
                                label={<>TWIN WINE ZONE</>}
                                title={<>Винный шкаф <br />Casarte</>}
                                text={<>Строгий и&nbsp;элегантный дизайн, стильный акцент вашего интерьера</>}
                                link={"/catalog/wine-coolers/twin-wine-zone/wine-coolers"}
                            />
                            <ProductBlock
                                picName={1}
                                label={<>FRENCH DOOR</>}
                                title={<>Холодильник<br />Casarte</>}
                                text={<>Больше,&nbsp;чем технологии. Больше, чем&nbsp;просто дизайн</>}
                                link={"/catalog/fridges/french-door/french-door"}
                            />
                        </div>
                        <div className="main-page__product-block__group">
                            <ProductBlock
                                picName={5}
                                label={<>Double Drum</>}
                                title={<>Стирально-<br />сушильная колонна Casarte</>}
                                text={<>Дань классике, идеальная интеграция линий, позволяющая перенести традиционный дизайн в&nbsp;эстетику дома будущего.</>}
                                link={"/catalog/washers/double-drum"}
                                video={true}
                            />
                            <ProductBlock
                                picName={8}
                                label={<>Double Drawer</>}
                                title={<>Двухдверная <br />встраиваемая<br />посудомоечная<br />машина Casarte</>}
                                text={<>Эргономичный дизайн для&nbsp;максимального удобства</>}
                                link={"/catalog/built-in-dishwashers/double-drawer"}
                            />
                        </div>
                    </div>
                </div>
                <div className="main-page__product-block__section">
                    <div className="wrap">
                        <div className="main-page__product-single-block__group">
                            <ProductBlock
                                picName={12}
                                label={<>Аромакапсулы Casarte</>}
                                text={<>Утонченный цветочный аромат пропитает волокна ткани и подарит новые эмоции.</>}
                                link={"/catalog/aroma-capsules/washers-aroma-capsules/"}
                                single={true}
                                picFormat="webp"
                            />
                        </div>
                    </div>
                </div>
                <div className="main-page__product-block__section _black _last">
                    <div className="wrap">
                        <div className="main-page__product-block__group">
                            <ProductBlock
                                picName={10}
                                label={<>Casarte</>}
                                title={<>Встраиваемый <br />холодильник</>}
                                text={<>Технологии свежести</>}
                                link={"/catalog/fridges/fridge-in/"}
                                picFormat="webp"
                            />
                            <ProductBlock
                                picName={11}
                                label={<>Casarte</>}
                                title={<>Встраиваемая <br />морозильная камера</>}
                                text={<>Сила холода для&nbsp;сохранения свойств продуктов</>}
                                link={"/catalog/fridges/freezer-in/"}
                                picFormat="webp"
                            />
                        </div>
                    </div>
                </div>

                <Features featuresList={featuresList.main} main={true} />
            </div>
        </PageContainer>
    );
}

export default withRouter(inject('store')(observer(Page)));

const ProductBlock = ({ picName, label, title, text, link, video = false, mosaic = false, single = false, className = '', picFormat="jpg" }) => {
    return (
        <>
            {
                single ?
                    <div className={`main-page__product-single-block ${className}`}>
                        <p className="main-page__product-single-block__label">{label}</p>
                        {title && <p className="main-page__product-single-block__title">{title}</p>}
                        <p className="main-page__product-single-block__text"><span>{text}</span></p>
                        <Link to={link} className="main-page__product-single-block__btn p-button p-component p-button-secondary p-button-white min-w">смотреть</Link>
                        {
                          video ?
                            <div className="main-page__product-single-block__pic">
                                <MediaQuery maxWidth={MOBILE_WIDTH}>
                                    <video src={`${path}images/main/product-blocks/${picName}-m.mp4`} loop autoPlay playsInline muted />
                                </MediaQuery>
                                <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                    <video src={`${path}images/main/product-blocks/${picName}-d.mp4`} loop autoPlay playsInline muted />
                                </MediaQuery>
                            </div>
                          : mosaic ?
                              <div className="main-page__product-single-block__mosaic">
                                  <MediaQuery maxWidth={MOBILE_WIDTH}>
                                      <div className="main-page__product-single-block__mosaic__pic _large" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-1-m.${picFormat})` }} />
                                      <div className="main-page__product-single-block__mosaic__pic _left _small" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-2-m.${picFormat})` }} />
                                      <div className="main-page__product-single-block__mosaic__pic _right _small" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-3-m.${picFormat})` }} />
                                  </MediaQuery>
                                  <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                      <div className="main-page__product-single-block__mosaic__pic _top _left _large" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-1-d.${picFormat})` }} />
                                      <div className="main-page__product-single-block__mosaic__pic _bottom _right _small" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-2-d.${picFormat})` }} />
                                      <div className="main-page__product-single-block__mosaic__pic _top _right _small" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-3-d.${picFormat})` }} />
                                  </MediaQuery>
                              </div>
                          :
                          <>
                              <MediaQuery maxWidth={MOBILE_WIDTH}>
                                  <div className="main-page__product-single-block__pic" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-m.${picFormat})` }} />
                              </MediaQuery>
                              <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                  <div className="main-page__product-single-block__pic" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-d.${picFormat})` }} />
                              </MediaQuery>
                          </>
                        }
                    </div>
                    :
                    <div className={`main-page__product-block ${className}`}>
                        <div className="main-page__product-block__wrap">
                            <div className="main-page__product-block__header">
                                <div>
                                    <p className="main-page__product-block__title">{title}</p>
                                    {label && <p className="main-page__product-block__label">{label}</p>}
                                </div>
                                <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                    <div>
                                        <Link to={link} className="main-page__product-block__btn p-button p-component p-button-secondary p-button-white min-w">смотреть</Link>
                                    </div>
                                </MediaQuery>
                            </div>
                            <p className="main-page__product-block__text"><span>{text}</span></p>
                            <MediaQuery maxWidth={MOBILE_WIDTH}>
                                <Link to={link} className="main-page__product-block__btn p-button p-component p-button-secondary p-button-white min-w">смотреть</Link>
                            </MediaQuery>
                        </div>
                        {video ?
                            <div className="main-page__product-block__pic">
                                <MediaQuery maxWidth={MOBILE_WIDTH}>
                                    <video src={`${path}images/main/product-blocks/${picName}-m.mp4`} loop autoPlay playsInline muted />
                                </MediaQuery>
                                <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                    <video src={`${path}images/main/product-blocks/${picName}-d.mp4`} loop autoPlay playsInline muted />
                                </MediaQuery>
                            </div>
                            :
                            <>
                                <MediaQuery maxWidth={MOBILE_WIDTH}>
                                    <div className="main-page__product-block__pic" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-m.${picFormat})` }} />
                                </MediaQuery>
                                <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                    <div className="main-page__product-block__pic" style={{ backgroundImage: `url(${path}images/main/product-blocks/${picName}-d.${picFormat})` }} />
                                </MediaQuery>
                            </>
                        }
                        <Link to={link} className="main-page__product-block__extra-btn" />
                    </div>
            }
        </>
    )
}

const InfoBlock = (props) => {

    return (
        <div className={`main-page__info-block ${props.className}`}>
            <MediaQuery maxWidth={MOBILE_WIDTH}>
                <div className="main-page__info-block__pic" style={{ backgroundImage: `url(${path}images/main/info-blocks/${props.picName}-m.jpg)` }} />
            </MediaQuery>
            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                <div className="main-page__info-block__pic" style={{ backgroundImage: `url(${path}images/main/info-blocks/${props.picName}-d.jpg)` }} />
            </MediaQuery>
            <div className="main-page__info-block__content">
                <p className="main-page__info-block__title">{props.title}</p>
                <div className="main-page__info-block__text">{props.text}</div>
            </div>
        </div>
    )
}
