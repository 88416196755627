import './style.scss';
import React, {useEffect, useRef, useState} from 'react';
import {inject, observer} from "mobx-react";
import { disableBodyScroll, enableBodyScroll } from '../../common/utils';

const Modal = ( {store} ) => {
  const name = 'video';
  const data = store.modal[name];
  const ref = useRef(null);
  const [playStatus, setPlayStatus] = useState(true);
  const [soundStatus, setSoundStatus] = useState(true);

  const handleClose = (e) => {
    if(e.target.className.indexOf('video-modal__outer-wrap') >= 0){
      store.setModal(name, null);
    }
  }

  const resetPlayer = () => {
    setPlayStatus(true);
    setSoundStatus(true);
    ref.current.play();
    ref.current.muted = false;
  }

  const handlePlay = () => {
    setPlayStatus(!playStatus);

    if(playStatus){
      ref.current.pause();
    }
    else{
      ref.current.play();
    }
  }

  const handleSound = () => {
    setSoundStatus(!soundStatus);
    ref.current.muted = !ref.current.muted;
  }

  data?.data ? disableBodyScroll() : enableBodyScroll();

  useEffect(() => {
    if(data){
      resetPlayer();
    }
  }, [data])

  useEffect(() => {
    return () => {
      store.setModal(name, null);
    }
  }, [store])

  return (
    data ?
    <div className={'video-modal _' + data.status + ' '+data?.data?.className} onClick={(e) => handleClose(e) }>
      <div className="video-modal__outer-wrap">
        <div className="video-modal__inner-wrap">
          <div className="video-modal__body">
            <div className="video-modal__close video-modal__btn" onClick={(e) => store.setModal(name, null) } />
            <div className={`video-modal__play video-modal__btn ${playStatus?'':'_off'}`} onClick={handlePlay} />
            <div className={`video-modal__sound video-modal__btn ${soundStatus?'':'_off'}`} onClick={handleSound} />
            <video ref={ref} src={data.data?.url} onEnded={handlePlay} autoPlay playsInline />
          </div>
        </div>
      </div>
    </div>
    : null
  )
}

export default inject('store')(observer(Modal));
