import './style.scss';
import {useState, useRef, useEffect} from 'react';
import Logo from '../../assets/images/casarte.svg';
import Profile from '../../assets/images/header/profile.svg';
import Cart from '../../assets/images/header/cart.svg';
import Geo from '../../assets/images/header/geo.svg';
import {NavLink, Link, useLocation} from 'react-router-dom';
import {disableBodyScroll, enableBodyScroll} from '../../common/utils';
import {
  OPENED,
  OPENING,
  CLOSED,
  CLOSING,
  MOBILE_WIDTH,
  MOBILE_WIDTH_1,
} from '../../common/const';
import MediaQuery from 'react-responsive';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {inject, observer} from "mobx-react";
import {debounce, markResults} from "../utils";
import catalogData from "../../json/catalog.json";
import useOutsideClick from '../utils/useOutsideClick';
import {isDesktop} from 'react-device-detect';

// const path = process.env.REACT_APP_ASSETS_PATH;
const TWEEN_TIME = 600;
const TWEEN_TIME_2 = 300;
let timer;

const initialLoading = {
  locationConfirm: false,
  locationSet: false,
  locationGet: false,
};

const OVERLAY_QUEUE_ID = 'locationConfirm';

const Header = ({ store }) => {
  const [menuStatus, setMenuStatus] = useState(CLOSED);
  const [hoverMenuStatus, setHoverMenuStatus] = useState(CLOSED);
  const [selectCityStatus, setSelectCityStatus] = useState(CLOSED);
  const [selectCityView, setSelectCityView] = useState(1);
  const [menuView, setMenuView] = useState(1);
  const menuRef = useRef(null);
  const [snapMenuStatus, setSnapMenuStatus] = useState(false);
  const [selectCatalog, setSelectCatalog] = useState(0);
  const [selectedMenuItem, setSelectedMenuItem] = useState(catalogData[0].items[0]);
  const [loading, setLoading] = useState(initialLoading);
  const [popularCities, setPopularCities] = useState([]);
  const [suggestedCities, setSuggestedCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [cityId, setCityId] = useState(null);
  const [error, setError] = useState(null);

  const location = useLocation();
  const isCatalogPage = location.pathname.includes('catalog');
  const isBlackBgPage = location.pathname.match('customers|service|personal|cart|legal|bonus-program|cookie-policy|terms-of-service|privacy-policy|complete') 
                        || catalogData.find((item) => item.url === (location.pathname+'/').replace('//', '/'));
  

  // Устанавливает выбранный город
  useEffect(() => {
    if (store.header?.geolocation && !selectedCity) {
      setSelectedCity({
        value: store.header.geolocation.value,
        id: store.header.geolocation.id,
      });
      setCityId(store.header.geolocation.id);
    }
  }, [store.header, selectedCity]);

  // сбрасывает выбранный город при закрытии модалки в случае наличия ошибки
  useEffect(() => {
    if (store.header?.geolocation && selectCityStatus === CLOSED && Boolean(error)) {
      setSelectedCity({
        value: store.header.geolocation.value,
        id: store.header.geolocation.id,
      })
      setCityId(store.header.geolocation.id);
      setError(null)
    }
  }, [store.header, selectCityStatus, error])

  // Устанавливает саджесты
  useEffect(() => {
    if (store.header?.geolocation?.items && !popularCities.length) {
      const suggestions = store.header.geolocation.items;

      if (suggestions.length) {
        const selected = {
          value: store.header.geolocation.value,
          id: store.header.geolocation.id,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(suggestions);
        } else {
          setSuggestedCities([
            ...suggestions,
            selected,
          ]);
        }
      }
      setPopularCities(suggestions);
      setSuggestedCities(suggestions);
    }
  }, [store.header, popularCities]);

  // Показывает модалку подтверждения геолокации
  useEffect(() => {
    if (store.header?.geolocation?.showOnReady) {
      if (window.overlayQueue) {
        window.overlayQueue.add({
          callback: () => openSelectCity(),
          id: OVERLAY_QUEUE_ID,
          order: 1,
        });
        window.overlayQueue.init();
      } else {
        openSelectCity();
      }
    }
  }, [store.header]);

  useEffect(() => {
    const onScroll = e => {
      if (window.scrollY >= 200 && !snapMenuStatus) {
        setSnapMenuStatus(true);
      }
      if (window.scrollY < 200 && snapMenuStatus) {
        setSnapMenuStatus(false);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [snapMenuStatus]);

  const handleEnter = (e) => {
    clearTimeout(timer);
    timer = null;

    if (hoverMenuStatus !== CLOSED) {
      return;
    }

    setHoverMenuStatus(OPENING);

    setTimeout(() => {
      setHoverMenuStatus(OPENED);
    }, TWEEN_TIME_2);
  }

  const handleLeave = (e) => {
    setHoverMenuStatus(CLOSING);

    setTimeout(() => {
      setHoverMenuStatus(CLOSED);
    }, TWEEN_TIME_2);
  }

  const toggleMenu = () => {
    if (menuStatus === OPENED) {
      setMenuStatus(CLOSING);

      setTimeout(() => {
        enableBodyScroll();
        setMenuStatus(CLOSED);
      }, TWEEN_TIME);
    } else if (menuStatus === CLOSED) {
      disableBodyScroll();
      setMenuStatus(OPENING);

      setTimeout(() => {
        menuRef.current.scrollTop = 0;
      }, 10);

      setTimeout(() => {
        setMenuStatus(OPENED);
      }, TWEEN_TIME);
    }
  }

  const openMenu = (view) => {
    if (menuStatus === CLOSED) {
      toggleMenu();
    }
    setMenuView(view);
  }

  const closeMenu = () => {
    if (menuStatus === OPENED) {
      toggleMenu();
    }
  }

  const toggleSelectCity = () => {
    if (selectCityStatus === OPENED) {
      closeSelectCity();
    } else if (selectCityStatus === CLOSED) {
      openSelectCity();
    }
  }

  const openSelectCity = () => {
    setSelectCityView(1);
    disableBodyScroll();
    setSelectCityStatus(OPENING);

    setTimeout(() => {
      setSelectCityStatus(OPENED);
    }, TWEEN_TIME_2);
  };

  const closeSelectCity = () => {
    setSelectCityStatus(CLOSING);

    setTimeout(() => {
      if (menuStatus === CLOSED) {
        enableBodyScroll();
      }
      setSelectCityStatus(CLOSED);
      setSelectCityView(1);

      if (window.overlayQueue) {
        window.overlayQueue.show(OVERLAY_QUEUE_ID);
      }
    }, TWEEN_TIME_2);
  };

  const locationConfirm = () => {
    if (loading.locationConfirm) {
      return;
    }
    setLoading((value) => ({
      ...value,
      locationConfirm: true
    }))

    store.bridge.locationConfirm({
      city: selectedCity?.value,
      id: selectedCity?.id,
    }).then((response) => {
      const { status } = response?.data || {};
      let resetLoading = true

      switch (status) {
        case 'LOCATION_CONFIRM_SUCCESS':
          resetLoading = false
          window.location.reload();
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      if (resetLoading) {
        setLoading((value) => ({
          ...value,
          locationConfirm: false
        }))
      }
    }).catch(() => {
      setLoading((value) => ({
        ...value,
        locationConfirm: false
      }))
    });
  };

  const handleCityChange = (id) => {
    const city = suggestedCities.find((item) => item.id === id);

    setCityId(city.id);
    selectCity(city);
  };

  const handleSearchInput = (value) => {
    const inputValue = value.trim();

    setError(null)

    if (inputValue.length) {
      debounceGetCities(inputValue);
    } else {
      setSuggestedCities(popularCities);
    }
  };

  const getCities = (term) => {
    setLoading((value) => ({
      ...value,
      locationGet: true
    }))

    store.bridge.locationFindCity({
      term,
    }).then((response) => {
      const { suggestions = [] } = response?.data?.data || {};

      if (suggestions.length) {
        const selected = {
          value: selectedCity?.value,
          id: selectedCity?.id,
        };
        const existing = suggestions.find((item) => item.id === selected.id);

        if (existing) {
          setSuggestedCities(markResults(suggestions, term));
        } else {
          setSuggestedCities([
            ...markResults(suggestions, term),
            selected,
          ]);
        }
      }
      setLoading((value) => ({
        ...value,
        locationGet: false
      }))
    }).catch(() => {
      setLoading((value) => ({
        ...value,
        locationGet: false
      }))
    });
  };

  const selectCity = (value) => {
    if (loading.locationSet) {
      return;
    }
    // toggleSelectCity();
    setSelectedCity(value);
    setLoading((value) => ({
      ...value,
      locationSet: true
    }))
    setError(null)

    store.bridge.locationSetCity({
      city: value.value,
      id: value.id,
    }).then((response) => {
      const { status, data } = response?.data || {};
      let resetLoading = true

      switch (status) {
        case 'LOCATION_CITY_CHANGE_SUCCESS':
          resetLoading = false
          window.location.reload();
          break;
        case 'LOCATION_CITY_CHANGE_FAIL':
          if (data?.errorMessage) {
            setError(data.errorMessage)
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      if (resetLoading) {
        setLoading((value) => ({
          ...value,
          locationSet: false
        }))
      }
    }).catch(() => {
      setLoading((value) => ({
        ...value,
        locationSet: false
      }))
    });
  };

  const debounceGetCities = debounce(getCities);

  const dropdownOptionTemplate = (option) => {
    return (
      <div className="page-header__select-city__list__item">
        <span className="page-header__select-city__list__item__title"
              dangerouslySetInnerHTML={{__html: option.markedValue || option.value}}/>
        <span className="page-header__select-city__list__item__text">{option.additionalInfo}</span>
      </div>
    );
  }

  return (
    <div className={`page-header ${selectCityStatus === CLOSED?'':'_disabled'}`}>
      {
        isBlackBgPage ?
          <MediaQuery minWidth={MOBILE_WIDTH_1}>
            <div className="page-wrap page-header__line__wrap">
              <div>
                <div className="page-header__line" />
              </div>
            </div>
          </MediaQuery>
          : null}

      {hoverMenuStatus === CLOSED ? null :
        <MediaQuery minWidth={MOBILE_WIDTH_1}>
          <div className={`page-header__hover-menu _${hoverMenuStatus}`}>
            <div
              onMouseEnter={handleEnter}
              onMouseLeave={handleLeave}
              className="page-header__hover-menu__wrap">
              <div className="page-header__hover-menu__left">
                {catalogData.map((item, index) => {
                  return (
                    <NavLink
                      onMouseEnter={(e) => {
                        setSelectCatalog(index);
                        setSelectedMenuItem(item.items[0]);
                      }}
                      onClick={handleLeave}
                      className={`page-header__hover-menu__left__item ${selectCatalog === index ? '_active' : ''}`}
                      to={item.url}
                      key={index}
                    >{item.title}</NavLink>
                  )
                })}
              </div>
              <div className="page-header__hover-menu__right">
                {catalogData[selectCatalog].items.map((item, index) => {// .filter((item) => item.url !== "")
                  return (
                    <NavLink
                      onMouseEnter={() => setSelectedMenuItem(item)}
                      onClick={handleLeave}
                      className={`page-header__hover-menu__right__item${item.url?'':' _disabled'}`}
                      exact
                      to={item.url}
                      key={index}
                      dangerouslySetInnerHTML={{__html: item.menu.htmlTitle}}
                    />
                  )
                })}
                {selectedMenuItem && 
                <img src={selectedMenuItem.menu.imageSet.desktop}
                  className="page-header__hover-menu__right__pic" 
                  alt={selectedMenuItem.menu.title} 
                  title={selectedMenuItem.menu.title} 
                />}

              </div>
            </div>
          </div>
        </MediaQuery>
      }

      {selectCityStatus === CLOSED ? null :
        <div className={`page-header__select-city _${selectCityStatus} ${loading.locationSet ? '_loading' : ''}`}>
          <div className="page-header__select-city__wrap">
            {
              selectCityView === 1 ?
                <div className="page-header__select-city__alert">
                  <div className="page-header__select-city__close" onClick={toggleSelectCity}/>
                  <span className="page-header__select-city__alert__title">Ваш город {selectedCity?.value}?</span>
                  <div className="p-fields-grid">
                    <div className="p-fields-grid__item d-50 m-50">
                      <Button className="p-button-secondary p-button-transparent" label="Нет, изменить" onClick={() => {
                        setSelectCityView(2)
                      }}/>
                    </div>
                    <div className="p-fields-grid__item d-50 m-50">
                      <Button className={`p-button-secondary p-button-white ${loading.locationConfirm ? 'p-loader' : ''}`} label="Да" onClick={locationConfirm}/>
                    </div>
                  </div>
                </div>
                : selectCityView === 2 ?
                  <div className="page-header__select-city__list">
                    <div className="page-header__select-city__close" onClick={toggleSelectCity}/>
                    <div className="page-header__select-city__list-content">
                      <span className="page-header__select-city__list__title">Выберите город</span>
                      <span className="p-float-label">
                        <Dropdown
                          panelClassName="city-select-dropdown-panel"
                          itemTemplate={dropdownOptionTemplate}
                          showFilterClear={true}
                          inputId="dropdown"
                          value={cityId}
                          onChange={(e) => handleCityChange(e.value)}
                          onFilter={(e) => handleSearchInput(e.filter)}
                          options={suggestedCities}
                          optionLabel="value"
                          optionValue="id"
                          filter
                        />
                        <label htmlFor="dropdown">Ваш город</label>
                      </span>

                      {Boolean(error) && <div className="city-select-error" dangerouslySetInnerHTML={{__html: error}} />}

                      <div className="page-header__select-city__list__preset">
                        {popularCities.map((city) =>
                          <span
                            key={city.id}
                            className="page-header__select-city__list__preset__item"
                            onClick={() => selectCity(city)}
                          >{city.value}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  : null
            }
          </div>
        </div>
      }

      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="page-wrap">
          <div className="page-header__wrap">
            <div className="page-header__left">
              <Link className="page-header__logo" to="/"><img src={Logo} alt="Casarte" /></Link>
              <span className="page-header__city__btn" onClick={toggleSelectCity}><img src={Geo} className="page-header__city__geo" alt="Выбрать город" />{selectedCity?.value}</span>
            </div>
            <div className="page-header__menu" >
              <div className="page-header__menu__wrap">
                <span
                  onClick={() => {
                    if(hoverMenuStatus === CLOSED){
                      handleEnter();
                    } else{
                      handleLeave();
                    }
                  }}
                  onMouseEnter={() => {isDesktop && handleEnter()}}
                  onMouseLeave={() => {isDesktop && (timer = setTimeout(handleLeave, 500))}}
                  className={`page-header__menu__item _no-hover ${isCatalogPage ? 'active' : ''}`}>Каталог</span>
                <Link className="page-header__menu__item" to="/#brand">О бренде</Link>
                <DropdownMenu id={1} />
                <DropdownMenu id={2} />
              </div>
            </div>
            <DesktopHeaderUI store={store} />
          </div>
        </div>
      </MediaQuery>

      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className={`${('page-header__fixed' + (snapMenuStatus ? ' active' : ''))}`}>
          <div className="page-wrap">
            <div className="page-header__wrap">
              <div className="page-header__left">
                <Link className="page-header__logo" to="/"><img src={Logo} alt="Casarte" /></Link>
                <span className="page-header__city__btn" onClick={toggleSelectCity}><img src={Geo} className="page-header__city__geo" alt="Выбрать город" />{selectedCity?.value}</span>
              </div>
              <div className="page-header__menu" >
                <div className="page-header__menu__wrap">
                  <span
                    onMouseEnter={handleEnter}
                    onMouseLeave={() => {timer = setTimeout(handleLeave, 500)}}
                    className={`page-header__menu__item _no-hover ${isCatalogPage ? 'active' : ''}`}>Каталог</span>
                  <Link className="page-header__menu__item" to="/#brand">О бренде</Link>
                  <DropdownMenu id={1} />
                  <DropdownMenu id={2} />
                </div>
              </div>
              <DesktopHeaderUI store={store} />
            </div>
          </div>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <Link className="page-header__logo" to="/"><img src={Logo} alt="Casarte"/></Link>
        {menuStatus===CLOSED ? null:
          <div className={`page-header__menu ${menuStatus}`} ref={menuRef}>
            <div className="page-header__menu__btn" onClick={closeMenu}/>
            {menuView === 2 || menuView === 4 ?
              <div className="page-header__menu__back" onClick={() => {
                if(menuView === 2){
                  openMenu(1)
                }else if(menuView === 4){
                  openMenu(2)
                }
              }}/>
              : null}
            <div className="page-header__menu__wrap">
              {
                menuView === 1 ?
                  <>
                    <span className="page-header__city__btn" onClick={toggleSelectCity}>
                      <img src={Geo} className="page-header__city__geo" alt="Выбрать город"/>
                      {selectedCity?.value}
                    </span>
                    <div className="page-header__city__line"/>
                    <div className="page-header__menu__item" onClick={() => openMenu(2)}>Каталог<span className="page-header__menu__item__arr _next" /></div>
                    <Link className="page-header__menu__item" onClick={closeMenu} to="/#brand">О бренде</Link>
                    <NavLink className="page-header__menu__item" onClick={closeMenu} exact to="/dealers/">Где купить</NavLink>
                    <SubMenu id={1} closeMenu={closeMenu} />
                    <SubMenu id={2} closeMenu={closeMenu} />
                  </>
                  : menuView === 2 ? 
                  <div className="page-header__menu__levels">
                      {
                        catalogData.map((item, index) => {
                          return (
                            <div 
                              key={'tab' + index} 
                              className="page-header__menu__levels__item" 
                              onClick={() => {
                                setSelectCatalog(index);
                                openMenu(4);
                              }}>{item.title}<span className="page-header__menu__levels__item__arr" /></div>
                          )
                        })
                      }
                    </div>
                  : menuView === 4 ? 
                  <div className="page-header__menu__levels">
                      {catalogData[selectCatalog].items.map((subItem, subIndex) => {
                        return (
                          <NavLink
                            className="page-header__menu__levels__sub-item"
                            onClick={closeMenu}
                            exact
                            to={subItem.url}
                            key={'link' + subIndex}
                            dangerouslySetInnerHTML={{__html:subItem.menu.htmlTitle}}
                          />
                        )
                      })}
                    </div>
                    : menuView === 3 ?
                      <>
                        <NavLink className="personal-page__summary__item i1" onClick={closeMenu} exact
                                 to="/personal/profile/">
                          <span>Личные данные</span>
                        </NavLink>
                        <NavLink className="personal-page__summary__item i2" onClick={closeMenu} to="/personal/orders/">
                          <span>Мои заказы</span>
                          {store.sidebar?.orders && <span className="personal-page__summary__item__value">{store.sidebar?.orders}</span>}
                        </NavLink>
                        {/* <NavLink className="personal-page__summary__item i3" onClick={closeMenu} exact to="/personal/bonus">
                                    <span>Мои баллы</span>
                                    <span className="personal-page__summary__item__value">{numWithSpaces('42290')}</span>
                                  </NavLink> */}
                        <Link className="personal-page__summary__item i4" onClick={closeMenu} to="/">
                          <span>Выход</span>
                        </Link>
                        <div className="personal-page__summary__line"/>
                        <span className="personal-page__summary__title">Помощь</span>
                        <div className="personal-page__summary__links">
                          <Link className="personal-page__summary__link" to="/customers">Что делать, если обнаружил брак
                            после оплаты товара?</Link>
                          {/* <Link className="personal-page__summary__link" to="/customers">Почему мне не&nbsp;добавили бонусов за&nbsp;покупку?</Link> */}
                        </div>
                        <Button className="personal-page__summary__btn p-button-secondary p-button-transparent min-w"
                                label="задать вопрос" onClick={() => {
                          store.setModal('contact')
                        }}/>
                      </>
                      : null
              }
            </div>
          </div>
        }
        <div className="page-header__fixed-menu">
          <NavLink className="page-header__fixed-menu__item i1" exact to="/" onClick={closeMenu}>Главная</NavLink>
          <NavLink
            className={`page-header__fixed-menu__item i2 ${(menuStatus === OPENED || menuStatus === OPENING) && menuView === 2 ? 'active' : ''}`}
            to="/catalog" onClick={(e) => {
            openMenu(2);
            e.preventDefault();
          }}>Каталог</NavLink>
          <NavLink className="page-header__fixed-menu__item i3" exact to="/personal/cart/" onClick={closeMenu}><span
            className="page-header__fixed-menu__widget">{store.basketCount}</span>Корзина</NavLink>
          <NavLink
            className={`page-header__fixed-menu__item i4 ${(menuStatus === OPENED || menuStatus === OPENING) && menuView === 3 ? 'active' : ''}`}
            exact to="/personal" onClick={(e) => {
            openMenu(3);
            e.preventDefault();
          }}>Профиль</NavLink>
          <span
            className={`page-header__fixed-menu__item i5 ${(menuStatus === OPENED || menuStatus === OPENING) && menuView === 1 ? 'active' : ''}`}
            onClick={() => {
              openMenu(1);
            }}>Ещё</span>
        </div>
      </MediaQuery>

    </div>
  );
}

export default inject('store')(observer(Header));

const DesktopHeaderUI = (props) => {
  return (
    <div className="page-header__ui">
      <a className="page-header__ui__item page-header__phone" href="tel:8 (800) 234-09-53">8 (800) 234-09-53</a>
      {props.store.appConst.isAuth ?
        <Link to="/personal/orders/" className="page-header__ui__item page-header__profile"><img src={Profile} alt="Профиль"/></Link>
        :
        <span className="page-header__ui__item page-header__profile" alt="Профиль" onClick={() => props.store.setModal('auth')}>
          <img src={Profile} />
        </span>
      }
      <Link to="/personal/cart/" className="page-header__ui__item page-header__cart">
        <img src={Cart} alt="Корзина"/>
        <span className="page-header__fixed-menu__widget">{props.store.basketCount}</span>
      </Link>
    </div>
  );
}

const SubMenu = (props) => {
  const [status, setStatus] = useState(0);

  return (
    <>
      <div className="page-header__menu__item" onClick={() => setStatus(!status)}>
        {
        props.id===1 ?
          <>Покупателям</>
        : props.id===2 ?
          <>Сервис и поддержка</>
        : null
        }
        <span className={`page-header__menu__item__arr _${status?'up':'down'}`} />
      </div>
      {
        status ?
        <div className="page-header__submenu">
          {
            props.id===1?
              <>
                <NavLink onClick={props.closeMenu} className="page-header__submenu__item" exact to="/dealers">Где купить</NavLink>
                <NavLink onClick={props.closeMenu} className="page-header__submenu__item" exact to="/delivery">Оплата и доставка</NavLink>
              </>
            : props.id===2?
              <>
                <NavLink onClick={props.closeMenu} className="page-header__submenu__item" exact to="/service">Контакты</NavLink>
                <NavLink onClick={props.closeMenu} className="page-header__submenu__item" exact to="/service/installation">Установка</NavLink>
                <NavLink onClick={props.closeMenu} className="page-header__submenu__item" exact to="/service/support">Cервисное обслуживание</NavLink>
                <NavLink onClick={props.closeMenu} className="page-header__submenu__item" exact to="/assurance">Гарантия</NavLink>
              </>
            : null
          }
        </div>
        : null
      }
    </>
  );
}

const DropdownMenu = (props) => {
  const {ref, isVisible, setIsVisible} = useOutsideClick(false);

  return (
    <span className="page-header__menu__item _dropdown"
      onMouseEnter={() => { isDesktop && setIsVisible(true) }}
      onMouseLeave={() => { isDesktop && setIsVisible(false) }}
    >
      <span ref={ref} onClick={() => { setIsVisible(isVisible?false:true) }}>
      {
      props.id===1 ?
        <>Покупателям</>
      : props.id===2 ?
        <>Сервис и поддержка</>
      : null
      }
      </span>
      {
        isVisible ?
        <div className="page-header__menu__item__dropdown">
          <div className="page-header__menu__item__dropdown__wrap">
            {
            props.id===1?
              <>
                <NavLink onClick={() => { setIsVisible(false) }} className="page-header__menu__item__dropdown__link" exact to="/dealers">Где купить</NavLink>
                <NavLink onClick={() => { setIsVisible(false) }} className="page-header__menu__item__dropdown__link" exact to="/delivery">Оплата и доставка</NavLink>
              </>
            : props.id===2?
              <>
                <NavLink onClick={() => { setIsVisible(false) }} className="page-header__menu__item__dropdown__link" exact to="/service">Контакты</NavLink>
                <NavLink onClick={() => { setIsVisible(false) }} className="page-header__menu__item__dropdown__link" exact to="/service/installation">Установка</NavLink>
                <NavLink onClick={() => { setIsVisible(false) }} className="page-header__menu__item__dropdown__link" exact to="/service/support">Cервисное обслуживание</NavLink>
                <NavLink onClick={() => { setIsVisible(false) }} className="page-header__menu__item__dropdown__link" exact to="/assurance">Гарантия</NavLink>
              </>
            : null
            }
          </div>
        </div>
        : null
      }
    </span>
  );
}
