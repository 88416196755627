import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import EvoVideo from "../EvoVideo";
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const FrenchDoorHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Холодильник</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        <h2 className="product-page__head__center__sub-title">French Door</h2>
        <p className="product-page__head__center__text">Больше, чем технологии. Больше, чем просто дизайн.</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const FrenchDoorContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section zoning-id">
        <div className="wrap">
          <SectionHeader
            title={<>Эффективное<br/>зонирование</>}
            text={<>Холодильник Casarte FRENCH DOOR с&nbsp;общим полезным объемом 700&nbsp;л <span className="d-br"/>и&nbsp;специальными зонами с&nbsp;индивидуальным контролем влажности и&nbsp;температуры</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="f130"
              picName="31"
              title={<>MSA <span className="m-br"/>Fresh Pro</>}
              text={<>Зона с низким содержанием кислорода для сохранения свежести фруктов и овощей.</>}
              detailText={<>В зоне создается среда с высоким содержанием азота и низким уровнем кислорода. Снижение уровня кислорода замедляет процесс окисления продуктов и препятствует их преждевременному дозреванию. Фрукты, овощи и зелень остаются свежими, сочными и ароматными дольше, сохраняя свои вкусовые качества и полезные свойства</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="f130"
              picName="32"
              title={<>Moist<br/>Zone</>}
              detailTitle={<>Moist Zone</>}
              text={<>Зона с&nbsp;высоким уровнем влажности для&nbsp;сохранения яиц, масла, молока, фруктов и&nbsp;овощей.</>}
              detailText={<>Внутри модуля поддерживается влажность до 90% при постоянной циркуляции воздуха. Создаются оптимальные условия для хранения продуктов с низким содержанием влаги: сухофруктов, масла, яиц, молока, хлеба, шоколада. В зависимости от типа продуктов, которые будут храниться в Moist Zone, предусмотрены несколько режимов влажности.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="small"
              picName="33"
              title={<>My Zone – нулевая зона с&nbsp;низким уровнем влажности для&nbsp;хранения напитков и&nbsp;деликатесов</>}
              detailTitle={<>My Zone</>}
              detailText={<>В данной зоне поддерживается влажность не выше 45%, а температура может быть понижена до 0 °C без влияния на температурный режим в остальных частях холодильного отделения. Предусмотрено несколько режимов для ранения разных продуктов: мяса, сыра, морепродуктов, деликатесов, а также напитков, фруктов и овощей.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="f130"
              picName="34"
              title={<>Beauty<br/>& Health  </>}
              detailTitle={<>Beauty & Health</>}
              text={<>Зоны для уходовых и косметических средств, добавок, <span className="d-br"/>требующих особых условий хранения. </>}
              detailText={<>Отделения Beauty Zone и Health Zone предназначены для хранения средств по уходу за кожей, косметики, пищевых добавок и средств, требующих деликатного хранения, включая наличие защиты от солнечного света. В настройках можно указать сроки годности средства с последующем предупреждением об их истечении.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>С заботой о здоровье</>}
            text={<>Технологии создания оптимальных условий хранения и поддержания свежести</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="f130"
              picName="35"
              title={<>Smart<br/>Sterilization</>}
              detailTitle={<>Smart Sterilization</>}
              text={<>Антибактериальная обработка и устранение запахов.</>}
              detailText={<>Инновационная система мониторинга и очистки воздуха отслеживает изменения качества воздуха в холодильной камере, уведомляет о наличии содержимого с насыщенным запахом. Многоступенчатая динамическая стерилизация воздуха уничтожает до 99% бактерий, максимально быстро устраняя посторонние запахи.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
      <EvoSection
        sectionTitle={<>Приложение evo</>}
        sectionText={<>Управление и мониторинг процессов через смартфон</>}
        planeTitle={<>Расширяем границы<br/>возможного с&nbsp;evo</>}
        planeText={<>Дистанционное управление и&nbsp;настройка, контроль состояния, <span className="d-br"/>уведомления и&nbsp;оповещения, рекомендации и&nbsp;онлайн-поддержка.<sup>*</sup></>}
        desc={true}
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={false}
        videoBlocks={
          <div className="product-page__grid-item">
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-1"
              title="Инструкция по&nbsp;подключению"
              text="Подключение холодильника French Door с&nbsp;помощью мобильного приложения evo"
            />
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-2"
              direction="right"
              title="Инструкция по&nbsp;управлению"
              text="Управление холодильниомк French Door с&nbsp;помощью мобильного приложения evo"
            />
          </div>
        }
      />
      <div className="product-page__section functionality-id">
        <div className="wrap">
          <SectionHeader
            title={<>Функциональность</>}
            text={<>Вариативные настройки и полезные датчики обеспечат удобство использования</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="36"
              title={<>Удобное управление <span className="d-br"/>и&nbsp;гибкость настроек </>}
              text={<>Широкий выбор температурных режимов.</>}
              detailText={<>
                «Ежедневный» устанавливается при первом включении. <br/>
                «Интеллектуальный (AI)» подходит для активного использования. <br/>
                «Адаптивный» - регулирует температуру камер в соответствии с температурой окружающей среды. <br/>
                «Полная загрузка» используется при необходимости одновременной загрузки большого количества продуктов в оба отделения.
              </>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="37"
              title={<>Супер-<br/>охлаждение <br/>и супер-<br/>заморзка</>}
              detailTitle={<>Суперохлаждение и&nbsp;суперзаморзка</>}
              text={<>Быстрое охлаждение и заморозка содержимого холодильной и морозильной камер.</>}
              detailText={<>При активации этих режимов (вместе или по отдельности) температура в холодильной и морозильной камерах опускается до минимально возможного значения. Оптимально для быстрого охлаждения и/или заморозки большого числа продуктов после масштабной закупки.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="small"
              picName="38"
              title={<>Генератор льда. <br/>Приготовление льда<br/>за&nbsp;60 и&nbsp;90&nbsp;минут</>}
              detailTitle={<>Генератор льда. Приготовление льда <span className="d-br"/>за&nbsp;60 и&nbsp;90&nbsp;минут</>}
              detailText={<>Генератор льда не требует подключения к водопроводу и заполнения форм. Достаточно налить воду в емкость в холодильном отделении. Далее останется лишь достать готовые кубики льда из лотка в верхнем отделении морозильной камеры через 60 или 90 минут.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="39"
              title={<>Датчик<br/>движения</>}
              text={<>Активация дисплея и&nbsp;индикации.</>}
              detailTitle={<>Датчик движения</>}
              detailText={<>Сенсорная система активирует дисплей и световую индикацию при приближении к холодильнику. Управление и отключение функции производится в меню настроек.</>}
              layer={0}
              video={1}
              videoInteractive={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="40"
              title={<>Сигнал<br/>открытия<br/>дверцы</>}
              detailTitle={<>Сигнал открытия дверцы</>}
              text={<>Звуковое оповещение для&nbsp;сохранения оптимальных условий работы холодильника. </>}
              detailText={<>Звуковое оповещение предотвратит оставление дверцы холодильника открытой. Через две минуты после открытия дверцы холодильника система напомнит, что длительный доступ теплого воздуха в любое из отделений может ухудшить условия хранения продуктов.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="small"
              picName="41"
              title={<>Таймер охлаждения напитков и&nbsp;генерации льда</>}
              detailText={<>Ассистент охлаждения напитков и приготовления льда в мобильном приложении настроит таймер охлаждения до нужной температуры с учетом вида напитка, его объема, типа тары и исходной температуры, а также запустит функцию генерации льда в выбранном режиме за 60 или 90 минут.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section awards-id">
        <div className="wrap">
          <SectionHeader
            title={<>Награды</>}
            text={<>Качество и функциональность, подтвержденные специалистами</>}
            btn={false}
          />
          <div className="product-page__awards">
            <div className="product-page__award">
              <img src={`${path}images/products/french-door/awards/1.jpg`} alt="" className="product-page__award__pic" />
              <span className="product-page__award__title">Red Dot Awards <span className="m-br"/>2021</span>
              <span className="product-page__award__text">Победитель</span>
            </div>
            <div className="product-page__award">
              <img src={`${path}images/products/french-door/awards/2.jpg`} alt="" className="product-page__award__pic" />
              <span className="product-page__award__title">Taihu Design Award <span className="m-br"/>2021</span>
              <span className="product-page__award__text">Золото</span>
            </div>
            <div className="product-page__award">
              <img src={`${path}images/products/french-door/awards/3.jpg`} alt="" className="product-page__award__pic" />
              <span className="product-page__award__title">IDEA 2021</span>
              <span className="product-page__award__text">Финалист</span>
            </div>
            <div className="product-page__award">
              <img src={`${path}images/products/french-door/awards/4.jpg`} alt="" className="product-page__award__pic" />
              <span className="product-page__award__title">iF 2022</span>
              <span className="product-page__award__text">Сертификат участника</span>
            </div>
          </div>
        </div>
      </div>
      <TrainerSection />
    </>
  )
}