import "./style.scss";
import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";
import { disableBodyScroll, enableBodyScroll } from "../../common/utils";
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from "../../common/const";
import MediaQuery from "react-responsive";
import { Accordion, AccordionTab } from "primereact/accordion";

const path = process.env.REACT_APP_ASSETS_PATH;

const Modal = ({ store }) => {
  const name = "delivery";
  const data = store.modal[name];

  const handleClose = (e) => {
    if (e.target.className.indexOf("delivery-modal__outer-wrap") >= 0) {
      store.setModal(name, null);
    }
  };

  data ? disableBodyScroll() : enableBodyScroll();

  useEffect(() => {
    return () => {
      store.setModal(name, null);
    };
  }, [store]);

  return data ? (
    <div
      className={"delivery-modal _" + data.status + " " + data?.data?.className}
      onClick={(e) => handleClose(e)}
    >
      <div className="delivery-modal__outer-wrap">
        <div className="delivery-modal__inner-wrap">
          <div className="delivery-modal__body">
            <div
              className="delivery-modal__close"
              onClick={(e) => store.setModal(name, null)}
            />
            {data.data?.id === 1 ? (
              <>
                <span className="delivery-modal__title">Оплата онлайн</span>
                <div className="delivery-modal__content">
                  <p>
                    Оплачивайте через систему быстрых платежей в&nbsp;своем
                    мобильном банке или&nbsp;картой онлайн.
                  </p>
                  <span className="delivery-modal__label">
                    Платежные системы:
                  </span>
                  <MediaQuery maxWidth={MOBILE_WIDTH}>
                    <div className="delivery-modal__pics _first">
                      <div className="delivery-modal__pic">
                        <img src={`${path}images/delivery/9.svg`} alt="" />
                      </div>
                      <div className="delivery-modal__pic">
                        <img src={`${path}images/delivery/3.svg`} alt="" />
                      </div>
                      <div className="delivery-modal__pic">
                        <img src={`${path}images/delivery/4.svg`} alt="" />
                      </div>
                    </div>
                    <div className="delivery-modal__pics">
                      <div className="delivery-modal__pic">
                        <img
                          width="82"
                          src={`${path}images/delivery/5.png`}
                          alt=""
                        />
                      </div>
                      <div className="delivery-modal__pic">
                        <img
                          width="90"
                          src={`${path}images/delivery/6.svg`}
                          alt=""
                        />
                      </div>
                      <div className="delivery-modal__pic">
                        <img
                          width="53"
                          src={`${path}images/delivery/7.png`}
                          alt=""
                        />
                      </div>
                    </div>
                  </MediaQuery>
                  <MediaQuery minWidth={MOBILE_WIDTH_1}>
                    <div className="delivery-modal__pics">
                      <div className="delivery-modal__pic">
                        <img src={`${path}images/delivery/9.svg`} alt="" />
                      </div>
                      <div className="delivery-modal__pic">
                        <img src={`${path}images/delivery/3.svg`} alt="" />
                      </div>
                      <div className="delivery-modal__pic">
                        <img src={`${path}images/delivery/4.svg`} alt="" />
                      </div>
                      <div className="delivery-modal__pic">
                        <img
                          width="82"
                          src={`${path}images/delivery/5.png`}
                          alt=""
                        />
                      </div>
                      <div className="delivery-modal__pic">
                        <img
                          width="90"
                          src={`${path}images/delivery/6.svg`}
                          alt=""
                        />
                      </div>
                      <div className="delivery-modal__pic">
                        <img
                          width="53"
                          src={`${path}images/delivery/7.png`}
                          alt=""
                        />
                      </div>
                    </div>
                  </MediaQuery>
                  <Accordion className="delivery-modal__accordion _show-first-line _show-last-line">
                    <AccordionTab header="Безопасно ли оплачивать заказ онлайн?">
                      <div>
                        <p>
                          При оплате заказа банковской картой обработка платежа
                          (включая ввод номера карты) происходит на защищённой
                          странице процессинговой системы, которая прошла
                          международную сертификацию. Это значит, что ваши
                          конфиденциальные данные (реквизиты карты,
                          регистрационные данные и др.) не поступают в
                          интернет-магазин. Их обработка полностью защищена и
                          никто, в том числе наш интернет-магазин, не может
                          получить персональные и банковские данные клиента.{" "}
                        </p>
                        <p>
                          При работе с карточными данными применяется стандарт
                          защиты информации, разработанный международными
                          платёжными системами Visa и MasterCard — Payment Card
                          Industry Data Security Standard (PCI DSS). Он
                          обеспечивает безопасную обработку реквизитов карты
                          благодаря протоколам TLS (Transport Layer Security),
                          Verified by Visa, Secure Code и закрытым банковским
                          сетям с наивысшей степенью защиты.{" "}
                        </p>
                        <p>
                          В случае возврата, денежные средства возвращаются на
                          ту же карту, с которой производилась оплата.
                        </p>
                      </div>
                    </AccordionTab>
                  </Accordion>
                </div>
              </>
            ) : data.data?.id === 2 ? (
              <>
                <span className="delivery-modal__title">Рассрочка</span>
                <div className="delivery-modal__content">
                  <p>Без первоначального взноса, комиссий и переплат.</p>
                  <p>Максимальный срок рассмотрения заявки — до 20 минут.</p>
                  <p>
                    Срок предоставления рассрочки – 3, 4, 6, 10, 12 или 18
                    месяцев
                  </p>
                  <p>
                    «Рассрочка в Casarte» — это потребительский кредит, который
                    выдают банки-партнеры Casarte. Интернет-магазин компенсирует
                    проценты по кредиту, предоставляя вам скидку на покупку.
                    Общая сумма к оплате не превышает исходную стоимость товара.
                  </p>
                  <span className="delivery-modal__label">Наши партнеры:</span>
                  <div className="delivery-modal__pics _x2">
                    <div className="delivery-modal__pic">
                      <img src={`${path}images/delivery/1.svg`} alt="" />
                    </div>
                    <div className="delivery-modal__pic">
                      <img src={`${path}images/delivery/8.svg`} alt="" />
                    </div>
                  </div>
                </div>
              </>
            ) : data.data?.id === 3 ? (
              <>
                <span className="delivery-modal__title">Оплата частями</span>
                <div className="delivery-modal__content">
                  <p>
                    Способ оплаты, позволяющий оплачивать заказы
                    интернет-магазина 4 частями за 6 недель картой любого банка
                    без предоплат и скрытых комиссий. Первая часть в размере 25%
                    должна быть оплачена сразу, в оставшиеся 75% списываются с
                    привязанной карты автоматически в течение 6 недель.
                  </p>
                  <p>Минимальная сумма заказа 5 000 рублей.</p>
                  <p>
                    Максимальная сумма заказа с учетом всех скидок, примененных
                    в корзине — 100 000 рублей.
                  </p>
                  <p>
                    При оплате нет комиссий и переплат, оплачивается только
                    стоимость заказа.
                  </p>
                  <p>
                    Возвраты и отмены заказов работают так же, как при
                    обыкновенной 100% оплате банковской картой.
                  </p>
                  <span className="delivery-modal__label">
                    Мы сотрудничаем с банком:
                  </span>
                  <div className="delivery-modal__pic">
                    <img src={`${path}images/delivery/2.svg`} alt="" />
                  </div>
                </div>
              </>
            ) : data.data?.id === 4 ? (
              <>
                <span className="delivery-modal__title">
                  Установка стирально-сушильной колонны
                </span>
                <div className="delivery-modal__content">
                  <h5 className="_first">Общие положения</h5>
                  <p>
                    Монтаж оборудования производится в строгом соответствии с
                    законодательством РФ, в том числе, регулирующем производство
                    шумных работ и вопросов охраны труда и техники безопасности.
                    Клиент обязан обеспечить беспрепятственный доступ
                    специалистов, осуществляющих монтаж, к местам,
                    предназначенным для установки оборудования.
                  </p>
                  <p>
                    Подключение изделия производится к существующим
                    коммуникациям водо-, электроснабжения, канализационной
                    системе без их доработки. Установка производится в
                    соответствии с техническими требованиями, предъявляемыми
                    производителем изделия, а также органами местного
                    самоуправления по обслуживанию внутриквартирных сетей. После
                    установки изделия производится проверка работоспособности и
                    консультация по правилам эксплуатации прибора.
                  </p>
                  <h5>Базовая установка</h5>
                  <p>
                    Техническое описание Premium монтажа стирально-сушильной
                    колонны Casarte, входящего в стоимость исключительно при
                    покупке товара в интернет-магазине casarte.ru:
                  </p>
                  <ul>
                    <li>
                      Консультация по вопросам установки, доработки
                      внутриквартирной сети клиента (водо-, электросети), по
                      вопросам эксплуатации изделия.
                    </li>
                    <li>
                      Установка изделия на место эксплуатации и выравнивание по
                      уровню.
                    </li>
                    <li>Снятие/установка транспортных креплений.</li>
                    <li>
                      Подключение к точкам водо-, и электроснабжения с
                      обязательным соблюдением требований инструкции по
                      эксплуатации, проверка работоспособности.
                    </li>
                  </ul>
                  <h5>Дополнительные сервисы</h5>
                  <p>
                    Не входящие в приведённый выше перечень услуг дополнительные
                    работы и материалы, оплачиваются отдельно по согласованию с
                    заказчиком (возможные доп.работы: демонтаж старой стм,
                    удлинение заливного шланга, установка
                    крана/переходника/муфты/манжетки, организация слива
                    сушильной и стиральной машины в единую канализацию
                    (установка переходника и манжеток, доработка розеток).
                  </p>
                  <p>
                    <b>Важно:</b> услуга штробления стен не предоставляется!
                  </p>
                  <p>
                    Для удобства наших клиентов выезд специалиста может
                    осуществляться в день доставки или в любой другой срок по
                    желанию клиента.
                  </p>
                  <p>
                    Товар будет занесен на заранее подготовленное место, за
                    исключением особых случаев:
                  </p>
                  <ul>
                    <li>
                      при выполнении потребуется перестановка мебели или других
                      предметов;
                    </li>
                    <li>
                      при выполнении потребуется снятие с петель дверей в
                      помещении;
                    </li>
                    <li>
                      потребуется снятие любых частей товара с целью уменьшения
                      его габаритных размеров;
                    </li>
                    <li>
                      расстояние между стороной товара и окружающими предметами
                      и частями помещения при перемещении товара составляет
                      менее десяти сантиметров.
                    </li>
                  </ul>
                  <p>
                    При покупке стирально-сушильной колонны выбирайте подходящую
                    услугу установки: стандартную или расширенную.
                  </p>
                </div>
              </>
            ) : /*: data.data?.id === 5 ?
              <>
                <span className="delivery-modal__title">Доставка по всей России</span>
                <div className="delivery-modal__content">
                  <p>В зависимости от вашего местоположения, заказ доставит либо транспортная компания со склада Casarte, либо один из партнеров:</p>
                  <p>
                    <b>ООО «Эдил-Импорт»</b><br/>
                    Юридический адрес: 140125, Московская область, город Раменское, село Михайловская Слобода, ул. Промышленная, д. 1, стр. 1, этаж 1, помещение 105<br/>
                    ИНН 7733510051<br/>
                  </p>
                  <p>
                    <b>ООО «Компания РБТ»</b><br/>
                    Юридический адрес: 454008, г. Челябинск, ул. Производственная, 8Б, оф. 303<br/>
                    ОГРН 1027403771680<br/>
                    ИНН 7452030451<br/>
                  </p>
                </div>
              </>*/
            data.data?.id === 6 ? (
              <>
                <span className="delivery-modal__title">
                  Желаемые дата и время доставки
                </span>
                <div className="delivery-modal__content">
                  <p>
                    При оформлении заказа по Москве и МО вы выбираете удобные
                    для вас дату и время доставки из доступных интервалов:
                  </p>
                  <ul className="_no-bullet">
                    <li>10:00 – 12:00</li>
                    <li>12:00 – 14:00</li>
                    <li>14:00 – 16:00</li>
                    <li>16:00 – 18:00</li>
                    <li>18:00 – 20:00</li>
                    <li>20:00 – 22:00</li>
                  </ul>
                  <p>
                    Более точные сроки согласовываются с оператором горячей
                    линии Casarte после оформления заказа. Подробную информацию
                    по своему заказу вы можете получить по номеру{" "}
                    <a className="_bold" href="tel:88002340953">
                      8 800 234 09 53
                    </a>
                    .
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : null;
};

export default inject("store")(observer(Modal));
