import { DesktopMediaQuery, MobileMediaQuery } from '../MediaQuery';
import { MOBILE_WIDTH } from '../../common/const';
import { renderToString } from 'react-dom/server';
import './style.scss';

const Image = (props) => {
    const {desktop, mobile, title="", className=""} = props;
    const regex = /(<([^>]+)>)/gi;
    const titleStr = renderToString(title).replace(regex, "");
    
    return (
        <>
        {
            desktop && mobile ?
            <picture className={`image ${className}`}>
                <source media={`(max-width:${MOBILE_WIDTH}px)`} srcset={mobile} />
                <img srcset={desktop} src={desktop.split(', ')[0]} alt={titleStr} title={titleStr} />
            </picture>
            : desktop ?
            <DesktopMediaQuery>
                <picture className={`image ${className}`}>
                    <img srcset={desktop} src={desktop.split(', ')[0]} alt={titleStr} title={titleStr} />
                </picture>
            </DesktopMediaQuery>
            : mobile ?
            <MobileMediaQuery>
                <picture className={`image ${className}`}>
                    <img srcset={mobile} src={mobile.split(', ')[0]} alt={titleStr} title={titleStr} />
                </picture>
            </MobileMediaQuery>
            : null
        }
        </>
    );
}

export default Image;