import './style.scss';
import React, {useEffect, useState, useMemo, useRef} from 'react';
import { withRouter, Link } from 'react-router-dom';
import Features from '../Features';
import Swiper from "react-id-swiper";
import {scrollTo, disableBodyScroll, enableBodyScroll, trackGADataLayer} from "../../common/utils";
import Anim from '../Anim';
import {Button} from 'primereact/button';
import Installation from '../../assets/images/products/installation.svg';
import {OPENED, CLOSING, OPENING, CLOSED, TWEEN_TIME, MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../common/const';
import MediaQuery, {useMediaQuery} from 'react-responsive';
import {inject, observer} from "mobx-react";
import OldTechProps from '../OldTechProps';
import ProductTable from "../ProductTable";
import axios from "axios";
import pageData from "../../json/productPage.json";
import pageDataFridges from "../../json/productPageFridges.json";
import pageDataOvens from "../../json/productPageOvens.json";
import pageDataOld from "../../json/productPageOld.json";
import menuData from "../../json/catalogItems.json";
import {formatNumber, getApiPath} from "../utils";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import featuresList from "../../json/featuresList.json";
import PageContainer from "../PageContainer";

import { DoubleDrumHead, DoubleDrumContent } from './products/DoubleDrum';
import { FrenchDoorHead, FrenchDoorContent } from './products/FrenchDoor';
import { VelatoHead, VelatoContent } from './products/Velato';
import { ElettoHead, ElettoContent } from './products/Eletto';
import { TrianoHead, TrianoContent } from './products/Triano';
import { MultiSplitHead, MultiSplitContent } from './products/MultiSplit';
import { HoodCasarteHead, HoodCasarteContent } from './products/HoodCasarte';
import { StylerCasarteHead, StylerCasarteContent } from './products/StylerCasarte';
import { OvenCasarteHead, OvenCasarteContent } from './products/OvenCasarte';
import { TwinWineZoneHead, TwinWineZoneContent } from './products/TwinWineZone';
import { CoveCasarteHead, CoveCasarteContent } from './products/CoveCasarte';
import { DishwasherCasarteHead, DishwasherCasarteContent } from './products/DishwasherCasarte';
import { DoubleDrawerHead, DoubleDrawerContent } from './products/DoubleDrawer';
import { getPageMenu } from './utils';
import ProductPagePackPlane from "./components/ProductPagePackPlane";
import ProductPageSuggestPlane from "./components/ProductPageSuggestPlane";
import ProductCounter from '../ProductCounter';

const normalizeNumber = (value) => {
  return typeof value === 'string' ? Number(value.replace(/\D/g, '')) : value;
};

const swiperParams = {
  slidesPerView: 'auto',
  freeMode: true,
  spaceBetween: 8,
  freeModeSticky: true,
  threshold: 3,
  //init: true,
};

const getColorClass = (color) => {
  switch (color?.toLowerCase()) {
    case 'матовый черный':
      return 'black';
    case 'шампань':
      return 'gold';
    case 'матовый белый':
      return 'white';
    case 'голубой антрацит':
      return 'blue';
    case 'черный':
      return 'black-2';
    case 'китайские чернила':
      return 'black-3';
    default:
      return '';
  }
};

const getOldPrice = (product) => {
  if (product.discount && product.price) {
    const discount = Number(product.discount.replace(/\D/g, ''));
    const price = Number(product.price.replace(/\D/g, ''));

    if (!isNaN(discount) && !isNaN(price)) {
      return price + discount;
    }
  }
  return null;
};

const Photo = (props) => {
  return (
    <div className="product-page__props-left__swiper__item">
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <img alt="" src={props.urlSet.m}/>
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <img alt="" src={props.urlSet.d}/>
      </MediaQuery>
    </div>
  )
}

const headMenuStatusDefault = {
  preText: '',
  price: '',
  isProductChecked: false,
};

const ProductPage = ({ store, match }) => {
  const isConditioners = match.path.includes('air-conditioners');
  const isOvens = match.path.includes('oven-casarte');
  const assetsPathName = match.path.split('/')[3];
  const productName = assetsPathName;
  const mvpPage = Boolean(productName === 'fridge-in' || productName === 'freezer-in' || productName === 'washers-aroma-capsules' || productName === 'stylers-aroma-capsules');

  const propsTitleRef = useRef(null);
  const propsTableRef = useRef(null);
  const refVideo = useRef();

  const galleryRef = useRef(null);
  const [galleryWrapWidth, setGalleryWrapWidth] = useState(1440);
  const rightRef = useRef(null);
  const [confStatus, setConfStatus] = useState('');

  const [documentTitle, setDocumentTitle] = useState(document.title)
  const [pageContent, setPageContent] = useState(null);
  const [tableStatus, setTableStatus] = useState(CLOSED);
  const isDesktopMediaQuery = useMediaQuery({minWidth: MOBILE_WIDTH_1});
  const [preload, setPreload] = useState(true);
  const [menuStatus, setMenuStatus] = useState(false);
  const [selectedColor, setSelectedColor] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  const [selectedSpecs, setSelectedSpecs] = useState();
  const [headMenuStatus, setHeadMenuStatus] = useState(headMenuStatusDefault);
  const [fixedBtnStatus, setFixedBtnStatus] = useState(false);
  const [fixedMenuStatus, setFixedMenuStatus] = useState('');
  const [categoryPath, setCategoryPath] = useState();
  const [responseError, setResponseError] = useState();

  const pageMenu = getPageMenu(productName);

  const addToBasket = () => {
    store.addToBasket(selectedProduct?.id).then((response) => {
      if (response?.data?.status !== 'BASKET_ADD_SUCCESS') {
        console.error('Error: addToBasket has failed');
      }
    });
  };

  const productUrl = useMemo(() => {
    return match.params.MODEL;
  }, [match.params]);

  const disabledBuyLabel = useMemo(() => {
    if (selectedProduct?.comingSoon || pageContent?.productList?.some(c => c.comingSoon)) {
      return 'СКОРО В ПРОДАЖЕ'
    }
    return 'НЕТ В НАЛИЧИИ'
  }, [pageContent, selectedProduct])

  const handleShowTableClick = () => {
    if (isDesktopMediaQuery) {
      scrollTo('product-page__props-table__modal');
    } else {
      if (tableStatus === CLOSED) {
        disableBodyScroll();
        setTableStatus(OPENING);
        setTimeout(() => {
          setTableStatus(OPENED);
        }, TWEEN_TIME);
      } else if (tableStatus === OPENED) {
        enableBodyScroll();
        setTableStatus(CLOSING);
        setTimeout(() => {
          setTableStatus(CLOSED);
        }, TWEEN_TIME);
      }
    }
    const product = selectedProduct || (pageContent?.productList && pageContent?.productList[0]) || {};

    trackGADataLayer(
      "view_item",
      {
        currency: "RUB",
        value: 100000.00, //полная стоимость представленного на карточке товара с учетом скидки
        items: [
          {
            item_id: String(product.id), //id товара
            item_name: product.title, // наименование товара
            index: 0, //порядковый номер в выдаче на странице каталога
            item_brand: product.brand, // brand товара
            item_category: product.category, //категория товара
            item_category2: product.category2,
            item_variant: product.color, //вариант товара, если есть
            price: normalizeNumber(product.price), // цена
            discount: normalizeNumber(product.discount), // скидка
            quantity: 1 // количество за цену выше
          },
        ]
      }
    );
  }

  const sliderImages = useMemo(() => {
    if (selectedProduct?.images) {
      return selectedProduct?.images;
    }
    if (selectedColor) {
      return pageContent?.productList.find((item) => item.available && item.color === selectedColor).images;
    }
    return (pageContent?.productList && pageContent?.productList[0]?.images) || [];
  }, [pageContent, selectedProduct, selectedColor]);

  const swiperParamsPhoto = {
    spaceBetween: 0,
    slidesPerView: 1,
    //speed: 350,
    navigation: {
      nextEl: '.product-page__props-left__swiper__arr._next',
      prevEl: '.product-page__props-left__swiper__arr._prev',
    },
    pagination: {
      el: '.product-page__props-left__swiper__pagination',
      clickable: true,
      dynamicBullets: (sliderImages.length > 5),
      dynamicMainBullets: 1
    },
    uniqueNavElements: false,
    // rebuildOnUpdate: true,
  };

  // Формирует цвета продукта
  // @TODO: обработать случаи когда у продукта несколько опций и только один цвет
  // в теории цвет не должен выводиться, т.к. нет возможности его выбора для пользователя
  const colorList = useMemo(() => {
    if (pageContent?.productList) {
      const colors = pageContent?.productList.reduce((acc, item) => {
        if (!acc[item.color]) {
          acc[item.color] = item.available;
        }
        return acc;
      }, {});

      return Object.entries(colors).map(([label, available]) => ({
        label,
        available,
      }));
    }
    return [];
  }, [pageContent]);

  // Формирует опции продукта
  // @TODO: обработать случаи разных опций в рамках одного цвета
  // сейчас опции выводятся только для кондиционеров с заголовком "Выберите мощность"
  const productOptions = useMemo(() => {
    // Фильтрует по выбранному цвету
    if (selectedColor) {
      return pageContent?.productList?.filter((product) => product.color === selectedColor) || [];
    }
    // Если цвет не выбран, фильтрует по первому доступному цвету
    const { color } = pageContent?.productList?.find((product) => product.available) || {};
    // Выводит опции как не доступные к выбору пока пользователь не выберет цвет
    return pageContent?.productList?.reduce((acc, product) => {
      if (product.color === color) {
        acc.push({
          ...product,
          available: false,
        });
      }
      return acc;
    }, []);
  }, [pageContent, selectedColor]);

  const productSpecsTabs = useMemo(() => {
    if (pageContent?.productList) {
      return pageContent?.productList.reduce((acc, item) => {
        if (!acc.find((title) => title === item.title)) {
          acc.push(item.title);
        }
        return acc;
      }, []);
    }
    return [];
  }, [pageContent]);

  const productSpecs = useMemo(() => {
    if (pageContent?.productList) {
      const { specificationList = [] } = pageContent?.productList.find((item) => item.title === selectedSpecs) || {};
      return specificationList;
    }
    return [];
  }, [pageContent, selectedSpecs]);

  const isBuyActive = useMemo(() => {
    if (pageContent?.productList?.length) {
      return Boolean(pageContent?.productList.find((product) => product.available && product.itemAvailableQuantity));
    }
    return false;
  }, [pageContent]);

  // Позиционирует слайдер с изображениями товара
  useEffect(() => {
    const y = 100;

    const onScroll = e => {
      if(!isDesktopMediaQuery){
        return;
      }

      if(!galleryRef.current || !rightRef.current){
        return;
      }

      const cw = window.innerWidth > 1216 ? 579 : 435;
      const hh =
        window.innerWidth > 1381 ? 88 :
        window.innerWidth > 1216 ? 72 :
        66;
      const ww = Math.min(1440, window.innerWidth - 15);
      const gh = window.innerHeight - 36 - hh;
      const gw = ww - cw;
      const galleryH = galleryRef.current.getBoundingClientRect().height;
      const rightH = rightRef.current.getBoundingClientRect().height;
      const rightOT = rightRef.current.getBoundingClientRect().top;
      const rightOB = rightRef.current.getBoundingClientRect().bottom;
      const deltaH = Math.max(gw - gh, 0);

      setGalleryWrapWidth(ww - deltaH);

      if(rightOT <= y && rightH > galleryH){
        if(rightOB < (y + galleryH)){
          setConfStatus('_flex');
        }
        else{
          setConfStatus('_fixed');
        }
      }
      else{
        setConfStatus('');
      }
    };

    onScroll();
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onScroll);
    };
  }, [isDesktopMediaQuery]);

  // Отправляет данные в аналитику при скролле страницы
  useEffect(() => {
    let propsTitleShown = false;
    let propsTableShown = false;

    const onScroll = e => {
      const wh = window.innerHeight;
      const wh05 = wh * 0.5;// при домотке до центра экрана

      if(propsTitleRef?.current){
        const otTitle = propsTitleRef?.current.getBoundingClientRect().top;

        if((otTitle < wh05 && !propsTitleShown)){
          propsTitleShown = true;

          if (selectedProduct?.itemAvailableQuantity) {
            setHeadMenuStatus({
              preText: '',
              price: formatNumber(selectedProduct?.price),
              isProductChecked: true,
            });
          }

          trackGADataLayer(
            "view_item_list",
            {
              item_list_name: match.path, //{{Page Path}}
              items: productOptions?.map((product, index) => ({
                item_id: String(product.id), //id товара
                item_name: product.title, // наименование товара
                index, //порядковый номер в выдаче на странице каталога
                item_brand: product.brand, // brand товара
                item_category: product.category, //категория товара
                item_category2: product.category2,
                item_list_name: match.path, // {{Page Path}}
                item_variant: product.color, //вариант товара, если есть
                price: normalizeNumber(product.price), // цена
                discount: normalizeNumber(product.discount), // скидка
                quantity: 1 // количество за цену выше
              })),
            }
          );
        }
      }

      if(propsTableRef?.current){
        const otTable = propsTableRef?.current.getBoundingClientRect().top;

        if((otTable < wh05 && !propsTableShown)){
          propsTableShown = true;

          const product = selectedProduct || (pageContent?.productList && pageContent?.productList[0]) || {};

          trackGADataLayer(
            "view_item",
            {
              currency: "RUB",
              value: normalizeNumber(product.price), //полная стоимость представленного на карточке товара с учетом скидки
              items: [
                {
                  item_id: String(product.id), //id товара
                  item_name: product.title, // наименование товара
                  index: 0, //порядковый номер в выдаче на странице каталога
                  item_brand: product.brand, // brand товара
                  item_category: product.category, //категория товара
                  item_category2: product.category2,
                  item_variant: product.color, //вариант товара, если есть
                  price: normalizeNumber(product.price), // цена
                  discount: normalizeNumber(product.discount), // скидка
                  quantity: 1 // количество за цену выше
                },
              ]
            }
          );
        }
      }

      if(propsTitleShown && propsTableShown){
        window.removeEventListener("scroll", onScroll);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [match.path, pageContent, productOptions, selectedProduct]);

  // Устанавливает выбранный цвет и продукт по-умолчанию
  useEffect(() => {
    if (pageContent?.productList?.length && !selectedProduct) {
      let product;

      // Если определена модель в адресе страницы, ищет ее в данных
      if (productUrl) {
        product = pageContent?.productList?.find((item) => item.url === productUrl && item.itemAvailableQuantity);
      }
      // Если по предыдущим условиям модель не нашлась, выбирает первую доступную модель
      // UPD: От выбора модели по-умолчанию отказались в DHAIONLN-4308
      // if (!product) {
      //   product = pageContent?.productList?.find((item) => item.available && item.color === colorList[0]?.label);
      // }
      // Выбирает модель, если она всего одна или не относится к кондиционерам
      if (!product && (pageContent?.productList?.length === 1 || !isConditioners)) {
        product = pageContent?.productList?.find((item) => item.available);
      } else if (!product) {
        // Иначе устанавливает первый доступный цвет
        const color = colorList.find((item) => item.available);

        if (color?.label) {
          setSelectedColor(color.label);
        }
      }
      // Устанавливает значения выбранной модели
      if (product) {
        setSelectedColor(product.color);
        setSelectedProduct(product);
        setSelectedSpecs(product.title);
      } else if (productUrl && categoryPath) {
        // Убирает урл продукта из адресной строки, если он не был найден
        const { origin, search } = window.location;
        const url = `${origin}${categoryPath}/${search}`;

        window.history.replaceState(null, documentTitle, url);
      }
    }
  }, [pageContent, selectedProduct, productUrl, colorList, categoryPath, documentTitle, isConditioners]);
  // Устанавливает заголовок страницы и первоначальные состояния приложения
  useEffect(() => {
    document.title = documentTitle;

    setHeadMenuStatus({
      preText: pageContent?.productList?.length > 1 && !isOvens ? 'от' : '',
      price: formatNumber(pageContent?.minPrice ? pageContent.minPrice : 0),
      isProductChecked: false,
    })

    setTimeout(() => {
      setPreload(false);
    }, 500);

  }, [pageContent, documentTitle, preload]);

  useEffect(() => {
    let techPropsShown = false;
    let timer;

    const onScroll = e => {
      const wh = window.innerHeight;
      const wh05 = wh * 0.5;// при домотке до центра экрана

      if (window.scrollY >= wh && !menuStatus) {
        setMenuStatus(true);
      }
      if (window.scrollY < wh && menuStatus) {
        setMenuStatus(false);
      }

      if(productName === 'multi-split'){
        const otTitle = document.querySelector(`.product-page__old-props-title`).getBoundingClientRect().top;

        if(otTitle > wh05){
          if(techPropsShown){
            techPropsShown = false;
            setFixedBtnStatus(false);
            setFixedMenuStatus('_show2');
            clearTimeout(timer);
            timer = setTimeout(() => {
              setFixedMenuStatus('_shown2');
            }, 200);
          }
        }
        else{
          if(!techPropsShown){
            techPropsShown = true;
            setFixedBtnStatus(true);
            setFixedMenuStatus('_hide2');
            clearTimeout(timer);
            timer = setTimeout(() => {
              setFixedMenuStatus('_hidden2');
            }, 200);
          }
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [menuStatus, productName]);

  useEffect(() => {
    document.body.classList.add('_catalog');

    return () => document.body.classList.remove('_catalog');
  }, []);

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path).replace(':MODEL/', ''),
      method: 'get',
    }).then((response) => {
      if(response.data.seoData?.pageTitle){
        setDocumentTitle(response.data.seoData.pageTitle)
      }
      setPageContent(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      let pageContentData

      switch (productName) {
        case 'french-door':
          pageContentData = {
            featureList: featuresList.main,
            ...pageDataFridges,
          };
          break
        case 'oven-casarte':
          pageContentData = {
            featureList: featuresList.main,
            ...pageDataOvens,
          }
          break
        case 'multi-split':
          pageContentData = {
            featureList: featuresList.main,
            ...pageDataOld,
          }
          break
        default:
          pageContentData = {
            featureList: featuresList.main,
            ...pageData
          }
      }
      setPageContent(pageContentData);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    }).finally(() => {
      setCategoryPath(match.path.split('/').slice(0, 4).join('/'));
    });
    return () => {
      setPageContent(null);
      setSelectedColor(null);
      setSelectedProduct(null);
      setSelectedSpecs(null);
      setCategoryPath(null);
      setHeadMenuStatus(headMenuStatusDefault);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  // Устанавливает ссылку страницы для выбранного товара
  useEffect(() => {
    if (categoryPath && selectedProduct?.url) {
      const { origin, search } = window.location;
      const url = `${origin}${categoryPath}/${selectedProduct?.url}/${search}`;

      window.history.replaceState(null, documentTitle, url);
    }
  }, [categoryPath, selectedProduct, documentTitle]);

  // Устанавливает данные о состоянии продуктов в корзине
  useEffect(() => {
    if (pageContent?.productList) {
      store.setProductsMap(pageContent?.productList);
    }
  }, [pageContent, store]);

  useEffect(() => {
    if(refVideo.current) {
      refVideo.current.defaultMuted = true;
      refVideo.current.muted = true;
    }
  }, [refVideo]);

  const productCartState = useMemo(() => {
    if (store.productsMap && selectedProduct) {
      return store.productsMap[selectedProduct?.id];
    }
  }, [store.productsMap, selectedProduct]);

  const productMiniSpecsTop = useMemo(() => {
    if ((pageContent?.productList?.length > 1 && isConditioners && !selectedProduct) || !pageContent?.productList?.length) {
      return null
    }

    const specs = <>
      <div className="product-page__props-right__table__tr">
        <span className="product-page__props-right__table__param">Модель:</span>
        <span className="product-page__props-right__table__spacer"/>
        <span className="product-page__props-right__table__value">{selectedProduct?.code || pageContent?.productList[0]?.code}</span>
      </div>
      <div className="product-page__props-right__table__tr">
        <span className="product-page__props-right__table__param">Цвет:</span>
        <span className="product-page__props-right__table__spacer"/>
        <span className="product-page__props-right__table__value">{selectedProduct?.color || pageContent?.productList[0]?.color}</span>
      </div>
      {(selectedProduct || pageContent?.productList[0])?.miniSpecificationList?.filter((option) => option.value).map((option, index) =>
        <div key={index} className="product-page__props-right__table__tr">
          <span className="product-page__props-right__table__param">{option.title}:</span>
          <span className="product-page__props-right__table__spacer"/>
          <span className="product-page__props-right__table__value">{option.value}</span>
        </div>
      )}
    </>

    if (pageContent?.productList?.length > 1 && isConditioners) {
      return specs
    }

    return <>
      <span className="product-page__props-right__title">Характеристики:</span>
      <div className="product-page__props-right__table">
        {specs}
      </div>
    </>
  }, [pageContent, selectedProduct, isConditioners])

  const productMiniSpecsBottom = useMemo(() => {
    if (!selectedProduct || !pageContent?.productList?.length) {
      return null
    }

    const specs = <>
      {Boolean(selectedProduct?.discount) &&
      <div className="product-page__props-right__table__tr">
        <span className="product-page__props-right__table__param">Скидка:</span>
        <span className="product-page__props-right__table__spacer"/>
        <span className="product-page__props-right__table__value">-{selectedProduct?.discount} <span
          className='rub'>P</span></span>
      </div>}
      <div className="product-page__props-right__table__tr">
        <span className="product-page__props-right__table__param">Итоговая цена:</span>
        <span className="product-page__props-right__table__spacer"/>
        <span className="product-page__props-right__table__value _big">{selectedProduct?.price} <span
          className='rub'>P</span></span>
      </div>
    </>

    if (pageContent?.productList?.length > 1 && isConditioners) {
      return <>
        <span className="product-page__props-right__title">Вы выбрали:</span>
        <div className="product-page__props-right__table">
          {productMiniSpecsTop}
          {specs}
        </div>
      </>
    }

    return <>
      <div className="product-page__props-right__table">
        {specs}
      </div>
    </>
  }, [pageContent, selectedProduct, isConditioners, productMiniSpecsTop])

  return (
    <PageContainer responseError={responseError} data={pageContent}>
    <div className={`page product-page ${assetsPathName} ${preload ? 'preload' : ''} ${mvpPage ? ' _mvp' : ''}`}>
      {!mvpPage && <div className="product-page__head">
        {
          // ШАПКА ->
            productName === 'double-drum' ?
            <DoubleDrumHead isBuyActive={isBuyActive} assetsPathName={assetsPathName} />
          : productName === 'french-door' ?
            <FrenchDoorHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'velato' ?
            <VelatoHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'eletto' ?
            <ElettoHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'triano' ?
            <TrianoHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'multi-split' ?
            <MultiSplitHead productName={productName} pageContent={pageContent} />
          : productName === 'hood-casarte' ?
            <HoodCasarteHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'styler-casarte' ?
            <StylerCasarteHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'oven-casarte' ?
            <OvenCasarteHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'twin-wine-zone' ?
            <TwinWineZoneHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'cove-casarte' ?
            <CoveCasarteHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'dishwasher-casarte' ?
            <DishwasherCasarteHead isBuyActive={isBuyActive} productName={productName} />
          : productName === 'double-drawer' ?
            <DoubleDrawerHead isBuyActive={isBuyActive} productName={productName} />
          : null
          // -> ШАПКА
        }
      </div>}

      {!mvpPage && <div className="product-page__menu">
        <div className="product-page__menu-wrap">
          <Swiper {...swiperParams} activeSlideKey="1">
            {pageMenu}
          </Swiper>
        </div>
      </div>}

      {isBuyActive && <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className={`product-page__menu fixed ${menuStatus ? 'active' : ''} ${fixedMenuStatus}`}>
          <div className="wrap">
            {/* <div className="product-page__menu-wrap m-view">
              <Swiper {...swiperParams} activeSlideKey="1">
                {pageMenu}
              </Swiper>
            </div> */}
            <div className="product-page__menu-wrap d-view">
              <span className="product-page__menu-logo">{pageContent?.title}</span>
              {/* <div>
                {pageMenu}
              </div> */}
              <div className="product-page__menu-cta">
                {Boolean(headMenuStatus.price) && productName !== 'multi-split' &&
                  <span className="product-page__menu-price">
                    {headMenuStatus.preText} <span dangerouslySetInnerHTML={{__html: headMenuStatus.price}} /> <span className='rub'>P</span>
                  </span>
                }
                {productCartState?.itemBasketCount ?
                  <Link to="/personal/cart" className="product-page__menu__cart-btn">
                    <div>
                      <span className="product-page__menu__cart-btn__title">в корзине</span>
                      <span className="product-page__menu__cart-btn__label">Перейти</span>
                    </div>
                  </Link>
                  : headMenuStatus.isProductChecked ?
                    <Button
                      className={`p-button-primary p-button-white ${productCartState?.isBasketLoading ? 'p-loader' : ''}`}
                      label="В корзину"
                      onClick={addToBasket}
                    />
                    :
                    <Button className="p-button-primary p-button-white min-w" label="Купить" onClick={() => {
                      scrollTo('tech-id');
                    }} />
                }
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>}

      {isBuyActive && <MediaQuery maxWidth={MOBILE_WIDTH}>
        {
        // для аромакапул вместо кнопки - кнопка-счетчик
        (productName === 'washers-aroma-capsules' || productName === 'stylers-aroma-capsules') 
        ? <ProductCounter className={`_primary fixed-buy ${menuStatus ? '_show' : ''}`} valueText="В корзине 0 шт." labelText="В корзину" />
        : productCartState?.itemBasketCount ?
          <Link to="/personal/cart" className={`product-page__menu__cart-btn fixed-buy ${menuStatus ? '_show' : ''}`}>
            <div>
              <span className="product-page__menu__cart-btn__title">в корзине</span>
              <span className="product-page__menu__cart-btn__label">Перейти</span>
            </div>
          </Link>
          : headMenuStatus.isProductChecked ?
            <Button
              className={`p-button-primary p-button-white fixed-buy ${menuStatus ? '_show' : ''} ${productCartState?.isBasketLoading ? 'p-loader' : ''}`}
              label="В корзину"
              onClick={addToBasket}
            />
            :
            <Button className={`p-button-primary p-button-white fixed-buy ${menuStatus ? '_show' : ''} ${fixedBtnStatus ? '_hide' : ''}`} label="Купить" onClick={() => {
              scrollTo('tech-id');
            }} />
        }
      </MediaQuery>}

      {
       // РИЧ-КОНТЕНТ ->
        productName === 'velato' ?
        <VelatoContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'eletto' ?
        <ElettoContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'triano' ?
        <TrianoContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'multi-split' ?
        <MultiSplitContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'double-drum' ?
        <DoubleDrumContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'french-door' ?
        <FrenchDoorContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'hood-casarte' ?
        <HoodCasarteContent assetsPathName={assetsPathName} />
      : productName === 'styler-casarte' ?
        <StylerCasarteContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'oven-casarte' ?
        <OvenCasarteContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'twin-wine-zone' ?
        <TwinWineZoneContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'cove-casarte' ?
        <CoveCasarteContent assetsPathName={assetsPathName} />
      : productName === 'dishwasher-casarte' ?
        <DishwasherCasarteContent assetsPathName={assetsPathName} productName={productName} />
      : productName === 'double-drawer' ?
        <DoubleDrawerContent assetsPathName={assetsPathName} productName={productName} />
      : null
      // -> РИЧ-КОНТЕНТ
      }

      {productName === 'multi-split' ?
        <OldTechProps pageContent={pageContent} match={match} />
        :
        <Anim className="props-anim tech-id">
          <div className={`product-page__props`}>
            {mvpPage && <div className="wrap"><div className="product-page__mvp-spacer" /></div>}
            <div className="wrap">
              <h3 ref={propsTitleRef} className="product-page__props-title" dangerouslySetInnerHTML={{__html: pageContent?.slogan || ''}} />
            </div>
            {(productName === 'washers-aroma-capsules' || productName === 'stylers-aroma-capsules') && 
              <p  className="product-page__props-subline">
                Утонченный цветочный аромат пропитает волокна ткани и&nbsp;подарит новые эмоции от&nbsp;их использования. Нежное 
                дыхание уюта и&nbsp;чистоты с&nbsp;применением новейших технологий парфюмерии. Концентрат содержит 
                дезодорирующий состав на&nbsp;основе натуральных ароматических масел. 
              </p>
            }
            <div className="wrap" style={{ maxWidth: galleryWrapWidth+'px'}}>
              <div className={`product-page__props-conf ${confStatus}`}>
                <div className="product-page__props-left">
                  <div className="product-page__props-left__gallery__fixed-wrap">
                    <div className="product-page__props-left__gallery__outer-wrap" style={{ maxWidth: galleryWrapWidth+'px'}}>
                      <div className="product-page__props-left__gallery__inner-wrap">
                        {Boolean(sliderImages?.length) && <div ref={galleryRef} className="product-page__props-left__gallery">
                          <div className="product-page__props-left__swiper">
                            <Swiper {...swiperParamsPhoto} shouldSwiperUpdate>
                              {sliderImages.map((item, i) =>
                                <div key={i}>
                                  <Photo urlSet={item}/>
                                </div>
                              )}
                            </Swiper>
                            {/*<div className="product-page__props-left__swiper__nav">
                              <div className="product-page__props-left__swiper__arr _prev swiper-button-disabled"/>
                              <div className="product-page__props-left__swiper__pagination"/>
                              <div className="product-page__props-left__swiper__arr _next swiper-button-disabled"/>
                            </div>*/}
                          </div>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
                <div ref={rightRef} className="product-page__props-right">
                  {pageContent?.productList?.length === 1
                    ? productMiniSpecsTop
                    : <>
                      <span className="product-page__props-right__title">Выберите цвет:</span>
                      <div className="product-page__props-colors">
                        {colorList?.map((item, index) =>
                          <div
                            key={index}
                            className={`product-page__props-colors__item ${getColorClass(item.label)}
                            ${item.label && item.label?.toLowerCase() === selectedColor?.toLowerCase() ? '_active' : ''}
                            ${!item.available ? '_disabled' : ''}`}
                            onClick={() => {
                              if (selectedProduct) {
                                // Ищем продукт в наличии, соответствующий выбранному цвету и названию предыдущего выбранного продукта
                                let product = pageContent?.productList?.find((product) => product.available && product.color === item.label && product.title === selectedProduct.title);
                                // Если по предыдущему условию продукт не найден, ищем снова в наличии, первый доступный в выбранном цвете
                                product = product || pageContent?.productList?.find((product) => product.available && product.color === item.label);

                                setSelectedColor(item.label);
                                setSelectedProduct(product);
                                setSelectedSpecs(product.title);

                                if (product.itemAvailableQuantity) {
                                  setHeadMenuStatus({
                                    preText: '',
                                    price: formatNumber(product.price),
                                    isProductChecked: true,
                                  });
                                } else {
                                  setHeadMenuStatus(headMenuStatusDefault);
                                }
                              } else {
                                setSelectedColor(item.label);
                              }
                            }}/>
                        )}
                      </div>
                      <span className="product-page__props-colors__title">{selectedColor}</span>
                      <div className="product-page__props-right__hr"/>
                      {pageContent?.productList?.length > 1 && isConditioners
                        ? <>
                          <span className="product-page__props-right__title _disabled">Выберите мощность:</span>
                          <div className="product-page__props-right__tabs">
                            {productOptions?.map((item, index) =>
                              <div
                                key={index}
                                className={`product-page__props-right__tab ${selectedProduct?.title?.toLowerCase() === item.title.toLowerCase() ? '_active' : ''}
                              ${!item?.available || !selectedColor ? '_disabled' : ''}`}
                                onClick={() => {
                                  setSelectedProduct(item);
                                  setSelectedSpecs(item.title);

                                  if (item.itemAvailableQuantity) {
                                    setHeadMenuStatus({
                                      preText: '',
                                      price: formatNumber(item.price),
                                      isProductChecked: true,
                                    });
                                  } else {
                                    setHeadMenuStatus(headMenuStatusDefault);
                                  }
                                }}
                              >
                                <div className="product-page__props-right__tab__left">
                                  <span className="product-page__props-right__tab__title">{item.title}</span>
                                </div>
                                <div className="product-page__props-right__tab__right">
                                  <span className="product-page__props-right__tab__code">{item.code}</span>
                                  {item.optionsList && item.optionsList.map((option, index) =>
                                      <span
                                        key={index}
                                        className="product-page__props-right__tab__text">
                                    {option.title} {option.value}
                                  </span>
                                  )}
                                  <span className="product-page__props-right__tab__price">
                                  {Boolean(item.discount) &&
                                  <>
                                    <span className="_old">
                                      <span dangerouslySetInnerHTML={{__html: getOldPrice(item)}} />
                                      <span className='rub'>P</span>
                                    </span>&nbsp;
                                  </>
                                  }
                                  {item.price} <span className='rub'>P</span>
                                </span>
                                  {item.status &&
                                  <span className="product-page__props-right__tab__status">{item.status}</span>
                                  }
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                        : productMiniSpecsTop}
                    </>
                  }
                  {/* {(productName === 'washers-aroma-capsules' || productName === 'stylers-aroma-capsules') && 
                    <ProductPagePackPlane 
                      title={<>Выберите упаковку:</>}
                    />
                  } */}
                  {//(productName === 'double-drum' || productName === 'styler-casarte') && 
                     (productName === 'double-drum') && 
                    <ProductPageSuggestPlane 
                      productName={productName}
                      title={<>Добавьте аромакапсулы</>}
                    />
                  }
                  <div className="product-page__props-right__pl">
                    <span className="product-page__props-right__pl__title">Нужна помощь с выбором?</span>
                    <span className="product-page__props-right__pl__text">Вы можете проконсультироваться со специалистом по телефону <a
                      href="tel:8(800)234-09-53">8(800)234-09-53</a></span>
                  </div>
                  {productName === 'double-drum' ?
                    <div className="product-page__props-right__install-pl">
                      <div>
                        <span className="product-page__props-right__pl__title">Бесплатно установим и&nbsp;подключим</span>
                        <Link to="/installation" className="product-page__props-right__install-pl__link">Узнайте больше</Link>
                      </div>
                      <img className="product-page__props-right__install-pl__pic" src={Installation} alt="Бесплатно установим и подключим" />
                    </div>
                  : null}
                  {
                    selectedProduct
                    ? <>
                      <span className="product-page__props-right__all" onClick={handleShowTableClick}>Смотреть все характеристики</span>
                      <div className="product-page__props-right__hr"/>
                      {productMiniSpecsBottom}
                      {isBuyActive && selectedProduct?.itemAvailableQuantity
                        ? <MediaQuery minWidth={MOBILE_WIDTH_1}>
                          {
                            // для аромакапул вместо кнопки - кнопка-счетчик
                            (productName === 'washers-aroma-capsules' || productName === 'stylers-aroma-capsules') 
                            ? <ProductCounter className="_primary" valueText="В корзине 0 шт." labelText="В корзину" />
                            : productCartState?.itemBasketCount
                            ? <Link
                              to={'/personal/cart'}
                              className="p-button p-component p-button-primary p-button-black"
                              style={{'justifyContent': 'center'}}
                            >В корзине</Link>
                            : <Button
                              className={`p-button-primary p-button-white ${productCartState?.isBasketLoading ? 'p-loader' : ''}`}
                              label="В корзину"
                              onClick={addToBasket}
                            />
                            }
                        </MediaQuery>
                        : <Button className="p-button-primary p-button-transparent _disabled" label={disabledBuyLabel} />
                      }
                    </>
                    : !isBuyActive && <>
                      <div className="product-page__props-right__hr"/>
                      <Button className="p-button-primary p-button-transparent _disabled" label={disabledBuyLabel}  />
                    </>
                  }
                  
                </div>
              </div>
            </div>
            <div ref={propsTableRef} className={`product-page__props-table__modal _${tableStatus}`}>
              <div className="product-page__props-table__outer-wrap">
                <div className="product-page__props-table__inner-wrap">
                  <div className="product-page__props-table__close" onClick={handleShowTableClick}/>
                  <div className="product-page__props-wrap">
                    {Boolean(productSpecsTabs?.length > 1) && <div className="product-page__props-tabs">
                      {productSpecsTabs?.map((item, index) =>
                        <div key={index}
                             className={`product-page__props-tab ${selectedSpecs === item ? `active` : ``}`}
                             onClick={() => setSelectedSpecs(item)}>
                          <span>{item}</span>
                        </div>
                      )}
                    </div>}
                    <div className={`product-page__props-table-line ${productName === 'double-drum' || productName === 'french-door' || productName === 'twin-wine-zone' || productName === 'oven-casarte' || productName === 'hood-casarte' || productName === 'styler-casarte' ? '_fix-space' : ''}`}/>
                    {productSpecs?.map((item, index) =>
                      <ProductTable key={index} data={item} open={index === 0}/>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Anim>
      }

      <Features featuresList={pageContent?.featureList}/>

    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(ProductPage)));
