import {decorate, observable, action} from "mobx"
import {OPENED, CLOSING, OPENING} from './const';
// import getAppConst from '../components/utils/get-app-const';
import HaieronlineBridge from 'haieronline-bridge';
import {updateBasketDataLayer} from "./utils";

const initialModals = {
  restorePassword: null,
  clearCart: null,
  partnerCart: null,
  contact: null,
  auth: null,
  register: null,
  changePassword: null,
  video: null,
  service: null,
  confirm: null
};

const isDev = process.env.NODE_ENV === 'development';

class Store {
  isDev = isDev;
  bridge = new HaieronlineBridge();
  modal = initialModals;
  loading = {};
  appConst = {
    isAuth: true,
    dataTermsLink: '',
    usageTermsLink: '',
    cookieLink: '',
    cookieText:'',
    recaptchaSiteKey: '',
    eyezonBusinessId: '',
    eyezonMode: '',
  };
  creditPlan = null;
  plane = null;
  catalogItems = [];
  cartData = null;
  textPageContent = null;
  // orderComplete = null;
  feedbackForm = null;
  serviceForm = null;
  header = null;
  footer = null;
  basketCount = 0;
  mainPage = {
    catalogList: [
      {
        title: '',
        img: '1.jpg',
        path: ''
      }
    ]
  }
  isLoaded = false;
  sidebar = {};
  productsMap = {};
  pointsAmount = null;
  constructor() {
    // Object.assign(this, getAppConst());

    // @TODO: отрефакторить и удалить
    window.store = this;
  }

  setAppConst(value) {
    this.appConst = value;
  }

  // Плашки на продуктовой
  setPlane(m) {
    this.plane = m;
  }

  // Оплата в кредит: выбранный кредитный план
  setCreditPlan(value) {
    this.creditPlan = value;
  }

  /**
   * Устанавливает состояние модалки
   * @param { String } name
   * @param { Object | Undefined } data
   */
  setModal(name, data = {}) {
    // Открытие
    if (data && !this.modal[name]) {
      this.closeModals();

      this.modal = {
        ...this.modal,
        [name]: {data, status: OPENING},
      }

      setTimeout(() => {
        this.modal = {
          ...this.modal,
          [name]: {data, status: OPENED},
        };
      }, 400);
      // Закрытие
    } else if (!data && this.modal[name]) {
      this.modal = {
        ...this.modal,
        [name]: {data, status: CLOSING},
      }

      setTimeout(() => {
        this.modal = {
          ...this.modal,
          [name]: null,
        };
      }, 400);
      // Обновление данных
    } else if (data && this.modal[name]) {
      this.modal = {
        ...this.modal,
        [name]: {data, status: OPENED},
      };
    }
  }

  /**
   * Закрывает все открытые модалки
   */
  closeModals() {
    const openModals = Object.entries(this.modal);

    if (openModals.length) {
      openModals.forEach(([name, data]) => {
        if (data) {
          this.modal = {
            ...this.modal,
            [name]: {data, status: CLOSING},
          }

          setTimeout(() => {
            this.modal = {
              ...this.modal,
              [name]: null,
            };
          }, 400);
        }
      });
    }
  }

  // Каталог товаров
  setCatalogItems(data) {
    this.catalogItems = data;
  }

  // Контент текстовой страниц
  setTextPageContent(data) {
    this.textPageContent = data;
  }

  setCartData(data) {
    this.cartData = data;
  }

  // Страница оформленного заказа
  // setOrderComplete(data) {
  //   this.orderComplete = data;
  // }

  // Форма обратной связи
  setFeedbackForm(data) {
    this.feedbackForm = data;
  }

  // Форма заявки на сервис
  setServiceForm(data) {
    this.serviceForm = data;
  }

  // Шапка
  setHeader(data) {
    this.header = data;
    this.basketCount = data?.basketCount || 0;
  }

  // Футер
  setFooter(data) {
    this.footer = data;
  }

  // Количество товаров в корзине
  setBasketCount(value) {
    this.basketCount = value;
  }

  // Устанавливает данные о состоянии продуктов в корзине
  setProductsMap(items) {
    this.productsMap = items.reduce((acc, item) => {
      const id = String(item.productId || item.id);

      if (!id) {
        acc[id] = {
          id,
          // itemCount: Number(item.itemBasketCount) || 0,
          itemBasketCount: Number(item.itemBasketCount) || 0,
          itemAvailableQuantity: Number(item.itemAvailableQuantity) || 0,
          // isProductDiscontinued: item.isProductDiscontinued || false,
          isBasketLoading: false,
        };
      }
      return acc;
    }, { ...this.productsMap });
  }

  // Устанавливает данные о количестве переданного продукта в корзине
  setProductData(data) {
    if (data) {
      const id = String(data.productId || data.id);

      this.productsMap = {
        ...this.productsMap,
        [id]: {
          ...this.productsMap[id],
          // itemCount: Number(data.itemBasketCount) || 0,
          itemBasketCount: Number(data.itemBasketCount) || 0,
          itemAvailableQuantity: Number(data.maxCount) || 0,
        },
      };
    }
  }

  // Устанавливает состояние загрузки для указанного товара
  setLoading(id, isBasketLoading) {
    this.productsMap = {
      ...this.productsMap,
      [id]: {
        ...this.productsMap[id],
        isBasketLoading,
      },
    };
  }

  // Получает данные продукта в переданном массиве
  getProductData(id, products = []) {
    return products.find((product) => String(product.productId) === String(id));
  }

  // Добавляет товар в корзину
  addToBasket = (id) => {
    if (this.loading[id]) {
      return;
    }
    this.setLoading(id, true);

    return this.bridge.basketAddItem({ id: String(id) })
      .then((response) => {
        const { status, data = {} } = response?.data || {};
        const productData = this.getProductData(id, data.basket?.products);

        switch (status) {
          case 'BASKET_ADD_SUCCESS':
            this.setProductData(productData);

            if (typeof data.basket?.count !== 'undefined') {
              this.setBasketCount(data.basket.count);
            }
            updateBasketDataLayer(id, data);
            break;
          case 'BASKET_ADD_FAIL':
            this.setProductData(productData);

            if (typeof data.basket?.count !== 'undefined') {
              this.setBasketCount(data.basket.count);
            }
            break;
          default:
        }
        this.setLoading(id, false);

        return response;
      })
      .catch(() => {
        this.setLoading(id, false);
      });
  }

  // Главная страница
  setMainPage(data) {
    this.mainPage = data;
  }

  setIsLoaded(value) {
    this.isLoaded = value;
  }

  setSidebar(value) {
    this.sidebar = value;
  }
}

decorate(Store, {
  modal: observable,
  setModal: action,

  loading: observable,

  appConst: observable,
  setAppConst: action,

  creditPlan: observable,
  setCreditPlan: action,

  plane: observable,
  setPlane: action,

  catalogItems: observable,
  setCatalogItems: action,

  textPageContent: observable,
  setTextPageContent: action,

  cartData: observable,
  setCartData: action,

  // orderComplete: observable,
  // setOrderComplete: action,

  feedbackForm: observable,
  setFeedbackForm: action,

  serviceForm: observable,
  setServiceForm: action,

  header: observable,
  setHeader: action,

  footer: observable,
  setFooter: action,

  basketCount: observable,
  setBasketCount: action,

  productsMap: observable,
  setProductsMap: action,

  addToBasket: action,

  mainPage: observable,
  setMainPage: action,

  isLoaded: observable,
  setIsLoaded: action,

  sidebar: observable,
  setSidebar: action,
})

export default Store;
