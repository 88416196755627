import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';

const path = process.env.REACT_APP_ASSETS_PATH;

export const ElettoHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Кондиционер</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        <h2 className="product-page__head__center__sub-title">Eletto</h2>
        <p className="product-page__head__center__text">Cдержанные классические формы, четко<br/>очерченные грани, простой и элегантный дизайн.</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const ElettoContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section comfort-id">
        <div className="wrap">
          <SectionHeader
            title={<>Максимальный<br/>комфорт</>}
            text={<>Простой доступ к&nbsp;управлению из&nbsp;любого места и&nbsp;сверхтихая работа благодаря усовершенствованной конструкции</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="center"
              titleClass="large"
              picName="1"
              title={<>Спиральный<br/>воздушный поток</>}
              text={<>Для равномерного распределения температуры в&nbsp;комнате.</>}
              detailText={<>Благодаря специальной конструкции дефлектора формируется восходящий воздушный поток в режиме охлаждения. В режиме обогрева поток теплого воздуха направляется в пол. Помещение охлаждается и нагревается более равномерно.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small _i4"
              wrapClass="bottom-left"
              titleClass="large"
              picName="4"
              title={<>Умный<span className="d-br"/> датчик</>}
              text={<>Направляющий струи воздуха в&nbsp;помещении.</>}
              detailText={<>Интеллектуальный датчик отслеживает положение людей в помещении для обеспечения
                максимального уровня комфорта, а также позволяет экономить до 36% электроэнергии. Доступны режимы
                сопровождения либо избегания человека воздушным потоком. Если люди покидают помещение, кондиционер
                переходит в экономичный режим.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="small"
              picName="3"
              title={<>Сверхтихая работа<br/>при максимуме<br/>комфорта</>}
              detailText={<>Благодаря специальной конструкции вентилятора внутреннего блока кондиционера, его корпуса, а
                также использованию высококачественных современных приводов обеспечивается максимально низкий уровень
                шума.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
      <EvoSection assetsPathName={assetsPathName} productName={productName}/>
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>С заботой<br/>о вашем здоровье</>}
            text={<>Функции самоочистки и&nbsp;стерилизации позволяют сохранять воздух в&nbsp;комнате&nbsp;чистым</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="5"
              title={<>Стерилизация<br/>ультрафиолетом<br/>и&nbsp;ионизация</>}
              detailText={<>Кондиционер оснащен комбинированным модулем обработки воздуха 3 поколения с UVC LED
                (ультрафиолетовым излучателем) и Nano-aqua ионизатором. УФ излучатель обеззараживает воздух, убивает
                микробы и бактерии. Ионизатор создает аэроионы, делая воздух более «свежим» и полезным для человека.
                Стерилизацию можно использовать в разных режимах работы кондиционера, например, в режиме охлаждения,
                нагрева или вентиляции.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="small"
              picName="6"
              title={<>Убивает вирусы<br/>и&nbsp;бактерии при 56&deg;C<br/>в течение<br/>30&nbsp;минут</>}
              detailTitle={<>Убивает вирусы и&nbsp;бактерии</>}
              detailText={<>После завершения цикла самоочистки автоматически запускается процесс стерилизации
                внутреннего блока сплит-системы. Теплообменник принудительно осушается, и с помощью
                интеллектуального контроля температуры и регулирования частоты вращения вентилятора, температура
                внутри кондиционера поддерживается на уровне 56°С в течение 30 минут. Благодаря применению данной
                технологии вся внутренняя часть сплит-системы стерилизуется, а находящиеся внутри вирусы, микробы и
                микроорганизмы погибают.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="7"
              title={<><img src={`${path}images/products/eletto/features/1.svg`} alt=""
                            className="eletto-feature-pic"/>Покрытие <span className="d-br"/>корпуса <span
                className="m-br"/>c <span className="d-br"/>нано-ионами<br/>серебра</>}
              detailTitle={<>Покрытие <span className="d-br"/>корпуса <span className="m-br"/>c <span
                className="d-br"/>нано-ионами<br/>серебра</>}
              detailText={<>Все пластиковые элементы внутреннего блока кондиционера изготавливаются с применением
                нано-ионов серебра, которые предотвращают рост бактерий, а также образование плесени и грибка.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large i8"
              wrapClass="bottom-center"
              titleClass="large"
              picName="8"
              title={<>Инновационная<br/>экспресс-очистка</>}
              text={<>Технология Self-Clean.</>}
              detailText={<>Инновационная технология самоочистки удаляет микробы, бактерии и пыль, со временем
                накапливающиеся на поверхности теплообменника, с помощью процесса термического расширения. После
                активации функции очистки, кондиционер намораживает небольшой слой инея на теплообменнике, а затем
                нагревает теплообменник. В процессе оттаивания жидкость удаляется вместе с грязью, очищая
                теплообменник.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
    </>
  )
}