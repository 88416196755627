import './style.scss';
import React, {useEffect, useRef, useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button} from 'primereact/button';
import MediaQuery from 'react-responsive';
import {MOBILE_WIDTH_1} from '../../common/const';
import PersonalPageSummary from '../PersonalPage';
import pointsData from '../../json/points.json';
import {formatNumber, getApiPath} from "../utils";
import {inject, observer} from "mobx-react";
import axios from "axios";
import PageLoader from "../PageLoader";
import { Tooltip } from 'primereact/tooltip';

//const path = process.env.REACT_APP_ASSETS_PATH;
let currentHistoryPage = 0;

const Page = ({ match, store }) => {
  const [pointsHistory, setPointsHistory] = useState([]);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [isHistoryMore, setIsHistoryMore] = useState(true);
  const [balance, setBalance] = useState('');
  const [isDataLoaded, setDataLoaded] = useState(false);
  const [isUserHasEmptyPoints, setUserHasEmptyPoints] = useState(true);

  // Объявляем константы для баллов
  const requestAttemptsMax = 10;
  const historyRequestAttempts = 10;
  const historyRequestInterval = 1000;
  const entriesPerPage = 10;

  let id;
  let historyTimeout = useRef();
  let requestAttemptsDone = 0;
  let historyRequestAttemptsDone = 0;
  let entriesRecieved = 0;
  useEffect(() => {
    document.title = 'Мои баллы';
  }, []);
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      const data = response.data.pageContent || {};
      store.setAppConst(response.data.appConst);
      setDataLoaded(true);
      if(data.balance) {
        setBalance(Number(data.balance).toLocaleString('ru-Ru'))
      } else {
        setBalance('')
      }
      setUserHasEmptyPoints(data['isEmpty']);
      store.setSidebar(data.sidebar);
    }).catch(() => {
      setPointsHistory(pointsData.points);
      setBalance(Number(pointsData.balance).toLocaleString('ru-Ru'))
      setDataLoaded(true);
      setUserHasEmptyPoints(false);
    });
    tryLoadHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadHistory = (ignoreLoading = false) => {
    if(isHistoryLoading && !ignoreLoading) return;
    setIsHistoryLoading(true);
    store.bridge.pointsGetHistory({
      ...(id ? { id: id } : { historyPage: currentHistoryPage }),
    }).then((response) => {
      const {data= {} } = response?.data || {};
      if(data.id) {
        id = data.id;
        requestAttemptsDone += 1;
        if(requestAttemptsDone < requestAttemptsMax) {
          tryLoadHistory();
        } else {
          setIsHistoryLoading(false);
          setIsHistoryMore(false);
        }
      } else if (data.history) {
        id = null;
        requestAttemptsDone = 0;
        historyRequestAttemptsDone = 0;
        currentHistoryPage += 1;

        if (data.history.length) {
          entriesRecieved += data.history.length;
          updatePointsHistory(convertHistory(data.history));
        }
        if(entriesRecieved < entriesPerPage && data.nextPage) {
          tryLoadHistory();
        } else {
          clearTimeout(historyTimeout.current);
          setIsHistoryLoading(false);
          entriesRecieved = 0;
        }
        setIsHistoryMore(!!data.nextPage);
      }
    }).catch(() => {
      setIsHistoryLoading(false);
    })
  }
  // @TODO: fix interval
  const tryLoadHistory = () => {
    clearTimeout(historyTimeout.current);
    if(historyRequestAttemptsDone < historyRequestAttempts) {
      historyRequestAttemptsDone += 1;
      historyTimeout.current = setTimeout(() => {
        loadHistory(true)
      }, historyRequestInterval);
    }
  }

  const updatePointsHistory = (items = [], isPrepend = false) => {
    const history = items;
    setPointsHistory((pointsHistory) => isPrepend
      ? [...history, ...pointsHistory]
      : [...pointsHistory, ...history]
    )
  }

  const convertHistory = (items) => {
    return items.map((item) => destructItem(item));
  }

  const destructItem= (item) => {
    return {
      date: getDate(item.CreatedDate),
      text: item.Description,
      points: formatNumber(item.Sum),
      status: item.Color === '#FDB222' ? 'pending' : item.Sum ? getStatus(item.Sign) : null,
      sign: item.Sign,
    };
  }
  const getStatus = (code) => {
    const statuses = {
      '+': 'income', // е
      '-': 'expense', // списание
      // return: 'return', // возврат
    };
    return statuses[code];
  };
  const convertDate = (day = '', month = '', year = '') => {
    const months = {
      1: 'января',
      2: 'февраля',
      3: 'марта',
      4: 'апреля',
      5: 'мая',
      6: 'июня',
      7: 'июля',
      8: 'августа',
      9: 'сентября',
      10: 'октября',
      11: 'ноября',
      12: 'декабря',
    };
    return `${day} ${months[Number(month)]} ${year}`;
  };

  const getDate = (date = '') => {
    const d = date.split('T')[0];
    return d.length ? convertDate(...d.split('-').reverse()) : '';
  }

  const pointsClassType = (status) => {
    if (status === 'income') return '_golden';
    if (status === 'expense') return '_gray';
    if (status === 'pending') return '_clock';
    // eslint-disable-next-line no-console
    return console.error('Unknown points entity status');
  }

  const renderContent = () => {
    if (!store.appConst.isAuth) {
      return (
          <div className="bg-gray rounded-15 p-50 tablet:p-40 mobile:py-35 mobile:px-20">
            Чтобы попасть в личный кабинет, пожалуйста, <a href="#" className="text-blue" onClick={(e) => {
            e.preventDefault();
            store.setModal('auth');
          }}>авторизуйтесь.</a>
          </div>
      )
    }

    return (
        <div className="personal-page__top">
          <div className="personal-page__left">
            {isUserHasEmptyPoints ?
                <>
                  <div className="personal-bonus-page__pl">
                    <span className="personal-bonus-page__title">К&nbsp;сожалению, у&nbsp;вас пока нет баллов</span>
                    <span className="personal-bonus-page__text">Вы можете выбрать подходящую технику в&nbsp;каталоге товаров</span>
                    <Link to="/" className="p-button p-component p-button-secondary p-button-white min-w">Перейти к покупкам</Link>
                  </div>
                </>
                :
                <>
                  <div className="personal-bonus-page__pl personal-bonus-page__header">
                    <span className="personal-bonus-page__title">Баланс</span>
                    <span
                        className="personal-bonus-page__balance"
                        dangerouslySetInnerHTML={{__html: balance}}
                    ></span>
                  </div>
                  <div className="personal-bonus-page__pl">
                    <span className="personal-bonus-page__title">История операций</span>
                    <div className="personal-bonus-page__table">
                      <MediaQuery minWidth={MOBILE_WIDTH_1}>
                        <div className="personal-bonus-page__th">
                          <div className="personal-bonus-page__td">Дата</div>
                          <div className="personal-bonus-page__td _w100">Операция</div>
                          <div className="personal-bonus-page__td">Сумма</div>
                        </div>
                      </MediaQuery>
                      {pointsHistory.map((item, index) =>
                          <div key={index} className="personal-bonus-page__tr">
                            <div className="personal-bonus-page__td">{item.date}</div>
                            <div className="personal-bonus-page__td">{item.text}</div>
                            <div className="personal-bonus-page__td">
                              <Tooltip
                                  target="._clock"
                                  autoHide={false}
                              >
                                <div className="personal-bonus-page__tooltip-wrapper">
                                  <h3 className="personal-bonus-page__tooltip-header">В обработке</h3>
                                  <p className="personal-bonus-page__tooltip-text">Баллы за вашу покупку находятся в обработке. Совсем скоро они будут зачислены. Обычно на это уходит до 5 рабочих дней.</p>
                                </div>
                                <div className="personal-bonus-page__tooltip-arrow"></div>
                              </Tooltip>
                            <span
                                className={pointsClassType(item.status)}
                                dangerouslySetInnerHTML={{__html: `${item.sign} ${item.points}`}}
                                data-pr-at="right-10 top-20"
                                data-pr-position="top"
                                data-pr-classname="personal-bonus-page__tooltip"
                            ></span>
                            </div>
                          </div>
                      )
                      }
                    </div>
                    <Button
                        className={"p-button-secondary p-button-transparent min-w " + (isHistoryMore ? '' : 'profile-history__hidden')}
                        label={ isHistoryLoading ? "Идёт загрузка..." : "загрузить еще" }
                        onClick={() => loadHistory()}
                    />
                  </div>
                </>
            }
          </div>
          <PersonalPageSummary />
        </div>
    )
  }

  return (!isDataLoaded ? <PageLoader /> :
    <div className="personal-bonus-page page">
      <div className="personal-bonus-page__bg-top">
        <div className="page-wrap">
          <div className="personal-page__left">
            <h1 className="personal-page__h1">Мои баллы</h1>
          </div>
          {renderContent()}
        </div>
      </div>
    </div>
  );
}

export default withRouter(inject('store')(observer(Page)));

