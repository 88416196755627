import './style.scss';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import {getApiPath} from "../utils";
import PageContainer from "../PageContainer";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import { inject } from "mobx-react";
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from '../../common/const';

const path = process.env.REACT_APP_ASSETS_PATH;

const Page = ({ match, store }) => {
  const [data, setData] = useState();
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      if(response.data.seoData?.pageTitle){
        document.title = response.data.seoData.pageTitle
      }
      setData(true);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(true);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    document.body.classList.add('_white-logo');

    return () => {
      setData(null);
      setResponseError(null);
      document.body.classList.remove('_white-logo');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path])

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="assurance-page page">
      <div className="assurance-page__bg">
        <div className="assurance-page__wrap">
          <span className="assurance-page__h1">Гарантия</span>
          <div className="assurance-page__pl">
            <MediaQuery maxWidth={MOBILE_WIDTH}>
              <div className="assurance-page__pl__pic" style={{backgroundImage: `url(${path}images/assurance/assurance-m.jpg)`}} />
            </MediaQuery>
            <div className="assurance-page__pl__content">
              <span className="assurance-page__pl__header">7 лет на всю технику</span>
              <div className="assurance-page__pl__cols">
                <div className="assurance-page__pl__col">
                  <span className="assurance-page__pl__title">Кухня</span>
                  <span className="assurance-page__pl__text">Вытяжка</span>
                  <span className="assurance-page__pl__text">Духовой шкаф</span>
                  <span className="assurance-page__pl__text">Индукционная варочная поверхность</span>
                  <span className="assurance-page__pl__text">Двухдверная посудомоечная машина Double&nbsp;Drawer</span>
                  <span className="assurance-page__pl__title">Хранение продуктов</span>
                  <span className="assurance-page__pl__text">Холодильник French&nbsp;Door</span>
                  <span className="assurance-page__pl__text">Винный шкаф Twin&nbsp;Wine&nbsp;Zone</span>
                </div>
                <div className="assurance-page__pl__col">
                  <span className="assurance-page__pl__title">Уход за одеждой</span>
                  <span className="assurance-page__pl__text">Стирально-сушильная колонна Double&nbsp;Drum</span>
                  <span className="assurance-page__pl__text">Паровой шкаф для&nbsp;ухода за&nbsp;одеждой</span>
                  <span className="assurance-page__pl__title">Климат</span>
                  <span className="assurance-page__pl__text">Серия Velato</span>
                  <span className="assurance-page__pl__text">Серия Eletto</span>
                  <span className="assurance-page__pl__text">Серия Triano</span>
                  <span className="assurance-page__pl__text">Мульти-сплит системы</span>
                </div>
              </div>
            </div>
            <MediaQuery minWidth={MOBILE_WIDTH_1}>
              <div className="assurance-page__pl__pic" style={{backgroundImage: `url(${path}images/assurance/assurance-d.jpg)`}} />
            </MediaQuery>
          </div>
          <div className="assurance-page__text">
            <p>Уверенность в технологиях, их пользе и востребованности, ответственность за каждый этап производства лежат в основе создания продуктов Casarte.</p>
            <p>За визуальную и техническую части отвечают инженеры собственных центров дизайна, исследований и разработок. Процессы производства техники соответствуют требованиям международного стандарта ISO 9001 и сопровождаются многоступенчатым контролем качества, сочетающим в себе тщательную проверку каждого изделия автоматизированным способом и вручную.</p>
            <p>Наслаждайтесь комфортом, удобством и получайте приятные эмоции при использовании техники Casarte долгие годы.</p>
            <div className="assurance-page__text__line" />
            <p><b>Полные условия гарантийного обслуживания доступны в приложенном документе:</b></p>
          </div>
          <div className="assurance-page__docs">
            <a class="personal-orders-page__order__certificate" href="#">Гарантийный сертификат Casarte</a>
          </div>
        </div>
      </div>
    </div>
    </PageContainer>
  );
}

export default inject('store')(withRouter(Page));
