import React, {useState} from 'react';
import {fixDeg} from "../../common/utils";

const ProductTable = ({data, open = false}) => {
  let rows = [];
  const [opened, setOpened] = useState(open);

  if (data) {
    for (let i = 0; i < data.data.length; i++) {
      const group = data.data[i];

      if (group.head.length) {
        rows.push(
          <tr key={data.title + i} className="product-page__props-table-tr head">
            <td className="product-page__props-table-td first">{fixDeg(group.head[0])}</td>
            {group.head.length >= 2 ?
            <td className="product-page__props-table-td last">{fixDeg(group.head[1])}</td> : null}
          </tr>
        );
      }

      for (let j = 0; j < group.rows.length; j++) {
        const row = group.rows[j];

        rows.push(
          <tr key={data.title + i + j} className="product-page__props-table-tr">
            {
              group.links && group.links.length && group.links[j] ?
                <td className="product-page__props-table-td first">
                  <a href={group.links[j]} download rel="noreferrer" target="_blank">{fixDeg(row[0])}</a>
                </td>
                :
                <td className="product-page__props-table-td first">{fixDeg(row[0])}</td>
            }
            {row.length >=2 ? <td className="product-page__props-table-td last">{fixDeg(row[1])}</td> : null}
          </tr>
        );
      }
    }
  }

  return (
    <>
      {data ?
        <table cellSpacing="0" cellPadding="0" className="product-page__props-table">
          <thead>
            <tr className={`product-page__props-table-title ${opened ? 'opened' : 'closed'}`}
                onClick={() => setOpened(!opened)}>
              <th colSpan="2">{data.title}</th>
            </tr>
          </thead>
          {opened ?
            <tbody>
            {rows}
            </tbody>
            : null}
        </table>
        : null
      }
    </>
  )
}

export default ProductTable;
