import './style.scss';
import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import axios from "axios";
import {getApiPath} from "../utils";
import washersPageData from "../../json/installationWashersPage.json";
import airConditionersBasicPageData from "../../json/installationAirConditionersBasicPage.json";
import airConditionersStandartPageData from "../../json/installationAirConditionersStandartPage.json";
import airConditionersExtendedPageData from "../../json/installationAirConditionersExtendedPage.json";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import {inject, observer} from "mobx-react";
import PageContainer from "../PageContainer";

const URL = [
  '/installation',
  '/installation/washers',
  '/installation/air-conditioners',
  '/installation/air-conditioners/basic',
  '/installation/air-conditioners/standart',
  '/installation/air-conditioners/extended'
];

const Page = ({ store, match }) => {
  const [data, setData] = useState(null);
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    document.title = 'Casarte Premium монтаж';
  }, [])

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      // TO DO: сделать разделение по match.path от типа продукта и типа установки:
      // 1 Стирки (текущая версия) /installation/washers или /installation
      // 2 Кондеи, базовая установка /installation/air-conditioners/basic или /installation/air-conditioners
      // 3 Кондеи, стандартная установка /installation/air-conditioners/standart
      // 4 Кондеи, расширенная установка /installation/air-conditioners/extended
      setData(response.data.pageContent);

      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }

      if(match.path === URL[0] || match.path === URL[1]){
        setData(washersPageData);
      }
      else if(match.path === URL[2] || match.path === URL[3]){
        setData(airConditionersBasicPageData);
      }
      else if(match.path === URL[4]){
        setData(airConditionersStandartPageData);
      }
      else if(match.path === URL[5]){
        setData(airConditionersExtendedPageData);
      }
      
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="installation-page page">
      <div className="wrap">
        <h3 className="installation-page__topic">
          {
            match.path === URL[0] || match.path === URL[1] ?
              <>Установка стирально-сушильной колонны Casarte</>
            : match.path === URL[2] || match.path === URL[3] ?
              <>Базовая установка кондиционера Casarte</>
            : match.path === URL[4] ?
              <>Стандартная установка кондиционера Casarte</>
            : match.path === URL[5] ?
              <>Расширенная установка кондиционера Casarte</>
            : null
          }
        </h3>
        <div className={`installation-page__head ${match.path === URL[0] || match.path === URL[1] ? '_washers' : '_air-conditioners'}`} />
        <div className="installation-page__content">
          {Boolean(data?.sections?.length) && data?.sections?.map((section) =>
            <div className="installation-page__row" dangerouslySetInnerHTML={{__html: section}} />
          )}
        </div>
        {
          match.path === URL[2] || match.path === URL[3] || match.path === URL[4] || match.path === URL[5] ?
            <a className="installation-page__link">Правила Акции по установке кондиционеров Casarte</a>
          : null
        }
      </div>
    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(Page)));
