import './style.scss';
import React, {useEffect, useState, useMemo} from 'react';
import {withRouter, Link} from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from '../../common/const';
import PersonalPageSummary from '../PersonalPage';
import {numWithSpaces} from '../../common/utils';
import {inject, observer} from "mobx-react";
import axios from "axios";
import {getApiPath, pluralize} from "../utils";
import pageData from "../../json/orders.json";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import PageContainer from "../PageContainer";
import initCloudpaymentsWidget from '../utils/init-cloudpayments-widget'

// const path = process.env.REACT_APP_ASSETS_PATH;

const Page = ({ store, match }) => {
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [responseError, setResponseError] = useState();
  const [isWidgetLoading, setIsWidgetLoading] = useState(false);
  const [isCloudpaymentsInited, setIsCloudpaymentsInited] = useState(false);

  const ordersPerPage = 4;

  const pagesCount = Math.ceil(data?.orders?.length / ordersPerPage);

  const pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i);
  }
  const sliceStart = (currentPage - 1) * ordersPerPage;
  const sliceEnd = ordersPerPage * currentPage;

  useEffect(() => {
    document.title = 'Мои заказы';
  }, []);

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      setData(response.data.pageContent);
      store.setSidebar(response.data.pageContent.sidebar);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(pageData);
      store.setSidebar(pageData.sidebar);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  const handlerPageClick = (page) => {
    setCurrentPage(page);
  }

  const initWidget = (button, orderId, e) => {
    if (isWidgetLoading) {
      e.preventDefault();
      return;
    }
    if (button.id === 'payButton' && !isCloudpaymentsInited) {
      e.preventDefault();
      setIsWidgetLoading(true);

      initCloudpaymentsWidget(orderId, () => {
        setIsCloudpaymentsInited(true);
        setIsWidgetLoading(false);
      })
    }
  }

  const renderContent = () => {
    if (!store.appConst.isAuth) {
      return (
        <div className="bg-gray rounded-15 p-50 tablet:p-40 mobile:py-35 mobile:px-20">
          Чтобы попасть в личный кабинет, пожалуйста, <a href="#" className="text-blue" onClick={(e) => {
            e.preventDefault();
            store.setModal('auth');
          }}>авторизуйтесь.</a>
        </div>
      )
    }

    return (
      <div className="personal-page__top">
        <div className="personal-page__left">
          {
            data?.orders?.length === 0 ?
              <div className="personal-orders-page__empty">
                <span className="personal-orders-page__empty__title">К сожалению, ваша история заказов пуста</span>
                <span className="personal-orders-page__empty__text">Вы можете выбрать подходящую технику в нашем каталоге</span>
                <Link to="/" className="p-button p-component p-button-secondary p-button-white min-w">Каталог
                  товаров</Link>
              </div>
              :
              <>
                <div className="personal-orders-page__orders">
                  {data?.orders?.slice(sliceStart, sliceEnd).map((item) =>
                    <Order key={item.id} {...item } isWidgetLoading={isWidgetLoading} initWidget={initWidget} />
                  )}
                </div>
                <div className="personal-orders-page__pager">
                  <span className="personal-orders-page__pager__label">Страницы:</span>
                  <div className="personal-orders-page__pager__items">
                    {
                      pages.map((item, index) => {
                        return (
                          <span
                            onClick={() => handlerPageClick(item)}
                            className={`personal-orders-page__pager__item ${currentPage === item ? '_current' : ''}`}
                            key={item + '-' + index}
                          >{item}</span>
                        )
                      })
                    }
                  </div>
                </div>
              </>
          }
        </div>
        <PersonalPageSummary/>
      </div>
    )
  }

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="personal-orders-page page">
      <div className="page-wrap">
        <div className="personal-page__left">
          <h1 className="personal-page__h1">Мои заказы</h1>
        </div>
        {renderContent()}
      </div>
    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(Page)));

const Order = (props) => {
  const orderStatus = {
    "выполнен": 1,
    "доставлен": 2,
    "ожидает оплату": 3,
    "отменен": 4
  }
  const [detailStatus, setDetailStatus] = useState(false);

  const certificatesCount = useMemo(() => {
    return props.products.reduce((acc, product) => {
      if (product.certificates) {
        return acc + product.certificates.length;
      }
      return acc;
    }, 0);
  }, [props.products]);

  const BuyButton = () => {
    return (
      props.button ?
        props.button.id
          ? <button
            id={props.button.id}
            className={`p-button p-component p-button-secondary p-button-white min-w personal-orders-page__order__buy-btn ${props.isWidgetLoading ? 'p-loader' : ''}`}
            type="button"
            dangerouslySetInnerHTML={{__html: props.button.text}}
            onClick={(e) => props.initWidget(props.button, props.id, e)}
          />
          : <a
            href={props.button.link}
            className="p-button p-component p-button-secondary p-button-white min-w personal-orders-page__order__buy-btn"
            dangerouslySetInnerHTML={{__html: props.button.text}}
          />
        : <div/>
    )
  }

  return (
    <div className="personal-orders-page__order">
      <div className="personal-orders-page__order__header-row personal-orders-page__order__title">
        <span>{props.type} № {props.id}</span>
        <span>{numWithSpaces(props.price?.total)} <span className='rub'>P</span></span>
      </div>
      <div className="personal-orders-page__order__header-row">
        <span className="personal-orders-page__order__date">{props.date}</span>
        <span className={`personal-orders-page__order__status i${orderStatus[props.status.toLowerCase()]}`}>{props.status}</span>
      </div>
      {Boolean(certificatesCount) && <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="personal-orders-page__order__header-row">
          <span className="personal-orders-page__order__certificate-avaible">
            Вам {pluralize(certificatesCount, ['доступен', 'доступно', 'доступно'])} {certificatesCount} {pluralize(certificatesCount, ['сертификат', 'сертификата', 'сертификатов'])} на&nbsp;установку
          </span>
          <span/>
        </div>
      </MediaQuery>}
      <div className="personal-orders-page__order__header-row _last">
        {(props.receipt || props.certificate) && <div className="personal-orders-page__order__links">
          {props.receipt && <a className="personal-orders-page__order__ticket" href={props.receipt}>
            Электронный чек
          </a>}
          {props.certificate && <a className="personal-orders-page__order__certificate" href={props.certificate}>
            Гарантийный сертификат
          </a>}
        </div>}
        <MediaQuery minWidth={MOBILE_WIDTH_1}>
          <BuyButton/>
        </MediaQuery>
        {Boolean(certificatesCount) && <MediaQuery maxWidth={MOBILE_WIDTH}>
          <span className="personal-orders-page__order__certificate-avaible">
            Вам {pluralize(certificatesCount, ['доступен', 'доступно', 'доступно'])} {certificatesCount} {pluralize(certificatesCount, ['сертификат', 'сертификата', 'сертификатов'])} на&nbsp;установку
          </span>
        </MediaQuery>}
        <span className={`personal-orders-page__order__more ${detailStatus ? '_opened' : ''}`}
              onClick={() => setDetailStatus(!detailStatus)}>{detailStatus ? 'Скрыть' : 'Подробнее'}</span>
        <MediaQuery maxWidth={MOBILE_WIDTH}>
          <BuyButton/>
        </MediaQuery>
      </div>
      {detailStatus && <>
        <div className="personal-orders-page__order__detail-summary">
          {Boolean(props.products?.length) && props.products?.map((item, i) =>
            <div key={i} className="personal-orders-page__order__detail-summary__product">
              {item.product.url
                ? <Link className="personal-orders-page__order__detail-summary__product__pic" to={item.product.url}>
                  {Boolean(item.product.image) && <img src={item.product.image} alt={item.product.title} />}
                </Link>
                : <div className="personal-orders-page__order__detail-summary__product__pic _free">
                  <img src={`/images/personal/free.svg`} alt="Бесплатная установка" />
                </div>}
              <div className="personal-orders-page__order__detail-summary__product__title">
                {item.product.url
                  ? <Link to={item.product.url}>
                    {item.product.title}
                    {item.quantity > 1 && <> x <b>{item.quantity}</b></>}
                  </Link>
                  : <>
                    {item.product.title}
                    {item.quantity > 1 && <> x <b>{item.quantity}</b></>}
                  </>}
                {Boolean(item.certificates?.length) && <span className="personal-orders-page__order__certificate-avaible-detail">
                  Cертификаты на&nbsp;установку:
                  {item.certificates?.map((certificate, ci) =>
                    <span key={ci} className="personal-orders-page__order__certificate-avaible-detail__value">{certificate}</span>
                  )}
                </span>}
              </div>
              <span className="personal-orders-page__order__detail-summary__product__price">
                {Number(item.product.price) ? numWithSpaces(item.product.price) : '0'} <span className='rub'>P</span>
              </span>
            </div>
          )}

          <div className="personal-orders-page__order__detail-summary__total">
            <span>Всего</span>
            <span>{numWithSpaces(props.price?.total)} <span className='rub'>P</span></span>
          </div>

          <span className="personal-orders-page__order__detail-summary__title">Контактные данные</span>
          <span className="personal-orders-page__order__detail-summary__text">{props.contacts}</span>
          <span className="personal-orders-page__order__detail-summary__title">Способ доставки</span>
          <span className="personal-orders-page__order__detail-summary__text">{props.delivery}</span>
          <span className="personal-orders-page__order__detail-summary__title">Способ оплаты</span>
          <span className="personal-orders-page__order__detail-summary__text">{props.paymentMethod}</span>
        </div>
        <div className="personal-orders-page__order__detail-price">
          {
            props.price?.detail?.map((item, i) =>
              <div key={i} className="personal-orders-page__order__detail-price__item">
                <span>{item.label}</span>
                <span>- {numWithSpaces(item.value)} <span className='rub'>P</span></span>
              </div>
            )
          }
          <div className="personal-orders-page__order__detail-price__item">
            <span>Итого</span>
            <span>{numWithSpaces(props.price?.total)} <span className='rub'>P</span></span>
          </div>
        </div>
      </>}
    </div>
  );
}
