import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const HoodCasarteHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Вытяжка</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        {/*<h2 className="product-page__head__center__sub-title">CASVHP9DT1NBGH</h2>*/}
        <p className="product-page__head__center__text">Лаконичный дизайн, изящные формы, продвинутые <span className='br-d' />технологии.</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const HoodCasarteContent = (props) => {
  const { assetsPathName } = props;

  return (
    <>
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>Удобство</>}
            text={<>Наслаждайтесь процессом готовки благодаря простоте использования и&nbsp;обслуживания вытяжки Casarte</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="51"
              title={<>Ширина 90 см</>}
              text={<>Широкая вытяжка — яркий акцент дизайна.</>}
              detailText={<>Эффективное удаление загрязненного воздуха со всей
                поверхности варочной поверхности, даже если ее ширина 90 см.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="52"
              title={<>Индикация<br/>состояния <span className="m-br"/>фильтра</>}
              text={<>Вытяжка сама сообщит вам, <span className="m-br"/>когда придет время <span className="m-br"/>почистить фильтр.</>}
              detailText={<>Для эффективной работы необходима периодическая очистка
                фильтра. Вытяжка напомнит об этом с помощью индикатора
                на панели управления.</>}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section reliability-id">
        <div className="wrap">
          <SectionHeader
            title={<>Функциональность</>}
            text={<>Удаление пара и&nbsp;очистка воздуха для&nbsp;комфортной атмосферы на&nbsp;вашей кухне</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-s"
              picName="53"
              title={<>Очистка<br/>активированным<br/>углем</>}
              text={<>Для возможности рециркуляции <span className="d-br"/>воздуха.</>}
              detailText={<>Функция рециркуляции позволяет очищать воздух от дыма,
                паров, запахов и выдувать его обратно в помещение. Это
                особенно полезно в случаях когда нет возможности вывести
                вытяжку на улицу или через вентиляционную систему. Очистка
                активированным углем это хороший способ избежать
                неприятных запахов и дыма на кухне. Загрязненный воздух
                проходит через фильтр, где активированный уголь поглощает
                запахи и другие загрязнения. После этого чистый воздух
                возвращается обратно в кухню.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-xs"
              picName="54"
              title={<>Вентиляция</>}
              text={<>Программирование ежедневной вентиляции.</>}
              detailText={<>Вытяжку можно настроить на&nbsp;ежедневную вентиляцию воздуха
                в помещении, например, в течение 30 минут. Длительность
                такого проветривания можно настроить в диапазоне
                от 10 до 120 минут.</>}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section comfort-id">
        <div className="wrap">
          <SectionHeader
            title={<>Комфорт</>}
            text={<>Вытяжка Casarte акцентирует внимание на&nbsp;современном дизайне вашей кухни</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="55"
              title={<>Удобство управления<br/>и легкость ухода</>}
              text={<>Стильная сенсорная панель на&nbsp;черном стекле с&nbsp;белыми индикаторами.</>}
              detailText={<>Сенсорная панель – это удобство управления и легкость ухода.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-center-2"
              titleClass="large"
              picName="56"
              title={<>Изменяемая <span className="m-br"/>яркость <span className="m-br"/>подсветки</>}
              text={<>Подсветку можно сделать ярче или&nbsp;приглушить.</>}
              detailText={<>По желанию можно изменить яркость подсветки простым
                касанием сенсорного поля на панели.</>}
              video={1}
              videoInteractive={1}
            />
          </div>
        </div>
      </div>
      <TrainerSection />
    </>
  )
}