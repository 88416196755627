import './style.scss';
import React, { useRef } from 'react';
import {inject, observer} from "mobx-react";
import { disableBodyScroll, enableBodyScroll } from '../../common/utils';
import classNames from "classnames";

const Modal = ( {store, children, title, icon, name, size = 's', className = ''} ) => {
  const data = store.modal[name];

  const isClickOutsideRef = useRef();

  const handleClose = (e) => {
    if(!e.target.closest('.modal__body') && isClickOutsideRef.current){
      store.setModal(name, null);
    }
  }

  data?.data ? disableBodyScroll() : enableBodyScroll();

  return (
    data ?
      <div className={classNames('global', 'modal', `_${data.status}`, data?.data?.className, `modal--size-${size}`, className)} onMouseUp={(e) => handleClose(e) } onMouseDown={(e) => {
        isClickOutsideRef.current = !e.target.closest('.modal__body');
      }}>
        <div className="modal__outer-wrap">
          <div className="modal__inner-wrap">
            <div className="modal__body">
              <div className="modal__close" onClick={() => store.setModal(name, null) } />
              {icon ? <div className={'modal__icon-wrap'}>{icon}</div> : null}
              {title ? <span className="modal__title">{ title }</span> : null}
              {children}
            </div>
          </div>
        </div>
      </div>
      : null
  )
}

export default inject('store')(observer(Modal));
