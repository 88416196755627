import './style.scss';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {inject, observer} from "mobx-react";
import axios from "axios";
import pageData from '../../json/customerPage.json';
import menuData from "../../json/catalogItems.json";
import {getApiPath} from "../utils";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import PageContainer from "../PageContainer";

const Page = ({ match, store }) => {
  const [data, setData] = useState(null);
  const [responseError, setResponseError] = useState();

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      setData(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(pageData.sections);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  let items = [];

  data?.forEach((section, i) => {
    items.push({
      title: section.section,
      text: '',
      isSection: true
    })
    section.items.map((item, j) =>
      items.push({
        title: item.title,
        text: item.text,
        isSection: false
      })
    )
  })

  useEffect(() => {
    document.title = 'Покупателям';
  }, [])

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="customers-page page">
      <div className="wrap">
        <h3 className="customers-page__title">Оплата и доставка</h3>
        <p className="customers-page__text">За многолетнюю историю разработок и сотрудничества с нашими пользователями, мы собрали ответы на самые популярные вопросы о нашей продукции.</p>
        <Accordion className="customers-page__accordion">
          {items?.map((item, i) =>
            <AccordionTab data-disabled={item.isSection} disabled={item.isSection} className={item.isSection ? 'customers-page__accordion__title':''} header={item.title} key={i}>
              <div
                dangerouslySetInnerHTML={{
                  __html: item.text
                }}
              />
            </AccordionTab>
          )}
        </Accordion>
       </div>
    </div>
    </PageContainer>
  );
}

export default withRouter(inject('store')(observer(Page)));
