import MediaQuery from 'react-responsive';
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../common/const';

export const MobileMediaQuery = (props) => {
    return (
        <MediaQuery maxWidth={MOBILE_WIDTH}>
            {props.children}
        </MediaQuery>
    );
}

export const DesktopMediaQuery = (props) => {
    return (
        <MediaQuery minWidth={MOBILE_WIDTH_1}>
            {props.children}
        </MediaQuery>
    );
}