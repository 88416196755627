import './style.scss';
import React, { useEffect } from 'react';
import {withRouter} from 'react-router-dom';

const Page = () => {
  useEffect(() => {
    document.title = 'Технические работы';
    document.body.classList.add('_no-header-and-footer');

    return () => document.body.classList.remove('_no-header-and-footer');
  }, [])

  return (
    <div className="page tech-work-page">
      <div className="page-wrap">
        <div className="page-top page-top--center">
          <div className="h1">
            Уважаемые покупатели!
          </div>
          <div className="intro t1">
            Прямо сейчас мы улучшаем интернет-магазин, чтобы он стал еще более удобным, информативным и красивым. Пожалуйста, зайдите к нам еще раз позднее. Ждем вас!
          </div>
        </div>
        <div className="tech-work-items">
          <div className="content-block text">
            <div className="h4">Остались вопросы? Позвоните нам</div>
            <p className="text-light-2">
              Москва и МО, 09:00 - 22:00: <a href="tel:88002340953">8 800 234 09 53</a>
            </p>
          </div>
          <div className="content-block text">
            <div className="h4">Сервисное обслуживание и ремонт</div>
            <p className="text-light-2">
              Москва и МО, 09:00 - 22:00: <a href="tel:88002340953">8 800 234 09 53</a>
            </p>
          </div>
        </div>
        <div className="tech-work-footer">
          <a href="mailto:support@casarte.ru" className="font-500">support@casarte.ru</a>
          <a href="#" className="social-icon social-icon--youtube" />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Page);
