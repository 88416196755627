import Modal from '../Modal'
import {inject, observer} from "mobx-react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Formik, Form} from "formik";
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import {
  RESTORE_VALUE_COOKIE_NAME,
  RESTORE_TYPE_COOKIE_NAME,
  // RESTORE_COOKIE_LIFETIME,
  CODE_RESEND_COOLDOWN, RESTORE_CODE_COOKIE_NAME, CONFIRM_CODE_LENGTH, ERROR_MESAGES
} from '../../common/const';
import Recaptcha from '../utils/recaptcha';
import { debounce } from '../utils';
import * as Yup from "yup";
import TimeCounter from "../TimeCounter";

const modalName = 'restorePassword';

const valueFormSchema = Yup.object().shape({
  value: Yup.string()
    .required(ERROR_MESAGES.required),
});

const codeFormSchema = Yup.object().shape({
  code: Yup.string()
    .length(CONFIRM_CODE_LENGTH, ERROR_MESAGES.codeNotFull)
    .required(ERROR_MESAGES.required),
});

const initialLoading = {
  valueForm: false,
  codeForm: false,
};

const initialErrors = {
  valueForm: null,
  codeForm: null,
};

const RestorePasswordModal = ({ store }) => {
  const { isConfirm, isCodeResend, isError, type } = store.modal[modalName]?.data ?? {};
  const recaptcha = new Recaptcha();

  const setRestoreValueCookie = (restoreValue)  => {
    // Cookies.set(RESTORE_VALUE_COOKIE_NAME, `${restoreValue}`, { expires: RESTORE_COOKIE_LIFETIME });
    const expires = new Date(
      new Date().getTime() + Number(CODE_RESEND_COOLDOWN) * 1000,
    );
    Cookies.set(RESTORE_VALUE_COOKIE_NAME, `${restoreValue}`, { expires });
  };

  const getRestoreValueCookie = ()  => {
    return Cookies.get(RESTORE_VALUE_COOKIE_NAME);
  };

  const setRestoreTypeCookie = (restoreType)  => {
    // Cookies.set(RESTORE_TYPE_COOKIE_NAME, `${restoreType}`, { expires: RESTORE_COOKIE_LIFETIME });
    const expires = new Date(
      new Date().getTime() + Number(CODE_RESEND_COOLDOWN) * 1000,
    );
    Cookies.set(RESTORE_TYPE_COOKIE_NAME, `${restoreType}`, { expires });
  };

  const getRestoreTypeCookie = ()  => {
    return Cookies.get(RESTORE_TYPE_COOKIE_NAME);
  };

  const setCodeResendDateCookie = () => {
    const expires = new Date(
      new Date().getTime() + Number(CODE_RESEND_COOLDOWN) * 1000,
    );
    Cookies.set(RESTORE_CODE_COOKIE_NAME, `${expires}`, { expires });
  };

  const getCodeResendDateCookie = () => {
    return Cookies.get(RESTORE_CODE_COOKIE_NAME);
  }

  const [restoreValue, setRestoreValue] = useState(getRestoreValueCookie());
  const [restoreType, setRestoreType] = useState(getRestoreTypeCookie());
  const [codeResendDate, setCodeResendDate] = useState(getCodeResendDateCookie());
  const [loading, setLoading] = useState(initialLoading);
  const [errors, setErrors] = useState(initialErrors);

  const sendRestoreCode = async (email = restoreValue) => {
    // setCodeResendDateCookie();
    // setCodeResendDate(getCodeResendDateCookie());
    // store.setModal('restorePassword', { isConfirm: true });
    // return;

    if (getCodeResendDateCookie()) {
      setCodeResendDate(getCodeResendDateCookie());
      store.setModal('restorePassword', { isConfirm: true });
      return;
    }
    if (loading.codeForm) {
      return;
    }
    setErrors({ ...errors, valueForm: null, codeForm: null });
    setLoading({ ...loading, valueForm: true, codeForm: true });

    const token = await recaptcha.execute();

    store.bridge.authRestorePassword({
      email,
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'RESUBMIT_SEND_SUCCESS':
          setCodeResendDateCookie();
          setRestoreValueCookie(data.phone ?? data.email);
          setRestoreTypeCookie(data.phone ? 'phone' : 'email');
          setCodeResendDate(getCodeResendDateCookie());
          setRestoreValue(getRestoreValueCookie());
          setRestoreType(getRestoreTypeCookie());
          store.setModal('restorePassword', { isConfirm: true });

          break;
        case 'RESUBMIT_SEND_FAIL':
          if (data.errorMessage) {
            setErrors({
              ...errors,
              valueForm: data.errorMessage,
              codeForm: data.errorMessage,
            });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, valueForm: false, codeForm: false });
    }).catch(() => {
      setLoading({ ...loading, valueForm: false, codeForm: false });
    });
  };

  const handleCodeInput = (code) => {
    if (code.length === CONFIRM_CODE_LENGTH) {
      sendConfirmRestoreCode(code);
    }
  };

  const sendConfirmRestoreCode = async (code) => {
    // store.setModal('changePassword');
    // return;

    if (loading.codeForm) {
      return;
    }
    setErrors({ ...errors, codeForm: null });
    setLoading({ ...loading, codeForm: true });

    const token = await recaptcha.execute();

    store.bridge.authRestoreCode({
      code,
      type: restoreType,
      token,
    }).then((response) => {
      const { status, data = {} } = response?.data || {};

      switch (status) {
        case 'PASSWORD_RESTORE_CODE_SUCCESS':
          store.setModal('changePassword', { ...data });

          break;
        case 'PASSWORD_RESTORE_CODE_FAIL':
          if (data.errorMessage) {
            setErrors({ ...errors, codeForm: data.errorMessage });
          }
          break;
        default:
          // eslint-disable-next-line no-console
          console.error(`Error ${status}: Unknown status`);
      }
      setLoading({ ...loading, codeForm: false });
    }).catch(() => {
      setLoading({ ...loading, codeForm: false });
    });
  };

  const debounceHandleCodeInput = debounce(handleCodeInput);

  if (isConfirm) {
    return <Modal size='xs' title={'Восстановление пароля'} name={modalName}>
      {(restoreType === 'phone'|| (isConfirm && type === 'phone')) && <div className="text-light">
        На номер телефона <strong className='text-blue'>{restoreValue || '+7 (999) 899-65-65'}</strong> выслан код восстановления. Введите его в&nbsp;поле ниже для доступа к&nbsp;форме смене пароля
      </div>}
      {(restoreType === 'email' || (isConfirm && type === 'email')) && <div className="text-light">
        На e-mail <strong className='text-blue'>{restoreValue || 'test@bstd.ru'}</strong> выслан код восстановления. Введите его в&nbsp;поле ниже для доступа к&nbsp;форме смене пароля
      </div>}
      <Formik
        initialValues={{
          code: '',
        }}
        validationSchema={codeFormSchema}
        // validator={() => {}}
        onSubmit={(values) => {
          sendConfirmRestoreCode(values.code);
        }}
      >
        {({ values, setFieldValue, touched, setTouched, errors: formErrors }) => (
          <Form>
            <div className="mt-20">
              <div className="p-float-label">
                <InputText
                  className={formErrors.code && touched.code ? 'p-invalid' : ''}
                  id="restoreCode"
                  name="restoreCode"
                  value={values.code}
                  readOnly={loading.codeForm}
                  maxLength={CONFIRM_CODE_LENGTH}
                  onChange={(e) => {
                    setFieldValue('code', e.target.value);
                    debounceHandleCodeInput(e.target.value);
                  }}
                />
                <label htmlFor="restoreCode">Введите код</label>
              </div>
              {formErrors.code && <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.code}} />}
              <div className="mt-30">
                {codeResendDate || isCodeResend ?
                  <strong>Отправить код повторно можно будет через {isCodeResend ? '00:59' : <TimeCounter
                    targetDate={codeResendDate}
                    onComplete={() => setCodeResendDate(null)}
                  />}</strong>
                  :
                  <div className={`retry ${loading.codeForm ? '_loading' : ''}`} onClick={() => sendRestoreCode()}>Отправить код повторно</div>
                }
              </div>
              {errors.valueForm && <div className="text-light mt-30" dangerouslySetInnerHTML={{__html: errors.valueForm}} />}
              {errors.codeForm && <div className="text-light mt-30" dangerouslySetInnerHTML={{__html: errors.codeForm}} />}
              <Button
                className={`p-button-secondary p-button-white mt-40 min-w-2 ${loading.codeForm ? 'p-loader' : ''}`}
                label="Подтвердить"
                type="submit"
                onClick={() => {
                  setTouched(Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: true }), {}));
                }}
              />
            </div>
          </Form>
        )}
      </Formik>

      <div className="mt-40">
        <a href={'#'} className="link-back" onClick={(e) => {
          e.preventDefault();
          store.setModal('restorePassword');
        }}>Назад</a>
      </div>
    </Modal>
  }

  return <Modal size='xs' title={'Восстановление пароля'} name={modalName}>
    <Formik
      initialValues={{
        value: '',
      }}
      validationSchema={valueFormSchema}
      // validator={() => {}}
      onSubmit={(values) => {
        sendRestoreCode(values.value);
      }}
    >
      {({ values, setFieldValue, touched, setTouched, errors: formErrors }) => (
        <Form>
          <div className="p-float-label">
            <InputText
              id="restoreValue"
              name="restoreValue"
              className={(formErrors.value && touched.value) || isError ? 'p-invalid' : ''}
              value={values.value}
              onChange={(e) => setFieldValue('value', e.target.value)}
            />
            <label htmlFor="restoreValue">Телефон или e-mail</label>
          </div>
          {isError && <span className="p-error">Пользователь не найден</span>}
          {formErrors.value && <span className="p-error" dangerouslySetInnerHTML={{__html: formErrors.value}} />}
          {errors.valueForm && <div className="text-light mt-30" dangerouslySetInnerHTML={{__html: errors.valueForm}} />}

          <div className="text-light mt-30">
            Если вы укажете зарегистрированный в магазине e-mail, то мы вышлем на него инструкцию по восстановлению пароля. Если вы укажете зарегистрированный в магазине номер телефона, то мы вышлем на него проверочный код.
          </div>
          <Button
            className={`p-button-secondary p-button-white min-w-2 mt-40 ${loading.codeForm ? 'p-loader' : ''}`}
            label="Продолжить"
            type="submit"
            onClick={() => {
              setTouched(Object.keys(values).reduce((acc, item) => ({ ...acc, [item]: true }), {}));
            }}
          />
        </Form>
      )}
    </Formik>

    <div className="mt-40">
      <a href={'#'} className="link-back" onClick={(e) => {
        e.preventDefault();
        store.setModal('auth', { isEmail: true });
      }}>Назад</a>
    </div>
  </Modal>
}

export default inject('store')(observer(RestorePasswordModal));
