export function formatNumber(number) {
  const numberString = number.toString().replace(/\s/g, '');
  return typeof number !== 'undefined' ? `<nobr>${Number(numberString).toLocaleString('ru-Ru')}</nobr>` : '';
}

export function formatPrice(price) {
  return typeof price !== 'undefined' ? `<nobr>${Number(price).toLocaleString('ru-Ru')} ₽</nobr>` : '';
}

export function pluralize(number, titles) {
  const cases = [2, 0, 1, 1, 1, 2];

  return titles[(number % 100 > 4 && number % 100 < 20)
    ? 2
    : cases[(number % 10 < 5)
      ? number % 10
      : 5]];
}

export function debounce(func, timeout = 300) {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

export function markResults(results, value, key = 'value') {
  return results.map((item) => ({
    ...item,
    markedValue: item[key].replace(new RegExp(`(${value})`, 'gi'), '<mark style="background: transparent;color:#ffffff;font-weight: 500;">$1</mark>'),
  }));
}

export function getApiPath(path) {
  if(window.location.href.match(/(localhost|bstd)/)){
    return '/api/';// чтоб на localhost и bstd отдавало ошибку
  }
  return `/api/casarte/${path === '/' ? '' : `${path.replace(/^\/|\/$/g, '')}/`}`
}
