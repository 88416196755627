import React from 'react';
import App1 from '../../assets/images/appstore.png';
import App2 from '../../assets/images/googleplay.png';
import Swiper from "react-id-swiper";
import Plane from './Plane';

const path = process.env.REACT_APP_ASSETS_PATH;
const swiperParamsSlides = {
  spaceBetween: 8,
  slidesPerView: 1.11,
  //speed: 350,
  navigation: {
    nextEl: '.product-page__slides__swiper__arr._next',
    prevEl: '.product-page__slides__swiper__arr._prev',
  },
  pagination: {
    el: '.product-page__slides__swiper__pagination',
    clickable: true,
  },
  uniqueNavElements: false,
  slidesOffsetAfter: 6,
  slidesOffsetBefore: 6,
  breakpoints: {
    801: {
      slidesPerView: 3,
      slidesOffsetAfter: 0,
      slidesOffsetBefore: 0,
    }
  }
};

const EvoSection = ({ assetsPathName, productName, sectionTitle = null, sectionText = null, planeTitle = null, planeText = null, desc = null, videoBtn = true, itemClass = '', picFormat = "jpg", videoBlocks=null }) => {
  return (
    <div className="product-page__section evo-id">
      <div className="wrap">
        <h3 className="product-page__section-title">
          {
            sectionTitle ? sectionTitle : <>Дистанционное<br />управление</>
          }
        </h3>
        <p className="product-page__section-text _wide">
          {
            sectionText ? sectionText : <>Контроль и управление с помощью мобильного приложения</>
          }
        </p>
        <div className="product-page__section-apps">
          <a href="https://apps.apple.com/ru/app/evo-клуб-привилегий-от-haier/id1542043377" target='_blank'><img src={App1} alt='' /></a>
          <a href="https://play.google.com/store/apps/details?id=ru.haier.app" target='_blank'><img src={App2} alt='' /></a>
          {/* <MediaQuery maxWidth={MOBILE_WIDTH}>
            <a href="https://apps.apple.com/ru/app/evo-клуб-привилегий-от-haier/id1542043377" target='_blank'><img src={App1M} alt='' /></a>
            <a href="https://play.google.com/store/apps/details?id=ru.haier.app" target='_blank'><img src={App2M} alt='' /></a>
          </MediaQuery>
          <MediaQuery minWidth={MOBILE_WIDTH_1}>
            <a href="https://apps.apple.com/ru/app/evo-клуб-привилегий-от-haier/id1542043377" target='_blank'><img src={App1} alt='' /></a>
            <a href="https://play.google.com/store/apps/details?id=ru.haier.app" target='_blank'><img src={App2} alt='' /></a>
          </MediaQuery> */}
        </div>
        <div className="product-page__grid">
          <Plane
            assetsPathName={assetsPathName}
            itemClass={`large ${itemClass}`}
            wrapClass="bottom-left"
            titleClass="large"
            picName="10"
            picFormat={picFormat}
            title={planeTitle ? planeTitle : <>Управляется<span className="br-m" /> через<br /> приложение evo</>}
            text={planeText ? planeText : <>Выбор режима работы, настройка скорости вентилятора <span className='br-d' />и&nbsp;управление температурой на&nbsp;вашем смартфоне.</>}
            detailText={<></>}
            layer={0}
            videoBtn={videoBtn}
          />
          <div className={`product-page__slides${productName === '' ? '' : ' _x3'}`}>
            {productName === 'double-drum' ?
              <Swiper {...swiperParamsSlides}>
                <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/5.jpg)` }}>
                  <span className="product-page__slide__title">Удаленное управление</span>
                  <span className="product-page__slide__text">Запуск программ стирки, сушки и&nbsp;ухода.</span>
                </div>
                <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/13.jpg)` }}>
                  <span className="product-page__slide__title">Мониторинг</span>
                  <span className="product-page__slide__text">Таймер завершения и&nbsp;детали активной программы доступны в&nbsp;приложении при&nbsp;любом способе запуска.</span>
                </div>
                <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/6.jpg)` }}>
                  <span className="product-page__slide__title">Дополнительные программы</span>
                  <span className="product-page__slide__text">Более 20&nbsp;специальных программ стирки, сушки и&nbsp;ухода.</span>
                </div>
                <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/7.jpg)` }}>
                  <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                  <span className="product-page__slide__text">Приложение пришлет уведомление о&nbsp;завершении программы, напомнит о&nbsp;необходимости обслуживания устройства.</span>
                </div>
              </Swiper>
              : productName === 'french-door' ?
                <Swiper {...swiperParamsSlides}>
                  <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/8.jpg)` }}>
                    <span className="product-page__slide__title">Охлаждение напитков и&nbsp;генерация льда</span>
                    <span className="product-page__slide__text">Охлаждение до&nbsp;нужной температуры с&nbsp;учетом вида напитка, объема, типа тары и&nbsp;др. Настройка режима генерации льда.</span>
                  </div>
                  <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/9.jpg)` }}>
                    <span className="product-page__slide__title">Удаленное управление</span>
                    <span className="product-page__slide__text">Настройка температурных режимов в&nbsp;разных отделениях и&nbsp;зонах.</span>
                  </div>
                  <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/10.jpg)` }}>
                    <span className="product-page__slide__title">Мониторинг</span>
                    <span className="product-page__slide__text">Отслеживание условий хранения продуктов: температуры, уровня кислорода и&nbsp;влажности.</span>
                  </div>
                  <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/11.jpg)` }}>
                    <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                    <span className="product-page__slide__text">Настройка таймеров и&nbsp;уведомлений, предупреждение об&nbsp;открытой дверце, мониторинг технического состояния.</span>
                  </div>
                </Swiper>
                : productName === 'twin-wine-zone' ?
                  <Swiper {...swiperParamsSlides}>
                    <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/15.jpg)` }}>
                      <span className="product-page__slide__title">Удаленное<br />управление</span>
                      <span className="product-page__slide__text">Настройка температурных режимов в&nbsp;зонах, выбор типа напитка (красное, белое, игристое вино).</span>
                    </div>
                    <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/16.jpg)` }}>
                      <span className="product-page__slide__title">Мониторинг</span>
                      <span className="product-page__slide__text">Отслеживание уровня влажности в&nbsp;каждой зоне.</span>
                    </div>
                    <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/17.jpg)` }}>
                      <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                      <span className="product-page__slide__text">Предупреждение об&nbsp;открытой дверце, мониторинг технического состояния устройства.</span>
                    </div>
                  </Swiper>
                  : productName === 'oven-casarte' ?
                    <Swiper {...swiperParamsSlides}>
                      <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/18.jpg)` }}>
                        <span className="product-page__slide__title">Удаленное управление</span>
                        <span className="product-page__slide__text">Дистанционный выбор, настройка и&nbsp;старт программ в&nbsp;мобильном приложении.</span>
                      </div>
                      <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/19.jpg)` }}>
                        <span className="product-page__slide__title">Уведомления и&nbsp;статусы</span>
                        <span className="product-page__slide__text">Получайте оповещения о&nbsp;завершении программы готовки и&nbsp;состоянии устройства.</span>
                      </div>
                      <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/20.jpg)` }}>
                        <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                        <span className="product-page__slide__text">Отслеживайте процесс готовки в&nbsp;мобильном приложении.</span>
                      </div>
                    </Swiper>
                    : productName === 'dishwasher-casarte' ?
                      <Swiper {...swiperParamsSlides}>
                        <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/21.webp)` }}>
                          <span className="product-page__slide__title">Удаленное управление</span>
                          <span className="product-page__slide__text">Настройка, запуск, пауза, отложенный старт для&nbsp;программ&nbsp;мытья.</span>
                        </div>
                        <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/22.webp)` }}>
                          <span className="product-page__slide__title">Дополнительные программы и&nbsp;настройки</span>
                          <span className="product-page__slide__text">Дополнительные программы мытья, настройка мягкости воды и&nbsp;дозирования ополаскивателя.</span>
                        </div>
                        <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/23.webp)` }}>
                          <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                          <span className="product-page__slide__text">Приложение пришлет уведомление о&nbsp;завершении программы, напомнит о&nbsp;необходимости добавления соли или&nbsp;ополаскивателя.</span>
                        </div>
                        <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/28.webp)` }}>
                          <span className="product-page__slide__title">Мониторинг</span>
                          <span className="product-page__slide__text">Таймер завершения и&nbsp;детали активной программы доступны в&nbsp;приложении при&nbsp;любом способе запуска.</span>
                        </div>
                      </Swiper>
                      : productName === 'double-drawer' ?
                        <Swiper {...swiperParamsSlides}>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/24.jpg)` }}>
                            <span className="product-page__slide__title">Удаленное управление</span>
                            <span className="product-page__slide__text">Настройка, запуск, пауза, отложенный старт для&nbsp;программ&nbsp;мытья.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/25.jpg)` }}>
                            <span className="product-page__slide__title">Дополнительные программы и&nbsp;настройки</span>
                            <span className="product-page__slide__text">Дополнительные программы мытья, настройка мягкости воды и&nbsp;дозирования ополаскивателя.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/26.jpg)` }}>
                            <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                            <span className="product-page__slide__text">Приложение пришлет уведомление о&nbsp;завершении программы, напомнит о&nbsp;необходимости добавления соли или&nbsp;ополаскивателя.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/27.jpg)` }}>
                            <span className="product-page__slide__title">Мониторинг</span>
                            <span className="product-page__slide__text">Таймер завершения и&nbsp;детали активной программы доступны в&nbsp;приложении при&nbsp;любом способе запуска.</span>
                          </div>
                        </Swiper>
                      : productName === 'styler-casarte' ?
                        <Swiper {...swiperParamsSlides}>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/33.webp)` }}>
                            <span className="product-page__slide__title">Удаленное <br/>управление</span>
                            <span className="product-page__slide__text">Дистанционный запуск программ сушки и&nbsp;ухода.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/34.webp)` }}>
                            <span className="product-page__slide__title">Мониторинг</span>
                            <span className="product-page__slide__text">Таймер завершения программы и&nbsp;детали активной программы доступны при&nbsp;любом способе запуска.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/35.webp)` }}>
                            <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                            <span className="product-page__slide__text">Приложение пришлет уведомление о&nbsp;завершении программы, напомнит о&nbsp;необходимости обслуживания устройства.</span>
                          </div>
                        </Swiper>
                      : productName === 'velato' ?
                        <Swiper {...swiperParamsSlides}>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/29.webp)` }}>
                            <span className="product-page__slide__title">Удаленный доступ</span>
                            <span className="product-page__slide__text">Контроль температуры в&nbsp;помещении через смартфон или&nbsp;планшет.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/30.webp)` }}>
                            <span className="product-page__slide__title">Дополнительные возможности</span>
                            <span className="product-page__slide__text">Доступ к&nbsp;расширенным настройкам и&nbsp;функциям.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/31.webp)` }}>
                            <span className="product-page__slide__title">Управление голосом</span>
                            <span className="product-page__slide__text">Настройка температуры и&nbsp;режимов работы с&nbsp;помощью колонок Алиса или&nbsp;Салют.</span>
                          </div>
                        </Swiper>
                      : productName === 'multi-split' ?
                      <Swiper {...swiperParamsSlides}>
                        <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/29.webp)` }}>
                          <span className="product-page__slide__title">Удаленный доступ</span>
                          <span className="product-page__slide__text">Контроль температуры из&nbsp;любого места без&nbsp;пульта дистанционного управления.</span>
                        </div>
                        <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/32.webp)` }}>
                          <span className="product-page__slide__title">Работа по&nbsp;графику</span>
                          <span className="product-page__slide__text">Установка графика работы кондиционера с&nbsp;предпочтительными настройками.</span>
                        </div>
                        <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/31.webp)` }}>
                          <span className="product-page__slide__title">Управление голосом</span>
                          <span className="product-page__slide__text">Настройка температуры и&nbsp;режимов работы кондиционера с&nbsp;помощью Алисы или&nbsp;Салют.</span>
                        </div>
                      </Swiper>
                      :
                        <Swiper {...swiperParamsSlides}>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/1${productName === 'triano' ? '-2' : ''}.jpg)` }}>
                            <span className="product-page__slide__title">Удаленный доступ</span>
                            <span className="product-page__slide__text">Контролируйте температуру из&nbsp;любого места без&nbsp;пульта дистанционного управления.</span>
                          </div>
                          {/* <div className="product-page__slide" style={{backgroundImage: `url(${path}images/products/evo/2.jpg)`}}>
                  <span className="product-page__slide__title">Работа по&nbsp;графику</span>
                  <span className="product-page__slide__text">Устанавливайте графики <span className='br-m' />работы кондиционера с&nbsp;предпочтительными настройками.</span>
                </div> */}
                          {/* <div className="product-page__slide" style={{backgroundImage: `url(${path}images/products/evo/3.jpg)`}}>
                  <span className="product-page__slide__title">Онлайн-мониторинг</span>
                  <span className="product-page__slide__text">Проверяйте текущее качество воздуха в&nbsp;помещении, влажность и&nbsp;температуру.</span>
                </div> */}
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/4.jpg)` }}>
                            <span className="product-page__slide__title">Всегда в&nbsp;курсе</span>
                            <span className="product-page__slide__text">Получайте уведомления о&nbsp;статусе <span className='br-d' />устройства и&nbsp;неполадках.</span>
                          </div>
                          <div className="product-page__slide" style={{ backgroundImage: `url(${path}images/products/evo/14.jpg)` }}>
                            <span className="product-page__slide__title">Управление голосом</span>
                            <span className="product-page__slide__text">Регулируйте температуру и&nbsp;режимы работы кондиционера с&nbsp;помощью Алисы.</span>
                          </div>
                        </Swiper>
            }
            <div className="product-page__slides__swiper__arr _prev swiper-button-disabled" />
            <div className="product-page__slides__swiper__arr _next swiper-button-disabled" />
          </div>
          {videoBlocks}
          {
            desc && <p className="product-page__slides__desc">*&nbsp;Дизайн приложения и&nbsp;текст уведомлений может отличаться от&nbsp;актуальной версии приложения evo.</p>
          }
        </div>

      </div>
    </div>
  )
}

export default EvoSection;
