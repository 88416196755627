import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {inject, observer} from "mobx-react";

const AppContainer = ({ history, children, store }) => {
  useEffect(() => {
    return history.listen(() => {
      if (window.overlayQueue) {
        window.overlayQueue.reset();
      }
      store.setHeader(null);
    });
  }, [history, store]);

  return children;
}

export default withRouter(inject('store')(observer(AppContainer)));
