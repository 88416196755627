import './style.scss';
import React, {useEffect, useState, useRef} from 'react';
import {withRouter} from 'react-router-dom';
import {YMaps, Map, ZoomControl, Placemark} from 'react-yandex-maps';
import {disableBodyScroll, enableBodyScroll, formatTel, isTouchDevice} from "../../common/utils";
import useOutsideClick from '../utils/useOutsideClick';
import {inject} from "mobx-react";
import axios from "axios";
import pageDataDealers from "../../json/contact2.json";
import menuData from "../../json/catalogItems.json";
import {getApiPath} from "../utils";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";
import PageContainer from "../PageContainer";
import MVIDEO_LOGO from "../../assets/images/contact2/mvideo-logo.svg";
import MediaQuery, { useMediaQuery }  from 'react-responsive';
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from '../../common/const';
import { scrollTo } from "../../common/utils";
import {isDesktop} from 'react-device-detect';
import Swiper from "react-id-swiper";

let markersRefs = [];

const path = process.env.REACT_APP_ASSETS_PATH;
const iconPath = `${path}images/contact2/marker-`;
const mapIcons = {
  1: {
    default: {
      href: `${iconPath}1.svg`,
      size: [104, 41],
      offset: [-52, 0],
    },
    hover: {
      href: `${iconPath}1-hover.svg`,
      size: [104, 41],
      offset: [-52, 0],
    },
    active: {
      href: `${iconPath}1-active.svg`,
      size: [112, 49],
      offset: [-56, 0],
    }
  },
  2: {
    default: {
      href: `${iconPath}2.svg`,
      size: [32, 37],
      offset: [-16, 0],
    },
    hover: {
      href: `${iconPath}2-hover.svg`,
      size: [32, 37],
      offset: [-16, 0],
    },
    active: {
      href: `${iconPath}2-active.svg`,
      size: [40, 47],
      offset: [-20, 0],
    }
  },
  3: {
    default: {
      href: `${iconPath}3.svg`,
      size: [32, 38],
      offset: [-16, 0],
    },
    hover: {
      href: `${iconPath}3-hover.svg`,
      size: [32, 37],
      offset: [-16, 0],
    },
    active: {
      href: `${iconPath}3-active.svg`,
      size: [40, 46],
      offset: [-20, 0],
    }
  },
}

const Page = ({ match, store }) => {
  const isDesktopMediaQuery = useMediaQuery({ minWidth: MOBILE_WIDTH_1 });
  const [view, setView] = useState(isDesktopMediaQuery ? 1 : 0);
  const [data, setData] = useState(null);
  const [tags, setTags] = useState(null);
  const [cityLabel, setCityLabel] = useState(null);
  const [city, setCity] = useState(null);
  const [tag, setTag] = useState(0);
  const [listHighlight, setListHighlight] = useState(null);
  const [placemark, setPlacemark] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {ref, isVisible, setIsVisible} = useOutsideClick(false);
  const [responseError, setResponseError] = useState();
  const [mapRef, setMapRef] = useState(null);
  const [boundsChange, setBoundsChange] = useState(1);
  
  const ymaps = useRef(null);
  const listRef = useRef(null);
  const listItemRefs = useRef({});
  const mapWrapRef = useRef(null);

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      const { cities = [], tags } = response.data.pageContent || {};
      const city = cities.find((item) => item.title === response.data.header?.geolocation?.value);// || cities[0];// подставляем город из хедера, иначе null

      if(response.data.seoData?.pageTitle){
        document.title = response.data.seoData.pageTitle
      }

      setTags(tags);
      setData(cities);
      setCity(city);

      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
      store.setFeedbackForm(window.feedbackForm);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      const { cities = [], tags } = pageDataDealers;
      const city = cities.find((item) => item.title === headerData?.geolocation?.value);// || cities[0];// подставляем город из хедера, иначе null
      
      setTags(tags);
      setData(cities);
      setCity(city);

      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
      store.setFeedbackForm(feedbackFormData);
    });
    return () => {
      setData(null);
      setCity(null);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  // При инициализации карты устанавливаем город
  useEffect(() => {
    mapRef?.geoObjects.events.once('boundschange', function (){
      handleSelectCity(city);
    })
  }, [mapRef]);

  const handleSelectCity = (city) => {
    setCity(city);
    setIsVisible(false);

    const options = {
      zoomMargin: 70
    }

    if(city){
      const points = [];
      
      data
        .find((item) => item.title === city.title).items
        .map((item) => points.push(item.coords.flat()));

      if(points.length===1){
        mapRef.setCenter(points[0], 14);
      }
      else{
        const bounds = ymaps.current.util.bounds.fromPoints(points);
        mapRef.setBounds(bounds, options);
      }
    }
    else{
      mapRef.setBounds(mapRef.geoObjects.getBounds(), options);
    }
  }

  const handleSelectTag = (tagId) => {
    markersRefs = [];
    setTag(tagId);
  }

  const handleInfoBtnClick = (tagId) => {
    handleSelectTag(tagId);
    scrollTo('map-anchor-id', 0);
  }

  const handleNearestClick = () => {
    const center = mapRef.getCenter();
    const nearestArr = getAllItems()
    .filter((item) => tag ? item.type === tag : true)
    .sort((itemA, itemB) =>
      ymaps.current.coordSystem.geo.distance(center, itemA.coords.flat()) - ymaps.current.coordSystem.geo.distance(center, itemB.coords.flat())
    ).slice(0, 1);

    if(nearestArr && nearestArr.length){
      const nearestItem = nearestArr[0];

      mapRef.setCenter(nearestItem.coords.flat(), mapRef.getZoom(), { duration: 300 });
      openBalloon(nearestItem);
    }
  }

  const onBoundsChange = () => {
    markersRefs = [];
    setBoundsChange(boundsChange + 1);
    checkOneCity();
  }

  const checkOneCity = () => {
    const visibleItems = mapRef && getAllItems()
      .filter((item) => tag ? item.type === tag : true)
      .filter((item) => ymaps.current.util.bounds.containsPoint(
        mapRef.getBounds(),
        item.coords.flat()
      ))
    
    if(visibleItems && visibleItems.length){
      const testCity = visibleItems[0].city;
      const testItems = visibleItems.filter((item) => testCity.title === item.city.title)

      if(testItems.length === visibleItems.length){
        setCityLabel(null);
        setCity(testCity);
      }
      else{
        setCityLabel('Все магазины');
        setCity(null);
      }
    }
    else{
      setCityLabel('Все магазины');
      setCity(null);
    }
  }

  const getAllItems = () => {
    const arr = [];

    if(!data){
      return arr;
    }

    for(let i=0; i<data.length; i++){
      for(let j=0; j<data[i].items.length; j++){
        let obj = data[i].items[j];
        obj.city = data[i];
        arr.push(obj);
      }
    }

    return arr;
  }

  const listItems = mapRef && boundsChange && getAllItems()
    .filter((item) => tag ? item.type === tag : true)
    .filter((item) => ymaps.current.util.bounds.containsPoint(
      mapRef.getBounds(),
      item.coords.flat()
    ))
    .sort(
      (itemA, itemB) =>
        itemA.name.localeCompare(itemB.name),
    )
    .sort(
      (itemA, itemB) =>
        itemA.type - itemB.type,
    )
    .map((item, i) =>
    <div
      ref={listItemRefs.current[item.name] ??= { current: null }}
      key={item.coords && item.coords.join('') + item.name}
      className={`
        contact-page-2__list__item${item.type===1&&tag!==1?' _boutique':''}
        ${item.name === listHighlight ? 'contact-page-2__list__item_state_active' : ''}
      `}
      onClick={() => {
        mapRef.setCenter(item.coords.flat(), mapRef.getZoom(), { duration: 300 });
        openBalloon(item);
      }}
    >
      {item.image && <div className="contact-page-2__list__item__pic" style={{backgroundImage: `url(${item.image})`}} />}
      {item.type===1 && <p className="contact-page-2__list__item__label">скоро открытие</p>}
      {item.name && <p className={`contact-page-2__list__item__title${item.type===1?' _boutique':''}`}>{item.name}</p>}
      {item.address && <p className="contact-page-2__list__item__address" dangerouslySetInnerHTML={{__html:item.address}} />}
      {item.address2 && <p className="contact-page-2__list__item__address">{item.address2}</p>}
      {item.time && <p className="contact-page-2__list__item__time">{item.time}</p>}
      {
        item.type===1 && item.tel && item.tel.length ?
        <div className="contact-page-2__list__item__tels">
          {item.tel.map((tel, index) =>
            <a
              key={tel + index}
              href={'tel:' + formatTel(tel)}
              className="contact-page-2__list__item__tel"
            >{formatTel(tel)}</a>
          )}
        </div>
        : null}
      {/* {item.email && <a href={'mailto:' + item.email} rel="noreferrer" className="contact-page-2__list__item__email">{item.email}</a>} */}
      {
        item.site || item.route ?
        <div className="contact-page-2__list__item__links">
          {/* {item.site && <a href={item.site} rel="noreferrer" target="_blank" className="contact-page-2__list__item__link _a">Перейти на сайт</a>} */}
          {item.route && <a href={item.route} rel="noreferrer" target="_blank" className="contact-page-2__list__item__link _a">Построить маршрут</a>}
        </div>
        : null
      }
    </div>
  )

  const openBalloon = React.useCallback((item) => {
    const isMobileMedia = window.matchMedia(`(max-width: ${ MOBILE_WIDTH }px)`).matches;
    const mapItem = markersRefs.find((y) => y.options._options.key === (item?.coords && item?.coords.join('') + item?.name));

    setPlacemark(item);
    setTimeout(() => {
      setListHighlight(item.name);
    }, 100);

    if(isMobileMedia) {
      setView(0);
      setIsModalOpen(true);

      mapRef.balloon.close();

      if(!document.body.classList.contains('scroll-lock')) {
        if(mapWrapRef.current.offsetTop !== window.scrollY) {
          mapWrapRef.current.scrollIntoView({ behavior: 'smooth' });

          const wrapDisableBodyScroll = () => {
            disableBodyScroll();

            document.removeEventListener('scrollend', wrapDisableBodyScroll);
          }

          document.addEventListener('scrollend', wrapDisableBodyScroll, { once: true });
        } else {
          disableBodyScroll();
        }
      }
    } else {
      setIsModalOpen(false);

      mapItem && mapItem.balloon.open();

      enableBodyScroll();
    }
  }, [mapRef]);

  const closeBalloon = () => {
    setIsModalOpen(false);
    setListHighlight(null);

    enableBodyScroll();
  }

  const onPlacemarkClick = e => {
    const item = e.get('target').properties.get('placemarkData').item;

    mapRef.setCenter(item.coords, mapRef.getZoom(), { duration: 300 });

    listRef.current?.scrollTo({
      top: listItemRefs.current[item.name].current.offsetTop,
      left: 0,
      behavior: 'smooth',
    });

    setTimeout(() => {
      openBalloon(item);
    }, 100);
  };

  const mapItems = getAllItems()
      .filter((item) => item.coords?.join('').length)
      .filter((item) => tag ? item.type === tag : true)
      .sort(
        (itemA, itemB) =>
          itemB.type - itemA.type,
      )
      .map((item) =>
    <Placemark
      key={item.coords && item.coords.join('') + item.name}
      geometry={item.coords.flat()}
      properties={{
        balloonContentBody:
          `<div class="baloon-content">
            ${item.type===1 || item.type===3  ? `<div class="baloon-content__label">${item.type===1?'скоро открытие':'дилер климатической техники'}</div>` : ''}
            ${item.type===2  ? `<img class="baloon-content__logo" src=${MVIDEO_LOGO} alt='М.Видео' title='М.Видео' />` : ''}
            ${item.name ? `<div class="baloon-content__title">${item?.name}</div>` : ''}
            ${item.address ? `<div class="baloon-content__address">${item?.address}</div>` : ''}
            ${item.address2 ? `<div class="baloon-content__address">${item?.address2}</div>` : ''}
            ${item.time ? `<div class="baloon-content__time">${item.time}</div>` : ''}
            ${item.tel && item.tel.map((tel) => `<a href=${'tel:' + formatTel(tel.split(' ').join(''))} class="baloon-content__tel">${formatTel(tel)}</a>`).join('')}
            ${item.email ? `<a href=${'mailto:' + item.email} class="baloon-content__email">${item.email}</a>` : ''}
            ${item.site || item.route ? `<div class="baloon-content__links">` : ''}
            ${item.site ? `<a href=${item.site} target="_blank" class="baloon-content__link _a">Перейти на сайт</a>` : ''}
            ${item.route ? `<a href=${item.route} target="_blank" class="baloon-content__link _a">Построить маршрут</a>` : ''}
            ${item.site || item.route ? `</div>` : ''}
          </div>`,
        placemarkData: { item },
      }}
      modules={['geoObject.addon.balloon']}
      options={{
        key: item.coords && item.coords.join('') + item.name,
        hideIconOnBalloonOpen: false,
        balloonAutoPan: false,
        //balloonOffset: [0, -10],
        openBalloonOnClick: false,
        iconLayout: 'default#image',
        iconImageHref: mapIcons[item.type][item.name === listHighlight ? 'active' : 'default'].href,
        iconImageSize: mapIcons[item.type][item.name === listHighlight ? 'active' : 'default'].size,
        iconImageOffset: mapIcons[item.type][item.name === listHighlight ? 'active' : 'default'].offset,
        zIndex: item.name === listHighlight ? 2 : 1,
      }}
      onClick={onPlacemarkClick}
      instanceRef={ref => {
        ref && markersRefs.push(ref);
      }}
    />
  )
  const selectItems = data && data.sort(
    (itemA, itemB) =>
      itemA.order - itemB.order ||
      itemA.title.localeCompare(itemB.title),
  ).map((item, i) =>
    <div key={i} className={`contact-page-2__select__item ${city && city.title === item.title ? 'active' : ''}`}
         onClick={() => handleSelectCity(item)}>
      {item.title}
    </div>
  )
  const tagsItems = tags && tags.map((item, i) =>
    <div key={i} className={`contact-page-2__head__tags__item ${tag=== item.type ? '_active' : ''}`}
         onClick={() => handleSelectTag(item.type)}>
      {
      i===0?
      <>
        <MediaQuery maxWidth={MOBILE_WIDTH}>
          Все
        </MediaQuery>
        <MediaQuery minWidth={MOBILE_WIDTH_1}>
          Показать все
        </MediaQuery>
      </>
      : item.title}
    </div>
  )

  useEffect(() => {
    function resizeListenerModal() {
      if(mapRef.balloon.isOpen() || isModalOpen) {
        openBalloon(placemark);
      }
    }

    window.addEventListener('resize', resizeListenerModal);

    return () => {
      window.removeEventListener('resize', resizeListenerModal);
    }
  }, [mapRef, placemark, isModalOpen, openBalloon]);

  useEffect(() => {
    const changeMapIcon = (element, type, action) => {
      if(action === 'hover') {
        element.get('target').options
          .set('iconImageHref', mapIcons[type][action].href)
      } else {
        element.get('target').options
          .set('iconImageHref', mapIcons[type][action].href)
          .set('iconImageSize', mapIcons[type][action].size)
          .set('iconImageOffset', mapIcons[type][action].offset);
      }
    }

    mapRef?.geoObjects.events
    .add('balloonclose', function () { setListHighlight(null) })
    .add('mouseenter', function (e) {
      const type = e.get('target').properties.get('placemarkData').item.type;

      if(!e.get('target').balloon.isOpen())
        changeMapIcon(e, type, 'hover')
    })
    .add('mouseleave', function (e) {
      const type = e.get('target').properties.get('placemarkData').item.type;

      if(!e.get('target').balloon.isOpen())
        changeMapIcon(e, type, 'default')
    })
  }, [mapRef]);

  const PlacemarkModal = () => {
    return (
      <div className="placemark-modal">
        <div
          onClick={closeBalloon}
          className="placemark-modal__overlay"
        />
        <div className="placemark-modal__body">
          <div className="baloon-content">
            {(placemark.type===1 || placemark.type===3) && <div className="baloon-content__label">{placemark.type===1?'скоро открытие':'дилер климатической техники'}</div>}
            {placemark.type===2 && <img className="baloon-content__logo" src={MVIDEO_LOGO} alt='М.Видео' title='М.Видео' />}
            {placemark.name && <div className="baloon-content__title">{placemark.name}</div>}
            {placemark.address && <div className="baloon-content__address" dangerouslySetInnerHTML={{__html:placemark.address}} />}
            {placemark.address2 && <div className="baloon-content__address">{placemark.address2}</div>}
            {placemark.time && <p className="baloon-content__time">{placemark.time}</p>}
            {placemark.tel && placemark.tel.map((tel) => <a key={tel} href={'tel:' + formatTel(tel.split(' ').join(''))} className="baloon-content__tel">{formatTel(tel)}</a>)}
            {placemark.email && <a href={'mailto:' + placemark.email} className="baloon-content__email">{placemark.email}</a>}
            {
              (placemark.site || placemark.route) && (
                <div className="baloon-content__links">
                  {placemark.site && <a href={placemark.site} target="_blank" className="baloon-content__link _a">Перейти на сайт</a>}
                  {placemark.route && <a href={placemark.route} target="_blank" className="baloon-content__link _a">Построить маршрут</a>}
                </div>
              )
            }
          </div>
          <button
            onClick={closeBalloon}
            className="placemark-modal__close"
            />
        </div>
      </div>
    )
  }

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="contact-page-2 page">
      <div className="contact-page-2__head">
        <div className="wrap">
          <p className="contact-page-2__head__title">Где купить</p>
          <p className="contact-page-2__head__text">Вы можете приобрести технику Casarte в&nbsp;бутике, магазинах М.Видео и&nbsp;дилерских центрах климатической техники.</p>
          
          <div className="map-anchor-id" />
          <div className="contact-page-2__head__tags">
            {tagsItems}
          </div>
        </div>
      </div>


      <div ref={mapWrapRef} className="contact-page-2__body">
        <div className="contact-page-2__map">
          <YMaps>
            <Map
              defaultState={{
                center: [55.751393,37.583781],
                zoom: 10,
              }}
              onLoad={ymapsInstance => {
                ymaps.current = ymapsInstance;
              }}
              instanceRef={ref => {
                ref && isTouchDevice() && ref.behaviors.disable('scrollZoom');
                ref && !isDesktop && ref.behaviors.disable('drag');
                ref && setMapRef(ref);
              }}
              onBoundsChange={onBoundsChange}
              modules={["util.bounds", "coordSystem.geo"]}
              width="100%" height="100%">
              <ZoomControl options={{size: 'small', position: {right: 20, top: 20}}}/>
              {mapItems}
            </Map>
          </YMaps>
        </div>
        {
          view === 1 && mapRef ?
          <div className="contact-page-2__list">
            {
            !isVisible && 
            <div
              onClick={() => setView(0)}
              className="contact-page-2__list__close"
            />
            }
            <div className={`contact-page-2__select ${isVisible ? 'opened' : ''}`}>
              <div className='contact-page-2__select__value' onClick={() => setIsVisible(true)}>
                {cityLabel || city?.title}
              </div>
              <div className='contact-page-2__select__wrap' ref={ref}>
                <div className='contact-page-2__select__items'>
                  {selectItems}
                </div>
              </div>
            </div>
            {
              listItems.length ?
              <div ref={listRef} className="contact-page-2__list__items">{listItems}</div>
              :
              <div className="contact-page-2__list__no-items">
                <div className="contact-page-2__list__item">
                  <p className="contact-page-2__list__item__title _boutique">Мы не&nbsp;нашли магазинов в&nbsp;вашем городе</p>
                  <p className="contact-page-2__list__item__address">Найдите магазин поблизости или&nbsp;проверьте возможность и&nbsp;сроки доставки в&nbsp;ваш населенный пункт</p>
                  <div className="contact-page-2__list__item__links">
                    <span className="contact-page-2__list__item__link _a" onClick={handleNearestClick}>Найти ближайший магазин</span>
                  </div>
                </div>
              </div>
            }
          </div>
          :
          <div
            onClick={() => setView(1)}
            className="contact-page-2__list__btn"
          >список</div>
        }
      </div>

      <div className="contact-page-2__section _black">
        <div className="wrap">
          <p className="contact-page-2__section__label">скоро открытие</p>
          <h3 className="contact-page-2__section__title">Флагманский бутик Casarte</h3>
          <p className="contact-page-2__section__text">Красота в&nbsp;чистом виде и&nbsp;высочайший стиль жизни – вот что ждет вас в&nbsp;нашем эксклюзивном салоне.</p>
          <span
            onClick={() => handleInfoBtnClick(1)}
            className="contact-page-2__section__btn p-button p-component p-button-primary p-button-white min-w"
          >показать на карте</span>
        </div>
        <div className="wrap _blocks">
          <div className="contact-page-2__info-block">
            <div className="contact-page-2__info-block__video">
              <video loop autoPlay playsInline muted>
                {/* <source src={`${path}images/contact2/info-blocks/casarte-boutique.webm`} type="video/webm" /> */}
                <source src={`${path}images/contact2/info-blocks/casarte-boutique.mp4`} type="video/mp4" />
              </video>
            </div>
          </div>
          <InfoBlock
            picName="1"
            title={<>Эксклюзивный выставочный&nbsp;зал</>}
            text={<>
              <p>Погрузитесь в&nbsp;мир Casarte. Удобное расположение бутика и&nbsp;премиальное пространство сделает ваш визит максимально комфортным и&nbsp;приятным. </p>
            </>}
            className="_reverse"
          />
          <SliderBlock />
          <InfoBlock
            picName="2"
            title={<>Индивидуальные консультации</>}
            text={<>
              <p>В&nbsp;рамках консультации наши эксперты познакомят вас с&nbsp;функциями и&nbsp;возможностями техники Сasarte, а&nbsp;также подберут подходящие модели для&nbsp;ваших индивидуальных потребностей.</p>
            </>}
            className=""
          />
        </div>
      </div>

      <div className="contact-page-2__section _gray">
        <div className="wrap">
          <div className="contact-page-2__section__logo">
            <img src={MVIDEO_LOGO} alt='М.Видео' title='М.Видео' />
          </div>
          <h3 className="contact-page-2__section__title">Брендзоны Casarte в&nbsp;М.Видео</h3>
          <p className="contact-page-2__section__text">В&nbsp;магазинах М.Видео открыто более 30&nbsp;выставочных площадок Casarte.</p>
          <span
            onClick={() => handleInfoBtnClick(2)}
            className="contact-page-2__section__btn p-button p-component p-button-primary p-button-white min-w"
          >показать на карте</span>
        </div>
        <div className="wrap _blocks">
          <InfoBlock
            picName="3"
            title={<>30 уникальных пространств</>}
            text={<>
              <p>В&nbsp;магазинах М.Видео представлена широкая линейка&nbsp;техники Casarte.<br />Пожалуйста, уточните наличие интересующих вас товаров перед визитом.</p>
            </>}
            className="_reverse"
          />
          <InfoBlock
            picName="4"
            title={<>Расширьте свое представление о&nbsp;Casarte</>}
            text={<>
              <p>Консультанты М.Видео, прошли дополнительное обучение, чтобы рассказать вам о&nbsp;всех преимуществах техники Casarte и&nbsp;помогут с&nbsp;выбором.</p>
            </>}
            className=""
          />
        </div>
      </div>

      <div className="contact-page-2__section _black _last">
        <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="wrap">
          <h3 className="contact-page-2__section__title _min">Дилерские центры с&nbsp;климатическим оборудованием</h3>
          <p className="contact-page-2__section__text">Партнеры предоставляют услуги по&nbsp;установке климатического оборудования.</p>
          <span
            onClick={() => handleInfoBtnClick(3)}
            className="contact-page-2__section__btn p-button p-component p-button-primary p-button-white min-w"
          >показать на карте</span>
        </div>
        <div className="wrap _blocks">
          <div className="contact-page-2__info-block">
            <div className="contact-page-2__info-block__pic" style={{backgroundImage: `url(${path}images/contact2/info-blocks/5-m.jpg)`}} />
          </div>
        </div>
        </MediaQuery>
        <MediaQuery minWidth={MOBILE_WIDTH_1}>
          <div className="wrap">
            <InfoBlock
              picName="5"
              title={<>Дилерские центры с&nbsp;климатическим оборудованием</>}
              text={<>
                <p>Партнеры предоставляют услуги по&nbsp;установке климатического оборудования.</p>
              </>}
              className="_reverse"
              linkId={1}
              handleInfoBtnClick={handleInfoBtnClick}
            />
          </div>
        </MediaQuery>
      </div>
      { isModalOpen && <PlacemarkModal /> }
    </div>
    </PageContainer>
  );
}

export default inject('store')(withRouter(Page));

const SliderBlock = () => {
    const swiperParamsPhoto = {
        autoplay: {
            delay: 3000,
        },
        speed: 700,
        spaceBetween: 0,
        slidesPerView: 1,
        speed: 500,
        loop: true,
        navigation: {
          nextEl: '.contact-page-2__swiper__arr._next',
          prevEl: '.contact-page-2__swiper__arr._prev',
        },
        pagination: {
          el: '.contact-page-2__swiper__pagination',
          clickable: true
        },
        uniqueNavElements: false,
    };

    return (
        <div className="contact-page-2__swiper">
            <div className="contact-page-2__swiper__wrap">
                <Swiper {...swiperParamsPhoto}>
                    {[1,4,2,3,5,6,7].map((item, i) => // TEMP
                    <div key={i}>
                        <div className="contact-page-2__swiper__item">
                            <MediaQuery maxWidth={MOBILE_WIDTH}>
                                <img alt="" src={`${path}images/contact2/slider/${item}-m.webp`} />
                            </MediaQuery>
                            <MediaQuery minWidth={MOBILE_WIDTH_1}>
                                <img alt="" src={`${path}images/contact2/slider/${item}-d.webp`} />
                            </MediaQuery>
                        </div>
                    </div>
                    )}
                </Swiper>
            </div>
            <div className="contact-page-2__swiper__nav">
                <div className="contact-page-2__swiper__arr _prev" />
                <div className="contact-page-2__swiper__arr _next" />
            </div>
        </div>
    )
}

const InfoBlock = (props) => {

  return (
    <div className={`contact-page-2__info-block ${props.className}`}>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="contact-page-2__info-block__pic" style={{backgroundImage: `url(${path}images/contact2/info-blocks/${props.picName}-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="contact-page-2__info-block__pic" style={{backgroundImage: `url(${path}images/contact2/info-blocks/${props.picName}-d.jpg)`}} />
      </MediaQuery>
      <div className="contact-page-2__info-block__content">
        <p className="contact-page-2__info-block__title">{props.title}</p>
        <div className="contact-page-2__info-block__text">{props.text}</div>
        {props.linkId &&
          <>
          {
            props.linkId === 1 ?
            <p className="contact-page-2__info-block__link _a" onClick={() => props.handleInfoBtnClick(3)}>Показать на карте</p>
            : null
          }
          </>
        }
      </div>
    </div>
  )
}
