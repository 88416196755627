import './style.scss';
import React, { useEffect, useState, useMemo } from 'react';
import {inject, observer} from "mobx-react";
import { disableBodyScroll, enableBodyScroll, numWithSpaces } from '../../common/utils';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

// const path = process.env.REACT_APP_ASSETS_PATH;

const Modal = ( {store} ) => {
  const name = 'partnerCart';
  const data = store.modal[name];
  const paymentData = data?.data;

  const handleClose = (e) => {
    if(e.target.className.indexOf('cart-page__partner-modal__outer-wrap') >= 0){
      store.setModal(name, null);
    }
  }

  paymentData ? disableBodyScroll() : enableBodyScroll();

  const [selected, setSelected] = useState(null);
  const [period, setPeriod] = useState(null);

  const creditModalItems = useMemo(() => {
    const { items = [] } = paymentData?.find((item) => item.isChooser) || {};

    return items;
  }, [paymentData]);

  const paymentsKeys = useMemo(() => {
    return selected?.payments ? Object.keys(selected.payments).map((key) => ({ label: key, value: key })) : [];
  }, [selected]);

  const payment = useMemo(() => {
    return selected?.payments[period];
  }, [selected, period]);

  const creditPlan = useMemo(() => {
    if (selected && period && payment) {
      return {
        id: selected.id,
        name: selected.name,
        prodiver: selected.provider,
        logo: selected.logo,
        title: selected.title,
        period,
        payment,
      };
    }
    return null;
  }, [selected, period, payment]);

  useEffect(() => {
    let selected;
    let period;

    if (creditModalItems.length) {
      if (store.creditPlan) {
        selected = creditModalItems.find((item) => item.name === store.creditPlan.name);
        period = store.creditPlan.period;
      } else {
        [selected] = creditModalItems;
        period = Object.keys(selected.payments)[0];
      }
      setSelected(selected);
      setPeriod(period);
    }
  }, [store.creditPlan, creditModalItems]);

  return (
    data ?
    <div
      className={'cart-page__partner-modal _' + data.status + ' '+data?.data?.className}
      onClick={(e) => handleClose(e)}
    >
      <div className="cart-page__partner-modal__outer-wrap">
        <div className="cart-page__partner-modal__inner-wrap">
          <div className="cart-page__partner-modal__body">
            <div className="cart-page__partner-modal__close" onClick={() => store.setModal(name, null)} />
            <span className="cart-page__partner-modal__title">Выберите одного из наших партнеров</span>
            <div className="cart-page__partner-modal__partners">
              <div className="p-fields-grid">
                {creditModalItems.map((item) =>
                  <div key={item.id} className="p-fields-grid__item d-33 m-100">
                    <Partner selected={selected?.id === item.id} item={item} setSelected={setSelected} />
                  </div>
                )}
              </div>
              <div className="cart-page__partner-modal__wrap">
                <span className="p-float-label">
                  <Dropdown
                    className=""
                    inputId="dropdown"
                    value={period}
                    onChange={(e) => setPeriod(e.value)}
                    options={paymentsKeys}
                    optionLabel="label"
                    appendTo="self"
                  />
                  <label htmlFor="dropdown">Срок рассрочки</label>
                </span>
                <span className="cart-page__partner-modal__rent">
                  <span className="cart-page__partner-modal__rent__text">Платёж в месяц</span>
                  <span className="cart-page__partner-modal__rent__val">{numWithSpaces(payment)} <span className="rub">P</span></span>
                </span>
              </div>
              <Button className="p-button-secondary p-button-white min-w" label="Продолжить" onClick={() => {
                store.setCreditPlan(creditPlan);
                store.setModal(name, null);
              }} />
            </div>
          </div>
        </div>
      </div>
    </div>
    : null
  )
}

export default inject('store')(observer(Modal));

const Partner = observer((props) => {
  console.log({ props });
  return (
    <div
      className={`cart-page__partner-modal__partner ${props.selected ? '_selected' : ''}`}
      onClick={() => props.setSelected(props.item)}
    >
      {/*<img src={`${path}images/cart/partner.png`} alt="" />*/}
      <img src={props.item.images[1920]} alt="" />
    </div>
  )
})
