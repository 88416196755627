import "../primereact/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import 'swiper/swiper.scss';
import './style.scss';
import './buyers-data.scss';
import React from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import {Provider, observer} from 'mobx-react';
import Store from '../../common/store';
import '../../common/utils';
import {isDesktop, isMobile} from 'react-device-detect';
import {locale, addLocale} from 'primereact/api';
import primelocale from '../primereact/ru.json';
import "../primereact/style.scss";
import ScrollToTop from "../utils/ScrollToTop";
import Header from "../Header";
import Footer from "../Footer";
// import ComponentsPage from '../ComponentsPage';
import AuthPage from '../AuthPage';
import MainPage from '../MainPage';
// import OrdersPage from '../OrdersPage';
import CartPage from '../CartPage';
import CatalogPage from '../CatalogPage';
import CatalogPage2 from '../CatalogPage2';
import ErrorPage from "../ErrorPage";
// import ProductPage from "../ProductPage";
import Velato from "../../products/Velato";
import FreezerIn from "../../products/FreezerIn";
import FridgeIn from "../../products/FridgeIn";
import StylersAromaCapsules from "../../products/StylersAromaCapsules";
import WashersAromaCapsules from "../../products/WashersAromaCapsules";
import Eletto from "../../products/Eletto";
import Triano from "../../products/Triano";
import MultiSplit from "../../products/MultiSplit";
import DoubleDrum from "../../products/DoubleDrum";
import FrenchDoor from "../../products/FrenchDoor";
import OvenCasarte from "../../products/OvenCasarte";
import HoodCasarte from "../../products/HoodCasarte";
import StylerCasarte from "../../products/StylerCasarte";
import TwinWineZone from "../../products/TwinWineZone";
import CoveCasarte from "../../products/CoveCasarte";
import DoubleDrawer from "../../products/DoubleDrawer";
import DishwasherCasarte from "../../products/DishwasherCasarte";
import AuthModal from "../AuthModal";
import RegisterModal from "../RegisterModal";
import ChangePasswordModal from "../ChangePasswordModal";
import RestorePasswordModal from "../RestorePasswordModal";
// import ContactPage from '../ContactPage';
import ContactPage2 from '../ContactPage2';
import PersonalOrdersPage from '../PersonalOrdersPage';
import PersonalDetailsPage from '../PersonalDetailsPage';
import PersonalBonusPage from '../PersonalBonusPage';
// import TechWorkPage from '../TechWorkPage';
import LegalPage from '../LegalPage';
import VideoModal from "../VideoModal";
import CustomersPage from '../CustomersPage';
import AssurancePage from '../AssurancePage';
import AppContainer from '../AppContainer';
import OverlayQueue from "../utils/overlay-queue";
import DeliveryPage from '../DeliveryPage';
// import PremiumPage from '../PremiumPage';
import InstallationPage from '../InstallationPage';
import ServicePage from '../ServicePage';
import catalogData from "../../json/catalog.json";
import "./utils.scss";
import CompletePage from "../CompletePage";
import ConfirmModal from "../ConfirmModal";
import ProductSuggestModal from "../ProductSuggestModal";

addLocale('ru', primelocale.ru);
locale('ru');

window.onload = () => {
  if (isDesktop) {
    document.body.classList.add('_desktop');
  } else if (isMobile) {
    document.body.classList.add('_mobile');
  }
}

const store = new Store();
const path = process.env.REACT_APP_INTEGRATION_PATH;

new OverlayQueue();

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter basename={path}>
        <AppContainer>
          <ScrollToTop/>
          <Header/>
          <Switch>
            <Route exact path="/" component={MainPage}/>
            {/* <Route exact path="/dealers" component={ContactPage}/> */}
            <Route exact path="/dealers" component={ContactPage2}/>
            <Route path="/service" component={ServicePage}/>
            <Route exact path="/catalog/air-conditioners" component={CatalogPage}/>
            <Route exact path="/catalog/air-conditioners/velato" component={Velato}/>
            <Route exact path="/catalog/air-conditioners/velato/:MODEL" component={Velato}/>
            <Route exact path="/catalog/air-conditioners/eletto" component={Eletto}/>
            <Route exact path="/catalog/air-conditioners/eletto/:MODEL" component={Eletto}/>
            <Route exact path="/catalog/air-conditioners/triano" component={Triano}/>
            <Route exact path="/catalog/air-conditioners/triano/:MODEL" component={Triano}/>
            <Route exact path="/catalog/air-conditioners/multi-split" component={MultiSplit}/>
            <Route exact path="/catalog/washers">
              <Redirect to={"/catalog/washers/double-drum/"} />
            </Route>
            <Route exact path="/catalog/washers/double-drum" component={DoubleDrum}/>
            <Route exact path="/catalog/washers/double-drum/:MODEL" component={DoubleDrum}/>
            <Route exact path="/catalog/fridges">
              <Redirect to={"/catalog/fridges/french-door/"} />
            </Route>
            <Route exact path="/catalog/fridges/french-door" component={FrenchDoor}/>
            <Route exact path="/catalog/fridges/french-door/:MODEL" component={FrenchDoor}/>
            <Route exact path="/catalog/fridges/fridge-in" component={FridgeIn}/>
            <Route exact path="/catalog/fridges/fridge-in/:MODEL" component={FridgeIn}/>
            <Route exact path="/catalog/fridges/freezer-in" component={FreezerIn}/>
            <Route exact path="/catalog/fridges/freezer-in/:MODEL" component={FreezerIn}/>
            <Route exact path="/catalog/ovens">
              <Redirect to={"/catalog/ovens/oven-casarte"} />
            </Route>
            <Route exact path="/catalog/ovens/oven-casarte" component={OvenCasarte}/>
            <Route exact path="/catalog/ovens/oven-casarte/:MODEL" component={OvenCasarte}/>
            <Route exact path="/catalog/hoods">
              <Redirect to={"/catalog/hoods/hood-casarte"} />
            </Route>
            <Route exact path="/catalog/hoods/hood-casarte" component={HoodCasarte}/>
            <Route exact path="/catalog/hoods/hood-casarte/:MODEL" component={HoodCasarte}/>
            <Route exact path="/catalog/stylers">
              <Redirect to={"/catalog/stylers/styler-casarte"} />
            </Route>
            <Route exact path="/catalog/stylers/styler-casarte" component={StylerCasarte}/>
            <Route exact path="/catalog/stylers/styler-casarte/:MODEL" component={StylerCasarte}/>
            <Route exact path="/catalog/wine-coolers">
              <Redirect to={"/catalog/wine-coolers/twin-wine-zone"} />
            </Route>
            <Route exact path="/catalog/wine-coolers/twin-wine-zone" component={TwinWineZone}/>
            <Route exact path="/catalog/wine-coolers/twin-wine-zone/:MODEL" component={TwinWineZone}/>
            <Route exact path="/catalog/glass-ceramic-coves">
              <Redirect to={"/catalog/glass-ceramic-coves/cove-casarte"} />
            </Route>
            <Route exact path="/catalog/glass-ceramic-coves/cove-casarte" component={CoveCasarte}/>
            <Route exact path="/catalog/glass-ceramic-coves/cove-casarte/:MODEL" component={CoveCasarte}/>
            <Route exact path="/catalog/built-in-dishwashers/dishwasher-casarte" component={DishwasherCasarte}/>
            <Route exact path="/catalog/built-in-dishwashers/dishwasher-casarte/:MODEL" component={DishwasherCasarte}/>
            <Route exact path="/catalog/built-in-dishwashers/double-drawer" component={DoubleDrawer}/>
            <Route exact path="/catalog/built-in-dishwashers/double-drawer/:MODEL" component={DoubleDrawer}/>
            <Route exact path="/catalog/built-in-dishwashers">
              <Redirect to={"/catalog/built-in-dishwashers/double-drawer"} />
            </Route>
            <Route exact path="/catalog/aroma-capsules">
              <Redirect to={"/catalog/aroma-capsules/washers-aroma-capsules/"} />
            </Route>
            <Route exact path="/catalog/aroma-capsules/washers-aroma-capsules" component={WashersAromaCapsules}/>
            <Route exact path="/catalog/aroma-capsules/washers-aroma-capsules/:MODEL" component={WashersAromaCapsules}/>
            <Route exact path="/catalog/aroma-capsules/stylers-aroma-capsules" component={StylersAromaCapsules}/>
            <Route exact path="/catalog/aroma-capsules/stylers-aroma-capsules/:MODEL" component={StylersAromaCapsules}/>
            <Route exact path="/auth" component={AuthPage}/>
            <Route exact path="/personal/orders" component={PersonalOrdersPage}/>
            <Route exact path="/personal/orders/empty" component={PersonalOrdersPage}/> {/* TEMP */}
            <Route exact path="/personal/profile" component={PersonalDetailsPage}/>
            <Route exact path="/personal/bonus" component={PersonalBonusPage} />
            <Route exact path="/personal/cart/:ORDER_ID" component={CompletePage}/>
            <Route exact path="/personal/cart" component={CartPage}/>
            {/*<Route exact path="/tech-work" component={TechWorkPage}/>*/}
            <Route exact path={'/content/:id'}  component={LegalPage}/>
            <Route exact path="/customers" component={CustomersPage}/>
            <Route exact path="/assurance" component={AssurancePage}/>
            <Route exact path="/delivery" component={DeliveryPage}/>
            <Route exact path="/premium" component={LegalPage}/>
            <Route exact path="/installation" component={InstallationPage}/> 
            <Route exact path="/installation/washers" component={InstallationPage}/>
            <Route exact path="/installation/air-conditioners" component={InstallationPage}/>
            <Route exact path="/installation/air-conditioners/basic" component={InstallationPage}/>
            <Route exact path="/installation/air-conditioners/standart" component={InstallationPage}/>
            <Route exact path="/installation/air-conditioners/extended" component={InstallationPage}/>

            {/* Новая структура каталога */}
            {
                catalogData.map((item, index) =>
                <Route key={index} exact path={item.url} component={CatalogPage2}/>
              )
            }

            {/* TEMP (Страница с компонентами формы) */}
            {/* <Route exact path="/components" component={ComponentsPage}/> */}

            <Route component={ErrorPage}/>
          </Switch>
          <Footer/>
          {store.modal.auth && <AuthModal/>}
          {store.modal.register && <RegisterModal/>}
          {store.modal.changePassword && <ChangePasswordModal/>}
          {store.modal.restorePassword && <RestorePasswordModal/>}
          {store.modal.video && <VideoModal />}
          <ConfirmModal />
          <ProductSuggestModal />
        </AppContainer>
      </BrowserRouter>
    </Provider>
  );
}

export default observer(App);
