import './style.scss';
import React, {useEffect, useState, useMemo} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Button} from 'primereact/button';
import {TWEEN_TIME, OPENED, OPENING, CLOSED, CLOSING, MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../common/const';
import {scrollTo} from '../../common/utils';
import ContactModal from '../ContactModal';
import {inject, observer} from "mobx-react";
import catalogData from "../../json/catalog.json";

import qs from 'qs';
import Cookies from 'js-cookie';
import MediaQuery from 'react-responsive';

const OVERLAY_QUEUE_COOKIE_ID = 'cookie';
const OVERLAY_QUEUE_INFO_ID = 'dangerMessage';

const path = process.env.REACT_APP_ASSETS_PATH;
/**
 * Компонент подвала
 * @param store - глобальное хранилище
 * @returns {JSX.Element}
 * @constructor
 */
const Footer = ({store}) => {
  //state
  const [upBtnStatus, setUpBtnStatus] = useState(false);
  const [cookieBtnStatus, setCookieBtnStatus] = useState(CLOSED);
  const [infoBtnStatus, setInfoBtnStatus] = useState(CLOSED);
  const [isInfoLoading, setIsInfoLoading] = useState(false);

  //const
  const isCookieAccepted = Cookies.get('cookies_accepted') === 'true';
  const infoData = store.header?.popupNotification;
  const cookieText = store.appConst?.cookieText
  const isInfoDataAccepted = Boolean(!infoData?.sendRequest && !infoData?.forceShow && Cookies.get('danger_message_hidden') === 'true');

  //memo
  const hasInfoData = useMemo(() => {
    return Boolean(infoData && Object.keys(infoData).length && infoData?.text);
  }, [infoData]);

  //funcs
  const handleInfoButtonClick = () => {
    if (infoData?.sendRequest) {
      if (isInfoLoading) {
        return;
      }
      setIsInfoLoading(true);

      store.bridge.fromDangerAuthMess({
        data: {
          link: Boolean(infoData?.url),
          id: infoData?.id,
        },
      }).then((response) => {
        const {status, data} = response;

        switch (status) {
          case 'SECURITY_CONFIRM_SUCCESS':
            if (infoData?.url) {
              window.location.href = infoData?.url;
            } else {
              setIsInfoLoading(false);
              closeInfo(false);
            }
            break;
          case 'SECURITY_CONFIRM_FAIL':
            // eslint-disable-next-line no-console
            console.error(data.errorMessage);
            setIsInfoLoading(false);
            // @TODO: show error
            break;
          default:
            // eslint-disable-next-line no-console
            console.error(`Error ${status}: Unknown status`);
            setIsInfoLoading(false);
          // @TODO: show error
        }
      }).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error: Request has failed');
        setIsInfoLoading(false);
        // @TODO: show error
      });
    } else {
      closeInfo();
    }
  };
  /**
   * Отвечает за открытие плашки с куками
   */
  const openCookie = () => {
    const time = 250;

    setTimeout(() => {
      setCookieBtnStatus(OPENING);
    }, time);
    setTimeout(() => {
      setCookieBtnStatus(OPENED);
    }, time + TWEEN_TIME);
  };
  /**
   * Закрывает плашку с куками
   * @param setCookie - устанавливает параметр принятия/непринятия куков
   */
  const closeCookie = (setCookie = true) => {
    if (cookieBtnStatus === CLOSING) {
      return;
    }
    setCookieBtnStatus(CLOSING);
    setTimeout(() => {
      setCookieBtnStatus(CLOSED);
    }, TWEEN_TIME);

    if (setCookie) {
      const expires = 30;

      if (expires) {
        Cookies.set('cookies_accepted', 'true', {expires});
      }
    }
    if (window.overlayQueue) {
      window.overlayQueue.show(OVERLAY_QUEUE_COOKIE_ID);
    }
  };
  /**
   * Показывает инфоплашку с сообщением
   */
  const openInfo = () => {
    const time = 250;

    setTimeout(() => {
      setInfoBtnStatus(OPENING);
    }, time);
    setTimeout(() => {
      setInfoBtnStatus(OPENED);
    }, time + TWEEN_TIME);
  };
  /**
   * Закрывает информационное сообщение
   * @param setCookie - устанавливает параметр danger_message_hidden
   */
  const closeInfo = (setCookie = true) => {
    if (infoBtnStatus === CLOSING) {
      return;
    }
    setInfoBtnStatus(CLOSING);
    setTimeout(() => {
      setInfoBtnStatus(CLOSED);
    }, TWEEN_TIME);

    if (setCookie) {
      const expires = Number(infoData?.cookieLifespan) || 1;

      if (expires) {
        Cookies.set('danger_message_hidden', 'true', {expires});
      }
    }
    if (window.overlayQueue) {
      window.overlayQueue.show(OVERLAY_QUEUE_INFO_ID);
    }
  };

  // const checkAndScroll = (e) => {
  //   const currentURL = window.location.pathname;
  //   if(
  //     currentURL === path ||
  //     currentURL === path + 'brand'
  //   ){
  //     scrollTo('brand-id');
  //     e.preventDefault();
  //   }
  // }

  //effects

  /**
   * Показывает кнопку сролла в начало страницы
   */
  useEffect(() => {
    const onScroll = e => {
      if (window.scrollY >= 200 && !upBtnStatus) {
        setUpBtnStatus(true);
      }
      if (window.scrollY < 200 && upBtnStatus) {
        setUpBtnStatus(false);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [upBtnStatus]);

  /**
   * Показывает инфоплашку про куки
   */
  useEffect(() => {
    if (!isCookieAccepted) {
      if (window.overlayQueue) {
        window.overlayQueue.add({
          callback: () => openCookie(),
          id: OVERLAY_QUEUE_COOKIE_ID,
          order: 2,
        });
        window.overlayQueue.init();
      } else {
        openCookie();
      }
    }
  }, [isCookieAccepted]);

  /**
   * Показывает инфоплашку с сообщением
   */
  useEffect(() => {
    if (infoData && hasInfoData && !isInfoDataAccepted) {
      if (window.overlayQueue) {
        window.overlayQueue.add({
          callback: () => openInfo(),
          id: OVERLAY_QUEUE_INFO_ID,
          order: 3,
        });
        window.overlayQueue.init();
      } else {
        openInfo();
      }
    }
  }, [infoData, hasInfoData, isInfoDataAccepted]);

  /**
   * Открывает модалку обратной связи при наличии параметра в адресной строке
   */
  useEffect(() => {
    const getParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (getParams.from === 'feedback') {
      const {from, ...rest} = getParams;
      const query = qs.stringify(rest, {encode: false, addQueryPrefix: true});

      window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}${query}`);

      store.setModal('contact');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-footer">
      <div className="page-footer__top">
        <div className="page-wrap">
          <div className="page-footer__top__left">
            <p className="page-footer__top__title">Каталог</p>
            <div className="page-footer__top__secondary-links">
              {
                catalogData.map((item, index) =>
                  <Link key={index} to={item.url}>{item.title}</Link>
                )
              }
            </div>
            <p className="page-footer__top__title _vspace">Покупателям</p>
            <div className="page-footer__top__secondary-links">
              <Link to="/dealers">Где купить</Link>
              <Link to="/delivery">Оплата и доставка</Link>
            </div>
            <div className="page-footer__top__primary-links _vspace">
              <Link to="/#brand">О бренде</Link>
            </div>
          </div>
          <div className="page-footer__top__center">
            <p className="page-footer__top__title">Сервис и поддержка</p>
            <div className="page-footer__top__secondary-links">
              <Link to="/service">Контакты</Link>
              <Link to="/service/installation">Установка</Link>
              <Link to="/service/support">Сервисное обслуживание</Link>
              <Link to="/assurance">Гарантия</Link>
            </div>
            <p className="page-footer__top__title _vspace">Мы в соцсетях</p>
            <div className="page-footer__top__socials">
                <a href="https://t.me/CasarteRussia" target="_blank" rel="noreferrer"><img src={`${path}images/footer/telegram.svg`} alt="Telegram"/></a>
                <a href="https://m.youtube.com/@CasarteRussia" target="_blank" rel="noreferrer"><img src={`${path}images/footer/youtube.svg`} alt="Youtube"/></a>
            </div>
          </div>
          <div className="page-footer__top__right">
            <div className="page-footer__top__flex">
              <MediaQuery minWidth={MOBILE_WIDTH_1}>
                <div className="page-footer__top__flex__right">
                  <div className="page-footer__top__flex__pl">
                    <p className="page-footer__top__title">Установите мобильное приложение evo и&nbsp;получайте дополнительные преимущества от&nbsp;Casarte</p>
                      <img className="page-footer__top__qr" src={`${path}images/footer/qr.svg`}
                          alt="Наведите камеру на QR-код, чтобы скачать"/>
                    <p className="page-footer__top__qr__label">Наведите камеру на QR-код,<br/>чтобы скачать</p>
                    <div className="page-footer__top__apps">
                      <a href="https://apps.apple.com/ru/app/evo-клуб-привилегий-от-haier/id1542043377"
                        target="_blank"><img src={`${path}images/footer/app-store.svg`} alt="App Store"/></a>
                      <a href="https://play.google.com/store/apps/details?id=ru.haier.app" target="_blank"><img
                        src={`${path}images/footer/google-play.svg`} alt="Google Play"/></a>
                    </div>
                  </div>
                </div>
              </MediaQuery>
              <div className="page-footer__top__flex__left">
                <div className="page-footer__top__flex__pl">
                  <p className="page-footer__top__title">Мы принимаем платежи</p>
                  <div className="page-footer__top__payment">
                    <div><img src={`${path}images/footer/1.svg`} alt="СБП"/></div>
                    <div><img src={`${path}images/footer/2.svg`} alt="Мир"/></div>
                    <div><img src={`${path}images/footer/3.svg`} alt="Халва"/></div>
                    <div><img src={`${path}images/footer/4.svg`} alt="Visa"/></div>
                    <div><img src={`${path}images/footer/5.svg`} alt="MasterCard"/></div>
                    <div><img src={`${path}images/footer/6.svg`} alt="UnionPay"/></div>
                  </div>
                </div>
                <MediaQuery maxWidth={MOBILE_WIDTH}>
                  <div className="page-footer__top__flex__pl">
                    <p className="page-footer__top__title">Установите мобильное приложение evo и&nbsp;получайте дополнительные преимущества от&nbsp;Casarte</p>
                    <div className="page-footer__top__apps">
                      <a href="https://apps.apple.com/ru/app/evo-клуб-привилегий-от-haier/id1542043377"
                        target="_blank"><img src={`${path}images/footer/app-store.svg`} alt="App Store"/></a>
                      <a href="https://play.google.com/store/apps/details?id=ru.haier.app" target="_blank"><img
                        src={`${path}images/footer/google-play.svg`} alt="Google Play"/></a>
                    </div>
                  </div>
                </MediaQuery>
                <div className="page-footer__top__flex__pl">
                  <p className="page-footer__top__title">Ответим на ваши вопросы</p>
                  <p className="page-footer__top__tel__label">{store.footer?.contacts?.text}</p>
                  <a href={store.footer?.contacts?.url}
                     className="page-footer__top__tel">{store.footer?.contacts?.urlText}</a>
                  <Button className="p-button-secondary p-button-transparent min-w" label="Напишите нам"
                          onClick={() => {
                            window.store.setModal('contact', true)
                          }}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-footer__bottom">
        <div className="page-wrap">
          <span className="page-footer__bottom__copyright">Официальный сайт Casarte Inc.</span>
          <div className="page-footer__bottom__primary">
            {store.footer?.docs?.map((item, index) =>
              item.url.startsWith('/sitemap') ? <a key={index} href={item.url}>{item.label}</a> :
                <NavLink key={index} exact to={item.url}>{item.label}</NavLink>
            )}
          </div>
          <div className="page-footer__bottom__secondary">
            <span>© {store.footer?.copyrightYear} Casarte Inc. Все авторские права защищены</span>
            <span>ООО «ХАР», ИНН 1650292810 КПП 770401001, ОГРН 1141650016540</span>
          </div>
        </div>
      </div>
      <div className={'page-footer__up' + (upBtnStatus ? ' active' : '')} onClick={() => {
        scrollTo('page');
      }}/>
      {isCookieAccepted ? null :
        <div className={`page-footer__pl _bottom ${cookieBtnStatus}`}>
          <div className="page-footer__pl-wrap">
            <div className="page-footer__pl-text">
              {cookieText ? <div dangerouslySetInnerHTML={{__html: cookieText}}></div> :
                <div>Сайт casarte.ru использует файлы cookie. Продолжая использование сайта, вы соглашаетесь с&nbsp;<a
                  href="https://casarte.ru/content/politika-v-otnoshenii-obrabotki-personalnykh-dannykh-casarte/">политикой
                  конфиденциальности и&nbsp;обработки персональных данных</a>. Информация, представленная на&nbsp;сайте,
                  не&nbsp;является публичной офертой, определяемой положениями ст.&nbsp;437&nbsp;ГК&nbsp;РФ
                </div>} <Link to={store.appConst.cookieLink}/>
            </div>
            <div className="page-footer__pl-btn__wrap">
              <div className="page-footer__pl-btn" onClick={() => closeCookie()}>Принять</div>
            </div>
          </div>
        </div>
      }
      {
        hasInfoData &&
        <div className={`page-footer__pl _top ${infoBtnStatus}`}>
          <div className="page-footer__pl-wrap">
            {Boolean(infoData?.text) &&
              <div className="page-footer__pl-text" dangerouslySetInnerHTML={{__html: infoData?.text}}/>}
            {Boolean(infoData?.buttonText) &&
              <div className="page-footer__pl-btn__wrap">
                <div
                  className={`page-footer__pl-btn ${isInfoLoading ? 'p-loader' : ''}`}
                  onClick={() => handleInfoButtonClick()}
                >Ok
                </div>
              </div>
            }
          </div>
        </div>
      }
      {/* <div className="page-footer__rotate">
        <div className="page-footer__rotate-wrap">
          <img src={Logo} className="page-footer__rotate-logo" alt="Casarte" />
          <p className="page-footer__rotate-text">Поверните<br/>ваше устройство</p>
          <img src={Rotate} className="page-footer__rotate-pic m-view" alt="" />
          <img src={Rotate2} className="page-footer__rotate-pic t-view" alt="" />
        </div>
      </div> */
      }
      {
        store.modal.contact && <ContactModal/>
      }
    </div>
  )
    ;
}

export default inject('store')(observer(Footer));
