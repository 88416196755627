import React from 'react';
import {scrollTo} from "../../common/utils";

export const getPageMenu = (productName) => {
  const pageMenuList = 
      productName === 'double-drum' ?
    [
      {
        id: 'config-id',
        title: 'УНИКАЛЬНАЯ КОНСТРУКЦИЯ'
      },
      {
        id: 'carefully-id',
        title: 'УХОД ЗА ВЕЩАМИ'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'care-id',
        title: 'Забота о здоровье'
      },
      {
        id: 'reliability-id',
        title: 'Надежность'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'hood-casarte' ?
    [
      {
        id: 'care-id',
        title: 'Удобство'
      },
      {
        id: 'reliability-id',
        title: 'Функциональность'
      },
      {
        id: 'comfort-id',
        title: 'комфорт'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'styler-casarte' ?
    [
      {
        id: 'care-id',
        title: 'Удобство'
      },
      {
        id: 'technology-id',
        title: 'Технологии'
      },
      {
        id: 'functionality-id',
        title: 'Функциональность'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'programs-id',
        title: 'Эксклюзивные программы'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'oven-casarte' ?
    [
      {
        id: 'reliability-id',
        title: 'Функциональность'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'care-id',
        title: 'Удобство'
      },
      {
        id: 'safety-id',
        title: 'Безопасность'
      },
      {
        id: 'comfort-id',
        title: 'комфорт'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'french-door' ?
    [
      {
        id: 'zoning-id',
        title: 'Зонирование'
      },
      {
        id: 'care-id',
        title: 'Забота о здоровье'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'functionality-id',
        title: 'Функциональность'
      },
      {
        id: 'awards-id',
        title: 'Награды'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'twin-wine-zone' ?
    [
      {
        id: 'zoning-id',
        title: 'Эффективное зонирование'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'ergonomics-id',
        title: 'Эргономичность'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'cove-casarte' ?
    [
      {
        id: 'design-id',
        title: 'Дизайн'
      },
      {
        id: 'control-id',
        title: 'программы и управление'
      },
      {
        id: 'reliability-id',
        title: 'Функциональность'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'dishwasher-casarte' ?
    [
      {
        id: 'config-id',
        title: 'конструкция'
      },
      {
        id: 'control-id',
        title: 'программы и управление'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'reliability-id',
        title: 'Функциональность'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'double-drawer' ?
    [
      {
        id: 'config-id',
        title: 'конструкция'
      },
      {
        id: 'control-id',
        title: 'программы и управление'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'reliability-id',
        title: 'Функциональность'
      },
      {
        id: 'trainer-id',
        title: 'Премиальный сервис'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : (productName === 'eletto' || productName === 'triano') ?
    [
      {
        id: 'comfort-id',
        title: 'комфорт'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'care-id',
        title: 'Забота о здоровье'
      },
      {
        id: 'features',
        title: 'Надежность'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'velato' ?
    [
      {
        id: 'comfort-id',
        title: 'комфорт'
      },
      {
        id: 'care-id',
        title: 'Забота о здоровье'
      },
      {
        id: 'performance-id',
        title: 'Надежность'
      },
      {
        id: 'control-id',
        title: 'Управление'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : productName === 'multi-split' ?
    [
      {
        id: 'inside-id',
        title: 'Внутренние блоки'
      },
      {
        id: 'outside-id',
        title: 'Наружные блоки'
      },
      {
        id: 'control-id',
        title: 'Управление'
      },
      {
        id: 'evo-id',
        title: 'EVO'
      },
      {
        id: 'tech-id',
        title: 'Характеристики'
      }
    ]
    : 
    [];

  return pageMenuList.map((item, index) => {
    return (
      <div key={item.title + index} className="product-page__menu-item" onClick={() => {
        scrollTo(item.id)
      }}>{item.title}</div>
    )
  });
}