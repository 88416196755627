import './style.scss';
import Image from '../../../Image';
import { numWithSpaces } from "../../../../common/utils";
import { Link } from 'react-router-dom';
import ProductCounter from '../../../ProductCounter';
import { Tooltip } from 'primereact/tooltip';

const ProductPageSuggestPlane = (props) => {
  const {title, productName} = props;
  const path = `./assets/${productName}/`; // TEMP
  const link = productName === 'double-drum' // TEMP
    ? '/catalog/aroma-capsules/washers-aroma-capsules/' 
    : '/catalog/aroma-capsules/stylers-aroma-capsules/';

  return (
    <div className="product-page-suggest-plane">
      <p className="product-page-suggest-plane__title">{title}<span className="product-page-suggest-plane__title__icon" data-pr-position="top" /></p>
      <Tooltip className="product-page-suggest-plane__tooltip" target=".product-page-suggest-plane__title__icon" hideDelay={30} autoHide={false}>
        <div className="product-page-suggest-plane__tooltip__text">Аромат пеларгонии, ванили, лаванды, лимона и&nbsp;лемонграсса проникнет в&nbsp;ткань, даря новые ощущения.</div>
        <a className="product-page-suggest-plane__tooltip__link" href="#">Подробнее</a>
      </Tooltip>
      <div className="product-page-suggest-plane__items">
        {
          // TEMP заменить данные
          [
            {
              title: "2 капсулы / уп.", 
              price: "2990",
              url: link,
              imageSet: {
                desktop: `${require(path+'1-d-1x.webp').default}, ${require(path+'1-d-2x.webp').default} 2x`,
                mobile: `${require(path+'1-m-2x.webp').default}`
              },
              disabled: !true,//true/false
            },
            /*{
              title: "6 капсул / уп.", 
              price: "8900",
              url: link,
              imageSet: {
                desktop: `${require(path+'2-d-1x.webp').default}, ${require(path+'2-d-2x.webp').default} 2x`,
                mobile: `${require(path+'2-m-2x.webp').default}`
              },
            },*/
          ].map((item) => 
            <Item {...item} />
          )
        }
      </div>
    </div>
  )
}

const Item = (props) => {
  const {title, price, url, imageSet, disabled} = props;

  return (
    <div className="product-page-suggest-plane__item">
      <div className="product-page-suggest-plane__item__col">
        <Link to={url}>
          <Image
            className="product-page-suggest-plane__item__pic"
            desktop={imageSet.desktop}
            mobile={imageSet.mobile}
            title={title}
          />
        </Link>
      </div>
      <div className="product-page-suggest-plane__item__col">
        <div className="product-page-suggest-plane__item__row">
          <Link to={url} className="product-page-suggest-plane__item__title">{title}</Link>
          <p className="product-page-suggest-plane__item__price">{numWithSpaces(price)} <span className='rub'>P</span></p>
        </div>
        <div className="product-page-suggest-plane__item__row">
          <ProductCounter className="_tertiary" disabled={disabled} />
        </div>
      </div>
    </div>
  )
}

export default ProductPageSuggestPlane;