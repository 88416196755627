import './style.scss';
import React, { useEffect, useRef, useState } from 'react';
import {NavLink, Link} from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH_1 } from '../../common/const';
//import { numWithSpaces } from '../../common/utils';
import { Button } from 'primereact/button';
import axios from "axios";
import {inject, observer} from "mobx-react";
import {formatNumber} from "../utils";
// import pointsData from "../../json/points.json";

const PersonalPageSummary = ({store}) => {
  const topRef = useRef(null);
  const [summaryStatus, setSummaryStatus] = useState('');
  const [summaryTY, setSummaryTY] = useState(0);
  const [isLogoutLoading, setIsLogoutLoading] = useState(false);

  const logout = () => {
    if (isLogoutLoading) {
      return;
    }
    setIsLogoutLoading(true);

    axios({
      url: `/?logout=yes`,
      method: 'post',
    }).then(() => {
      window.location.href = '/';
    }).catch(() => {
      setIsLogoutLoading(false);
    });
  };

  useEffect(() => {
    let fixed = false;

    const onScroll = e => {
      if(!topRef.current){
        return;
      }

      const ot = topRef.current.getBoundingClientRect().top;
      const fixedOB = document.querySelector(`.personal-page__summary`).getBoundingClientRect().bottom + 40;
      const footerOT = document.querySelector(`.page-footer`).getBoundingClientRect().top;
      const leftH =  document.querySelector(`.personal-page__top .personal-page__left`).getBoundingClientRect().height;
      const rightH =  document.querySelector(`.personal-page__right`).getBoundingClientRect().height;

      if(ot <= 128){
        if(!fixed){
          fixed = true;

          if(rightH < leftH){
            setSummaryStatus('_fixed');
          }
        }

        if(fixedOB >= footerOT){
          setSummaryTY(footerOT-fixedOB);
        }
        else{
          setSummaryTY(0);
        }
      }
      else{
        if(fixed){
          fixed = false;
          setSummaryStatus('');
          setSummaryTY(0);
        }
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="personal-page__right" ref={topRef}>
          <div className={`personal-page__summary ${summaryStatus}`}>
            <div className="personal-page__summary__outer-wrap" style={{transform: `translateY(${summaryTY}px)`}}>
              <div className="personal-page__summary__inner-wrap">
                <span className="personal-page__summary__title">Личный кабинет</span>
                <NavLink className="personal-page__summary__item i1" exact to="/personal/profile/">
                  <span>Личные данные</span>
                </NavLink>
                <NavLink className="personal-page__summary__item i2" to="/personal/orders/">
                  <span>Мои заказы</span>
                  {store.sidebar?.orders && <span>{store.sidebar?.orders}</span>}
                </NavLink>
                <NavLink className="personal-page__summary__item i3" exact to="/personal/bonus">
                  <span>Мои баллы</span>
                  {
                    store.sidebar?.points && <span
                      dangerouslySetInnerHTML={{__html: formatNumber(store.sidebar?.points)}}
                    ></span>
                  }
                </NavLink>
                <div className="personal-page__summary__item i4" onClick={logout}>
                  <span>Выход</span>
                </div>
                <div className="personal-page__summary__line" />
                <span className="personal-page__summary__title">Помощь</span>
                <div className="personal-page__summary__links">
                  <Link className="personal-page__summary__link" to="/customers">Что делать, если обнаружил брак после оплаты товара?</Link>
                  {/* <Link className="personal-page__summary__link" to="/customers">Почему мне не&nbsp;добавили бонусов за&nbsp;покупку?</Link> */}
                </div>
                <Button className="p-button-secondary p-button-transparent min-w" label="задать вопрос" onClick={() => {window.store.setModal('contact')}} />
              </div>
            </div>
          </div>
        </div>
      </MediaQuery>
    </>
  );
}

export default inject('store')(observer(PersonalPageSummary));
