import React from 'react';
import Plane from './Plane';
import SectionHeader from './SectionHeader';

const TrainerSection = () => {  
  return (
    <div className="product-page__section trainer-id">
      <div className="wrap">
        <SectionHeader
          title={<>Премиальный сервис</>}
          text={<>Индивидуальный тренинг для&nbsp;клиентов Casarte</>}
          btn={false}
        />
        <div className="product-page__grid">
          <Plane
            assetsPathName="trainer"
            itemClass="large no-btn long _trainer"
            wrapClass="bottom-left"
            titleClass="large"
            picName="1"
            picFormat="webp"
            title={<>Выезд тренера <br/>для&nbsp;обучения</>}
            text={<>Клиентам интернет-магазина и&nbsp;фирменного бутика Casarte доступна бесплатная услуга по&nbsp;обучению тонкостям работы с&nbsp;техникой и&nbsp;управлению через&nbsp;приложение evo. Услуга предоставляется на&nbsp;территории Москвы и&nbsp;Московской области.</>}
            layer={0}
            btn={0}
          />
        </div>
      </div>
    </div>
  )
}

export default TrainerSection;
