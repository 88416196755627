import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const DoubleDrawerHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Двухдверная<br/>встраиваемая<br/>посудомоечная</p>
        <h1 className="product-page__head__center__title">машина Casarte</h1>
        <h2 className="product-page__head__center__sub-title">Double Drawer</h2>
        <p className="product-page__head__center__text">Эргономичный дизайн для максимального удобства.</p>
        <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />
      </div>
    </>
  )
}

export const DoubleDrawerContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section config-id">
        <div className="wrap">
          <SectionHeader
            title={<>Конструкция</>}
            text={<>Уникальная встраиваемая посудомоечная машина с двумя выдвижными моечными камерами</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="120"
              title={<>Стандартные <span className="m-br"/>размеры <span className="m-br"/>для&nbsp;встраивания</>}
              text={<>Загрузка до&nbsp;14 комплектов посуды.</>}
              detailText={<>Посудомоечная машина имеет ширину 60&nbsp;см, что&nbsp;обеспечивает возможность ее&nbsp;встраивания в&nbsp;нишу стандартных размеров. Общая вместимость обеих камер составляет до&nbsp;14 комплектов посуды.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="121"
              title={<>Две независимые <br/>моечные камеры</>}
              text={<>Широкие возможности настроек работы.</>}
              detailText={<>Две независимые выдвижные моечные камеры могут работать на&nbsp;одной или&nbsp;разных программах мытья, одновременно и&nbsp;по&nbsp;отдельности.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large-2"
              picName="122"
              title={<>Эргономичные<br/>корзины</>}
              text={<>Бесшумная выдвижная система и&nbsp;ультратонкие разбрызгиватели.</>}
              detailText={<>Каждая корзина рассчитана на&nbsp;размещение до&nbsp;7 комплектов посуды, оснащена бесшумной выдвижной системой и&nbsp;ультратонким разбрызгивателем, складными держателями тарелок, полками для&nbsp;чашек, а&nbsp;также силиконовыми держателями бокалов для&nbsp;максимально эффективного и&nbsp;бережного мытья.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="123"
              title={<>Два<br/>инвенторных<br/>мотора</>}
              detailTitle={<>Два инвенторных мотора</>}
              text={<>С прямым приводом обеспечивают тихую и&nbsp;эффективную работу.</>}
              detailText={<>Специальные моторы имеют ультратонкую конструкцию, что&nbsp;позволило вместить в&nbsp;стандартные для&nbsp;встраиваемых посудомоечных машин размеры две&nbsp;независимых моечных камеры. Инверторные моторы с&nbsp;прямым приводом обеспечивают тихую и&nbsp;эффективную работу.</>}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section control-id">
        <div className="wrap">
          <SectionHeader
            title={<>Программы и&nbsp;управление</>}
            text={<>Индивидуальная панель управления для&nbsp;каждой моечной камеры</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="124"
              title={<>Сенсорные<br/>панели <span className="m-br"/>управления</>}
              text={<>Выбор программы одним касанием.</>}
              detailText={<>Обе моечные камеры имеют независимые панели управления, расположенные в&nbsp;верхней части дверцы выдвижной корзины. На&nbsp;них размещены самые востребованные программы, включая автоматическую программу мытья с&nbsp;индивидуальным подбором параметров в&nbsp;соответствии с&nbsp;объемом и&nbsp;степенью загрязнения посуды.</>}
            />
          </div>
        </div>
      </div>
      <EvoSection
        sectionTitle={<>Приложение evo</>}
        sectionText={<>Управление и мониторинг процессов через смартфон</>}
        planeTitle={<>Расширяем границы <span className="d-br"/>возможного в&nbsp;evo</>}
        planeText={<>Дистанционное управление и&nbsp;настройка программ мытья, <span className="d-br"/>контроль состояния, уведомления и&nbsp;оповещения.<sup>*</sup></>}
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={false}
        desc={true}
      />
      <div className="product-page__section reliability-id">
        <div className="wrap">
          <SectionHeader
            title={<>Функциональность</>}
            text={<>Вариативные настройки и&nbsp;полезные датчики обеспечат удобство использования</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="125"
              title={<>Индикация<br/>процессов</>}
              detailTitle={<>Индикация процессов</>}
              text={<>Мытье, ополаскивание, сушка.</>}
              detailText={<>На дверце каждой моечной камеры расположены световые индикаторы оповещения об&nbsp;этапах программы: мытье, ополаскивание, сушка.</>}
              //videoFormat="webm"
              video={1}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="126"
              title={<>Открывание<br/>по стуку</>}
              text={<>Приостановка процесса мытья.</>}
              detailTitle={<>Разблокирование дверцы по&nbsp;стуку</>}
              detailText={<>В&nbsp;целях безопасности при&nbsp;запуске цикла дверца корзины автоматически блокируется. При&nbsp;необходимости приостановки программы достаточно постучать по&nbsp;дверце. Процесс мытья приостановится и&nbsp;световой индикатор уведомит, когда можно открыть дверцу.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-center-2"
              titleClass="large"
              picName="127"
              title={<>Внутренняя <span className="m-br"/>подсветка</>}
              text={<>Для максимального удобства.</>}
              detailTitle={<>Внутренняя подсветка</>}
              detailText={<>В каждой моечной камере предусмотрена подсветка, которая делает процесс загрузки и выгрузки посуды максимально комфортным.</>}
            />
          </div>
        </div>
      </div>
      <TrainerSection />
    </>
  )
}