import './style.scss';
import React, {useEffect, useState} from 'react';
import {withRouter, Link} from 'react-router-dom';
import MediaQuery from 'react-responsive';
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../common/const';
import axios from "axios";
import {inject, observer} from "mobx-react";
import pageData from "../../json/errorPage.json";
import menuData from "../../json/catalogItems.json";
import {getApiPath} from "../utils";
import PageLoader from "../PageLoader";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import feedbackFormData from "../../json/feedbackForm.json";

// const path = process.env.REACT_APP_ASSETS_PATH;

const Page = ({store, match, error}) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    document.title = 'Страница не найдена';
  }, [])

  // Устанавливает данные страницы
  useEffect(() => {
    if (error) {
      setData(error.pageContent);
      store.setCatalogItems(error.menu);
      store.setAppConst(error.appConst);
      store.setHeader(error.header);
      store.setFooter(error.footer);
      store.setFeedbackForm(error.feedbackForm);
    } else {
      axios({
        url: getApiPath(match.path) + (window.location.href.match(/(localhost|dev.bstd.ru)/) ? '' : '404/'),
        method: 'get',
      }).then((response) => {
        setData(response.data.pageContent);
        store.setCatalogItems(response.data.menu);
        store.setAppConst(response.data.appConst);
        store.setHeader(response.data.header);
        store.setFooter(response.data.footer);
        store.setFeedbackForm(window.feedbackForm);
      }).catch(() => {
        setData(pageData);
        store.setCatalogItems(menuData);
        store.setHeader(headerData);
        store.setFooter(footerData);
        store.setFeedbackForm(feedbackFormData);
      });
    }
    return () => setData(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  return (!data ? <PageLoader/> :
      <div className="error-page page">
        <div className="error-page__bg">
          <div className="error-page__wrap">
            {data?.code && <span className="error-page__h2" dangerouslySetInnerHTML={{__html: data.code}}/>}
            {data?.title && <span className="error-page__h1" dangerouslySetInnerHTML={{__html: data.title}}/>}
            {data?.text && <span className="error-page__text" dangerouslySetInnerHTML={{__html: data.text}}/>}
            {data?.button && <Link to={data.button.url}
                                   className="p-button p-component p-button-secondary p-button-white min-w">{data.button.text}</Link>}
            {data?.menu && <MediaQuery minWidth={MOBILE_WIDTH_1}>
              <div className="error-page__links">
                {data.menu.map((item, index) =>
                  <Link key={index} to={item.url}>{
                    //костыль из за кривой верстки карточек в мобилке
                    item.text?.replace('<br>' ,'')}</Link>
                )}
              </div>
            </MediaQuery>}
          </div>
        </div>
        {data?.menu && <MediaQuery maxWidth={MOBILE_WIDTH}>
          <div className="error-page__links">
            {data.menu.map((item, index) =>
              <Link key={index} to={item.url}>
                <img src={item.image} className="error-page__pic" alt={item.text}/>
                <span dangerouslySetInnerHTML={{__html: item.textMobile || item.text}}/>
              </Link>
            )}
          </div>
        </MediaQuery>}
      </div>
  );
}

export default withRouter(inject('store')(observer(Page)));
