import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import EvoVideo from "../EvoVideo";
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';
import TrainerSection from '../TrainerSection';

const path = process.env.REACT_APP_ASSETS_PATH;

export const OvenCasarteHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Духовой шкаф</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        {/*<h2 className="product-page__head__center__sub-title">CASOQC5QAP4GBU/<span className='br-m' />IGU</h2>*/}
        <p className="product-page__head__center__text">Классические формы, элегантный дизайн, современные <span className='br-d' />технологии.</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const OvenCasarteContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section reliability-id">
        <div className="wrap">
          <SectionHeader
            title={<>Функциональность</>}
            text={<>Широкий выбор режимов для приготовления самых разных блюд</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="f120"
              picName="61"
              title={<>Sous-vide</>}
              text={<>Низкотемпературное приготовление с сохранением <span className="d-br"/>витаминов и питательных веществ.</>}
              detailText={<>
                Приготовление под вакуумом сделает блюда мягкими и сочными
                с минимальной потерей питательных веществ. Готовка
                профессионального уровня будет доступна у вас дома.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="62"
              title={<>Специальные<br/>программы</>}
              text={<>Духовка знает, в каком режиме расстаивать тесто, печь пиццу или запекать картошку-фри.</>}
              detailText={<>
                Специальные программы приготовления - не нужно выставлять
                параметры вручную, просто выберите задачу и духовка
                поможет выполнить ее в идеальном режиме. Для пиццы,
                например, нужно установить настройку в зависимости от
                толщины основы, а для аэрогриля от типа продукта. Акваочистку
                духовка выполнит без дополнительных настроек.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="63"
              title={<>Термощуп</>}
              text={<>Полный контроль готовности блюд <span className="d-br"/>по внутренней температуре.</>}
              detailText={<>
                Приготовление свиной рульки, индейки на день благодарения,
                сочного стейка или других блюд, которые должны пропечься
                внутри до нужной температуры, для гарантированно
                хорошего результата. Стейк с кровью, значит с кровью, грудка
                индейки не сырая и не пересушенная. Ровно настолько,
                насколько нужно.</>}
            />
          </div>
        </div>
      </div>
      <EvoSection
        sectionText={<>Информация о готовности блюда у вас под рукой</>}
        assetsPathName={assetsPathName}
        productName={productName}
        videoBtn={false}
        videoBlocks={
          <div className="product-page__grid-item">
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-1"
              title="Инструкция по&nbsp;подключению"
              text="Подключение духового шкафа Casarte с&nbsp;помощью мобильного приложения&nbsp;evo"
            />
            <EvoVideo
              assetsPathName={assetsPathName}
              picName="evo-video-2"
              direction="right"
              title="Инструкция по&nbsp;управлению"
              text="Управление духовым шкафом Casarte с&nbsp;помощью мобильного приложения&nbsp;evo"
            />
          </div>
        }
      />
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>Удобство</>}
            text={<>Современные технологии на расстоянии вытянутой руки</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="middle-left"
              titleClass="large"
              picName="64"
              title={<>Плавное<br/>закрывание<br/>двери</>}
              text={<>Плавность и солидность<br/>при обращении с духовкой.</>}
              detailText={<>
                Доводчик дверцы духовки сделает закрывание плавным и мягким.
                Мы ведь ценим плавное закрывание и в других жизненных
                ситуациях. Приятное ощущение от обращения с качественной
                техникой.</>}
              video={1}
              videoInteractive={1}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section safety-id">
        <div className="wrap">
          <SectionHeader
            title={<>Без риска</>}
            text={<>Полезные функции, отвечающие за безопасность в процессе приготовления пищи</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="65"
              title={<>Безопасное<br/>приготовление</>}
              text={<>Даже если вы готовите на двух противнях одновременно.</>}
              detailText={<>
                Плавное выдвигание решетки и противней без риска
                опрокидывания благодаря телескопическим направляющим,
                которые располагаются на двух уровнях духовки.</>}
            />
          </div>
        </div>
      </div>
      <div className="product-page__section comfort-id">
        <div className="wrap">
          <SectionHeader
            title={<>Комфорт</>}
            text={<>Удобство использования благодаря отзывчивой сенсорной панели и качественным материалам</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="66"
              title={<>Информативный<br/>дисплей</>}
              text={<>Дисплей логично разделен на 3 области — температура, время и&nbsp;режимы работы.</>}
              detailText={<>
                В области индикации температуры информация не только
                об установленной температуре выбранного режима,
                но и текущей температуре и прогрессе нагрева. В области
                индикации режимов не только наглядная индикация работающих
                нагревательных элементов, но и специальные программы
                приготовления и очистки.</>}
            />
          </div>
        </div>
      </div>
      <TrainerSection />
    </>
  )
}