import './style.scss';
import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { MOBILE_WIDTH, MOBILE_WIDTH_1 } from '../../common/const';
import DeliveryModal from '../DeliveryModal';
import ServiceModal from '../ServiceModal';
import {Dropdown} from 'primereact/dropdown';
import axios from "axios";
import {getApiPath} from "../utils";
import pageData from "../../json/servicePage.json";
import menuData from "../../json/catalogItems.json";
import headerData from "../../json/header.json";
import footerData from "../../json/footer.json";
import {inject, observer} from "mobx-react";
import Recaptcha from "../utils/recaptcha";
import PageContainer from "../PageContainer";
import { scrollTo } from "../../common/utils"

const path = process.env.REACT_APP_ASSETS_PATH;

const Page = ({ store, match, location }) => {
  const recaptcha = new Recaptcha();
  const [data, setData] = useState(null);
  const [responseError, setResponseError] = useState();

  useEffect(() => {
    document.title = 'Сервис и поддержка';
  }, [])

  useEffect(() => {
    if(location.pathname.indexOf('installation') >= 0){
      setTimeout(() => {
        scrollTo('installation-id');
      }, 300);
    } else if(location.pathname.indexOf('support') >= 0){
      setTimeout(() => {
        scrollTo('support-id');
      }, 300);
    }
  }, [location.pathname])

  // Устанавливает данные страницы
  useEffect(() => {
    axios({
      url: getApiPath(match.path),
      method: 'get',
    }).then((response) => {
      setData(response.data.pageContent);
      store.setCatalogItems(response.data.menu);
      store.setAppConst(response.data.appConst);
      store.setHeader(response.data.header);
      store.setFooter(response.data.footer);
    }).catch((error) => {
      if (!store.isDev && [404, 500].includes(error?.response?.status)) {
        setResponseError(error.response);
        return;
      }
      setData(pageData);
      store.setCatalogItems(menuData);
      store.setHeader(headerData);
      store.setFooter(footerData);
    });
    return () => {
      setData(null);
      setResponseError(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.path]);

  return (
    <PageContainer responseError={responseError} data={data}>
    <div className="service-page page">
      <div className="service-page__bg-top">
        <div className="service-page__bg-bottom">
          <div className="wrap">
            {data?.pageTitle ? <h3 className="service-page__title" dangerouslySetInnerHTML={{__html: data?.pageTitle}} /> : null}
            <MediaQuery maxWidth={MOBILE_WIDTH}>
              <p className="service-page__text">Высочайший уровень сервиса – это приоритет бренда Casarte.</p>
            </MediaQuery>
            <MediaQuery minWidth={MOBILE_WIDTH_1}>
              <>{data?.pageText ? <p className="service-page__text" dangerouslySetInnerHTML={{__html: data?.pageText}} /> : null}</>
            </MediaQuery>

            <div className="service-page__planes _first">
              <div className="service-page__planes__wrap">
                <div className="service-page__planes__row">
                  <XSmallPlane
                    id={10}
                    title={<>8 (800) 234-09-53</>}
                    text={<>08:00 - 22:00 Мск</>}
                    url="tel:88002340953"
                  />
                  <XSmallPlane
                    id={13}
                    title={<>Задать вопрос</>}
                    modalId={2}
                  />
                </div>
              </div>
            </div>
            
            <div className="service-page__planes installation-id">
              <div className="service-page__planes__wrap">
                <div className="service-page__planes__row">
                  <BigPlane
                    id={1}
                    reverse={true}
                    label={<>премиальный сервис</>}
                    title={<>Установка</>}
                    text={<>Установку и&nbsp;подключение бытовой техники Casarte должны выполнять квалифицированные специалисты. Сотрудники сервисного центра могут произвести корректную установку и&nbsp;настройку приобретённой техники, проверить её работу и&nbsp;продемонстрировать функции.</>}
                  />
                </div>
                <div className="service-page__planes__row">
                  <SmallPlane
                    id={2}
                    title={<>Бесплатная установка и&nbsp;подключение</>}
                    text={<>Доверьте установку стирально-сушильной колонны специалистам Casarte.</>}
                    link="Подробнее"
                    url="/installation"
                  />
                  <SmallPlane
                    id={3}
                    title={<>Заявка на&nbsp;установку</>}
                    text={<>Выберите удобное время визита специалиста.</>}
                    link="Оставить заявку"
                    modalId={2}
                  />
                </div>
              </div>
            </div>

            <div className="service-page__planes">
              <div className="service-page__planes__wrap">
                <div className="service-page__planes__row">
                  <BigPlane
                    id={15}
                    picFormat="webp"
                    reverse={!true}
                    label={<>премиальный сервис</>}
                    title={<>Установка кондиционера</>}
                    text={<>Закажите установку кондиционера у&nbsp;наших профессионалов, чтобы быть уверенными в&nbsp;качественном результате.  Услуга действует на&nbsp;территории Москвы и&nbsp;Московской области</>}
                  />
                </div>
                <div className="service-page__planes__row">
                  <SmallPlane
                    id={16}
                    title={<>Базовая</>}
                    text={<>Выезд специалиста, установка, проверка и&nbsp;демонстрация работоспособности системы.</>}
                    link="Подробнее"
                    url="/installation/air-conditioners/basic"
                  />
                  <SmallPlane
                    id={17}
                    title={<>Стандартная</>}
                    text={<>Базовая установка плюс предоставление одного бесплатного обслуживания в&nbsp;течение 12&nbsp;месяцев.</>}
                    link="Подробнее"
                    url="/installation/air-conditioners/standart"
                  />
                  <SmallPlane
                    id={18}
                    title={<>Расширенная</>}
                    text={<>Стандартная установка плюс расширенные технические параметры и&nbsp;гарантийный срок 24&nbsp;месяца.</>}
                    link="Подробнее"
                    url="/installation/air-conditioners/extended"
                  />
                </div>
              </div>
            </div>

            <div className="service-page__planes trainer-id">
              <div className="service-page__planes__wrap">
                <div className="service-page__planes__row">
                  <div className="service-page__planes__video">
                    <video loop autoPlay playsInline muted>
                      <source src={`${path}images/service/service.mp4`} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </div>
            </div>

            <div className="service-page__planes trainer-id">
              <div className="service-page__planes__wrap">
                <div className="service-page__planes__row">
                  <BigPlane
                    id={14}
                    reverse={true}
                    label={<>премиальный сервис</>}
                    title={<>Выезд тренера</>}
                    text={<>Клиентам интернет-магазина и&nbsp;фирменного бутика Casarte доступна бесплатная услуга по&nbsp;обучению тонкостям работы с&nbsp;техникой и&nbsp;управлению через&nbsp;приложение evo. Услуга предоставляется на&nbsp;территории Москвы и&nbsp;Московской области.</>}
                  />
                </div>
              </div>
            </div>

            <div className="service-page__planes support-id">
              <div className="service-page__planes__wrap">
                <div className="service-page__planes__row">
                  <BigPlane
                    reverse={!true}
                    id={4}
                    label={<>забота о&nbsp;клиенте</>}
                    title={<>Сервисное обслуживание</>}
                    text={<>Бытовая техника Casarte – это высокотехнологичные продукты, в&nbsp;которых нашли воплощение уникальные инженерные решения.  Для обеспечения максимальной эффективности, комфорта и&nbsp;безопасности при пользовании техникой, рекомендуется своевременная очистка, замена расходных материалов и&nbsp;гигиеническая обработка.</>}
                  />
                </div>
                <div className="service-page__planes__row">
                  <SmallPlane
                    id={5}
                    title={<>365&nbsp;дней в&nbsp;году на&nbsp;связи</>}
                    text={<>Ответим на&nbsp;любой вопрос по&nbsp;эксплуатации и&nbsp;обслуживанию техники.</>}
                    link="Задать вопрос"
                    modalId={3}
                  />
                  <SmallPlane
                    id={6}
                    title={<>Заявка на&nbsp;сервисное обслуживание</>}
                    text={<>Профессиональные услуги с&nbsp;заботой о вашей технике.</>}
                    link="Оставить заявку"
                    modalId={4}
                  />
                </div>
              </div>
            </div>

            <div className="service-page__planes">
              <div className="service-page__planes__wrap">
                <div className="service-page__planes__row">
                  <BigPlane
                    id={7}
                    reverse={true}
                    label={<>все о&nbsp;технике Casarte</>}
                    title={<>Полезная информация</>}
                    text={<>Вся актуальная информация о вашей технике. Ознакомьтесь с&nbsp;инструкциями и&nbsp;будьте в&nbsp;курсе условий гарантийного обслуживания.</>}
                  />
                </div>
                <div className="service-page__planes__row">
                  <SmallPlane
                    id={8}
                    title={<>Информация о&nbsp;гарантии</>}
                    text={<>Качество — приоритет бренда Casarte, поэтому мы уверены в&nbsp;своей технике.</>}
                    link="Подробнее"
                    url="/assurance"
                  />
                  <SmallPlane
                    id={9}
                    title={<>Инструкции</>}
                    text={<>Пользуйтесь техникой Casarte с&nbsp;комфортом.</>}
                    data={data}
                  />
                </div>
              </div>
            </div>

          </div>
          <DeliveryModal />
          <ServiceModal />
        </div>
      </div>
    </div>
    </PageContainer>
  );
}

const XSmallPlane = ( props ) => {
  return (
    <div className="service-page__xsmall-plane">
      <div className="service-page__xsmall-plane__wrap">
        <div className="service-page__xsmall-plane__content">
          <img className="service-page__xsmall-plane__pic" src={`${path}images/service/${props.id}.svg`} alt="" />
          <div className="service-page__xsmall-plane__content__center">
            <p className="service-page__xsmall-plane__title">{props.title}</p>
            <p className="service-page__xsmall-plane__text">{props.text}</p>
          </div>
          <div>
            <div className="service-page__xsmall-plane__arr" />
          </div>
        </div>
        {props.url && <a href={props.url} className="service-page__xsmall-plane__btn" />}
        {props.modalId && <div className="service-page__xsmall-plane__btn" onClick={() => {
          if (props.modalId===1) {
            try{
              window.usedeskMessenger.openChat();
            } catch(e){}
          } else if(props.modalId===2) {
            window.store.setModal('contact', true);
          }
        }}/>}
      </div>
    </div>
  );
}

const BigPlane = ( props ) => {
  const picFormat = props.picFormat || "jpg";

  return (
    <div className={`service-page__big-plane${props.reverse?' _reverse':''}`}>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="service-page__big-plane__pic" style={{backgroundImage: `url(${path}images/service/${props.id}-m.${picFormat})`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="service-page__big-plane__pic" style={{backgroundImage: `url(${path}images/service/${props.id}-d.${picFormat})`}} />
      </MediaQuery>
      <div className="service-page__big-plane__content">
        <div className="service-page__big-plane__wrap">
          <p className="service-page__big-plane__label">{props.label}</p>
          <p className="service-page__big-plane__title">{props.title}</p>
          <p className="service-page__big-plane__text">{props.text}</p>
        </div>
      </div>
    </div>
  );
}

const SmallPlane = ( props ) => {
  const [instructionsValue, setInstructionsValue] = useState(undefined);

  return (
    <div className="service-page__small-plane">
      <div className="service-page__small-plane__wrap">
        <img className="service-page__small-plane__pic" src={`${path}images/service/${props.id}.svg`} alt="" />
        <p className="service-page__small-plane__title">{props.title}</p>
        <p className="service-page__small-plane__text">{props.text}</p>
        {props.link && <p className="service-page__small-plane__link">{props.link}</p>}
        {props.url && <Link to={props.url} className="service-page__small-plane__btn" />}
        {props.modalId && <div className="service-page__small-plane__btn" onClick={() => {
          if (props.modalId===1) {
            window.store.setModal('delivery', {id: 7});
          } else if(props.modalId===2) {
            window.store.setModal('service', {id: 1});
          } else if(props.modalId===3) {
            window.store.setModal('contact', true);
          } else if(props.modalId===4) {
            window.store.setModal('service', {id: 2});
          }
        }}/>}
        {
        props.id===9?
          <div className="service-page__small-plane__instructions">
            <div className="service-page__small-plane__instructions__left">
              <span className="p-float-label">
                <Dropdown
                  panelClassName="service-page__small-plane__instructions__dropdown"
                  inputId="instructions"
                  value={instructionsValue}
                  onChange={(e) => {
                    console.log(e.value);
                    setInstructionsValue(e.value);
                  }}
                  options={props.data?.instructions}
                  optionLabel="product"
                  optionValue="link"
                />
                <label htmlFor="topic">Выберите тип техники</label>
              </span>
            </div>
            {
            instructionsValue && 
              <div className="service-page__small-plane__instructions__right">
                <a href={instructionsValue} download={instructionsValue} className="service-page__small-plane__instructions__download" />
              </div>
            }
          </div>
        : 
          null
        }
      </div>
    </div>
  );
}

export default withRouter(inject('store')(observer(Page)));
