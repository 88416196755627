import './style.scss';
import { useEffect } from 'react';
import {inject, observer} from "mobx-react";
import Image from '../Image';
//import ProductPacks from '../ProductPacks';
import ProductCounter from '../ProductCounter';
import { numWithSpaces } from "../../common/utils";
import { Link } from 'react-router-dom';

const Modal = ({ store }) => {
  const name = 'productSuggest';
  const data = store.modal[name];

  const handleClose = (e) => {
    if(e.target.className.indexOf('product-suggest-modal__outer-wrap') >= 0){
      store.setModal(name, null);
    }
  }

  // TEMP -> показываем попапы допродаж по GET storyboard
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const storyboard = parseInt(params.get('storyboard'), 10);
    const productName = (storyboard === 100 || storyboard === 102) ? 'double-drum' : 'styler-casarte';// TEMP
    const isCartView = (storyboard === 102 || storyboard === 103);

    if(storyboard===100 || storyboard===101 || storyboard===102 || storyboard===103){
      store.setModal(name, {productName: productName, isCartView: isCartView})
    }
  }, []);
  // -> TEMP

  //data ? disableBodyScroll() : enableBodyScroll();
  useEffect(() => {
    if(data){
      document.body.classList.add('scroll-lock');
    }

    return () => document.body.classList.remove('scroll-lock');
  }, [data]);
  
  return (
    data ?
    <div className={'product-suggest-modal _' + data.status + ' '+data?.data?.className} onClick={(e) => handleClose(e) }>
      <div className="product-suggest-modal__outer-wrap">
          <div className="product-suggest-modal__inner-wrap">
            <div className="product-suggest-modal__body">
              <div className="product-suggest-modal__close" onClick={(e) => store.setModal(name, null) } />
              <div className="product-suggest-modal__cols">
                <div className="product-suggest-modal__col">
                  {data?.data?.productName && <Image
                    className="product-suggest-modal__pic _height"
                    desktop={`${require('./assets/'+data?.data?.productName+'/1-d-1x.webp').default}, ${require('./assets/'+data?.data?.productName+'/1-d-2x.webp').default} 2x`}
                    mobile={`${require('./assets/'+data?.data?.productName+'/1-m-2x.webp').default}`}
                    title="Аромакапсулы"
                  />}
                </div>
                <div className="product-suggest-modal__col">
                {
                  data?.data?.isCartView ? 
                  <>
                    <p className="product-suggest-modal__title">Аромакапсулы</p>
                    <p className="product-suggest-modal__text">Используйте аромакапсулы, чтобы насладиться новыми ощущениями от любимых вещей. После сушки ваше белье будет окутано нежным ароматом пеларгонии, ванили, лаванды, лимона и лемонграсса, создавая атмосферу свежести и уюта.</p>
                    {/* TODO нужен копирайт на стайлер */}
                    <p className="product-suggest-modal__text _light">Один комплект аромакапсул в подарок при покупке стиральной машины Casarte Double Drum.</p>
                    {/* <ProductPacks /> */}
                    <div className="product-suggest-modal__extra-cols _no-packs">
                      <div className="product-suggest-modal__extra-col _price">
                        <p className="product-suggest-modal__old-price">{numWithSpaces('3190')} <span className='rub'>P</span></p>
                        <p className="product-suggest-modal__price">{numWithSpaces('2990')} <span className='rub'>P</span></p>
                      </div>
                      <div className="product-suggest-modal__extra-col">
                        <ProductCounter className="_secondary" labelText="В корзину" valueText='0 шт.' />
                      </div>
                    </div>
                  </>
                  :
                  <>
                    <p className="product-suggest-modal__title">Хотите добавить аромакапсулы?</p>
                    <p className="product-suggest-modal__text">Используйте аромакапсулы, чтобы насладиться новыми ощущениями от любимых вещей. После сушки ваше белье будет окутано нежным ароматом пеларгонии, ванили, лаванды, лимона и лемонграсса, создавая атмосферу свежести и уюта.</p>
                    {/* <ProductPacks /> */}
                    <div className="product-suggest-modal__extra-cols _no-packs">
                      <div className="product-suggest-modal__extra-col">
                        <Link to="/personal/cart/" className="p-button p-component p-button-secondary p-button-transparent flex justify-content-center" onClick={(e) => store.setModal(name, null) }>Перейти в корзину</Link>
                      </div>
                      <div className="product-suggest-modal__extra-col">
                        <ProductCounter className="_secondary" valueText='0 шт.' />
                      </div>
                    </div>
                  </>
                }
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    : null
  )
}

export default inject('store')(observer(Modal));
