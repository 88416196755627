import React from 'react';
import Plane from '../Plane';
import SectionHeader from '../SectionHeader';
import EvoSection from '../EvoSection';
import {Button} from 'primereact/button';
import {scrollTo} from "../../../common/utils";
import {MOBILE_WIDTH, MOBILE_WIDTH_1} from '../../../common/const';
import MediaQuery from 'react-responsive';

const path = process.env.REACT_APP_ASSETS_PATH;

export const TrianoHead = (props) => {
  const { isBuyActive, productName } = props;

  return (
    <>
      <MediaQuery maxWidth={MOBILE_WIDTH}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-m.jpg)`}} />
      </MediaQuery>
      <MediaQuery minWidth={MOBILE_WIDTH_1}>
        <div className="main-page__slide__bg" style={{backgroundImage: `url(${path}images/products/${productName}/head-d.jpg)`}} />
      </MediaQuery>
      <div className="product-page__head__center__wrap _right">
        <p className="product-page__head__center__label">Кондиционер</p>
        <h1 className="product-page__head__center__title">Casarte</h1>
        <h2 className="product-page__head__center__sub-title">Triano</h2>
        <p className="product-page__head__center__text">Дань классике, идеальная интеграция линий, <span className='br-d' />позволяющая перенести традиционный дизайн <span className='br-d' />в эстетику дома будущего.</p>
        {isBuyActive && <Button className="p-button-primary p-button-white min-w product-page__head__center__btn" label="Купить" onClick={() => {
          scrollTo('tech-id')
        }} />}
      </div>
    </>
  )
}

export const TrianoContent = (props) => {
  const { assetsPathName, productName } = props;

  return (
    <>
      <div className="product-page__section comfort-id">
        <div className="wrap">
          <SectionHeader
            title={<>Максимальный<br/>комфорт</>}
            text={<>Простой доступ к&nbsp;управлению из&nbsp;любого места и&nbsp;сверхтихая работа благодаря усовершенствованной конструкции</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="14"
              title={<>Сверхтихая работа</>}
              text={<>охлаждение при максимуме комфорта.</>}
              detailText={<>Благодаря специальной конструкции вентилятора внутреннего блока кондиционера, его корпуса, а также использованию высококачественных современных приводов обеспечивается максимально низкий уровень шума.</>}
            />
          </div>
        </div>
      </div>
      <EvoSection assetsPathName={assetsPathName} productName={productName}/>
      <div className="product-page__section care-id">
        <div className="wrap">
          <SectionHeader
            title={<>С заботой<br/>о вашем здоровье</>}
            text={<>Функции самоочистки и&nbsp;стерилизации позволяют сохранять воздух в&nbsp;комнате&nbsp;чистым</>}
          />
          <div className="product-page__grid">
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large"
              wrapClass="bottom-left"
              titleClass="large"
              picName="5"
              title={<>Стерилизация<br/>ультрафиолетом</>}
              detailText={<>Кондиционер оснащен модулем обработки воздуха UVC LED (ультрафиолетовым излучателем). УФ
                излучатель обеззараживает воздух, убивает микробы и бактерии. Стерилизацию можно использовать в
                разных режимах работы кондиционера, например в режиме охлаждения, нагрева или вентиляции.</>}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="large"
              picName="6"
              title={<>Убивает <br/>вирусы <br/>и&nbsp;бактерии</>}
              text={<>при 56&deg;C в течение <span className="m-br"/>30 минут.</>}
              detailText={<>После завершения цикла самоочистки автоматически запускается процесс стерилизации
                внутреннего блока сплит-системы. Теплообменник принудительно осушается, и с помощью
                интеллектуального контроля температуры и регулирования частоты вращения вентилятора, температура
                внутри кондиционера поддерживается на уровне 56°С в течение 30 минут. Благодаря применению данной
                технологии вся внутренняя часть сплит-системы стерилизуется, а находящиеся внутри вирусы, микробы и
                микроорганизмы погибают.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="small"
              wrapClass="bottom-left"
              titleClass="small"
              picName="9"
              title={<>Двухсторонняя <span className="m-br"/>подача <span className="d-br"/>воздуха <span
                className="m-br"/>для&nbsp;равномерного <br/>охлаждения <span className="m-br"/>помещения</>}
              detailText={<>Воздух в помещении смешивается максимально быстро, обеспечивая равномерность
                распределения температуры.</>}
              layer={0}
            />
            <Plane
              assetsPathName={assetsPathName}
              itemClass="large i8"
              wrapClass="bottom-center"
              titleClass="large"
              picName="8"
              title={<>Инновационная<br/>экспресс-очистка</>}
              text={<>Технология Self-Clean.</>}
              detailText={<>Инновационная технология самоочистки удаляет микробы, бактерии и пыль, со временем
                накапливающиеся на поверхности теплообменника, с помощью процесса термического расширения. После
                активации функции очистки, кондиционер намораживает небольшой слой инея на теплообменнике, а затем
                нагревает теплообменник. В процессе оттаивания жидкость удаляется вместе с грязью, очищая
                теплообменник.</>}
              layer={0}
            />
          </div>
        </div>
      </div>
    </>
  )
}