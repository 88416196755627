import { pluralize } from '../components/utils';

export const DESKTOP_WIDTH = 1440;
export const MOBILE_WIDTH = 1023;
export const MOBILE_WIDTH_1 = MOBILE_WIDTH + 1;

export const CLOSED = 'closed';
export const OPENED = 'opened';
export const CLOSING = 'closing';
export const OPENING = 'opening';
export const TWEEN_TIME = 400;

export const EMAIL_REGX = /^[A-Za-z0-9а-яА-Я._-]+@([A-Za-z0-9а-яА-Я]{1,2}|[A-Za-z0-9а-яА-Я]((?!(\.\.))[A-Za-z0-9а-яА-Я.-])+[A-Za-z0-9а-яА-Я])\.[A-Za-zа-яА-Я]{2,}$/;
// eslint-disable-next-line no-useless-escape
//export const EMAIL_REGX = /^[A-Za-z0-9а-яА-Я\._-]+@([A-Za-z0-9а-яА-Я]{1,2}|[A-Za-z0-9а-яА-Я]((?!(\.\.))[A-Za-z0-9а-яА-Я.-])+[A-Za-z0-9а-яА-Я])\.[A-Za-zа-яА-Я]{2,}$/;

export const CATALOG_PRODUCT_PATH = {
    0: [
        'eletto',
        'triano',
        'multi-split'
    ],
    1: [
        'french-door'
    ],
    2: [
        'double-drum'
    ],
    3: [
        'oven-casarte'
    ],
    4: [
        'hood-casarte'
    ],
    5: [
        'twin-wine-zone'
    ],
    6: [
        'cove-casarte'
    ],
    7: [
        'dishwasher-casarte',
        'double-drawer'
    ],
}

export const CATALOG_PATH = {
    0: 'air-conditioners',
    1: 'fridges',
    2: 'washers',
    3: 'ovens',
    4: 'hoods',
    5: 'wine-coolers',
    6: 'glass-ceramic-coves',
    7: 'built-in-dishwashers',
}

export const CATALOG_NAME_INDEX = {
    'air-conditioners': 0,
    'fridges': 1,
    'washers': 2,
    'ovens': 3,
    'hoods': 4,
    'wine-coolers': 5,
    'glass-ceramic-coves': 6,
    'built-in-dishwashers': 7,
}

// export const REG_FORM_COOKIE_NAME = 'reg_form'; // Не актуально после редизайна
export const PHONE_VALUE_COOKIE_NAME = 'auth_phone';
// export const PHONE_VALUE_COOKIE_LIFETIME = 365; // Не актуально
// export const PHONE_CODE_COOKIE_NAME = 'phone_code_sent'; // @TODO: Разделить авторизацию, регистрацию и подтверждение заказа
export const AUTH_CODE_COOKIE_NAME = 'auth_code_sent';
export const REG_CODE_COOKIE_NAME = 'reg_code_sent';
export const CHECKOUT_CODE_COOKIE_NAME = 'checkout_code_sent';
export const RESTORE_VALUE_COOKIE_NAME = 'restore_value';
export const RESTORE_TYPE_COOKIE_NAME = 'restore_type';
// export const RESTORE_COOKIE_LIFETIME = 1; // Переделать аналогично PHONE_VALUE_COOKIE_LIFETIME
export const RESTORE_CODE_COOKIE_NAME = 'restore_code_sent';
export const CODE_RESEND_COOLDOWN = 300;
export const CONFIRM_CODE_LENGTH = 4;
export const PHONE_MASK = '+7 999 999-99-99';
export const PHONE_MASK_SLOT = '+7 ___ ___ __ __';
export const BIRTH_DATE_MASK = '99.99.9999';

export const ERROR_MESAGES = {
  notEngSymbols: 'Поле не должно содержать латинские буквы',
  format: 'Некорректный формат',
  required: 'Поле обязательно для заполнения',
  requiredShort: 'Обязательно',
  requiredName: 'Укажите имя получателя',
  requiredEmail: 'Укажите почтовый адрес',
  requiredPhone: 'Введите номер телефона',
  formatEmail: 'Неверный формат почтового адреса',
  phoneNotFull: 'Введите номер полностью',
  requiredStreet: 'Укажите название улицы',
  requiredBuilding: 'Укажите номер дома',
  requiredDate: 'Выберите дату доставки',
  requiredInterval: 'Выберите время доставки',
  requiredPassword: 'Введите пароль',
  passwordsMismatch: 'Пароли не совпадают',
  codeNotFull: `Код должен содержать ${CONFIRM_CODE_LENGTH} ${pluralize(CONFIRM_CODE_LENGTH, ['символ', 'символа', 'символов'])}`,
  formFormat: 'Форма заполнена некорректно',
};
