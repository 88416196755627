import './style.scss';
import {useState} from 'react';
import {inject, observer} from "mobx-react";
/*
className: 
_primary - для больших кнопок
_secondary - для маленьких кнопок
_tertiary - для мелаеньких прозрачных кнопок
*/
const ProductCounter = (props) => {
  const {store, disabled=false, disabledText='Скоро в продаже', valueText='0', labelText='Добавить', className=''} = props;
  const [counter, setCounter] = useState(0);

  const add = () => {
    setCounter(counter+1);
    // TODO обработать store
  }

  const remove = () => {
    setCounter(counter-1);
    // TODO обработать store
  }

  return (
    <div className={`product-counter ${className} ${counter > 0?'_active':''} ${disabled?'_disabled':''}`}>
      {
        counter > 0 ?
        <>
          <div className="product-counter__remove" onClick={remove} />
          <div className="product-counter__value">{valueText.replace('0', counter)}</div>
          <div className="product-counter__add" onClick={add} />
        </>
        :
        <>
          <div className="product-counter__label" onClick={add}>{disabled ? disabledText : labelText}</div>
        </>
      }
    </div>
  )
}

export default inject('store')(observer(ProductCounter));