import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import ErrorPage from "../ErrorPage";
import TechWorkPage from "../TechWorkPage";
import PageLoader from "../PageLoader";

const PageContainer = ({ responseError, data, children }) => {
  if (responseError?.status === 500) {
    return <TechWorkPage />;
  }
  if (responseError?.status === 404) {
    return <ErrorPage error={responseError?.data} />;
  }
  if (!data) {
    return <PageLoader />;
  }
  return children;
}

export default withRouter(inject('store')(observer(PageContainer)));
